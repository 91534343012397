import { EventEmitter, OnInit } from "@angular/core";
import { Observable } from "rxjs";
var VendorListComponent = /** @class */ (function () {
    function VendorListComponent() {
        this.showPricing = false;
        this.eventEmitter = new EventEmitter();
    }
    VendorListComponent.prototype.ngOnInit = function () {
    };
    VendorListComponent.prototype.selectVendor = function (v) {
        this.eventEmitter.next(v);
    };
    return VendorListComponent;
}());
export { VendorListComponent };
