import { OnInit } from "@angular/core";
import { UserService } from "../../service/user.service";
import { FileService } from "../../service/file.service";
import { ActivatedRoute } from "@angular/router";
var FolderViewerComponent = /** @class */ (function () {
    function FolderViewerComponent(route, userService, fileService) {
        this.route = route;
        this.userService = userService;
        this.fileService = fileService;
    }
    FolderViewerComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.entitytypes = new Array();
        this.entitytypes.push("project");
        this.entitytypes.push("company");
        this.entitytypes.push("user");
        this.entitytypes.push("invoice");
        this.route.params
            .subscribe(function (params) {
            _this.entityid = params['id'];
            // Check whether given type (if any) is in the entity types array
            // If not, just pick the first option as default
            if (params['type']) {
                if (_this.entitytypes.indexOf(params['type']) > -1)
                    _this.entitytype = params['type'];
                else
                    _this.entitytype = _this.entitytypes[0];
            }
            else
                _this.entitytype = _this.entitytypes[0];
            if (_this.entityid && _this.entitytype) {
                _this.entityid2view = _this.entityid;
                _this.entitytype2view = _this.entitytype;
            }
        });
    };
    FolderViewerComponent.prototype.openView = function () {
        this.entityid2view = this.entityid;
        this.entitytype2view = this.entitytype;
    };
    return FolderViewerComponent;
}());
export { FolderViewerComponent };
