import { map } from 'rxjs/operators';
import { DomSanitizer } from '@angular/platform-browser';
import { MatSnackBar } from "@angular/material/snack-bar";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { getBaseUrl } from "../../util/httpclient";
var EditorService = /** @class */ (function () {
    function EditorService(http, sanitizer, snackbar) {
        this.http = http;
        this.sanitizer = sanitizer;
        this.snackbar = snackbar;
        this.EDITOR_API = getBaseUrl() + "/api/v1/editor";
        console.debug("EditorService initializing => connects to " + this.EDITOR_API);
    }
    EditorService.prototype.getTaskFile = function (taskId, fileName) {
        return this.http.get(this.EDITOR_API + "/task/" + taskId + "/" + encodeURIComponent(fileName));
    };
    EditorService.prototype.getFinalCheckFile = function (projectId, source, target, fileName) {
        return this.http.get(this.EDITOR_API + "/finalcheck/" + projectId + "/" +
            encodeURIComponent(source + "_" + target) + "/" +
            encodeURIComponent(fileName));
    };
    EditorService.prototype.getContent = function (fileLocation, taskId) {
        var url = this.EDITOR_API + "/content/" + taskId + "?fileloc=" + encodeURIComponent(fileLocation);
        return this.http.get(url, { responseType: 'json' });
    };
    EditorService.prototype.getBestRepeatedMatch = function (fileLocation, taskId, pId, tuId, segId) {
        var url = this.EDITOR_API + "/repetition/" + taskId + "/" + tuId + "/" + segId + "?pid=" + pId + "&fileloc=" + encodeURIComponent(fileLocation);
        var obs = this.http.get(url, { responseType: 'json' });
        return obs.pipe(map(function (data) {
            if (data != null) {
                var repeatedMatch = data.segment;
                repeatedMatch.id = segId;
                repeatedMatch.score = data.score;
                repeatedMatch.origin = new Object();
                repeatedMatch.origin.file = data.file;
                repeatedMatch.origin.fileloc = data.fileloc;
                repeatedMatch.origin.tuid = data.tuid;
                repeatedMatch.origin.segid = data.segid;
                var o = Object();
                o.segment = repeatedMatch;
                o.currentTuId = tuId;
                return o;
            }
            else
                return null;
        }));
    };
    EditorService.prototype.postModifiedSegment = function (fileLocation, taskId, tuId, segId, newSegment) {
        // We do a JSON.stringify() on the segment value to escape illegal characters, such as "\", that would make the
        // parsing of the JSON fail
        // Note that the stringify() function surrounds the string with quotes (")
        var body = "{\"segment\": " + JSON.stringify(newSegment) + "}";
        var jsonObj = JSON.parse(body);
        var url = this.EDITOR_API + "/segment/" + taskId + "/" + tuId + "/" + segId + "?fileloc=" + encodeURIComponent(fileLocation);
        return this.http.post(url, jsonObj, { responseType: 'text' });
    };
    EditorService.prototype.completeTaskFile = function (fileLocation, taskId, fileName) {
        var url = this.EDITOR_API + "/task/" + taskId + "/" + encodeURIComponent(fileName) + "?fileloc=" + encodeURIComponent(fileLocation);
        return this.http.post(url, "", { responseType: 'text' });
    };
    EditorService.prototype.completeFinalCheckFile = function (fileLocation, fileGroupId, fileName, projectId, langpair) {
        var url = this.EDITOR_API + "/finalcheck/" + fileGroupId + "/" + projectId + "/" + encodeURIComponent(langpair) + "/" + encodeURIComponent(fileName);
        var params = new HttpParams().set("fileloc", fileLocation);
        return this.http.post(url, "", { responseType: "text", params: params });
    };
    EditorService.prototype.fetchMetaData = function (fileGroupId, fileName) {
        var url = this.EDITOR_API + "/metadata/" + fileGroupId + "?filename=" + encodeURIComponent(fileName);
        return this.http.get(url, { responseType: "json" });
    };
    EditorService.prototype.translateWithGoogle = function (sourceLang, targetLang, toTranslateText) {
        // Apparently not possible from here, produces the following error(s):
        // Cross-Origin Request Blocked: The Same Origin Policy disallows reading the remote resource at https://translate.googleapis.com/translate_a/single?client=gtx&sl=EN&tl=NL&dt=t&q=Figuur%20this%20is%20a%20test%20caption.
        // (Reason: missing token ‘authorization’ in CORS header ‘Access-Control-Allow-Headers’ from CORS preflight channel).[Learn More]
        // Cross-Origin Request Blocked: The Same Origin Policy disallows reading the remote resource at https://translate.googleapis.com/translate_a/single?client=gtx&sl=EN&tl=NL&dt=t&q=Figuur%20this%20is%20a%20test%20caption.
        // (Reason: CORS request did not succeed).
        // TODO: find a workaround for the above?
        var httpOptions = {
            headers: new HttpHeaders({
            // 'Access-Control-Allow-Origin': '*',
            // 'Authorization': 'authkey',
            // 'Access-Control-Allow-Headers': 'Access-Control-Allow-Origin, Authorization'
            }),
        };
        var gTransUrl = "http://translate.googleapis.com/translate_a/single?client=gtx" +
            "&sl=" + this.transformLangCode(sourceLang) +
            "&tl=" + this.transformLangCode(targetLang) +
            "&dt=t" +
            "&q=" + encodeURIComponent(toTranslateText);
        return this.http.get(gTransUrl, httpOptions);
    };
    EditorService.prototype.transformLangCode = function (langCode) {
        return langCode.substring(0, 2).toUpperCase();
    };
    return EditorService;
}());
export { EditorService };
