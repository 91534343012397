import { EventEmitter, OnInit } from "@angular/core";
import { AmazingTimePickerService } from 'amazing-time-picker';
var TimeComponent = /** @class */ (function () {
    function TimeComponent(atp) {
        this.atp = atp;
        this.checkMinimum = false;
        this.timeSelected = new EventEmitter();
        this.invalidTimeSelected = new EventEmitter();
        this.actualMinTime = undefined;
        this.tooltip = "Pick a date.";
        this.textColor = "inherit";
    }
    TimeComponent.prototype.ngOnInit = function () {
        this.timeField = makeTimeField(this.hours, this.minutes);
        this.actualMinTime = determineMinTime(this.minTime);
        this.validateTime();
    };
    TimeComponent.prototype.open = function () {
        var _this = this;
        var config = {
            theme: 'material-blue',
            preference: {
                labels: { ok: 'OK', cancel: 'Cancel' }
            }
        };
        if (this.timeField != undefined) {
            config.time = this.timeField;
        }
        /*
            if (this.minTime) {
              config.rangeTime = {
                start: this.minTime,
                end: '23:59'
              }
            }*/
        var amazingTimePicker = this.atp.open(config);
        amazingTimePicker.afterClose().subscribe(function (time) {
            _this.setTime(time);
        });
    };
    TimeComponent.prototype.setTime = function (time) {
        this.hours = this.extractHour(time);
        this.minutes = this.extractMin(time);
        this.timeField = makeTimeField(this.hours, this.minutes);
        if (this.validateTime())
            this.timeSelected.emit(time);
        else
            this.invalidTimeSelected.emit(time);
    };
    TimeComponent.prototype.validateTime = function () {
        if (this.checkTime(this.timeField)) {
            this.textColor = "inherit";
            return true;
        }
        else {
            this.textColor = "red";
            return false;
        }
    };
    TimeComponent.prototype.extractHour = function (time) {
        if (time != undefined) {
            var idx = time.indexOf(":");
            if (idx > 0) {
                var hrs = parseInt(time.substring(0, idx).trim());
                if (hrs >= 0 && hrs < 24) {
                    return hrs;
                }
            }
        }
        return null;
    };
    TimeComponent.prototype.extractMin = function (time) {
        if (time != undefined) {
            var idx = time.indexOf(":");
            if (idx >= 0 && (idx + 1) < time.length) {
                var min = parseInt(time.substring(idx + 1).trim());
                if (min >= 0 && min < 60) {
                    return min;
                }
            }
        }
        return null;
    };
    TimeComponent.prototype.checkTime = function (time) {
        if (this.checkMinimum && this.actualMinTime != undefined) {
            if (time < this.actualMinTime) {
                this.tooltip = "Time cannot be earlier than " + this.actualMinTime + ".";
                return false;
            }
        }
        this.tooltip = "Pick a time.";
        return true;
    };
    return TimeComponent;
}());
export { TimeComponent };
export function makeTimeField(hrs, min) {
    var time;
    if (hrs != undefined) {
        if (!(hrs instanceof Number))
            hrs = parseInt(hrs);
        time = hrs.toLocaleString(undefined, { minimumIntegerDigits: 2 });
    }
    else
        time = "23";
    time += ":";
    if (min != undefined) {
        if (!(min instanceof Number))
            min = parseInt(min);
        time += min.toLocaleString(undefined, { minimumIntegerDigits: 2 });
    }
    else
        time += "59";
    return time;
}
export function determineMinTime(minTime) {
    if (minTime == undefined)
        return undefined;
    var curMinTime;
    // If the minimum time is 'now', determine what 'now' is
    if (minTime != undefined && minTime.startsWith("now", 0)) {
        var now = new Date();
        var offsetHrs = 0;
        // Check whether 'now' needs to be offset by x hours
        if (minTime.startsWith("now+", 0)) {
            try {
                offsetHrs = parseInt(minTime.substring("now+".length));
            }
            catch (e) {
            }
        }
        else if (minTime.startsWith("now-", 0)) {
            try {
                offsetHrs = (parseInt(minTime.substring("now-".length)) * -1);
            }
            catch (e) {
            }
        }
        curMinTime = makeTimeField((now.getHours() + offsetHrs), now.getMinutes());
    }
    else
        curMinTime = minTime;
    return curMinTime;
}
