import { map, mergeMap } from 'rxjs/operators';
import { AfterViewInit, ApplicationRef } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { UserService } from "../service/user.service";
import { Router } from "@angular/router";
import { FileService } from "../service/file.service";
import { JoinDialogOutcome, JoinType } from "./user.join.model";
var UserJoinDialog = /** @class */ (function () {
    function UserJoinDialog(dialogRef, data, userservice, fileService, router, applicationRef) {
        this.dialogRef = dialogRef;
        this.data = data;
        this.userservice = userservice;
        this.fileService = fileService;
        this.router = router;
        this.applicationRef = applicationRef;
        this.user = {
            email: undefined,
            firstname: undefined,
            lastname: undefined,
            companytoken: undefined,
            grouptoken: undefined,
        };
        this.joining = false;
        this.auth2Joining = false;
        this.callback = undefined;
        dialogRef.disableClose = true;
    }
    UserJoinDialog.prototype.ngOnInit = function () {
        console.debug("Initializing join dialog", this.data);
        this.user.companytoken = this.data.companyToken;
        this.user.grouptoken = this.data.groupToken;
        this.callback = this.data.callback;
    };
    UserJoinDialog.prototype.ngAfterViewInit = function () {
        this.googleInit();
    };
    UserJoinDialog.prototype.hasToken = function () {
        return this.user.companytoken != null || this.user.grouptoken != null;
    };
    UserJoinDialog.prototype.joinWithLinkedIn = function () {
        var _this = this;
        this.auth2Joining = true;
        var liWindow = this.userservice.openLinkedInAuth();
        onstorage = function (event) {
            if (event.key == "linkedInAuthCode") {
                _this.userservice.authLinkedIn(event.newValue).pipe(map(function (e) {
                    localStorage.setItem("linkedInAccessToken", JSON.stringify(e));
                    return e;
                }), mergeMap(function (token) {
                    return _this.userservice.linkedInProfile(token["access_token"]);
                }), mergeMap(function (profile) {
                    return _this.userservice.joinWithAuth2(profile);
                }))
                    .subscribe(function (signInData) {
                    _this.close({ result: JoinDialogOutcome.SUCCESS, join: JoinType.LINKEDIN, user: signInData });
                    if (_this.callback) {
                        _this.router.navigate([_this.callback]);
                    }
                }, function (error) {
                    _this.close({ result: JoinDialogOutcome.ERROR, reason: error });
                }, function () { return _this.auth2Joining = false; });
            }
        };
    };
    UserJoinDialog.prototype.googleInit = function () {
        var _this = this;
        gapi.load('auth2', function () {
            _this.auth2 = gapi.auth2.init({
                client_id: '49647298930-jjon9r6naht63oq9mc9b9cc9avm5ttt4.apps.googleusercontent.com',
                cookiepolicy: 'single_host_origin',
                scope: 'profile email'
            });
            var googleBtn = document.getElementById('googleBtn');
            if (googleBtn != null)
                _this.attachSignIn(googleBtn);
        });
    };
    UserJoinDialog.prototype.attachSignIn = function (element) {
        var _this = this;
        element.onclick = function () {
            _this.auth2Joining = true;
        };
        this.auth2.attachClickHandler(element, {}, function (googleUser) {
            var profile = googleUser.getBasicProfile();
            //List of fields we want to retrieve from linked in
            var userData = _this.mapGoogleToUser(googleUser);
            _this.userservice.joinWithAuth2(userData).subscribe(function (signInData) {
                //TODO Try to store the picture as well
                // try {
                //   this.userservice.uploadPictureFromLinkedIn(googleUser.getBasicProfile().getImageUrl(), userData.id);
                // } catch (e) {
                //   //Do nothing if we were not able to store the picture
                // }
                _this.close({ result: JoinDialogOutcome.SUCCESS, join: JoinType.GOOGLE, user: signInData });
                if (_this.callback) {
                    _this.router.navigate([_this.callback]);
                }
                _this.applicationRef.tick();
            }, function (error) {
                _this.close({ result: JoinDialogOutcome.ERROR, reason: error });
                _this.applicationRef.tick();
            }, function () {
                _this.auth2Joining = false;
            });
        });
    };
    UserJoinDialog.prototype.joinViaMail = function () {
        var _this = this;
        this.joining = true;
        var user = {
            mail: this.user.email,
            firstName: this.user.firstname,
            lastName: this.user.lastname,
            callbackUrl: this.callback,
            companytoken: this.user.companytoken,
            grouptoken: this.user.grouptoken,
        };
        this.userservice.joinWithMail(user)
            .subscribe(function (r) {
            try {
                var user_1 = JSON.parse(r);
                _this.joining = false;
                _this.close({ result: JoinDialogOutcome.SUCCESS, join: JoinType.MAIL, user: user_1 });
            }
            catch (e) {
                console.error('Error joining user with mail: ', e);
                _this.close({ result: JoinDialogOutcome.ERROR, reason: e });
            }
        });
    };
    UserJoinDialog.prototype.signInPopup = function () {
        this.close({ result: JoinDialogOutcome.SIGN_IN });
    };
    UserJoinDialog.prototype.close = function (result) {
        if (result === void 0) { result = { result: JoinDialogOutcome.CANCEL }; }
        this.dialogRef.close(result);
    };
    UserJoinDialog.prototype.mapLinkedInToUser = function (linkedInData) {
        //'id', 'first-name', 'last-name', 'email-address', 'headline', 'industry', 'summary', 'positions', 'public-profile-url'
        return {
            id: linkedInData.id,
            firstName: linkedInData.firstName,
            lastName: linkedInData.lastName,
            email: linkedInData.emailAddress,
            headline: linkedInData.headline,
            industry: linkedInData.industry,
            description: linkedInData.summary,
            positions: linkedInData.positions,
            linkedInProfileUrl: linkedInData.publiceProfileUrl
        };
    };
    UserJoinDialog.prototype.mapGoogleToUser = function (googleUser) {
        var profile = googleUser.getBasicProfile();
        return {
            googleToken: googleUser.getAuthResponse().id_token,
            id: profile.getId(),
            firstName: profile.getName(),
            email: profile.getEmail()
        };
    };
    return UserJoinDialog;
}());
export { UserJoinDialog };
