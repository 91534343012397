import * as tslib_1 from "tslib";
var Job = /** @class */ (function () {
    function Job() {
        this.tasktypes = ["Translation"];
    }
    Job.prototype.getLangPair = function () {
        return this.source + "_" + this.target;
    };
    return Job;
}());
export { Job };
var TaskSpec = /** @class */ (function () {
    function TaskSpec() {
    }
    return TaskSpec;
}());
export { TaskSpec };
var Task = /** @class */ (function () {
    function Task() {
        this.jobs = [];
    }
    return Task;
}());
export { Task };
var TaskFile = /** @class */ (function () {
    function TaskFile() {
    }
    return TaskFile;
}());
export { TaskFile };
var Pricing = /** @class */ (function () {
    function Pricing() {
    }
    Pricing.prototype.mtSavings = function () {
        // Subtract the cost of MT from the savings to know what you really save in total thanks to MT
        if (this.mtsaving)
            return this.mtsaving - this.mt;
        return null;
    };
    Pricing.prototype.mtCost = function () {
        // Get the MT savings (if any) and revert them
        // If no savings, use the base MT cost
        var mtSavings = this.mtSavings();
        if (mtSavings)
            return mtSavings * -1;
        else
            return this.mt;
    };
    Pricing.prototype.totalSavings = function () {
        var mtSavings = this.mtSavings();
        return (this.tmsaving != undefined ? this.tmsaving : 0) + (mtSavings != undefined ? mtSavings : 0);
    };
    Pricing.prototype.totalExclFee = function () {
        return (this.translation != undefined ? this.translation : 0) + (this.revision != undefined ? this.revision : 0);
    };
    Pricing.prototype.total = function (inclCredit) {
        if (inclCredit === void 0) { inclCredit = true; }
        var creditSaving = 0;
        if (inclCredit && this.credit != null && this.credit.saving != null) {
            creditSaving = this.credit.saving;
        }
        return this.translation + this.revision + this.mt + this.lilo - creditSaving;
    };
    Pricing.prototype.vatTotal = function () {
        if (this.vatRate)
            return this.total() * this.vatRate;
        else
            return 0;
    };
    Pricing.prototype.totalIncVat = function () {
        return this.total() + this.vatTotal();
    };
    return Pricing;
}());
export { Pricing };
var DetailedPricing = /** @class */ (function (_super) {
    tslib_1.__extends(DetailedPricing, _super);
    function DetailedPricing() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    DetailedPricing.prototype.totalWithTasksOnly = function () {
        var _this = this;
        if (this.langPairDetails != undefined) {
            return Object.keys(this.langPairDetails)
                .map(function (v, i, a) { return v; })
                .map(function (v) { return _this.langPairDetails[v]; })
                .map(function (p) { return p; })
                .filter(function (p) { return p.taskCount > 0; })
                .map(function (p) { return p.total(); })
                .reduce(function (p1, p2) { return p1 + p2; }, 0);
        }
        return this.taskCount > 0 ? this.total() : 0;
    };
    DetailedPricing.prototype.totalInclVATWithTaskOnly = function () {
        return this.totalWithTasksOnly() * (this.vatRate + 1);
    };
    return DetailedPricing;
}(Pricing));
export { DetailedPricing };
