import { UserService } from "../service/user.service";
import { MatSnackBar } from "@angular/material/snack-bar";
var AvailabilitySettingComponent = /** @class */ (function () {
    function AvailabilitySettingComponent(snackbar, userService) {
        this.snackbar = snackbar;
        this.userService = userService;
        this.editMode = "none";
    }
    AvailabilitySettingComponent.prototype.ngOnInit = function () {
    };
    AvailabilitySettingComponent.prototype.addUnavailability = function (unavailability) {
        if (!this.user.unavailabilities)
            this.user.unavailabilities = [];
        this.user.unavailabilities.push(unavailability);
        this.saveUnavailabilities();
    };
    AvailabilitySettingComponent.prototype.remove = function (unavailability) {
        var index = this.user.unavailabilities.indexOf(unavailability, 0);
        if (index > -1)
            this.user.unavailabilities.splice(index, 1);
    };
    AvailabilitySettingComponent.prototype.saveUnavailabilities = function () {
        var _this = this;
        this.setEditMode("none");
        this.userService.saveUnavailabilities(this.user).subscribe(function (next) {
            _this.snackbar.open("Unavailabilities saved", '', {
                duration: 2000
            });
        }, function (error) { return console.error("Error saving unavailabilities for " + _this.user.id, error); });
    };
    AvailabilitySettingComponent.prototype.sortedUnavailabilities = function () {
        function isFutureUnavailability(a) {
            if (a && a.to) {
                return new Date(a.to).getTime() >= new Date().getTime();
            }
            return false;
        }
        if (this.user && this.user.unavailabilities) {
            return this.user.unavailabilities
                .filter(function (a) { return isFutureUnavailability(a); })
                .sort(function (a, b) {
                return a.from > b.from;
            });
        }
        return;
    };
    AvailabilitySettingComponent.prototype.setEditMode = function (mode) {
        this.editMode = mode;
    };
    AvailabilitySettingComponent.prototype.isEditMode = function () {
        return this.editMode == "edit";
    };
    AvailabilitySettingComponent.prototype.isAddMode = function () {
        return this.editMode == "add";
    };
    return AvailabilitySettingComponent;
}());
export { AvailabilitySettingComponent };
