import { AfterViewInit, ElementRef, EventEmitter, OnChanges, OnDestroy, OnInit, QueryList, SimpleChanges } from "@angular/core";
import { Observable } from "rxjs";
import { debounceTime, filter, switchMap, tap } from "rxjs/operators";
import { PricingService } from "../../service/pricing.services";
import { TaskSpec } from "../../dto/job";
import { UserService } from '../../service/user.service';
import { isTranslationActivity } from "../../util/jobutil";
import { SubscriptionHandler } from '../../util/subscription.handler';
var TranslatorListComponent = /** @class */ (function () {
    function TranslatorListComponent(pricingService, userService) {
        this.pricingService = pricingService;
        this.userService = userService;
        this.translators = undefined;
        this.selected = undefined;
        this.loadPrices = true;
        this.expanded = false;
        this.isQuote = false;
        this.onSelectionChange = new EventEmitter();
        this.onSelectedPriceChange = new EventEmitter();
        this.onPricesRecalc = new EventEmitter();
        this.priceCalcEmitter = new EventEmitter();
        this.scrollOffset = 0;
        this.rightScrollEnabled = false;
        this.leftScrollEnabled = false;
        this.initialized = false;
        this.subscriptionHandler = new SubscriptionHandler(this);
        this.userEmail = undefined;
        this.contactRequestResult = undefined;
    }
    TranslatorListComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.createCalcListPriceObs();
        this.calcListPrices();
        var priceFeedSubscription = this.pricingService.enrollForProjectAndLangPair(this.projectId, this.langpair.source + "_" + this.langpair.target)
            .filter(function (m) {
            // No need to recalculate the individual translator prices if a task was created or canceled
            return m.action != null && m.action !== "TASK_CREATE" && m.action !== "TASK_CANCEL";
        })
            .pipe(tap(function () { return _this.calcListPrices(); }))
            .subscribe(function (a) {
        });
        this.subscriptionHandler.push(priceFeedSubscription);
        this.initialized = true;
    };
    TranslatorListComponent.prototype.ngOnDestroy = function () {
    };
    TranslatorListComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        this.setScrollElement(this.translatorCardScroller.first);
        // The list and scroller @ViewChild element references were not always already initialized at this point (due to
        // the *ngIf clauses in the HTML); to work around this, we use @ViewChildren and subscribe to changes, so we
        // know when they're "ready"
        var scrollSubsription = this.translatorCardScroller.changes.subscribe(function (tcs) {
            _this.setScrollElement(tcs.first);
        });
        this.subscriptionHandler.push(scrollSubsription);
    };
    TranslatorListComponent.prototype.setScrollElement = function (scrollElement) {
        var _this = this;
        this.scroller = scrollElement;
        if (this.scroller != undefined)
            // Calculating the scroll sizes is wrapped in a setTimeout() function, to prevent the following error:
            // Error: "ExpressionChangedAfterItHasBeenCheckedError: Expression has changed after it was checked. Previous value: 'ngIf: false'. Current value: 'ngIf: true'."
            setTimeout(function () {
                _this.calcScrollWidths();
            });
    };
    TranslatorListComponent.prototype.ngOnChanges = function (changes) {
        // We added an "initialized" flag here to prevent the prices to be calculated twice
        // What happens otherwise is that ngOnChange is executed BEFORE ngOnInit on initialization
        // No need to recalculate the prices if the content of the arrays is the same
        if (this.initialized && changes.translators != null
            && !this.sameTranslators(changes.translators.previousValue, changes.translators.currentValue)) {
            this.translators = changes.translators.currentValue;
            this.calcListPrices();
        }
    };
    TranslatorListComponent.prototype.sameTranslators = function (oldTranslatorsList, newTranslatorsList) {
        if (oldTranslatorsList == null) {
            return newTranslatorsList == null;
        }
        return JSON.stringify(oldTranslatorsList.map(function (t) { return t.id; })) === JSON.stringify(newTranslatorsList.map(function (t) { return t.id; }));
    };
    TranslatorListComponent.prototype.createCalcListPriceObs = function () {
        var _this = this;
        var taskSpec = this.createTaskSpec();
        var debounceTimer = 10;
        var priceCalculateSubscription = this.priceCalcEmitter
            .pipe(tap(function () {
            if (_this.translators != null) {
                _this.translators.forEach(function (t) {
                    t['price'] = undefined;
                    t['serviceFee'] = undefined;
                    t['mtCost'] = undefined;
                });
            }
        }), debounceTime(debounceTimer), tap(function () { return debounceTimer = 500; }), filter(function () { return _this.translators != null && _this.translators.length > 0; }), tap(function () {
            if (isTranslationActivity(_this.activity)) {
                _this.pricingService.getServiceFeeAndMTCost(taskSpec.projectId, _this.langpair)
                    .pipe(tap(function (c) {
                    _this.translators.forEach(function (t) {
                        var oldMtCost = t['mtcost'];
                        var oldServiceFee = t['serviceFee'];
                        t['serviceFee'] = c['serviceFee'];
                        t['mtCost'] = c['mtCost'];
                        if (t['serviceFee'] !== oldServiceFee || t['mtCost'] !== oldMtCost) {
                            _this.onSelectedPriceChange.emit(t);
                        }
                    });
                })).subscribe(function () {
                    _this.onPricesRecalc.emit('Recalculated');
                }, function (error) {
                    return console.error('Error calculating translator prices', error);
                }, function () {
                    // Never reaches the 'complete' function, because the observable never completes
                });
            }
        }), switchMap(function () {
            // Because the first 3 translators are shown first, we first only calculate those (+1 extra, just in case)
            // In case there are more than 4, we calculate them right after the first translators
            // This is done to speed up the interface
            var firstTranslators = _this.pricingService.getPriceForTaskSpecAndVendors(taskSpec, _this.translators.slice(0, 4))
                .flatMap(function (p) { return p; })
                .map(function (price) {
                _this.setTranslatorPrice(_this.translators, price['translatorId'], price['price']);
            });
            var restTranslators = Observable.create();
            if (_this.translators.length > 4) {
                var i, j, chunk = 25;
                for (i = 4, j = _this.translators.length; i < j; i += chunk) {
                    var chunks = _this.pricingService.getPriceForTaskSpecAndVendors(taskSpec, _this.translators.slice(i, i + chunk))
                        .flatMap(function (p) { return p; })
                        .map(function (price) {
                        _this.setTranslatorPrice(_this.translators, price['translatorId'], price['price']);
                    });
                    restTranslators = restTranslators.merge(chunks);
                }
            }
            return firstTranslators
                .merge(restTranslators);
        }))
            .subscribe(function () {
            _this.onPricesRecalc.emit('Recalculated');
        }, function (error) {
            return console.error("Error calculating translator prices", error);
        }, function () {
            // Never reaches the 'complete' function, because the observable never completes
        });
        this.subscriptionHandler.push(priceCalculateSubscription);
    };
    TranslatorListComponent.prototype.calcListPrices = function () {
        if (this.loadPrices) {
            this.priceCalcEmitter.emit('go');
        }
    };
    TranslatorListComponent.prototype.setTranslatorPrice = function (translators, translatorId, price) {
        var _this = this;
        if (translators === undefined || this.translators.length === 0) {
            return;
        }
        translators
            .filter(function (translator) { return translator.id === translatorId; })
            .forEach(function (translator) {
            var oldPrice = translator['price'];
            translator['price'] = price;
            // Emit an event when the price of the selected translator changes
            if (_this.selected != null && translator.id === _this.selected) {
                if (price !== oldPrice) {
                    _this.onSelectedPriceChange.emit(translator);
                }
            }
        });
    };
    TranslatorListComponent.prototype.onTranslatorSelect = function (selectedTranslator) {
        this.selected = selectedTranslator !== undefined ? selectedTranslator.id : undefined;
        this.onSelectionChange.next(selectedTranslator);
    };
    TranslatorListComponent.prototype.calcScrollWidths = function () {
        if (this.initialized && this.translatorCardList != undefined) {
            this.listViewWidth = this.translatorCardList.nativeElement.clientWidth;
            if (this.scroller != undefined) {
                this.listScrollWidth = this.scroller.nativeElement.clientWidth;
                this.calcScrollArrows();
            }
        }
    };
    TranslatorListComponent.prototype.calcScrollArrows = function () {
        this.leftScrollEnabled = this.scrollOffset > 0;
        this.rightScrollEnabled = this.scrollOffset + this.listViewWidth < this.listScrollWidth;
    };
    TranslatorListComponent.prototype.getTransX = function () {
        return this.scrollOffset * -1;
    };
    TranslatorListComponent.prototype.scrollRight = function () {
        var temp = this.scrollOffset + this.listViewWidth - 50;
        if (temp > (this.listScrollWidth - this.listViewWidth))
            this.scrollOffset = this.listScrollWidth - this.listViewWidth;
        else
            this.scrollOffset = temp;
        this.calcScrollArrows();
    };
    TranslatorListComponent.prototype.scrollLeft = function () {
        var temp = this.scrollOffset - this.listViewWidth + 50;
        if (temp < 0)
            this.scrollOffset = 0;
        else
            this.scrollOffset = temp;
        this.calcScrollArrows();
    };
    TranslatorListComponent.prototype.onSubmitContactRequest = function () {
        var _this = this;
        this.userService.sendContactRequest(this.userEmail, this.createTaskSpec(), this.userService.isLoggedIn() ? this.userService.getCurrentUser().id : undefined).subscribe(function (v) {
            _this.contactRequestResult = "Great! We received your query and will get back to you as soon as possible.";
        }, function (error) {
            console.error("Error sending contact request", error);
            _this.contactRequestResult = "An error occurred sending your request. " +
                "Please try again and, if the problem persists, contact us directly at info@lilo.global.";
        });
    };
    TranslatorListComponent.prototype.createTaskSpec = function () {
        var taskSpec = new TaskSpec();
        taskSpec.activity = this.activity;
        taskSpec.service = this.service;
        taskSpec.projectId = this.projectId;
        taskSpec.sourcelanguage = this.langpair.source;
        taskSpec.targetlanguage = this.langpair.target;
        return taskSpec;
    };
    TranslatorListComponent.prototype.onListResize = function () {
        // Re-calculate scroll widths when the screen is resized
        this.calcScrollWidths();
    };
    return TranslatorListComponent;
}());
export { TranslatorListComponent };
