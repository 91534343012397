import { map, switchMap } from 'rxjs/operators';
import { ActivatedRoute, Router } from "@angular/router";
import { ProjectService } from "../service/project.service";
import { FileService } from "../service/file.service";
import { extractSourceLang, extractTargetLang } from "../util/jobutil";
import { EditorService } from "../editor/service/editor.service";
import { HelpDialogComponent } from "../util/help.dialog.component";
import { MatDialog } from "@angular/material/dialog";
import { HttpClient } from "@angular/common/http";
var TransCheckComponent = /** @class */ (function () {
    function TransCheckComponent(projectService, fileService, editorService, route, router, dialog, http) {
        this.projectService = projectService;
        this.fileService = fileService;
        this.editorService = editorService;
        this.route = route;
        this.router = router;
        this.dialog = dialog;
        this.http = http;
        this.pId = undefined;
        this.source = undefined;
        this.target = undefined;
        this.project = undefined;
        this.sourcefiles = undefined;
        this.selectedFile = undefined;
    }
    TransCheckComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.route.params.pipe(switchMap(function (params) {
            _this.pId = params['id'];
            return _this.projectService.getProjectBaseInfo(params['id']);
        }))
            .subscribe(function (project) {
            _this.setProject(project);
        });
        this.route.params.pipe(map(function (params) {
            return params['langPair'];
        }), map(function (lp) {
            return { source: extractSourceLang(lp), target: extractTargetLang(lp) };
        }))
            .subscribe(function (lp) {
            _this.source = lp.source;
            _this.target = lp.target;
        });
    };
    TransCheckComponent.prototype.setProject = function (project) {
        var _this = this;
        this.project = project;
        this.sourcefiles = null;
        //only show the files which have
        this.fileService.fetchAvailableFinals(this.pId, this.source + "_" + this.target).pipe(map(function (availableFiles) {
            return availableFiles;
        }), map(function (availableFiles) {
            return _this.project.files.filter(function (f) {
                return availableFiles.indexOf(f.name) >= 0;
            });
        }))
            .subscribe(function (files) {
            _this.initSourceFiles(files);
        });
    };
    TransCheckComponent.prototype.initSourceFiles = function (files) {
        var _this = this;
        this.sourcefiles = files;
        if (this.sourcefiles.length > 0)
            this.selectedFile = this.sourcefiles[0].name;
        this.sourcefiles
            .forEach(function (f) {
            var fileGroupId = _this.pId + "_" + _this.source + "_" + _this.target + "_FDC";
            _this.editorService.fetchMetaData(fileGroupId, f.name)
                .subscribe(function (i) {
                f.metaData = i;
            });
        });
    };
    TransCheckComponent.prototype.openEditor = function (fileName) {
        this.selectedFile = fileName;
    };
    TransCheckComponent.prototype.finalized = function (fileName) {
        this.selectedFile = undefined;
        var sourceFile = this.findSourceFile(fileName);
        if (sourceFile != undefined) {
            if (sourceFile.metaData == undefined)
                sourceFile.metaData = { 'saved': Date.now() };
            else
                sourceFile.metaData.saved = Date.now();
        }
    };
    TransCheckComponent.prototype.changes = function (fileName) {
        var sourceFile = this.findSourceFile(fileName);
        if (sourceFile != undefined) {
            if (sourceFile.metaData == undefined)
                sourceFile.metaData = { 'change': Date.now() };
            else
                sourceFile.metaData.change = Date.now();
        }
    };
    TransCheckComponent.prototype.hasUnsavedChanges = function (file) {
        if (file.metaData != undefined && file.metaData.change != undefined) {
            return file.metaData.change >= file.metaData.saved;
        }
        return false;
    };
    TransCheckComponent.prototype.findSourceFile = function (fileName) {
        return this.sourcefiles.find(function (f) { return f.name == fileName; });
    };
    TransCheckComponent.prototype.openHelp = function () {
        var _this = this;
        this.http.get('assets/help/transcheck-help.html', { responseType: 'text' })
            .subscribe(function (helptext) {
            var dialogRef = _this.dialog.open(HelpDialogComponent, {
                width: '700px',
                height: '500px',
                data: { helpTitle: "Why and how should you do translation checking?", helpHtmlContent: helptext }
            });
        });
    };
    return TransCheckComponent;
}());
export { TransCheckComponent };
