import { filter, map, publish } from 'rxjs/operators';
import { Observable } from "rxjs";
import { MatSnackBar } from "@angular/material/snack-bar";
import { IntervalObservable } from "rxjs/observable/IntervalObservable";
import { getBaseSocketUrl } from "../util/httpclient";
import { environment } from '../../environments/environment';
var AnalysisService = /** @class */ (function () {
    function AnalysisService(snackbar) {
        this.snackbar = snackbar;
        this.ANALYSES_SOCKET_URL = getBaseSocketUrl() + "/analysis/feed";
        console.debug("AnalysisService initializing => connects to " + this.ANALYSES_SOCKET_URL);
    }
    AnalysisService.prototype.initAnalysesFeed = function () {
        var _this = this;
        this.ws = Observable.webSocket(this.ANALYSES_SOCKET_URL);
        // Keep the socket alive (every 4 minutes, server timeout is set to 5 minutes)
        IntervalObservable.create(30000).subscribe(function (o) {
            return _this.ws.next({ 'action': 'keepalive' });
        });
        this.messages = this.makeHot(this.ws).pipe(filter(function (m) { return m != null; }));
        // this.messages.subscribe(msg => console.log("> Received " + JSON.stringify(msg) + " @" + this.ANALYSES_SOCKET_URL),
        //   error => console.error(error));
        // Subscribe for the snackbar
        if (environment.isDebug) {
            this.messages.pipe(map(function (m) { return m.data; }), map(function (m) {
                return m.type + "= " + m.value;
            }))
                .subscribe(function (v) { return _this.snackbar.open(v, '', {
                duration: 2000
            }); });
        }
    };
    //To know the difference between a cold and a hot observable check https://angular-2-training-book.rangle.io/handout/observables/cold_vs_hot_observables.html
    // or here : https://medium.com/@benlesh/hot-vs-cold-observables-f8094ed53339
    //Not really sure if it is needed to use a hot observable, but it does give more of a live messaging, also found other examples which were much more complicated, might have missed something there.
    AnalysisService.prototype.makeHot = function (cold) {
        var obs = cold.pipe(publish());
        obs.connect();
        return obs;
    };
    /**
     * Enrolling will update the websocket-sender so that it will send messages to your session on the objects you have enrolled in.
     * Once enrolled, an observable is created to filter out the messages for which you have enrolled but are not for the given object (project file in this case).
     * All the analyses that is done on the file with the given name and the project will be published in the observable
     */
    AnalysisService.prototype.enrollForFile = function (pId, file) {
        if (!this.ws || this.ws.closed)
            this.initAnalysesFeed();
        //To enroll you have to send a message to the socket with the action "enroll" and to which objects you want to enroll
        this.ws.next({ 'action': 'enroll', 'pid': pId, 'filename': file });
        //The JSON structure of the message normally has the objects parameters to identify and a "data" object which contains the actual information
        //E.g. {"pid":"10", "filename":"damocles.docx", "data":{"type":"sourcelang", "value":"en"}}
        return this.messages.pipe(filter(function (msg) {
            return msg.filename == file && msg.pid == pId;
        }), map(function (msg) { return msg.data; }));
    };
    return AnalysisService;
}());
export { AnalysisService };
