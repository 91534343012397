import { FileService } from "../../service/file.service";
var TargetFileComponent = /** @class */ (function () {
    function TargetFileComponent(fileService) {
        this.fileService = fileService;
    }
    TargetFileComponent.prototype.ngOnInit = function () {
    };
    return TargetFileComponent;
}());
export { TargetFileComponent };
