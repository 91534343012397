import { OnInit } from "@angular/core";
import { getProjectStatus } from "../../util/jobutil";
var ProjectCardTooltipComponent = /** @class */ (function () {
    function ProjectCardTooltipComponent() {
    }
    ProjectCardTooltipComponent.prototype.ngOnInit = function () {
        this.title = "UNKNOWN";
        this.initData();
    };
    Object.defineProperty(ProjectCardTooltipComponent.prototype, "data", {
        set: function (p) {
            this.project = p;
            this.initData();
        },
        enumerable: true,
        configurable: true
    });
    ProjectCardTooltipComponent.prototype.initData = function () {
        if (this.project) {
            this.title = this.project.title;
            this.startedOn = this.getFirstStartDate();
            if (this.project.startdate && this.startedOn !== this.project.startdate)
                this.lastUpdatedOn = this.project.startdate;
            this.dueOn = this.project.duedate;
            this.canceledOn = this.project.canceled;
            this.status = getProjectStatus(this.project);
        }
    };
    ProjectCardTooltipComponent.prototype.getFirstStartDate = function () {
        var firstDate;
        if (this.project.checkoutarchs && this.project.checkoutarchs.length > 0) {
            this.project.checkoutarchs.forEach(function (v) {
                if (!firstDate || (v.startdate && v.startdate < firstDate))
                    firstDate = v.startdate;
            });
        }
        if (firstDate)
            return firstDate;
        else
            return this.project.startdate;
    };
    return ProjectCardTooltipComponent;
}());
export { ProjectCardTooltipComponent };
