import { EventEmitter } from "@angular/core";
var PreUploadFileComponent = /** @class */ (function () {
    function PreUploadFileComponent() {
        this.file = undefined;
        this.removeEmitter = new EventEmitter();
    }
    PreUploadFileComponent.prototype.emitFileRemove = function () {
        this.removeEmitter.emit(this.file);
    };
    return PreUploadFileComponent;
}());
export { PreUploadFileComponent };
