import * as tslib_1 from "tslib";
import { debounceTime, map } from 'rxjs/operators';
import { ElementRef, EventEmitter, QueryList } from "@angular/core";
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Subject } from 'rxjs';
import { FileService } from "../service/file.service";
import { EditorService } from "./service/editor.service";
import { EditorRow } from "./dto/transunit";
import { HelpDialogComponent } from "../util/help.dialog.component";
import { isNoText, isRef, segContentToText, textToSegContent, toSymbol, trimSegment, visualizeFormattingMarks } from "./util/segmanipulator";
import { EditorSettingsComponent } from "./editor-setting.component";
import { environment } from "../../environments/environment";
var EditorComponent = /** @class */ (function () {
    function EditorComponent(editorService, dialog, fileService) {
        var _this = this;
        this.editorService = editorService;
        this.dialog = dialog;
        this.fileService = fileService;
        this.editable = false;
        this.changeEmitter = new EventEmitter();
        this.dataSource = new MatTableDataSource();
        this.userInput = new Subject();
        this.settings = new Settings();
        this.noTextCount = 0;
        //View settings
        this.displayedColumns = ['id', 'source', 'target', 'status'];
        this.inlayoutview = false;
        this.views = ['Layout, source & target', 'Layout & target', 'Source & target'];
        this.currentView = this.views[2];
        this.isDebug = environment.isDebug;
        this.loadingContent = false;
        // The input of the user is "debounced" so the modified segment does not get sent with each keystroke, but rather
        // when the user stops typing for a moment
        this.userInput.asObservable().pipe(map(function (element) {
            if (!element.modified)
                _this.updateModifiedFlag(element);
            return element;
        }), debounceTime(500)) // wait half a second after the last event before emitting it
            .subscribe(function (element) {
            _this.modifiedSegment(element);
            _this.updateModifiedFlag(element);
            _this.changeEmitter.emit(element);
            // If no more missing references, then re-focus on the text area
            // TODO: this should only be done when inserting the last reference, and not each time a sentence is saved
            // if (!element.missingRefs || element.missingRefs.length == 0)
            //   this.focusTxtArea(element);
        });
    }
    EditorComponent.prototype.ngOnInit = function () {
        // TODO: store settings both on user and task level
        this.settings = new Settings();
        this.settings.hideAffix = true;
        this.settings.hideNoText = true;
        this.errorMsg = null;
        this.setShowShortcuts(false);
        this.initContentData();
        this.scrollUp();
        this.initEditorView();
    };
    /**
     * Set the sort, paginator and the listener for the enabled textArea after the view init.
     */
    EditorComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        // Subscribe to the "textAreas" queryList
        this.textAreas.changes.subscribe(function (r) {
            // When a user clicks a segment, the queryList will get notified of the change and populate the list with the selected
            // item. We need to do it this way because the textAreas are not included in the DOM due to the *ngIf statement (so
            // can't be found through document.getElementId()). Since only one segment textArea is active at one time, this
            // queryList will only ever contain 1 item.
            if (r && r instanceof QueryList) {
                // Auto-focus on the first element in the list (if any)
                var textArea = r.first;
                if (textArea) {
                    // But only if it hasn't been focused yet
                    // Otherwise each change to the DOM will re-focus the textarea (not sure what triggers changes yet :-P)
                    if (!_this.currentTextArea || textArea.nativeElement !== _this.currentTextArea.nativeElement) {
                        if (_this.editable)
                            textArea.nativeElement.focus();
                        _this.currentTextArea = textArea;
                        _this.scrollToCurrent();
                    }
                }
                else
                    _this.currentTextArea = null;
            }
        });
    };
    Object.defineProperty(EditorComponent.prototype, "matSort", {
        set: function (ms) {
            this.sort = ms;
            this.dataSource.sort = this.sort;
            this.dataSource.paginator = this.paginator;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EditorComponent.prototype, "matPaginator", {
        set: function (mp) {
            this.paginator = mp;
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;
        },
        enumerable: true,
        configurable: true
    });
    EditorComponent.prototype.trackById = function (index, row) {
        return row.id;
    };
    EditorComponent.prototype.scrollUp = function () {
        window.scrollTo(0, 0);
    };
    EditorComponent.prototype.setShowShortcuts = function (show) {
        this.showShortcuts = show;
    };
    EditorComponent.prototype.setShowWhitespaces = function (show) {
        this.showWhitespaces = show;
    };
    EditorComponent.prototype.initContentData = function () {
        var _this = this;
        this.loadingContent = true;
        this.editorService.getContent(this.fileLocation, this.fileGroupId).subscribe(function (data) {
            // console.debug("FILE " + fileLocation + " ==> \n" + JSON.stringify(data, null, 2));
            try {
                _this.setContent(data);
                _this.errorMsg = null;
            }
            catch (e) {
                _this.displayError(e, "An error occurred loading the file's content.");
            }
        }, function (error) {
            _this.displayError(error, "An error occurred retrieving the file's content.");
            _this.loadingContent = false;
        }, function () {
            _this.loadingContent = false;
        });
    };
    EditorComponent.prototype.displayError = function (e, msg) {
        console.error("Error loading file content", e);
        this.errorMsg = msg;
    };
    EditorComponent.prototype.setContent = function (data) {
        this.content = data;
        this.rows = this.flattenContent();
        this.loadDataSource(this.rows);
    };
    EditorComponent.prototype.loadDataSource = function (rows) {
        var _this = this;
        this.dataSource.data = rows.filter(function (r, i, a) {
            // Filter out the "no text" text segments if needed
            return !r.noText || !_this.settings.hideNoText;
        })
            .sort(function (a, b) {
            var firstField = _this.numbersOnly(a.id);
            var secondField = _this.numbersOnly(b.id);
            if (firstField > 0 && secondField > 0 && firstField !== secondField) {
                return _this.numbersOnly(a.id) - _this.numbersOnly(b.id);
            }
            return a.id.localeCompare(b.id);
        });
    };
    // Get the number out of a string and create a new number with it (only for sorting)
    EditorComponent.prototype.numbersOnly = function (a) {
        var result = '';
        a.split('').map(function (a) { return Number(a); })
            .filter(function (a) { return !isNaN(a); })
            .forEach(function (element) {
            result = result + element;
        });
        if (result === '') {
            return 0;
        }
        return Number(result);
    };
    EditorComponent.prototype.flattenContent = function () {
        var e_1, _a, e_2, _b;
        var rows = Array();
        var row = null;
        try {
            try {
                for (var _c = tslib_1.__values(this.content), _d = _c.next(); !_d.done; _d = _c.next()) {
                    var tu = _d.value;
                    try {
                        for (var _e = (e_2 = void 0, tslib_1.__values(this.getSegmentIds(tu))), _f = _e.next(); !_f.done; _f = _e.next()) {
                            var segId = _f.value;
                            row = new EditorRow();
                            row.tuId = tu.id;
                            row.segId = segId;
                            row.id = tu.id + "--" + segId;
                            row.sourceSeg = this.getSegWithType(tu, "SRC", segId);
                            row.refs = this.inventoriseRefs(row.sourceSeg);
                            row.targetSeg = this.getSegWithType(tu, "TGT", segId);
                            row.tmSeg = this.getSegWithType(tu, "TM", segId);
                            row.mtSeg = this.getSegWithType(tu, "MT", segId);
                            row.userSeg = this.getSegWithType(tu, "USER", segId);
                            // Try and trim segment (to reduce the number of references)
                            trimSegment(row.sourceSeg);
                            // Only trim target and user segment if source was trimmed also
                            if (row.sourceSeg.trimmedContent != undefined) {
                                trimSegment(row.targetSeg);
                                if (row.tmSeg != null)
                                    trimSegment(row.tmSeg);
                                if (row.mtSeg != null)
                                    trimSegment(row.mtSeg);
                                if (row.userSeg != null)
                                    trimSegment(row.userSeg);
                            }
                            row.currentSeg = this.getCurrentSegText(row);
                            if (row.userSeg != null)
                                this.updateModifiedFlag(row);
                            if (!row.modified) {
                                // Determine missing refs
                                var segmentContent = this.getCurrentSeg(row).content;
                                var missingRefs = this.checkRefs(segmentContent, row.refs);
                                this.setMissingRefs(row, missingRefs);
                            }
                            // Flag no text segments so we can filter them
                            if (isNoText(row.sourceSeg.content)) {
                                this.noTextCount++;
                                row.noText = true;
                            }
                            rows.push(row);
                        }
                    }
                    catch (e_2_1) { e_2 = { error: e_2_1 }; }
                    finally {
                        try {
                            if (_f && !_f.done && (_b = _e.return)) _b.call(_e);
                        }
                        finally { if (e_2) throw e_2.error; }
                    }
                }
            }
            catch (e_1_1) { e_1 = { error: e_1_1 }; }
            finally {
                try {
                    if (_d && !_d.done && (_a = _c.return)) _a.call(_c);
                }
                finally { if (e_1) throw e_1.error; }
            }
            return rows;
        }
        catch (e) {
            if (row !== null)
                console.error("Error occurred while handling row " + row.id, row);
            throw e;
        }
    };
    EditorComponent.prototype.getCurrentSegText = function (row) {
        return this.getTextFromSeg(this.getCurrentSeg(row), row.refs);
    };
    EditorComponent.prototype.getCurrentSeg = function (row) {
        if (row.userSeg != null) {
            return row.userSeg;
        }
        else if (row.targetSeg != null) {
            return row.targetSeg;
        }
        else
            return row.sourceSeg;
    };
    EditorComponent.prototype.getSegmentIds = function (tu) {
        var e_3, _a;
        var ids = Array();
        try {
            for (var _b = tslib_1.__values(tu.segments), _c = _b.next(); !_c.done; _c = _b.next()) {
                var seg = _c.value;
                ids.push(seg.id);
            }
        }
        catch (e_3_1) { e_3 = { error: e_3_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_3) throw e_3.error; }
        }
        // Filter out doubles
        return ids.filter(function (item, i, ar) {
            return ar.indexOf(item) === i;
        });
    };
    EditorComponent.prototype.inventoriseRefs = function (sourceSeg) {
        var e_4, _a;
        var refArray = [];
        try {
            for (var _b = tslib_1.__values(sourceSeg.content), _c = _b.next(); !_c.done; _c = _b.next()) {
                var o = _c.value;
                if (o) {
                    if (o.className && o.className.indexOf('Reference') == 0) {
                        var ref = o;
                        refArray.push(ref);
                    }
                }
            }
        }
        catch (e_4_1) { e_4 = { error: e_4_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_4) throw e_4.error; }
        }
        return refArray;
    };
    EditorComponent.prototype.filter = function (refs) {
        // TODO: filter further and only show refs missing in segment
        var tmp = [];
        var filtered = [];
        // Filter out doubles
        for (var i = 0; i < refs.length; i++) {
            var jsonRef = JSON.stringify(refs[i]);
            if (tmp.indexOf(jsonRef) == -1) {
                tmp.push(jsonRef);
                filtered.push(refs[i]);
            }
        }
        return filtered;
    };
    EditorComponent.prototype.getSegTextWithType = function (tu, type, segId, refs) {
        var seg = this.getSegWithType(tu, type, segId);
        if (seg != null) {
            return this.getTextFromSeg(seg, refs);
        }
        else
            return null;
    };
    EditorComponent.prototype.getSegWithType = function (tu, type, segId) {
        var e_5, _a;
        try {
            for (var _b = tslib_1.__values(tu.segments), _c = _b.next(); !_c.done; _c = _b.next()) {
                var seg = _c.value;
                if (seg.type === type && seg.id === segId) {
                    return seg;
                }
            }
        }
        catch (e_5_1) { e_5 = { error: e_5_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_5) throw e_5.error; }
        }
        return null;
    };
    EditorComponent.prototype.getTextFromSeg = function (seg, refs) {
        if (seg) {
            var c = this.settings.hideAffix && seg.trimmedContent != undefined ? seg.trimmedContent : seg.content;
            return segContentToText(c, refs);
        }
        else
            return null;
    };
    EditorComponent.prototype.prepForDisplay = function (text) {
        var display = text;
        if (this.showWhitespaces) {
            display = visualizeFormattingMarks(text);
        }
        // If completely empty at least put a space, that way the div will expand properly and be clickable
        if (display.length == 0)
            display = " ";
        return display;
    };
    EditorComponent.prototype.toSymbol = function (ref, refs) {
        // (Added this method because we can't use the exported method directly in the HTML)
        return toSymbol(ref, refs);
    };
    EditorComponent.prototype.onSelect = function (element, scrollTo) {
        // If there was no previous input and the source segment equals the target one, we want to automatically empty the
        // textarea so the user doesn't need to clear it manually each time
        var prevRow = this.clickedRow;
        if (prevRow && !prevRow.modified && this.isTargetEqualToSource(prevRow)) {
            // If not modified, we first restore the "target segment" as "current segment" when the user switches to another row
            prevRow.currentSeg = this.getTextFromSeg(prevRow.targetSeg, prevRow.refs);
        }
        // Switch to the new row
        this.clickedRow = element;
        if (this.clickedRow) {
            // If not modified, initialize the current row
            if (!this.clickedRow.modified) {
                this.initSelectedRow(this.clickedRow);
            }
            // Scroll to "current" to make sure the "active" one stays in view if we navigate to the previous or next textarea
            // The below actually scrolls to the previously selected text area, but this works well enough for our purposes
            if (scrollTo)
                this.scrollToCurrent();
        }
    };
    EditorComponent.prototype.initSelectedRow = function (clickedRow) {
        var curSegContent = [];
        // Empty the "current segment" if target is equal to source
        if (this.isTargetEqualToSource(this.clickedRow)) {
            clickedRow.currentSeg = "";
            curSegContent = [];
        }
        else
            curSegContent = this.getCurrentSeg(clickedRow).content;
        if (this.settings.hideAffix)
            curSegContent = this.appendAffix(curSegContent, clickedRow.sourceSeg.prefix, clickedRow.sourceSeg.suffix);
        // Determine the missing refs
        var missingRefs = this.checkRefs(curSegContent, clickedRow.refs);
        this.setMissingRefs(clickedRow, missingRefs);
        this.initRepeatedMatch(clickedRow);
    };
    EditorComponent.prototype.initRepeatedMatch = function (clickedRow) {
        var _this = this;
        // Determine whether there is a relevant repeated match
        // To do so, the editor service:
        // - connects to the project content repository to get this segment's repeated matches
        // - checks whether any have a user-specified translation (cross-file within the task)
        // - returns the one for the repeated match the highest internal match score for this segment, giving preference to internal matches
        this.editorService.getBestRepeatedMatch(this.fileLocation, this.fileGroupId, this.projectId, clickedRow.tuId, clickedRow.segId)
            .subscribe(function (data) {
            // TODO: optimize the repeated match retrieval as it might not be fast enough:
            // - maybe, when the segments are loaded, check in the background (async) whether or not they at least have a rep, so we know in advance for which to execute this code
            // - or keep track internally of the same-file matches and update them client-side (so we only need to retrieve the rep data once)
            if (data) {
                // console.debug("FILE " + this.taskFile.filelocation + " [" + clickedRow.tuId + "/" + clickedRow.segId + "] ==> \n" +
                //    "" + JSON.stringify(data, null, 2));
                // Check whether the clickedRow is still the one open, as there might be a slight delay in getting the repeated match
                // and the user might have clicked away
                if (data.currentTuId === clickedRow.tuId && data.segment.id === clickedRow.segId) {
                    clickedRow.repeatedMatch = data.segment;
                    // Trim the segment, to get rid of surrounding references
                    trimSegment(clickedRow.repeatedMatch);
                }
            }
            else {
                // console.debug("FILE " + this.taskFile.filelocation + " [" + clickedRow.tuId + "/" + clickedRow.segId + "] ==> no repeated matches found");
            }
        }, function (error) {
            console.error("Error retrieving 'best repeated match' for " + _this.clickedRow.tuId + "--" + _this.clickedRow.segId, error);
        });
    };
    EditorComponent.prototype.getRepInfo = function (element) {
        if (element.repeatedMatch) {
            return "This segment is a " + element.repeatedMatch.score + "% match of segment " + element.repeatedMatch.origin.tuid + "--" +
                element.repeatedMatch.origin.segid + (this.isSameFile(element) ? "" : " in file " + element.repeatedMatch.origin.file) + ".";
        }
        return "";
    };
    EditorComponent.prototype.isSameFile = function (element) {
        return this.fileLocation === element.repeatedMatch.origin.fileloc;
    };
    EditorComponent.prototype.isClickedRow = function (element) {
        return this.clickedRow && this.clickedRow.id === element.id;
    };
    EditorComponent.prototype.getTextColor = function (element) {
        return element.modified ?
            (element.missingRefs != null && element.missingRefs.length > 0 ? 'red' : 'green') : 'black';
    };
    EditorComponent.prototype.updateCurrentSegTexts = function () {
        var e_6, _a;
        try {
            for (var _b = tslib_1.__values(this.rows), _c = _b.next(); !_c.done; _c = _b.next()) {
                var row = _c.value;
                // If the source was trimmed to begin with
                if (row.currentSeg && row.sourceSeg.trimmedContent != undefined) {
                    var text = "";
                    var prefix = segContentToText(row.sourceSeg.prefix, row.refs);
                    var suffix = segContentToText(row.sourceSeg.suffix, row.refs);
                    if (this.settings.hideAffix) {
                        // Remove the affixes if they should be hidden
                        text = row.currentSeg;
                        if (text.startsWith(prefix))
                            text = text.substring(prefix.length);
                        if (text.endsWith(suffix))
                            text = text.substring(0, text.length - suffix.length);
                    }
                    else {
                        // Add the affixes if they shouldn't be hidden
                        text += prefix + row.currentSeg + suffix;
                    }
                    row.currentSeg = text;
                }
            }
        }
        catch (e_6_1) { e_6 = { error: e_6_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_6) throw e_6.error; }
        }
    };
    EditorComponent.prototype.segmentChanged = function (element) {
        // Send user input through "subject" so it gets "debounced"
        // Otherwise, the input is sent with EACH KEYSTROKE
        this.userInput.next(element);
    };
    EditorComponent.prototype.focusOut = function (element, pos) {
        // Keep track of what position the cursor was in when the text area lost focus
        // This way, if the user inserts a reference, we can do it at that position
        element.lastPos = pos;
    };
    EditorComponent.prototype.focusMissingRefList = function () {
        if (this.missingRefList) {
            // Auto-focus on the first element in the list (if any)
            if (this.missingRefList.first)
                this.missingRefList.first.nativeElement.focus();
        }
    };
    EditorComponent.prototype.focusTxtArea = function (element) {
        if (this.textAreas) {
            // Auto-focus on the first element in the list (if any)
            if (this.textAreas.first && this.editable) {
                if (element.lastPos != undefined) {
                    this.textAreas.first.nativeElement.selectionStart = element.lastPos;
                    this.textAreas.first.nativeElement.selectionEnd = element.lastPos;
                }
                this.textAreas.first.nativeElement.focus();
            }
        }
    };
    EditorComponent.prototype.scrollToCurrent = function () {
        if (this.textAreas) {
            // Scroll to the current text area
            if (this.textAreas.first) {
                this.textAreas.first.nativeElement.scrollIntoView({
                    behavior: 'auto',
                    block: 'center',
                    inline: 'center'
                });
            }
        }
    };
    EditorComponent.prototype.insertFirstMissingRef = function (element, txtArea) {
        if (this.refToInsert != null) {
            var txt = element.currentSeg;
            var refSymbol = toSymbol(this.refToInsert, element.refs);
            if (txtArea != null && txtArea.selectionStart != undefined) {
                var pos = txtArea.selectionStart;
                var newText = txt.substring(0, txtArea.selectionStart);
                newText += refSymbol;
                newText += txt.substring(txtArea.selectionEnd);
                element.currentSeg = newText;
                // Offset the "last position" with the inserted symbol
                element.lastPos = pos + refSymbol.length;
                txtArea.focus();
                // todo: the below should set the cursor back to where it was, but it doesn't work :(
                txtArea.setSelectionRange(element.lastPos, element.lastPos, 'none');
            }
            else {
                // If no position, just shove it in the back
                element.currentSeg += refSymbol;
            }
            // Since the above does not seem to trigger an ngModelChange, we do the segment update manually
            this.segmentChanged(element);
        }
    };
    EditorComponent.prototype.insertMissingRef = function (element) {
        var txt = element.currentSeg;
        var refSymbol = toSymbol(this.refToInsert, element.refs);
        if (element.lastPos != undefined) {
            var newText = txt.substring(0, element.lastPos);
            newText += refSymbol;
            newText += txt.substring((element.lastPos));
            element.currentSeg = newText;
            // Offset the "last position" with the inserted symbol
            element.lastPos += refSymbol.length;
        }
        else {
            // If no "last position", just shove it in the back
            element.currentSeg += refSymbol;
        }
        // Since the above does not seem to trigger an ngModelChange, we do the segment update manually
        this.segmentChanged(element);
        // If no more missing refs, re-focus on the text area
        // ==> Due to the 'debounce' when storing segments, the missingRefs won't be updated at this point, so the txtArea won't be focused
        if (!element.missingRefs || element.missingRefs.length == 0)
            this.focusTxtArea(element);
    };
    EditorComponent.prototype.copyMissingRef = function (refs) {
        var txtArea;
        try {
            // Create fake text area
            txtArea = document.createElement("textarea");
            txtArea.id = 'txt';
            txtArea.style.position = 'fixed';
            txtArea.style.top = '0';
            txtArea.style.left = '0';
            txtArea.style.opacity = '0';
            // Set text to copy as its value
            txtArea.value = toSymbol(this.refToInsert, refs);
            // Add it to the DOM
            document.body.appendChild(txtArea);
            txtArea.select();
            // Perform the copy-to-clipboard command
            var successful = document.execCommand('copy');
        }
        catch (err) {
            console.warn("Oops, unable to copy reference to clipboard", err);
        }
        finally {
            // Remove the fake text area from the DOM again
            if (txtArea)
                document.body.removeChild(txtArea);
        }
    };
    EditorComponent.prototype.modifiedSegment = function (element) {
        var segmentContent = textToSegContent(element.currentSeg, element.refs);
        if (this.settings.hideAffix)
            segmentContent = this.appendAffix(segmentContent, element.sourceSeg.prefix, element.sourceSeg.suffix);
        var missingRefs = this.checkRefs(segmentContent, element.refs);
        this.setMissingRefs(element, missingRefs);
        // If there are missing refs, don't save
        if (!missingRefs || missingRefs.length == 0) {
            // console.log("segment => \"" + element.currentSeg + "\" => [" + segmentContent + "]");
            return this.editorService
                .postModifiedSegment(this.fileLocation, this.fileGroupId, element.tuId, element.segId, segmentContent)
                .subscribe();
        }
    };
    EditorComponent.prototype.setMissingRefs = function (element, missingRefs) {
        if (missingRefs && missingRefs.length > 0) {
            element.missingRefs = missingRefs;
            this.refToInsert = element.missingRefs[0];
        }
        else {
            element.missingRefs = null;
            this.refToInsert = null;
        }
    };
    EditorComponent.prototype.checkRefs = function (content, refs) {
        var e_7, _a;
        if (content == undefined || content.length == 0)
            return refs;
        // TODO: also check whether the count is correct (whether there are not too many?)
        var missingRefs = [];
        var refIndexMap = new Map();
        try {
            for (var refs_1 = tslib_1.__values(refs), refs_1_1 = refs_1.next(); !refs_1_1.done; refs_1_1 = refs_1.next()) {
                var ref = refs_1_1.value;
                var refIdx = -1;
                var refString = JSON.stringify(ref);
                // Get starting position of search
                var idx = void 0;
                // Check whether there already is a previous index, and whether the end of the content has already been reached
                if (refIndexMap.get(refString) != undefined) {
                    if (refIndexMap.get(refString) < content.length) {
                        // Add 1 to the previous index, so we start with the next element
                        idx = refIndexMap.get(refString) + 1;
                    }
                    else
                        idx = -1;
                }
                else
                    idx = 0;
                if (idx >= 0) {
                    var found = false;
                    // Check at what index in the content the reference occurs, starting from the given index
                    // (The get() from the map doesn't work, as the objects in the map are not "the same" as the ones in the content
                    // array, so we manually loop through it)
                    while (idx < content.length && !found) {
                        var c = content[idx];
                        if (isRef(c) && refString == JSON.stringify(c)) {
                            found = true;
                        }
                        else
                            idx++;
                    }
                    if (found)
                        refIdx = idx;
                }
                if (refIdx >= 0) {
                    // Keep track of the index where we last found this symbol, and search again next from this index
                    // This is to make sure we account for all occurrences
                    refIndexMap.set(refString, refIdx);
                }
                else {
                    // If not found, store it in the "missing reference" array
                    missingRefs.push(ref);
                }
            }
        }
        catch (e_7_1) { e_7 = { error: e_7_1 }; }
        finally {
            try {
                if (refs_1_1 && !refs_1_1.done && (_a = refs_1.return)) _a.call(refs_1);
            }
            finally { if (e_7) throw e_7.error; }
        }
        return missingRefs;
    };
    EditorComponent.prototype.getIndexOfRef = function (ref, refMap, content) {
        var idx;
        // Keep track of the index where we last found this reference, and search again next from this index
        // This is to make sure we account for all occurrences
        if (refMap.get(ref) != undefined) {
            // Explicit check on when the end of the text is reached, as indexOf would return 0 (iso the expected -1)
            if (refMap.get(ref) < content.length)
                idx = content.indexOf(ref, (refMap.get(ref) + 1));
            else
                idx = -1;
        }
        else
            idx = content.indexOf(ref);
        return idx;
    };
    EditorComponent.prototype.appendAffix = function (content, prefix, suffix) {
        var e_8, _a, e_9, _b, e_10, _c, e_11, _d;
        var contentArray = [];
        if (prefix != undefined) {
            try {
                for (var prefix_1 = tslib_1.__values(prefix), prefix_1_1 = prefix_1.next(); !prefix_1_1.done; prefix_1_1 = prefix_1.next()) {
                    var i = prefix_1_1.value;
                    contentArray.push(i);
                }
            }
            catch (e_8_1) { e_8 = { error: e_8_1 }; }
            finally {
                try {
                    if (prefix_1_1 && !prefix_1_1.done && (_a = prefix_1.return)) _a.call(prefix_1);
                }
                finally { if (e_8) throw e_8.error; }
            }
        }
        try {
            for (var content_1 = tslib_1.__values(content), content_1_1 = content_1.next(); !content_1_1.done; content_1_1 = content_1.next()) {
                var i = content_1_1.value;
                contentArray.push(i);
            }
        }
        catch (e_9_1) { e_9 = { error: e_9_1 }; }
        finally {
            try {
                if (content_1_1 && !content_1_1.done && (_b = content_1.return)) _b.call(content_1);
            }
            finally { if (e_9) throw e_9.error; }
        }
        if (suffix != undefined) {
            try {
                for (var suffix_1 = tslib_1.__values(suffix), suffix_1_1 = suffix_1.next(); !suffix_1_1.done; suffix_1_1 = suffix_1.next()) {
                    var i = suffix_1_1.value;
                    contentArray.push(i);
                }
            }
            catch (e_10_1) { e_10 = { error: e_10_1 }; }
            finally {
                try {
                    if (suffix_1_1 && !suffix_1_1.done && (_c = suffix_1.return)) _c.call(suffix_1);
                }
                finally { if (e_10) throw e_10.error; }
            }
        }
        // Now, run through the content again and "merge" subsequent strings
        // If we don't, we might run into issues in the backend when trying to force the affixes of the source on the user segment
        var mergedArray = [];
        var currentItem = null;
        try {
            for (var contentArray_1 = tslib_1.__values(contentArray), contentArray_1_1 = contentArray_1.next(); !contentArray_1_1.done; contentArray_1_1 = contentArray_1.next()) {
                var i = contentArray_1_1.value;
                if (typeof i === 'string' || i instanceof String) {
                    if (currentItem == null)
                        currentItem = i;
                    else
                        currentItem += i;
                }
                else {
                    if (currentItem != null && currentItem.length > 0)
                        mergedArray.push(currentItem);
                    mergedArray.push(i);
                    currentItem = null;
                }
            }
        }
        catch (e_11_1) { e_11 = { error: e_11_1 }; }
        finally {
            try {
                if (contentArray_1_1 && !contentArray_1_1.done && (_d = contentArray_1.return)) _d.call(contentArray_1);
            }
            finally { if (e_11) throw e_11.error; }
        }
        if (currentItem != null && currentItem.length > 0)
            mergedArray.push(currentItem);
        return mergedArray;
    };
    EditorComponent.prototype.updateModifiedFlag = function (element) {
        element.modified = this.isModified(element);
    };
    EditorComponent.prototype.isModified = function (element) {
        return (element.currentSeg != undefined && element.currentSeg !== this.getTextFromSeg(element.targetSeg, element.refs));
    };
    EditorComponent.prototype.isTargetEqualToSource = function (element) {
        return this.getTextFromSeg(element.sourceSeg, element.refs) === this.getTextFromSeg(element.targetSeg, element.refs);
    };
    EditorComponent.prototype.copyToCurrentSeg = function (element, seg) {
        if (seg) {
            element.currentSeg = this.getTextFromSeg(seg, element.refs);
            // Since the above does not seem to trigger an ngModelChange, we do the segment update manually
            this.segmentChanged(element);
        }
    };
    EditorComponent.prototype.moveToNext = function (element) {
        try {
            // Retrieves the "as rendered" by the table (=sorted, filtered and paginated)
            var renderedData = this.dataSource.connect().value;
            // Determine index of current element
            var currentIdx_1 = renderedData.indexOf(element);
            // Get next element
            var next = renderedData.find(function (item, idx) {
                return idx === (currentIdx_1 + 1);
            });
            // And select it
            if (next)
                this.onSelect(next, true);
        }
        catch (e) {
            console.error('Editor.moveToNext error: ' + e.toString());
        }
    };
    EditorComponent.prototype.moveToPrev = function (element) {
        try {
            // Retrieves the "as rendered" by the table (=sorted, filtered and paginated)
            var renderedData = this.dataSource.connect().value;
            // Determine index of current element
            var currentIdx_2 = renderedData.indexOf(element);
            if (currentIdx_2 > 0) {
                // Get next element
                var prev = renderedData.find(function (item, idx) {
                    return idx === (currentIdx_2 - 1);
                });
                // And select it
                if (prev)
                    this.onSelect(prev, true);
            }
        }
        catch (e) {
            console.error('Editor.moveToPrev error: ' + e.toString());
        }
    };
    EditorComponent.prototype.translateWithGoogle = function (element) {
        var sourceText = this.getTextFromSeg(element.sourceSeg, undefined);
        this.editorService.translateWithGoogle(this.sourceLang, this.targetLang, sourceText)
            .subscribe(function (data) {
            console.debug("G-TRANSLATED '" + sourceText + "' ==> \n" + JSON.stringify(data, null, 2));
        });
    };
    EditorComponent.prototype.getTotalCount = function () {
        if (this.rows)
            return this.rows.length;
        else
            return 0;
    };
    EditorComponent.prototype.getProgressCount = function () {
        var e_12, _a;
        if (this.rows) {
            var modifiedCount = 0;
            try {
                for (var _b = tslib_1.__values(this.rows), _c = _b.next(); !_c.done; _c = _b.next()) {
                    var row = _c.value;
                    if (row.modified)
                        modifiedCount++;
                }
            }
            catch (e_12_1) { e_12 = { error: e_12_1 }; }
            finally {
                try {
                    if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                }
                finally { if (e_12) throw e_12.error; }
            }
            return modifiedCount;
        }
        else
            return 0;
    };
    EditorComponent.prototype.openRefHelp = function () {
        var helpText = "\n        <p>This warning indicates the translation you entered is lacking some references. References represent elements of\n        the text that need to be placed in the translation. These can be markup, like pieces of a text that is in a different\n        font, or fields, like a variable name. We need your help to put the references in the correct place in the translated\n        sentence.</p>\n        <p>You can enter the references by placing the cursor on the position you want to insert it and then select the\n        right reference in the drop down box, then click the \"Add\" button next to it to insert the reference.</p>\n        <p>Save time by keeping your hands on the keyboard, when you reach a place where you want to insert a reference, press\n        CTRL+ALT+0 to switch from the text area to the drop down list and use the arrows up and down to select the right\n        reference, and then insert it by pressing the enter key.</p>\n        <p>More information about what the reference entails can be deduced from the source file, which you can\n        download from the task screen.</p>\n    ";
        var dialogRef = this.dialog.open(HelpDialogComponent, {
            width: '700px',
            height: '500px',
            data: { helpTitle: "What is a reference?", helpHtmlContent: helpText }
        });
    };
    EditorComponent.prototype.openSettings = function () {
        var _this = this;
        var dialogRef = this.dialog.open(EditorSettingsComponent, {
            width: '500px',
            height: '50%',
            data: { hideAffix: this.settings.hideAffix, hideNoText: this.settings.hideNoText }
        });
        dialogRef.afterClosed().subscribe(function (r) {
            if (r === "confirm") {
                if (_this.settings.hideAffix != dialogRef.componentInstance.hideAffix) {
                    _this.settings.hideAffix = dialogRef.componentInstance.hideAffix;
                    _this.updateCurrentSegTexts();
                }
                if (_this.settings.hideNoText != dialogRef.componentInstance.hideNoText) {
                    _this.settings.hideNoText = dialogRef.componentInstance.hideNoText;
                    _this.loadDataSource(_this.rows);
                }
            }
        });
    };
    EditorComponent.prototype.initEditorView = function () {
        var _this = this;
        //Fetch from local storage
        var editorView = localStorage.getItem("editorView");
        if (editorView != null) {
            this.currentView = editorView;
        }
        this.fileService.isSegHtmlAvailable(this.projectId, this.fileName).subscribe(function (f) {
            if (!f) {
                _this.views = ['Source & target'];
                _this.currentView = 'Source & target';
            }
            _this.changeEditorView();
        });
    };
    //todo create a cleaner method to set the view (especially when more options become available)
    EditorComponent.prototype.changeEditorView = function () {
        this.inlayoutview = this.currentView.toLowerCase().indexOf("layout") >= 0;
        if (this.currentView.toLowerCase().indexOf("source") >= 0) {
            if (this.inlayoutview) {
                this.displayedColumns = ['source', 'target', 'status'];
            }
            else {
                this.displayedColumns = ['id', 'source', 'target', 'status'];
            }
        }
        else {
            this.displayedColumns = ['target', 'status'];
        }
        localStorage.setItem("editorView", this.currentView);
    };
    return EditorComponent;
}());
export { EditorComponent };
var Settings = /** @class */ (function () {
    function Settings() {
    }
    return Settings;
}());
export { Settings };
