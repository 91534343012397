import { UserService } from "../../../service/user.service";
import { MatSnackBar } from "@angular/material/snack-bar";
var MimicUserComponent = /** @class */ (function () {
    function MimicUserComponent(userService, snackbar) {
        this.userService = userService;
        this.snackbar = snackbar;
        this.mimicUserMail = null;
    }
    MimicUserComponent.prototype.isMimicButtonValid = function () {
        return this.mimicUserMail != null && this.mimicUserMail.trim().length > 0;
    };
    MimicUserComponent.prototype.mimicUser = function () {
        var _this = this;
        var mimicRequest = { mimicUserMail: this.mimicUserMail.trim() };
        this.userService.mimicUser(mimicRequest).subscribe(function (r) {
            _this.snackbar.open("You are mimic as the requested user", '', { duration: 2000 });
        });
    };
    return MimicUserComponent;
}());
export { MimicUserComponent };
