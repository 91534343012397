import { EventEmitter } from "@angular/core";
var FileAddComponent = /** @class */ (function () {
    function FileAddComponent() {
        this.label = 'Add more files';
        this.onFileUpload = new EventEmitter();
    }
    FileAddComponent.prototype.uploadFiles = function (event) {
        this.onFileUpload.emit(event);
    };
    return FileAddComponent;
}());
export { FileAddComponent };
