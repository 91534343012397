var Vendor = /** @class */ (function () {
    function Vendor(id, firstname, lastname, mugshot, description, motherTongue, city, country, rating, reviewcount, responsiveness, basePrice, competences, domains, percentageSet, internal, companyId) {
        if (internal === void 0) { internal = false; }
        if (companyId === void 0) { companyId = null; }
        this.internal = false;
        this.id = id;
        if (firstname == undefined)
            this.firstName = "";
        else
            this.firstName = firstname;
        if (lastname == undefined)
            this.lastName = "";
        else
            this.lastName = lastname;
        this.name = this.firstName + " " + this.lastName;
        this.mugshot = mugshot;
        this.description = description;
        this.rating = rating;
        this.reviewcount = reviewcount;
        this.responsiveness = responsiveness;
        this.basePrice = basePrice;
        this.competences = competences;
        this.domains = domains;
        this.percentageSet = percentageSet;
        this.internal = internal;
        this.companyId = companyId;
        this.motherTongue = motherTongue;
        this.country = country;
        this.city = city;
    }
    return Vendor;
}());
export { Vendor };
var Competence = /** @class */ (function () {
    function Competence() {
        this.basePrice = 0;
    }
    Competence.prototype.toString = function () {
        return this.activity + " " + this.target + " " + this.basePrice;
    };
    return Competence;
}());
export { Competence };
var Reduction = /** @class */ (function () {
    function Reduction() {
    }
    return Reduction;
}());
export { Reduction };
