import { PipeTransform } from '@angular/core';
var FilenamePipe = /** @class */ (function () {
    function FilenamePipe() {
    }
    FilenamePipe.prototype.transform = function (value, length, keepExtension) {
        if (value === undefined) {
            return '';
        }
        if (!keepExtension) {
            if (value.lastIndexOf('.') > 0) {
                value = value.slice(0, value.lastIndexOf('.'));
            }
        }
        var l = length;
        var preInterrupt = (l - 1) / 2;
        var postInterrupt = l - preInterrupt - 1;
        if (value.length <= l)
            return value;
        else {
            return value.substr(0, preInterrupt) + "…" + value.substr(value.length - postInterrupt);
        }
    };
    return FilenamePipe;
}());
export { FilenamePipe };
