import { map } from 'rxjs/operators';
import { Observable } from "rxjs";
/**
 * Created by jefv on 3/11/2017.
 */
var TaskListComponent = /** @class */ (function () {
    function TaskListComponent() {
    }
    TaskListComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.tasks = this.tasks.pipe(map(function (t) {
            _this.taskCount = t.length;
            return t;
        }));
    };
    return TaskListComponent;
}());
export { TaskListComponent };
