import { tap } from 'rxjs/operators';
import { HttpErrorResponse } from "@angular/common/http";
var JWTInterceptor = /** @class */ (function () {
    function JWTInterceptor() {
    }
    JWTInterceptor.prototype.intercept = function (req, next) {
        var localStorageUser = localStorage.getItem("user");
        if (localStorageUser != null && localStorageUser.toString().length > 0) {
            var localStorageUserJ = JSON.parse(localStorageUser);
            var apiKey = localStorageUserJ.apiKey;
            //The key is not always added properly to use localStorage, in case apiKey is not directly available it might be available in the api object.
            //TODO cleanup sign in/mail authentication code to properly return the apiKey
            if (apiKey == undefined && localStorageUserJ.api != undefined)
                apiKey = localStorageUserJ.api.apiKey;
            if (apiKey) {
                //Followed this example: https://ryanchenkie.com/angular-authentication-using-the-http-client-and-http-interceptors
                var reqClone = req.clone({ setHeaders: { Authorization: apiKey } });
                return next.handle(reqClone).pipe(tap(function (event) {
                }, function (err) {
                    if (err instanceof HttpErrorResponse) {
                        if (err.status === 401) {
                            console.error("401 error", err);
                            localStorage.removeItem("user");
                        }
                    }
                    if (err.status === 500) {
                    }
                }));
            }
        }
        return next.handle(req);
    };
    return JWTInterceptor;
}());
export { JWTInterceptor };
