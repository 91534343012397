import { MatDialogRef } from "@angular/material/dialog";
import { ProjectService } from "../../service/project.service";
var FileMetaDataDialog = /** @class */ (function () {
    function FileMetaDataDialog(dialogRef, data, projectService) {
        this.dialogRef = dialogRef;
        this.data = data;
        this.projectService = projectService;
        this.words = 0;
    }
    FileMetaDataDialog.prototype.ngOnInit = function () {
        if (this.data.projectId)
            this.projectId = this.data.projectId;
        if (this.data.file) {
            this.file = this.data.file;
            this.words = this.file.wordcount;
        }
    };
    FileMetaDataDialog.prototype.save = function () {
        var _this = this;
        this.projectService.setFileWordCount(this.projectId, this.file.name, this.words)
            .subscribe(function (a) { return _this.dialogRef.close("success"); });
    };
    FileMetaDataDialog.prototype.enableSave = function () {
        return this.words != undefined && true && this.words > 0;
    };
    return FileMetaDataDialog;
}());
export { FileMetaDataDialog };
