import { filter, map, switchMap } from 'rxjs/operators';
import { UserService } from "../service/user.service";
import { ActivatedRoute, Router } from "@angular/router";
import { SimpleDialog } from "../simple-dialog.component";
import { MatDialog } from "@angular/material/dialog";
import { InvoiceService } from "../service/invoice.service";
import { environment } from "../../environments/environment";
var UserSettingsComponent = /** @class */ (function () {
    function UserSettingsComponent(route, userService, invoiceService, router, dialog) {
        this.route = route;
        this.userService = userService;
        this.invoiceService = invoiceService;
        this.router = router;
        this.dialog = dialog;
        this.page = "profile";
        this.hasPaymentMethod = false;
        this.accessAllowed = undefined;
        this.isDebug = environment.isDebug;
    }
    UserSettingsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.route.params.pipe(switchMap(function (params) { return _this.userService.getUser(params['id']); }))
            .subscribe(function (user) { return _this.setUser(user); });
        this.route.params.pipe(map(function (p) { return p['page']; }), filter(function (p) { return p != null; }))
            .subscribe(function (p) { return _this.page = p; });
    };
    UserSettingsComponent.prototype.setUser = function (user) {
        var _this = this;
        this.user = user;
        this.invoiceService.hasPaymentMethodRecord(this.user.id).subscribe(function (v) {
            _this.hasPaymentMethod = v;
        }, function (e) { return _this.hasPaymentMethod = false; });
        this.setAccessAllowed();
        this.userService.loginEmitter.subscribe(function (v) { return _this.setAccessAllowed(); });
    };
    UserSettingsComponent.prototype.setAccessAllowed = function () {
        // Only the current user is allowed access to his/her own data, or an admin
        this.accessAllowed = (this.userService.isLoggedIn() &&
            (this.userService.isCurrentUser(this.user) || this.userService.isCurrentUserAdmin));
        // console.debug("Access allowed to " + this.user.id + "? " + this.accessAllowed + " (is logged in? " + this.userService.isLoggedIn() + ") (is current user? " + this.userService.isCurrentUser(this.user) + ") (is admin? " + this.userService.isCurrentUserAdmin + ")");
    };
    UserSettingsComponent.prototype.goto = function (page) {
        this.page = page;
        this.scrollToTop();
    };
    UserSettingsComponent.prototype.getPage = function () {
        return this.page;
    };
    UserSettingsComponent.prototype.onCompanyEvent = function (company) {
        this.user.companyId = company.id;
        this.user.companyName = company.name;
        this.userService.updateUserCompanyInfo(this.user).subscribe();
    };
    UserSettingsComponent.prototype.isMailAuthenticated = function () {
        return this.user && this.user.mailAuthKey != null;
    };
    UserSettingsComponent.prototype.isTranslator = function () {
        return this.userService.isUserTranslator(this.user);
    };
    UserSettingsComponent.prototype.logout = function () {
        this.subscribeToLogout();
    };
    UserSettingsComponent.prototype.subscribeToLogout = function () {
        var _this = this;
        this.userService.signOut().subscribe({
            next: function () {
                _this.user = null;
                var dialogRef = _this.dialog.open(SimpleDialog, {
                    width: "400px",
                    panelClass: 'simpleDialog',
                    data: { dialogMessage: "You have successfully logged out." }
                });
                dialogRef.afterClosed().subscribe(function (r) {
                    return _this.router.navigateByUrl("");
                });
            },
            complete: function () {
                // Completed
            }
        });
    };
    UserSettingsComponent.prototype.scrollToTop = function () {
        window.scrollTo(0, 0);
    };
    return UserSettingsComponent;
}());
export { UserSettingsComponent };
