import { switchMap } from 'rxjs/operators';
import { ProjectService } from "../service/project.service";
import { ActivatedRoute } from "@angular/router";
import { MatSnackBar } from "@angular/material/snack-bar";
var ProjectDetailsComponent = /** @class */ (function () {
    function ProjectDetailsComponent(projectService, route, snackbar) {
        this.projectService = projectService;
        this.route = route;
        this.snackbar = snackbar;
        this.project = undefined;
        this.projectState = undefined;
        this.section = undefined;
        this.noForceRequester = undefined;
    }
    ProjectDetailsComponent.prototype.ngOnInit = function () {
        var _this = this;
        // TODO: page does not load immediately when creating new project with a bunch of files from the dashboard?
        var start = Date.now();
        this.route.queryParamMap.subscribe(function (params) {
            _this.section = params.get("section");
            _this.noForceRequester = params.get("nfreq");
        });
        this.route.paramMap.pipe(switchMap(function (params) {
            var pId = params.get('id');
            try {
                return _this.projectService.getProject(pId);
            }
            finally {
                console.debug("Project " + params.get('id') + ": getting observer took " + (Date.now() - start) + " ms");
            }
        })).subscribe(function (project) {
            console.debug("Project " + project.id + ": retrieving project took " + (Date.now() - start) + " ms");
            _this.project = project;
            _this.initProjectState();
        }, function (error) {
            console.error("Error loading project", error);
            _this.snackbar.open("Error loading project : " + error.toString(), "", {
                duration: 3000
            });
        });
    };
    ProjectDetailsComponent.prototype.initProjectState = function () {
        if (this.project.startdate == undefined && (this.project.checkoutarchs === undefined || this.project.checkoutarchs.length === 0))
            this.projectState = "create";
        else
            this.projectState = "followup";
    };
    return ProjectDetailsComponent;
}());
export { ProjectDetailsComponent };
