import { map } from 'rxjs/operators';
import { PricingService } from "../service/pricing.services";
import { UserService } from "../service/user.service";
var CreditCreatorComponent = /** @class */ (function () {
    function CreditCreatorComponent(pricingService, userService) {
        this.pricingService = pricingService;
        this.userService = userService;
        this.calculationParams = {};
        this.validationParams = {};
    }
    CreditCreatorComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.pricingService.getCreditCalculationTypes().pipe(map(function (types) {
            _this.calculationType = types[0];
            _this.fetchCalcParams();
            return types;
        }))
            .subscribe(function (types) { return _this.calculationTypes = types; });
        this.pricingService.getCreditValidationTypes().pipe(map(function (types) {
            _this.validationType = types[0];
            _this.fetchValidParams();
            return types;
        }))
            .subscribe(function (types) { return _this.validationTypes = types; });
    };
    CreditCreatorComponent.prototype.fetchCalcParams = function () {
        var _this = this;
        this.pricingService.getCreditCalculationParams(this.calculationType)
            .subscribe(function (params) { return _this.calculationParamFields = params; });
    };
    CreditCreatorComponent.prototype.fetchValidParams = function () {
        var _this = this;
        this.pricingService.getCreditValidationParams(this.validationType).subscribe(function (params) { return _this.validationParamFields = params; });
    };
    CreditCreatorComponent.prototype.submit = function () {
        var receiverType = this.getReceiverType();
        //Construct the credit object as defined by the backend
        var credit = {
            reason: this.reason,
            title: this.title,
            calcType: this.calculationType,
            calcParams: this.calculationParams,
            validType: this.validationType,
            validParams: this.validationParams,
            receiverType: receiverType,
            receiverId: this.getReceiverId(receiverType)
        };
        //call the create method for the credit
        this.pricingService.createCredit(credit).subscribe(function (token) { return console.debug("The token is: " + token); });
    };
    CreditCreatorComponent.prototype.getReceiverType = function () {
        if (this.receiverUserId != undefined && this.receiverUserId != "")
            return "USER";
        else if (this.receiverCompanyId != undefined && this.receiverCompanyId != "")
            return "COMPANY";
        return "UNDEFINED";
    };
    CreditCreatorComponent.prototype.getReceiverId = function (type) {
        if (type == "USER")
            return this.receiverUserId;
        else
            return this.receiverCompanyId;
    };
    return CreditCreatorComponent;
}());
export { CreditCreatorComponent };
