import { ActivatedRoute } from "@angular/router";
import { VendorService } from "../service/vendor.service";
var ProfileInfoComponent = /** @class */ (function () {
    //TODO Fully implement this page
    function ProfileInfoComponent(route, vendorService) {
        this.route = route;
        this.vendorService = vendorService;
    }
    ProfileInfoComponent.prototype.ngOnInit = function () {
        var _this = this;
        if (!this.userId) {
            this.route.params
                .forEach(function (params) {
                if (params['id'])
                    _this.setUserId(params['id']);
            });
        }
        this.vendorService.getVendor(this.userId).subscribe(function (v) {
            _this.user = v;
        });
    };
    ProfileInfoComponent.prototype.setUserId = function (userId) {
        this.userId = userId;
    };
    return ProfileInfoComponent;
}());
export { ProfileInfoComponent };
