import { defaultIfEmpty, merge } from 'rxjs/operators';
import { UserService } from "../service/user.service";
import { AfterViewInit } from "@angular/core";
import { Router } from "@angular/router";
import { LogInService } from "../service/login.service";
import { ProfileSettingsComponent } from "./profile.setting.component";
import { MatSnackBar } from "@angular/material/snack-bar";
import { CompetenceSettingsComponent } from "./competence.setting.component";
var NewTranslatorComponent = /** @class */ (function () {
    function NewTranslatorComponent(userService, router, loginService, snackbar) {
        this.userService = userService;
        this.router = router;
        this.loginService = loginService;
        this.snackbar = snackbar;
        this.saving = false;
    }
    NewTranslatorComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.userService.signInFromLocalStorage().pipe(defaultIfEmpty(undefined)).subscribe(function (u) {
            if (u == undefined) {
                Promise.resolve().then(function () {
                    _this.loginService.openJoinDialog("newtranslator");
                    _this.userService.loginEmitter.subscribe(function (v) {
                        _this.user = _this.userService.getCurrentUser();
                    });
                });
            }
            else {
                _this.user = u;
            }
        });
    };
    NewTranslatorComponent.prototype.ngAfterViewInit = function () {
    };
    NewTranslatorComponent.prototype.gotoProfile = function () {
        var _this = this;
        this.saving = true;
        this.competenceSettingComp.getSaveObservables().pipe(merge(this.profileSettingComp.getSaveObservables())).subscribe(function (r) {
            _this.router.navigate(["user", _this.user.id]);
            _this.snackbar.open("Profile updated", '', {
                duration: 2000
            });
            _this.saving = false;
        }, function (error) { return console.error("Error updating user " + _this.user.id, error); });
    };
    return NewTranslatorComponent;
}());
export { NewTranslatorComponent };
