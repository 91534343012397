import { OnInit } from "@angular/core";
import { from as observableFrom, Subject } from 'rxjs';
import { Project } from "../dto/project";
import { ProjectService } from "../service/project.service";
import { ActivatedRoute, Router } from "@angular/router";
import { getProjectStatus } from "../util/jobutil";
import { debounceTime } from 'rxjs/operators';
import { UserService } from "../service/user.service";
var ProjectDashboardComponent = /** @class */ (function () {
    function ProjectDashboardComponent(projectService, userService, router, activatedRoute) {
        var _this = this;
        this.projectService = projectService;
        this.userService = userService;
        this.router = router;
        this.activatedRoute = activatedRoute;
        this.allState = "All";
        this.selectedStates = ["Draft", "Active"];
        this.projectStates = ["Draft", "Active", "Completed", "Canceled"];
        this.quickAccess = [];
        this.projects = undefined;
        this.filteredProjects = undefined;
        this.filterInput = new Subject();
        this.displayedProjects = undefined;
        this.initTableSize = 20;
        this.tableSize = this.initTableSize;
        this.isFromCreated = false;
        this.createdId = undefined;
        this.filterInput.asObservable().pipe(debounceTime(500)) // wait half a second after the last event before emitting it
            .subscribe(function (element) {
            _this.setFilteredProjects();
        });
    }
    ProjectDashboardComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.loadQuickAccess();
        this.loadProjects();
        this.activatedRoute.queryParams
            .filter(function (params) { return params.hasOwnProperty("createdId"); })
            .subscribe(function (params) {
            _this.isFromCreated = true;
            _this.createdId = params.createdId;
        });
    };
    ProjectDashboardComponent.prototype.loadQuickAccess = function () {
        var _this = this;
        this.projectService.getQuickAccessProjects(-1, 4).subscribe(function (d) {
            if (d != null)
                _this.quickAccess = d.slice(0, 4);
            else
                _this.quickAccess = [];
        });
    };
    /**
     * https://blog.angular-university.io/angular-material-data-table/
     */
    ProjectDashboardComponent.prototype.loadProjects = function () {
        var _this = this;
        var start = Date.now();
        var obs;
        if (this.selectedStates.length === 0) {
            // If no status selected, don't show any projects
            obs = observableFrom([]);
        }
        else if (this.selectedStates.indexOf(this.allState) > -1)
            obs = this.projectService.getMyProjects();
        else
            obs = this.projectService.getMyProjectsWithStatus(this.selectedStates);
        obs.subscribe(function (projects) {
            _this.projects = _this.sort(projects);
            _this.setFilteredProjects();
            console.debug("Loading " + _this.filteredProjects.length + " projects took " + (Date.now() - start) + " ms.");
        }, function (error) {
            _this.projects = [];
            _this.filteredProjects = [];
            console.error("Error loading projects", error);
        });
    };
    ProjectDashboardComponent.prototype.gotoProject = function (id) {
        this.router.navigate(['/pdetail', id]);
    };
    ProjectDashboardComponent.prototype.selectState = function (state) {
        var _this = this;
        if (this.selectedStates.indexOf(state) > -1) {
            // State was already selected, so unselect it
            this.selectedStates = this.selectedStates.filter(function (i) { return i !== state; });
            // When a state is removed, remove all projects associated with this state
            // In case it was the "all" state, remove all projects
            if (state === this.allState) {
                this.projects = [];
            }
            else {
                this.projects = this.projects.filter(function (p) { return getProjectStatus(p) !== state; });
            }
            this.setFilteredProjects();
        }
        else {
            if (state === this.allState) {
                // If "all" was clicked, then remove all other selections
                this.selectedStates = [];
            }
            else if (this.selectedStates.indexOf(this.allState) > -1) {
                // If "all" is in the current selections and another is chosen, then remove "all"
                this.selectedStates = this.selectedStates.filter(function (i) { return i !== _this.allState; });
            }
            this.selectedStates.push(state);
            // Reload projects
            this.loadProjects();
        }
    };
    ProjectDashboardComponent.prototype.sort = function (projects) {
        return projects.sort(function (p1, p2) {
            if (p1.creationdate && p2.creationdate) {
                if (p1.creationdate > p2.creationdate)
                    return -1;
                if (p1.creationdate < p2.creationdate)
                    return 1;
                else
                    return 0;
            }
            else if (p1.creationdate)
                return -1;
            else if (p2.creationdate)
                return 1;
            else
                return 0;
        });
    };
    ProjectDashboardComponent.prototype.setFilteredProjects = function () {
        var _this = this;
        // Apply any filter
        if (this.filterText && this.filterText.length > 0)
            this.filteredProjects = this.projects
                .map(function (v) { return filterProject(v, _this.filterText); })
                .filter(function (v) { return v != undefined; });
        else
            this.filteredProjects = this.projects;
        // Then limit the list based on the desired table size
        this.setDisplayedProjects();
    };
    ProjectDashboardComponent.prototype.filterProjects = function () {
        // Send user input through "subject" so it gets "debounced"
        // Otherwise, the input is sent with EACH KEYSTROKE
        this.filterInput.next();
    };
    ProjectDashboardComponent.prototype.isListExpanded = function () {
        return this.tableSize > this.initTableSize;
    };
    ProjectDashboardComponent.prototype.setDisplayedProjects = function () {
        if (this.isListExpanded())
            this.displayedProjects = this.filteredProjects;
        else
            this.displayedProjects = this.filteredProjects.slice(0, this.initTableSize);
    };
    ProjectDashboardComponent.prototype.toggleListSize = function () {
        // TODO: implement actual loading from server when list is expanded/collapsed?
        if (this.isListExpanded())
            this.tableSize = this.initTableSize;
        else
            this.tableSize = this.filteredProjects.length;
        this.setDisplayedProjects();
    };
    ProjectDashboardComponent.prototype.newProject = function () {
        var _this = this;
        var p = new Project();
        this.projectService.initProject(p, this.userService.getRemoteUserIp()).subscribe(function (result) {
            var projectId = result.id;
            _this.router.navigate(['/pdetail', projectId]);
        }, function (err) {
            console.error("Error initializing project", err);
        });
    };
    return ProjectDashboardComponent;
}());
export { ProjectDashboardComponent };
function filterProject(project, text) {
    if (text) {
        var searchString_1 = project.title;
        if (project.files) {
            project.files.forEach(function (f) { return searchString_1 = searchString_1 + " " + f.name; });
        }
        if (searchString_1.trim().toLocaleLowerCase().indexOf(text.trim().toLocaleLowerCase()) >= 0)
            return project;
        else
            return undefined;
    }
    return project;
}
