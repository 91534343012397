import { OnChanges, OnInit } from "@angular/core";
import { TaskService } from "../service/task.service";
import { UserService } from "../service/user.service";
import * as FileSaver from "file-saver";
import { InvoiceService } from "../service/invoice.service";
import { FileService } from "../service/file.service";
import { Router } from "@angular/router";
import { MatSnackBar } from "@angular/material/snack-bar";
var PurchaseInvoiceComponent = /** @class */ (function () {
    function PurchaseInvoiceComponent(router, snackbar, taskService, invoiceService, fileService, userService) {
        this.router = router;
        this.snackbar = snackbar;
        this.taskService = taskService;
        this.invoiceService = invoiceService;
        this.fileService = fileService;
        this.userService = userService;
        this.isExisting = false;
        this.selectedTasks = [];
        this.totalInvoiceAmount = 0.0;
        this.totalInvitationReward = 0.0;
        this.includeInvitationReward = false;
    }
    PurchaseInvoiceComponent.prototype.ngOnInit = function () {
        this.init();
    };
    PurchaseInvoiceComponent.prototype.ngOnChanges = function () {
        this.init();
    };
    PurchaseInvoiceComponent.prototype.init = function () {
        var _this = this;
        this.isExisting = this.invoice != null && this.invoice.id != null;
        if (this.isExisting) {
            this.totalInvoiceAmount = this.invoice.total;
            // The tasks are fetched through the invoice details
            this.taskService.findTaskForInvoice(this.invoice.id).subscribe(function (tasks) {
                // For registered invoices the tasks are always all the selected tasks
                _this.tasks = tasks;
                _this.selectedTasks = tasks;
            });
            this.invitationRewards = this.invoice.rewards;
            this.calculateInvitationTotalRewards();
        }
        else {
            this.invoice = {};
            this.invoice.type = "PURCHASE";
            this.taskService.getInvoiceableTasks().subscribe(function (tasks) {
                _this.tasks = tasks;
            });
            this.userService.getTotalPendingInviteRewards().subscribe(function (rewards) {
                _this.invitationRewards = rewards;
                _this.calculateInvitationTotalRewards();
            });
        }
    };
    PurchaseInvoiceComponent.prototype.selectionChange = function (tasks) {
        if (!this.isExisting) {
            this.selectedTasks = tasks.selectedOptions.selected.map(function (item) { return item.value; });
            this.calculateTotal();
        }
    };
    PurchaseInvoiceComponent.prototype.calculateTotal = function () {
        var _this = this;
        if (!this.isExisting) {
            this.totalInvoiceAmount = 0.0;
            this.selectedTasks.map(function (t) { return parseFloat("" + t.price); }).forEach(function (p) {
                _this.totalInvoiceAmount += p;
            });
            if (this.includeInvitationReward) {
                this.totalInvoiceAmount += this.totalInvitationReward;
            }
        }
    };
    PurchaseInvoiceComponent.prototype.calculateInvitationTotalRewards = function () {
        var _this = this;
        this.totalInvitationReward = 0.0;
        if (this.invitationRewards != undefined) {
            this.invitationRewards.map(function (reward) { return reward.rewardAmount; })
                .forEach(function (rewardAmount) { return _this.totalInvitationReward = _this.totalInvitationReward += rewardAmount; });
        }
    };
    PurchaseInvoiceComponent.prototype.allDataComplete = function () {
        return this.selectedTasks.length > 0 && this.invoice.number && this.invoice.duedate && this.invoice.date && this.invoicePdf != null;
    };
    PurchaseInvoiceComponent.prototype.setInvoicePDFFile = function (event) {
        var fileList = event.target.files;
        if (fileList.length > 0) {
            this.invoicePdf = fileList[0];
        }
    };
    PurchaseInvoiceComponent.prototype.downloadInvoicePDF = function () {
        var _this = this;
        var entityId = this.getInvoiceEntityId();
        this.fileService.downloadInvoicePDF(entityId, this.invoice.id).subscribe(function (n) {
            FileSaver.saveAs(n, _this.invoice.number + ".pdf", true);
        });
    };
    PurchaseInvoiceComponent.prototype.getInvoiceEntityId = function () {
        if (this.company != null && this.company.id != null)
            return this.company.id;
        return this.user.id;
    };
    PurchaseInvoiceComponent.prototype.registerInvoice = function () {
        var _this = this;
        this.invoice.total = this.totalInvoiceAmount;
        var rewards = [];
        if (this.includeInvitationReward)
            rewards = this.invitationRewards;
        this.invoiceService.registerInvoice(this.invoice, this.selectedTasks, rewards, this.company).subscribe(function (invoiceId) {
            _this.invoice.id = invoiceId;
            _this.fileService.uploadPDFForInvoice(_this.invoice, _this.invoicePdf).subscribe(function (v) { return _this.snackbar.open("PDF uploaded", "", {
                duration: 2000
            }); });
            _this.snackbar.open("Invoice registered", "", {
                duration: 2000
            });
            _this.router.navigate(['/invoice', _this.invoice.id]);
        });
    };
    return PurchaseInvoiceComponent;
}());
export { PurchaseInvoiceComponent };
