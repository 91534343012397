import { AfterViewInit, EventEmitter, OnInit } from "@angular/core";
import { Observable } from "rxjs";
import { filter, map } from "rxjs/operators";
var ColleagueSelectListComponent = /** @class */ (function () {
    function ColleagueSelectListComponent() {
        this.selectedColleagues = [];
        this.colleagues = [];
        this.searchText = undefined;
        this.eventEmitter = new EventEmitter();
        this.filteredColleagues = [];
    }
    ColleagueSelectListComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.filteredColleaguesObservable = Observable.from(this.colleagues)
            .pipe(map(function (a) { return a; }), map(function (a) {
            return { user: a, filterValue: a.firstName + ' ' + a.lastName };
        }), filter(function (a) {
            if (_this.searchText === undefined) {
                return true;
            }
            return a.filterValue.toLocaleLowerCase().indexOf(_this.searchText.toLocaleLowerCase()) >= 0;
        }), map(function (a) { return a.user; })).toArray();
        this.filterColleagues();
    };
    ColleagueSelectListComponent.prototype.ngAfterViewInit = function () {
        this.focusOnSearchInput();
    };
    ColleagueSelectListComponent.prototype.focusOnSearchInput = function () {
        document.getElementById('colleagueSearchInput').focus();
    };
    ColleagueSelectListComponent.prototype.toggleColleague = function (colleague) {
        var index = this.selectedColleagues.indexOf(colleague);
        if (index >= 0) {
            this.onRemove(colleague);
        }
        else {
            this.onSelect(colleague);
        }
    };
    // No emitting needed because the array is the same object through the components
    ColleagueSelectListComponent.prototype.onSelect = function (colleague) {
        this.selectedColleagues.push(colleague);
        this.eventEmitter.emit({ colleague: colleague, type: 'add' });
        this.searchText = undefined;
        this.focusOnSearchInput();
    };
    // No emitting needed because the array is the same object through the components
    ColleagueSelectListComponent.prototype.onRemove = function (colleague) {
        this.selectedColleagues.splice(this.selectedColleagues.indexOf(colleague), 1);
        this.eventEmitter.emit({ colleague: colleague, type: 'remove' });
    };
    ColleagueSelectListComponent.prototype.isSelected = function (colleague) {
        return this.selectedColleagues.indexOf(colleague) >= 0;
    };
    ColleagueSelectListComponent.prototype.filterColleagues = function () {
        var _this = this;
        if (this.searchText === undefined) {
            this.filteredColleagues = this.colleagues;
        }
        else {
            this.filteredColleagues = [];
            this.filteredColleaguesObservable.subscribe(function (a) {
                _this.filteredColleagues = a;
            });
        }
    };
    return ColleagueSelectListComponent;
}());
export { ColleagueSelectListComponent };
