import { MatDialogRef } from "@angular/material/dialog";
var WaitingDialog = /** @class */ (function () {
    function WaitingDialog(dialogRef, data) {
        this.dialogRef = dialogRef;
        this.data = data;
    }
    WaitingDialog.prototype.ngOnInit = function () {
        this.waitingText = this.data.waitingText;
    };
    return WaitingDialog;
}());
export { WaitingDialog };
