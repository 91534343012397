import { from as observableFrom, of as observableOf } from 'rxjs';
import { filter, isEmpty, map, mergeMap } from 'rxjs/operators';
import { HttpClient } from "@angular/common/http";
import { getBaseUrl } from "../util/httpclient";
var CountryService = /** @class */ (function () {
    function CountryService(http) {
        var _this = this;
        this.http = http;
        this.COUNTRY_API = getBaseUrl() + "/api/v1/countries";
        this.PRICING_API = getBaseUrl() + "/api/v1/pricing";
        this.exchangeRateMap = [];
        this.currencies = [
            { code: "EUR", value: "Euro" },
            { code: "USD", value: "United States Dollars" },
            { code: "GBP", value: "United Kingdom Pounds" },
            // {code: "DZD", value: "Algeria Dinars"},
            // {code: "ARP", value: "Argentina Pesos"},
            // {code: "AUD", value: "Australia Dollars"},
            // {code: "ATS", value: "Austria Schillings"},
            // {code: "BSD", value: "Bahamas Dollars"},
            // {code: "BBD", value: "Barbados Dollars"},
            // {code: "BEF", value: "Belgium Francs"},
            // {code: "BMD", value: "Bermuda Dollars"},
            // {code: "BRR", value: "Brazil Real"},
            // {code: "BGL", value: "Bulgaria Lev"},
            { code: "CAD", value: "Canada Dollars" },
            // {code: "CLP", value: "Chile Pesos"},
            // {code: "CNY", value: "China Yuan Renmimbi"},
            // {code: "CYP", value: "Cyprus Pounds"},
            // {code: "CSK", value: "Czech Republic Koruna"},
            { code: "DKK", value: "Denmark Kroner" },
            // {code: "NLG", value: "Dutch Guilders"},
            // {code: "XCD", value: "Eastern Caribbean Dollars"},
            // {code: "EGP", value: "Egypt Pounds"},
            // {code: "FJD", value: "Fiji Dollars"},
            // {code: "FIM", value: "Finland Markka"},
            // {code: "FRF", value: "France Francs"},
            // {code: "DEM", value: "Germany Deutsche Marks"},
            // {code: "XAU", value: "Gold Ounces"},
            // {code: "GRD", value: "Greece Drachmas"},
            // {code: "HKD", value: "Hong Kong Dollars"},
            // {code: "HUF", value: "Hungary Forint"},
            // {code: "ISK", value: "Iceland Krona"},
            // {code: "INR", value: "India Rupees"},
            // {code: "IDR", value: "Indonesia Rupiah"},
            // {code: "IEP", value: "Ireland Punt"},
            // {code: "ILS", value: "Israel New Shekels"},
            // {code: "ITL", value: "Italy Lira"},
            // {code: "JMD", value: "Jamaica Dollars"},
            // {code: "JPY", value: "Japan Yen"},
            // {code: "JOD", value: "Jordan Dinar"},
            // {code: "KRW", value: "Korea (South) Won"},
            // {code: "LBP", value: "Lebanon Pounds"},
            // {code: "LUF", value: "Luxembourg Francs"},
            // {code: "MYR", value: "Malaysia Ringgit"},
            // {code: "MXP", value: "Mexico Pesos"},
            // {code: "NLG", value: "Netherlands Guilders"},
            // {code: "NZD", value: "New Zealand Dollars"},
            { code: "NOK", value: "Norway Kroner" },
            // {code: "PKR", value: "Pakistan Rupees"},
            // {code: "XPD", value: "Palladium Ounces"},
            // {code: "PHP", value: "Philippines Pesos"},
            // {code: "XPT", value: "Platinum Ounces"},
            // {code: "PLZ", value: "Poland Zloty"},
            // {code: "PTE", value: "Portugal Escudo"},
            // {code: "ROL", value: "Romania Leu"},
            { code: "RUB", value: "Russia Rubles" },
            // {code: "SAR", value: "Saudi Arabia Riyal"},
            // {code: "XAG", value: "Silver Ounces"},
            // {code: "SGD", value: "Singapore Dollars"},
            // {code: "SKK", value: "Slovakia Koruna"},
            // {code: "ZAR", value: "South Africa Rand"},
            // {code: "KRW", value: "South Korea Won"},
            // {code: "ESP", value: "Spain Pesetas"},
            // {code: "XDR", value: "Special Drawing Right (IMF)"},
            // {code: "SDD", value: "Sudan Dinar"},
            { code: "SEK", value: "Sweden Krona" },
            { code: "CHF", value: "Switzerland Francs" },
        ];
        this.timezones = [
            { code: "-12", value: "(UTC-12:00) International Date Line West", jzoneid: "Etc/GMT+12" },
            { code: "-11", value: "(UTC-11:00) Midway Island, Samoa", jzoneid: "Pacific/Midway" },
            { code: "-10", value: "(UTC-10:00) Hawaii", jzoneid: "Pacific/Honolulu" },
            { code: "-9", value: "(UTC-09:00) Alaska", jzoneid: "America/Anchorage" },
            { code: "-8", value: "(UTC-08:00) Pacific Time (US & Canada)", jzoneid: "America/Los_Angeles" },
            { code: "-8", value: "(UTC-08:00) Tijuana, Baja California", jzoneid: "America/Tijuana" },
            { code: "-7", value: "(UTC-07:00) Arizona", jzoneid: "America/Phoenix" },
            { code: "-7", value: "(UTC-07:00) Chihuahua, La Paz, Mazatlan", jzoneid: "America/Mazatlan" },
            { code: "-7", value: "(UTC-07:00) Mountain Time (US & Canada)", jzoneid: "America/Denver" },
            { code: "-6", value: "(UTC-06:00) Central Time (US & Canada)", jzoneid: "America/Chicago" },
            { code: "-6", value: "(UTC-06:00) Guadalajara, Mexico City, Monterrey", jzoneid: "America/Mexico_City" },
            { code: "-6", value: "(UTC-06:00) Saskatchewan", jzoneid: "America/Regina" },
            { code: "-5", value: "(UTC-05:00) Bogota, Lima, Quito, Rio Branco", jzoneid: "America/Bogota" },
            { code: "-5", value: "(UTC-05:00) Eastern Time (US & Canada)", jzoneid: "America/New_York" },
            { code: "-5", value: "(UTC-05:00) Indiana (East)", jzoneid: "America/Indiana/Indianapolis" },
            { code: "-5", value: "(UTC-05:00) Panama, Jamaica", jzoneid: "America/Panama" },
            { code: "-4", value: "(UTC-04:00) Atlantic Time (Canada)", jzoneid: "America/Halifax" },
            { code: "-4", value: "(UTC-04:00) Caracas, La Paz", jzoneid: "America/Caracas" },
            { code: "-4", value: "(UTC-04:00) Manaus", jzoneid: "America/Manaus" },
            { code: "-4", value: "(UTC-04:00) Santiago", jzoneid: "America/Santiago" },
            { code: "-3.5", value: "(UTC-03:30) Newfoundland", jzoneid: "America/St_Johns" },
            { code: "-3", value: "(UTC-03:00) Brasilia, Sao Paulo", jzoneid: "America/Sao_Paulo" },
            { code: "-3", value: "(UTC-03:00) Buenos Aires, Georgetown", jzoneid: "America/Argentina/Buenos_Aires" },
            { code: "-3", value: "(UTC-03:00) Greenland", jzoneid: "America/Godthab" },
            { code: "-3", value: "(UTC-03:00) Montevideo", jzoneid: "America/Montevideo" },
            { code: "-2", value: "(UTC-02:00) Mid-Atlantic", jzoneid: "America/Noronha" },
            { code: "-1", value: "(UTC-01:00) Cape Verde", jzoneid: "Atlantic/Cape_Verde" },
            { code: "-1", value: "(UTC-01:00) Azores", jzoneid: "Atlantic/Azores" },
            { code: "0", value: "(UTC+00:00) Casablanca, Monrovia, Reykjavik", jzoneid: "Africa/Casablanca" },
            { code: "0", value: "(UTC+00:00) Greenwich Mean Time : Dublin, Edinburgh, Lisbon, London", jzoneid: "Europe/London" },
            { code: "1", value: "(UTC+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna", jzoneid: "Europe/Rome" },
            { code: "1", value: "(UTC+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague", jzoneid: "Europe/Belgrade" },
            { code: "1", value: "(UTC+01:00) Brussels, Copenhagen, Madrid, Paris", jzoneid: "Europe/Brussels" },
            { code: "1", value: "(UTC+01:00) Sarajevo, Skopje, Warsaw, Zagreb", jzoneid: "Europe/Sarajevo" },
            { code: "1", value: "(UTC+01:00) West Central Africa", jzoneid: "Africa/Lagos" },
            { code: "2", value: "(UTC+02:00) Amman", jzoneid: "Asia/Amman" },
            { code: "2", value: "(UTC+02:00) Athens, Bucharest, Istanbul", jzoneid: "Europe/Athens" },
            { code: "2", value: "(UTC+02:00) Beirut", jzoneid: "Asia/Beirut" },
            { code: "2", value: "(UTC+02:00) Cairo", jzoneid: "Africa/Cairo" },
            { code: "2", value: "(UTC+02:00) Harare, Pretoria", jzoneid: "Africa/Maputo" },
            { code: "2", value: "(UTC+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius", jzoneid: "Europe/Helsinki" },
            { code: "2", value: "(UTC+02:00) Jerusalem", jzoneid: "Asia/Jerusalem" },
            { code: "2", value: "(UTC+02:00) Kaliningrad", jzoneid: "Europe/Kaliningrad" },
            { code: "2", value: "(UTC+02:00) Windhoek", jzoneid: "Africa/Windhoek" },
            { code: "3", value: "(UTC+03:00) Kuwait, Riyadh, Baghdad", jzoneid: "Asia/Riyadh" },
            { code: "3", value: "(UTC+03:00) Minsk, Moscow, St. Petersburg, Volgograd", jzoneid: "Europe/Minsk" },
            { code: "3", value: "(UTC+03:00) Nairobi", jzoneid: "Africa/Nairobi" },
            { code: "3.5", value: "(UTC+03:30) Tehran", jzoneid: "Asia/Tehran" },
            { code: "4", value: "(UTC+04:00) Abu Dhabi, Muscat", jzoneid: "Asia/Dubai" },
            { code: "4", value: "(UTC+04:00) Baku", jzoneid: "Asia/Dubai" },
            { code: "4", value: "(UTC+04:00) Tbilisi", jzoneid: "Asia/Tbilisi" },
            { code: "4", value: "(UTC+04:00) Yerevan", jzoneid: "Asia/Yerevan" },
            { code: "4.5", value: "(UTC+04:30) Kabul", jzoneid: "Asia/Kabul" },
            { code: "5", value: "(UTC+05:00) Islamabad, Karachi, Tashkent", jzoneid: "Asia/Karachi" },
            { code: "5", value: "(UTC+05:00) Yekaterinburg", jzoneid: "Asia/Yekaterinburg" },
            { code: "5.5", value: "(UTC+05:30) Sri Jayawardenapura", jzoneid: "Asia/Colombo" },
            { code: "5.5", value: "(UTC+05:30) Chennai, Kolkata, Mumbai, New Delhi", jzoneid: "Asia/Kolkata" },
            { code: "5.75", value: "(UTC+05:45) Kathmandu", jzoneid: "Asia/Kathmandu" },
            { code: "6", value: "(UTC+06:00) Almaty, Novosibirsk", jzoneid: "Asia/Almaty" },
            { code: "6", value: "(UTC+06:00) Astana, Dhaka", jzoneid: "Asia/Dhaka" },
            { code: "6.5", value: "(UTC+06:30) Yangon (Rangoon)", jzoneid: "Asia/Yangon" },
            { code: "7", value: "(UTC+07:00) Bangkok, Hanoi, Jakarta", jzoneid: "Asia/Bangkok" },
            { code: "7", value: "(UTC+07:00) Krasnoyarsk", jzoneid: "Asia/Krasnoyarsk" },
            { code: "8", value: "(UTC+08:00) Beijing, Chongqing, Hong Kong, Urumqi", jzoneid: "Asia/Hong_Kong" },
            { code: "8", value: "(UTC+08:00) Kuala Lumpur, Singapore", jzoneid: "Asia/Kuala_Lumpur" },
            { code: "8", value: "(UTC+08:00) Irkutsk, Ulaan Bataar", jzoneid: "Asia/Ulaanbaatar" },
            { code: "8", value: "(UTC+08:00) Perth", jzoneid: "Australia/Perth" },
            { code: "8", value: "(UTC+08:00) Taipei", jzoneid: "tAsia/Taipei" },
            { code: "9", value: "(UTC+09:00) Osaka, Sapporo, Tokyo", jzoneid: "Asia/Tokyo" },
            { code: "9", value: "(UTC+09:00) Seoul", jzoneid: "Asia/Seoul" },
            { code: "9", value: "(UTC+09:00) Yakutsk", jzoneid: "Asia/Yakutsk" },
            { code: "9.5", value: "(UTC+09:30) Adelaide", jzoneid: "Australia/Adelaide" },
            { code: "9.5", value: "(UTC+09:30) Darwin", jzoneid: "Australia/Darwin" },
            { code: "10", value: "(UTC+10:00) Brisbane", jzoneid: "Australia/Darwin" },
            { code: "10", value: "(UTC+10:00) Canberra, Melbourne, Sydney", jzoneid: "Australia/Sydney" },
            { code: "10", value: "(UTC+10:00) Hobart", jzoneid: "Australia/Hobart" },
            { code: "10", value: "(UTC+10:00) Guam, Port Moresby", jzoneid: "Pacific/Guam" },
            { code: "10", value: "(UTC+10:00) Vladivostok", jzoneid: "Asia/Vladivostok" },
            { code: "11", value: "(UTC+11:00) Magadan, Solomon Is., New Caledonia", jzoneid: "Asia/Magadan" },
            { code: "12", value: "(UTC+12:00) Auckland, Wellington", jzoneid: "Pacific/Auckland" },
            { code: "12", value: "(UTC+12:00) Fiji, Kamchatka, Marshall Is.", jzoneid: "Asia/Kamchatka" },
            { code: "12.75", value: "(UTC+12:45) Chatham Is.", jzoneid: "Pacific/Chatham" },
            { code: "13", value: "(UTC+13:00) Apia", jzoneid: "Pacific/Apia" },
            { code: "13", value: "(UTC+13:00) Nuku'alofa", jzoneid: "Pacific/Tongatapu" }
        ];
        this.getCountryMapObservable().subscribe(function (m) { return _this.countries = m; });
    }
    CountryService.prototype.getCountries = function () {
        //TODO store the pipe content in a local function and let that one be executed (lacking typescript knowledge)
        if (this.countries)
            return observableOf(this.countries).pipe(map(function (c) { return c.sort(function (a, b) { return a.display.localeCompare(b.display); }); }));
        else
            return this.getCountryMapObservable().pipe(map(function (c) { return c.sort(function (a, b) { return a.display.localeCompare(b.display); }); }));
    };
    CountryService.prototype.getCountryMapObservable = function () {
        return this.http.get(this.COUNTRY_API);
    };
    CountryService.prototype.getCurrencies = function () {
        return observableOf(this.currencies);
    };
    CountryService.prototype.getTimezones = function () {
        return observableOf(this.timezones);
    };
    CountryService.prototype.getExchangeRate = function (baseCurrency, currency) {
        var _this = this;
        if (baseCurrency === void 0) { baseCurrency = 'EUR'; }
        var cacheObservable = observableFrom(this.exchangeRateMap).pipe(filter(function (ex) { return ex.baseCurrency == baseCurrency && ex.currency == currency; }), map(function (ex) { return ex.rate; }));
        var serverObservable = this.http.get(this.PRICING_API + "/currency/rate/" + baseCurrency + "/" + currency).pipe(map(function (a) { return a; }), map(function (a) {
            _this.exchangeRateMap.push(a);
            return a.rate;
        }));
        return cacheObservable.pipe(isEmpty(), mergeMap(function (b) {
            if (b) {
                return serverObservable;
            }
            else {
                return cacheObservable;
            }
        }));
    };
    return CountryService;
}());
export { CountryService };
