import { CountryService } from "../service/country.service";
var PriceComponent = /** @class */ (function () {
    function PriceComponent(countryService) {
        this.countryService = countryService;
        this.baseCurrency = 'EUR';
        this.displayCurrency = sessionStorage.getItem("currency");
        this.digitsInfo = '1.2-2';
        this.display = 'symbol';
        this.showBase = false;
        this.rate = 1;
    }
    PriceComponent.prototype.ngOnInit = function () {
        var _this = this;
        if (this.baseCurrency == this.displayCurrency) {
            this.rate = 1;
        }
        else {
            this.countryService.getExchangeRate(this.baseCurrency, this.displayCurrency).subscribe(function (rate) {
                _this.rate = rate;
            });
        }
    };
    PriceComponent.prototype.tooltipText = function () {
        if (this.baseCurrency === this.displayCurrency)
            return undefined;
        else {
            return "The pricing in " + this.displayCurrency + " is merely indicative. " +
                "It is calculated with " + this.baseCurrency + " as base currency, and an exchange rate of " + this.rate + ".";
        }
    };
    PriceComponent.prototype.getCurrStyles = function () {
        if (this.currStyles)
            return this.currStyles;
        else
            return "";
    };
    return PriceComponent;
}());
export { PriceComponent };
