import * as tslib_1 from "tslib";
import { OnDestroy } from "@angular/core";
import { Project } from "../dto/project";
import { ProjectService } from "../service/project.service";
import { getProjectStatus, getStatusColor } from "../util/jobutil";
import { FileService } from "../service/file.service";
import { filter, map } from 'rxjs/operators';
import { SubscriptionHandler } from '../util/subscription.handler';
var ProjectCardComponent = /** @class */ (function () {
    function ProjectCardComponent(projectService, fileService) {
        this.projectService = projectService;
        this.fileService = fileService;
        this.filePageStack = [];
        this.frontpageImage = null;
        this.docTypeImage = null;
        this.status = "Active";
        this.subscriptionHandler = new SubscriptionHandler(this);
    }
    ProjectCardComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.files = this.projectService.getFileNames(this.project, "SOURCE");
        this.status = getProjectStatus(this.project);
        this.sourceLanguage = this.projectService.getSourceLanguage(this.project);
        this.targetLanguages = this.projectService.getTargetLanguages(this.project);
        if (this.targetLanguages != null)
            this.targetLanguages.sort(function (a, b) { return a.toUpperCase().localeCompare(b.toUpperCase()); });
        if (this.files != null && this.files.length > 0) {
            var firstFile = this.files[0];
            this.filePageStack = this.createFileStack(this.files);
            this.docTypeImage = this.getDoctypeImage(firstFile);
            // Try to get a frontpage image, but only go as far as trying 5 files to prevent taxing the system too much
            // Most projects won't contain more than 5 anyway
            this.getAnyCardFrontPage(this.files.slice(0, 6));
            var frontPageSubscription = this.fileService.enrollForProject(this.project.id)
                .pipe(map(function (data) {
                return data;
            }), filter(function (data) { return data.action == "FRONTPAGE_IMG" && _this.frontpageImage == null; }))
                .subscribe(function (data) {
                _this.getCardFrontPage(data.filename);
            });
            this.subscriptionHandler.push(frontPageSubscription);
        }
    };
    ProjectCardComponent.prototype.ngOnDestroy = function () {
    };
    ProjectCardComponent.prototype.getStatusColor = function () {
        return getStatusColor(getProjectStatus(this.project));
    };
    ProjectCardComponent.prototype.getAnyCardFrontPage = function (files) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var files_1, files_1_1, fileName, _a, e_1, e_2_1;
            var e_2, _b;
            return tslib_1.__generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _c.trys.push([0, 8, 9, 10]);
                        files_1 = tslib_1.__values(files), files_1_1 = files_1.next();
                        _c.label = 1;
                    case 1:
                        if (!!files_1_1.done) return [3 /*break*/, 7];
                        fileName = files_1_1.value;
                        _c.label = 2;
                    case 2:
                        _c.trys.push([2, 5, , 6]);
                        if (!(this.frontpageImage === null && fileName != null)) return [3 /*break*/, 4];
                        _a = this;
                        return [4 /*yield*/, this.fileService.getFrontpage(this.project.id, fileName).toPromise()];
                    case 3:
                        _a.frontpageImage = _c.sent();
                        _c.label = 4;
                    case 4: return [3 /*break*/, 6];
                    case 5:
                        e_1 = _c.sent();
                        return [3 /*break*/, 6];
                    case 6:
                        files_1_1 = files_1.next();
                        return [3 /*break*/, 1];
                    case 7: return [3 /*break*/, 10];
                    case 8:
                        e_2_1 = _c.sent();
                        e_2 = { error: e_2_1 };
                        return [3 /*break*/, 10];
                    case 9:
                        try {
                            if (files_1_1 && !files_1_1.done && (_b = files_1.return)) _b.call(files_1);
                        }
                        finally { if (e_2) throw e_2.error; }
                        return [7 /*endfinally*/];
                    case 10: return [2 /*return*/];
                }
            });
        });
    };
    ProjectCardComponent.prototype.getCardFrontPage = function (fileName) {
        var _this = this;
        if (fileName != null) {
            this.fileService.getFrontpage(this.project.id, fileName)
                .subscribe(function (next) {
                _this.frontpageImage = next;
            }, function (error) {
                // No frontpage found
            });
        }
    };
    ProjectCardComponent.prototype.createFileStack = function (sourceFiles) {
        if (sourceFiles.length > 1) {
            return sourceFiles.slice(1, 5);
        }
        else
            return [];
    };
    ProjectCardComponent.prototype.getDoctypeImage = function (fileName) {
        // Most images found at https://www.flaticon.com/packs/file-type-set
        var basePath = "assets/doctype/";
        return basePath + fileName.substring(fileName.lastIndexOf(".") + 1) + ".png";
    };
    ProjectCardComponent.prototype.getUnknownDoctypeImage = function () {
        return "assets/doctype/unknown.png";
    };
    ProjectCardComponent.prototype.getTargetLangDisplay = function () {
        var langDisplay = "--";
        if (this.targetLanguages != null && this.targetLanguages.length > 0) {
            langDisplay = this.targetLanguages.slice(0, 3).join(", ");
            if (this.targetLanguages.length > 3)
                langDisplay += " & " + (this.targetLanguages.length - 3) + " more";
        }
        return langDisplay;
    };
    return ProjectCardComponent;
}());
export { ProjectCardComponent };
