import * as tslib_1 from "tslib";
import { ElementRef, EventEmitter, OnInit } from "@angular/core";
import { InvoiceService } from "../../service/invoice.service";
import { PricingService } from '../../service/pricing.services';
var PaypalComponent = /** @class */ (function () {
    function PaypalComponent(invoiceService, pricingService) {
        this.invoiceService = invoiceService;
        this.pricingService = pricingService;
        this.approveEmitter = new EventEmitter();
        // This is the pu that is used to create the order and is given
        // afterwards to the server to store in the db
        this.cachedPurchaseUnit = undefined;
    }
    PaypalComponent.prototype.ngOnInit = function () {
        var _this = this;
        paypal
            .Buttons({
            style: {
                layout: 'vertical',
                color: 'blue',
                shape: 'pill',
                label: 'checkout'
            },
            createOrder: function (data, actions) {
                return _this.createPaypalOrder(actions);
            },
            onApprove: function (data, actions) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                var orderData;
                return tslib_1.__generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, actions.order.authorize().then(function (authorization) {
                                // Get the authorization id
                                var authorizationID = authorization.purchase_units[0]
                                    .payments.authorizations[0].id;
                                console.debug('Authorazation id', authorizationID);
                                orderData = { orderID: data.orderID,
                                    authorizationID: authorizationID };
                            })];
                        case 1:
                            _a.sent();
                            // set the pu that is created during order creation
                            orderData.pu = this.cachedPurchaseUnit;
                            this.invoiceService.storeProjectPaypal(this.project.id, orderData)
                                .subscribe(function (a) {
                                return console.debug('Authorization saved');
                            }, function (e) { return console.error(e); });
                            this.approveEmitter.emit(orderData);
                            return [2 /*return*/, {}];
                    }
                });
            }); },
            onError: function (err) {
                console.log(err);
            }
        })
            .render(this.paypalElement.nativeElement);
    };
    PaypalComponent.prototype.createPaypalOrder = function (actions) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var order;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.pricingService.getTotalProjectPrice(this.project.id)
                            .map(function (p) { return p.totalIncVat(); })
                            .map(function (p) { return Math.round(p * 100) / 100; })
                            .map(function (p) {
                            return _this.getPaypalPurchaseUnit('' + p);
                        })
                            .map(function (pu) {
                            // Cache the pruchase unit that is used so it can be used for the db as well
                            _this.cachedPurchaseUnit = pu;
                            return {
                                purchase_units: [pu]
                            };
                        })
                            .map(function (o) {
                            return actions.order.create(o);
                        }).toPromise()];
                    case 1:
                        order = _a.sent();
                        console.debug(order);
                        return [2 /*return*/, order];
                }
            });
        });
    };
    PaypalComponent.prototype.getPaypalPurchaseUnit = function (price) {
        return {
            description: this.project.title,
            amount: {
                currency_code: 'EUR',
                value: price
            }
        };
    };
    return PaypalComponent;
}());
export { PaypalComponent };
