import * as tslib_1 from "tslib";
// http://www.fileformat.info/info/unicode/category/No/list.htm
export var REF_ANCHOR = "\u2693";
export var REF_BASE = "2460";
export function isRef(o) {
    return o.className && o.className.indexOf('Reference') == 0;
}
export function print(s, prefix) {
    console.debug(prefix ? prefix : "segment", s);
}
export function printAsString(s, prefix) {
    console.debug((prefix ? prefix : "segment") + ": " + JSON.stringify(s));
}
export function trimLeading(s) {
    return s.replace(/^\s+/g, "");
}
export function trimTrailing(s) {
    return s.replace(/\s+$/g, "");
}
export function trimSegment(s) {
    var e_1, _a, e_2, _b, e_3, _c;
    // we only consider refs safe to trim IF:
    // - there is an equal amount both leading and trailing
    // - there are no other refs in the middle of the text
    // leading and trailing whitespaces are ignored and trimmed also
    // todo: this can be made smarter; the check that there is an equal amount in both affixes seems overly harsh
    s = trimSegAffix(s);
    // check whether the count of the leading refs is equal to the count of the trailing ones
    var start = Date.now();
    var prefixRefCount = 0;
    if (s.prefix && s.prefix.length > 0) {
        try {
            for (var _d = tslib_1.__values(s.prefix), _e = _d.next(); !_e.done; _e = _d.next()) {
                var i = _e.value;
                if (isRef(i))
                    prefixRefCount++;
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_e && !_e.done && (_a = _d.return)) _a.call(_d);
            }
            finally { if (e_1) throw e_1.error; }
        }
    }
    var suffixRefCount = 0;
    if (s.suffix && s.suffix.length > 0) {
        try {
            for (var _f = tslib_1.__values(s.suffix), _g = _f.next(); !_g.done; _g = _f.next()) {
                var i = _g.value;
                if (isRef(i))
                    suffixRefCount++;
            }
        }
        catch (e_2_1) { e_2 = { error: e_2_1 }; }
        finally {
            try {
                if (_g && !_g.done && (_b = _f.return)) _b.call(_f);
            }
            finally { if (e_2) throw e_2.error; }
        }
    }
    var invalidRefAffix = false;
    // if the counts are equal, then check whether any refs remain in the "trimmed content"
    if (prefixRefCount == suffixRefCount && s.trimmedContent) {
        try {
            for (var _h = tslib_1.__values(s.trimmedContent), _j = _h.next(); !_j.done; _j = _h.next()) {
                var i = _j.value;
                // if any ref left, then we can't trim the leading/trailing refs
                if (isRef(i)) {
                    invalidRefAffix = true;
                    break;
                }
            }
        }
        catch (e_3_1) { e_3 = { error: e_3_1 }; }
        finally {
            try {
                if (_j && !_j.done && (_c = _h.return)) _c.call(_h);
            }
            finally { if (e_3) throw e_3.error; }
        }
    }
    else
        invalidRefAffix = true;
    if (invalidRefAffix) {
        // revert the trimmed refs if invalid
        s.prefix = undefined;
        s.suffix = undefined;
        s.trimmedContent = undefined;
        // and try to just trim the whitespace then
        s = trimSegAffix(s, false);
    }
    // print(s, "==> trim RESULT");
    return s;
}
function trimSegAffix(s, trimRefs) {
    if (trimRefs === void 0) { trimRefs = true; }
    var start = Date.now();
    // printAsString(s, "1) before trim");
    s = trimSegPrefix(s, trimRefs);
    // printAsString(s, "2) trim prefix");
    s = trimSegSuffix(s, trimRefs);
    // printAsString(s, "3) trim suffix");
    return s;
}
function trimSegPrefix(s, trimRefs) {
    var e_4, _a;
    if (trimRefs === void 0) { trimRefs = true; }
    var prefix = new Array();
    var trimmed = new Array();
    var trimming = true;
    // make a deep copy of the array so we don't alter the original
    var contentToTrim = Object.assign([], s.trimmedContent && s.trimmedContent.length > 0 ? s.trimmedContent : s.content);
    try {
        for (var contentToTrim_1 = tslib_1.__values(contentToTrim), contentToTrim_1_1 = contentToTrim_1.next(); !contentToTrim_1_1.done; contentToTrim_1_1 = contentToTrim_1.next()) {
            var i = contentToTrim_1_1.value;
            if (i) {
                if (trimming) {
                    if (isRef(i)) {
                        if (trimRefs)
                            prefix.push(i);
                        else {
                            trimmed.push(i);
                            trimming = false;
                        }
                    }
                    else {
                        if (i.length > 0) {
                            // search for the first non-whitespace character (\S = [^ \t\r\n\f])
                            var idx = i.search(/\S/);
                            // if a non-whitespace character was found and it was not the very first character, then extract the bit
                            // before this character and store it in the prefix
                            // stop trimming if a non-whitespace character was found
                            if (idx > 0) {
                                var whites = i.substring(0, idx);
                                prefix.push(whites);
                                var rest = i.substring(idx);
                                trimmed.push(rest);
                                trimming = false;
                            }
                            else if (idx === 0) {
                                trimmed.push(i);
                                trimming = false;
                            }
                            else {
                                // no non-whitespace character found, so continue
                                prefix.push(i);
                            }
                        }
                    }
                }
                else
                    trimmed.push(i);
            }
        }
    }
    catch (e_4_1) { e_4 = { error: e_4_1 }; }
    finally {
        try {
            if (contentToTrim_1_1 && !contentToTrim_1_1.done && (_a = contentToTrim_1.return)) _a.call(contentToTrim_1);
        }
        finally { if (e_4) throw e_4.error; }
    }
    if (prefix.length > 0) {
        s.prefix = prefix;
        s.trimmedContent = trimmed;
    }
    return s;
}
function trimSegSuffix(s, trimRefs) {
    var e_5, _a;
    if (trimRefs === void 0) { trimRefs = true; }
    var suffix = new Array();
    var trimmed = new Array();
    var trimming = true;
    // make a deep copy of the array so we don't alter the original
    var contentToTrim = Object.assign([], s.trimmedContent && s.trimmedContent.length > 0 ? s.trimmedContent : s.content);
    try {
        // start from the back
        for (var _b = tslib_1.__values(contentToTrim.reverse()), _c = _b.next(); !_c.done; _c = _b.next()) {
            var i = _c.value;
            if (i) {
                if (trimming) {
                    if (isRef(i)) {
                        if (trimRefs)
                            suffix.push(i);
                        else {
                            trimmed.push(i);
                            trimming = false;
                        }
                    }
                    else {
                        if (i.length > 0) {
                            // reverse the string, so we can search backward
                            var reversed = i.split("").reverse().join("");
                            // search for the first non-whitespace character (\S = [^ \t\r\n\f])
                            var idx = reversed.search(/\S/);
                            // if a non-whitespace character was found and it was not the very first character, then extract suffix and store it
                            // stop trimming if a non-whitespace character was found
                            if (idx > 0) {
                                var ridx = (i.length) - idx;
                                var whites = i.substring(ridx);
                                suffix.push(whites);
                                var rest = i.substring(0, ridx);
                                trimmed.push(rest);
                                trimming = false;
                            }
                            else if (idx === 0) {
                                trimmed.push(i);
                                trimming = false;
                            }
                            else {
                                // no non-whitespace character found, so continue
                                suffix.push(i);
                            }
                        }
                    }
                }
                else
                    trimmed.push(i);
            }
        }
    }
    catch (e_5_1) { e_5 = { error: e_5_1 }; }
    finally {
        try {
            if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
        }
        finally { if (e_5) throw e_5.error; }
    }
    if (suffix.length > 0) {
        s.suffix = suffix.reverse();
        s.trimmedContent = trimmed.reverse();
    }
    return s;
}
export function isNoText(content) {
    var e_6, _a;
    var hasText = false;
    if (content != undefined) {
        try {
            for (var content_1 = tslib_1.__values(content), content_1_1 = content_1.next(); !content_1_1.done; content_1_1 = content_1.next()) {
                var o = content_1_1.value;
                if (o != null && !isRef(o)) {
                    hasText = o.toString().trim().length > 0;
                    if (hasText)
                        break;
                }
            }
        }
        catch (e_6_1) { e_6 = { error: e_6_1 }; }
        finally {
            try {
                if (content_1_1 && !content_1_1.done && (_a = content_1.return)) _a.call(content_1);
            }
            finally { if (e_6) throw e_6.error; }
        }
    }
    return !hasText;
}
export function segContentToText(content, refs) {
    var e_7, _a;
    var text = "";
    if (content != undefined) {
        try {
            for (var content_2 = tslib_1.__values(content), content_2_1 = content_2.next(); !content_2_1.done; content_2_1 = content_2.next()) {
                var o = content_2_1.value;
                if (o) {
                    if (isRef(o)) {
                        if (refs) {
                            text += toSymbol(o, refs);
                        }
                        else {
                            // Ignore refs if none given
                        }
                    }
                    else {
                        text += o;
                    }
                }
            }
        }
        catch (e_7_1) { e_7 = { error: e_7_1 }; }
        finally {
            try {
                if (content_2_1 && !content_2_1.done && (_a = content_2.return)) _a.call(content_2);
            }
            finally { if (e_7) throw e_7.error; }
        }
    }
    return text;
}
export function toSymbol(ref, refs) {
    var symbol;
    // Determine index of reference in the inventory, so we know what symbol to assign
    var index = -1;
    var currentAsJson = JSON.stringify(ref);
    for (var i = 0; i < refs.length; i++) {
        var refAsJson = JSON.stringify(refs[i]);
        // Use JSON representation to compare
        if (refAsJson === currentAsJson) {
            index = i;
            break;
        }
    }
    if (index >= 0) {
        // Start from \u2460 and count up
        // TODO: reorganize and check sequence
        var baseChar = parseInt(REF_BASE, 16);
        var symbolChar = (baseChar + index);
        // Prefix symbol with "anchor" to make it more unique
        symbol = REF_ANCHOR + String.fromCharCode(symbolChar);
    }
    if (!symbol)
        // "Unknown question mark" symbol, since the corresponding reference was not found
        symbol = "\uFFFD";
    return symbol;
}
export function toXml(ref) {
    var e_8, _a;
    var attribText = "";
    if (ref.attribs) {
        try {
            for (var _b = tslib_1.__values(Object.keys(ref.attribs)), _c = _b.next(); !_c.done; _c = _b.next()) {
                var a = _c.value;
                attribText += " " + a;
                if (ref.attribs[a])
                    attribText += "=\'" + ref.attribs[a] + "\'";
            }
        }
        catch (e_8_1) { e_8 = { error: e_8_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_8) throw e_8.error; }
        }
    }
    return "<" + (ref.start ? "" : "/") + ref.type
        + attribText +
        (ref.selfClosed ? "/" : "") + ">";
}
export function textToSegContent(segmentText, refs) {
    // Convert the segment text back a content array, split between text and references
    var content = [];
    // Determine range between which a reference symbol can be (based on the given reference array)
    var rangeStart = parseInt(REF_BASE, 16);
    var rangeEnd = rangeStart + refs.length;
    var idx = segmentText.indexOf(REF_ANCHOR);
    var prevIdx = 0;
    while (idx >= 0) {
        var symbolCode = segmentText.charCodeAt(idx + 1);
        // Check whether the character following the "anchor" is in the valid range
        // If not, then we assume this is no reference but that the "anchor" occurred "naturally" O.o
        if (symbolCode >= rangeStart && symbolCode < rangeEnd) {
            // If it is a valid symbol, we need to determine what reference it is
            var ref = refs[symbolCode - rangeStart];
            // Add text before reference
            if (prevIdx < idx)
                content.push(segmentText.substring(prevIdx, idx));
            // Add reference
            content.push(ref);
            // Add 2 to skip over anchor + reference symbol
            prevIdx = idx + 2;
        }
        // Add 1 to skip over anchor
        idx = segmentText.indexOf(REF_ANCHOR, (idx + 1));
    }
    // Add any remaining text
    if (prevIdx < segmentText.length) {
        content.push(segmentText.substring(prevIdx));
    }
    return content;
}
export function visualizeFormattingMarks(text) {
    return visualizeLineTerminators(visualizeWhiteSpaces(text));
}
/**
 * Replace the whitespaces by symbols to visualize them.
 *
 * @see https://en.wikipedia.org/wiki/Whitespace_character Whitespace character (wikipedia)
 * @see http://jkorpela.fi/chars/spaces.html Unicode whitespaces
 * @see https://docs.microsoft.com/en-us/typography/develop/character-design-standards/whitespace Space characters
 */
export function visualizeWhiteSpaces(text) {
    return text
        // SPACE => ·
        .replace(/ /g, "\u00B7")
        // NO-BREAK SPACE => ⍽
        .replace(/\u00A0/g, "\u237D")
        // EN QUAD => °
        .replace(/\u2000/g, "\u00B0")
        // EM QUAD => °
        .replace(/\u2001/g, "\u00B0")
        // EN SPACE (nut) => °
        .replace(/\u2002/g, "\u00B0")
        // EM SPACE (mutton) => °
        .replace(/\u2003/g, "\u00B0")
        // THREE-PER-EM SPACE (thick space) => ¦
        .replace(/\u2004/g, "\u00A6")
        // FOUR-PER-EM SPACE (mid space) => |
        .replace(/\u2005/g, "\u007C")
        // SIX-PER-EM SPACE =>  ⌇
        .replace(/\u2006/g, "\u2307")
        // FIGURE SPACE =>  ⌇
        .replace(/\u2007/g, "\u2307")
        // PUNCTUATION SPACE =>  ⌇
        .replace(/\u2008/g, "\u2307")
        // THIN SPACE =>  ⌇
        .replace(/\u2009/g, "\u2307")
        // HAIR SPACE =>  ⌇
        .replace(/\u200A/g, "\u2307")
        // ZERO WIDTH SPACE =>  ?
        .replace(/\u200B/g, "\uFFFD");
}
/**
 * Replace the line terminators (and tabs) by symbols to visualize them.
 *
 *@see https://en.wikipedia.org/wiki/Newline#Unicode
 */
export function visualizeLineTerminators(text) {
    return text
        // CR+LF: CR (U+000D) followed by LF (U+000A) => ⏎
        .replace(/\n\r/g, "\u23CE")
        // LF: Line Feed, U+000A => ␊
        .replace(/\n/g, "\u240A")
        // CR: Carriage Return, U+000D => ␍
        .replace(/\r/g, "\u240D")
        // TAB => →
        .replace(/\t/g, "\u2192")
        // U+000B <control-000B> (LINE TABULATION, vertical tabulation (VT)) => ␋
        .replace(/\u000B/g, "\u240B")
        // LS: Line Separator, U+2028 => ↵
        .replace(/\u2028/g, "\u21B5")
        // PS: Paragraph Separator, U+2029 => ¶ (pilcrow sign)
        .replace(/\u2029/g, "\u00B6");
}
