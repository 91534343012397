import { filter, map, publish } from 'rxjs/operators';
import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { IntervalObservable } from "rxjs/observable/IntervalObservable";
import { getBaseSocketUrl, getBaseUrl } from "../util/httpclient";
var ChatService = /** @class */ (function () {
    function ChatService(http) {
        this.http = http;
        this.CHAT_API = getBaseUrl() + "/api/v1/chats";
        this.CHAT_SOCKET_URL = getBaseSocketUrl() + "/chat/feed";
    }
    ChatService.prototype.fetchChatMessages = function (projectId) {
        return this.http.get(this.CHAT_API + "/project/" + projectId, { responseType: "json" }).pipe(map(function (r) { return r; }));
    };
    ChatService.prototype.createNewMessage = function (message) {
        return this.http.post(this.CHAT_API, message, { responseType: "json" });
    };
    ChatService.prototype.createReply = function (message, msgId) {
        return this.http.post(this.CHAT_API + "/reply/" + msgId, message, { responseType: "json" });
    };
    ChatService.prototype.fetchUnreadChatCount = function (projectId, userId) {
        return this.http.get(this.CHAT_API + "/unread/" + userId, { params: { userId: userId, projectId: projectId } }).pipe(map(function (r) { return r; }));
    };
    ChatService.prototype.markAsRead = function (msgId, userId) {
        return this.http.post(this.CHAT_API + "/read/" + msgId + "/" + userId, "", { responseType: "text" });
    };
    ChatService.prototype.initChatFeed = function () {
        var _this = this;
        this.ws = Observable.webSocket(this.CHAT_SOCKET_URL);
        //Keep the socket alive (every 4 minutes, server timeout is set to 5 minutes)
        IntervalObservable.create(30000).subscribe(function (o) {
            return _this.ws.next({ 'action': 'keepalive' });
        });
        this.messages = this.makeHot(this.ws).pipe(filter(function (m) { return m != null; }));
    };
    //For more info see analyses.service.ts
    ChatService.prototype.makeHot = function (cold) {
        var obs = cold.pipe(publish());
        obs.connect();
        return obs;
    };
    //TODO instead of enrolling for a project we should enroll for a user, this implies that the users which should receive the messages is included in the broadcast message
    ChatService.prototype.enrollForProjectChat = function (pId) {
        if (!this.ws || this.ws.closed)
            this.initChatFeed();
        //To enroll you have to send a message to the socket with the action "enroll" and to which objects you want to enroll
        this.ws.next({ 'action': 'enroll', 'pid': pId });
        //The JSON structure of the message normally has the objects parameters to identify and a "data" object which contains the actual information
        //E.g. {"pid":"10", "data":{"action":"PDF", "langname":"damocles.docx"}}
        var sessionUser = sessionStorage.getItem("user");
        var userId = undefined;
        if (sessionUser != null) {
            try {
                userId = JSON.parse(sessionUser).id;
            }
            catch (e) {
                userId = undefined;
            }
        }
        return this.messages.pipe(filter(function (msg) {
            return msg.pid == pId;
        }), filter(function (msg) {
            return msg.uid != userId;
        }), //If you are the one sending the message, no need to get notified
        map(function (msg) { return msg.data; }));
    };
    return ChatService;
}());
export { ChatService };
