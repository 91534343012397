import { MatDialogRef } from '@angular/material/dialog';
/**
 * This component acts as a "wrapper" for the user-profile component, so it can be opened in both a dialog and through
 * a normal redirect.
 */
var ProfileInfoDialogComponent = /** @class */ (function () {
    function ProfileInfoDialogComponent(dialogRef, data) {
        this.dialogRef = dialogRef;
        this.data = data;
    }
    ProfileInfoDialogComponent.prototype.ngOnInit = function () {
        if (this.data) {
            this.setUserId(this.data.id);
            this.isTranslator = this.data.isTranslator;
        }
    };
    ProfileInfoDialogComponent.prototype.setUserId = function (userId) {
        this.userId = userId;
    };
    ProfileInfoDialogComponent.prototype.closeDialog = function () {
        this.dialogRef.close();
    };
    return ProfileInfoDialogComponent;
}());
export { ProfileInfoDialogComponent };
