import { map, mergeMap } from 'rxjs/operators';
import { AfterViewInit, ApplicationRef } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { UserService } from "../service/user.service";
import { ConfirmDialog } from "../confirm.dialog";
import { SimpleDialog } from "../simple-dialog.component";
var UserSigninDialog = /** @class */ (function () {
    function UserSigninDialog(dialogRef, successText, userservice, dialog, applicationRef, router) {
        this.dialogRef = dialogRef;
        this.successText = successText;
        this.userservice = userservice;
        this.dialog = dialog;
        this.applicationRef = applicationRef;
        this.router = router;
        this.passwordFieldType = "password";
        this.failed = false;
        this.authJoining = false;
        this.joining = false;
        dialogRef.disableClose = true;
    }
    UserSigninDialog.prototype.signInWithLinkedIn = function () {
        var _this = this;
        this.userservice.openLinkedInAuth();
        this.authJoining = true;
        onstorage = function (event) {
            if (event.key == "linkedInAuthCode") {
                _this.userservice.authLinkedIn(event.newValue).pipe(map(function (e) {
                    localStorage.setItem("linkedInAccessToken", JSON.stringify(e));
                    return e;
                }), mergeMap(function (token) {
                    return _this.userservice.linkedInProfile(token["access_token"]);
                }), mergeMap(function (profile) {
                    return _this.userservice.signInWithAuth(profile);
                }))
                    .subscribe(function (signInData) {
                    console.debug("Signing in with LinkedIn", signInData);
                    _this.dialogRef.close("success");
                }, function (error) { return _this.openJoinInstead(); }, function () { return _this.authJoining = false; });
            }
        };
    };
    UserSigninDialog.prototype.googleInit = function () {
        var _this = this;
        gapi.load('auth2', function () {
            _this.auth2 = gapi.auth2.init({
                client_id: '49647298930-jjon9r6naht63oq9mc9b9cc9avm5ttt4.apps.googleusercontent.com',
                cookiepolicy: 'single_host_origin',
                scope: 'profile email'
            });
            _this.attachSignin(document.getElementById('googleBtn'));
        });
    };
    UserSigninDialog.prototype.attachSignin = function (element) {
        var _this = this;
        element.onclick = function () {
            _this.authJoining = true;
        };
        this.auth2.attachClickHandler(element, {}, function (googleUser) {
            //List of fields we want to retrieve from linked in
            var userData = _this.mapGoogleToUser(googleUser);
            _this.userservice.signInWithAuth(userData).subscribe(function (signInData) {
                _this.dialogRef.close("success");
                _this.applicationRef.tick();
            }, function (error) {
                _this.openJoinInstead();
                _this.applicationRef.tick();
            }, function () { return _this.authJoining = false; });
        });
    };
    UserSigninDialog.prototype.ngAfterViewInit = function () {
        this.googleInit();
    };
    UserSigninDialog.prototype.openJoinInstead = function () {
        this.dialogRef.close("join");
    };
    UserSigninDialog.prototype.signInViaMail = function () {
        var _this = this;
        this.joining = true;
        this.failed = false;
        try {
            this.userservice.signInWithMail(this.email, this.password).subscribe(function (r) {
                _this.dialogRef.close("success");
            }, function (error) {
                console.error("Error signing in via mail", error);
                _this.failed = true;
                _this.joining = false;
            }, function () { return _this.joining = false; });
        }
        catch (e) {
            console.error("Login error on sign-in", e);
            this.failed = true;
            this.joining = false;
        }
    };
    UserSigninDialog.prototype.resetPassword = function () {
        var _this = this;
        if (this.email == undefined) {
            var errorRef = this.dialog.open(SimpleDialog, {
                width: '400px',
                panelClass: 'simpleDialog',
                data: {
                    dialogMessage: "Please fill in your email address in the login form first."
                }
            });
        }
        else {
            var confirmRef = this.dialog.open(ConfirmDialog, {
                width: '400px',
                panelClass: 'confirmDialog',
                data: {
                    confirmTitle: "Are you sure?",
                    confirmMessage: "If you decide to reset your password, you will receive an email at " + this.email + " with " +
                        "instructions on how to proceed.",
                    confirmText: 'Yes, reset',
                    cancelText: 'No, nevermind',
                }
            });
            confirmRef.afterClosed().subscribe(function (r) {
                if (r === "confirm") {
                    _this.userservice.resetPassword(_this.email).subscribe(function (m) {
                        var successRef = _this.dialog.open(SimpleDialog, {
                            width: '400px',
                            panelClass: 'simpleDialog',
                            data: {
                                dialogMessage: "An email has been sent to " + _this.email + ". Please follow the instructions."
                            }
                        });
                        successRef.afterClosed().subscribe(function (r) {
                            return _this.dialogRef.close();
                        });
                    });
                }
            });
        }
    };
    UserSigninDialog.prototype.showPassword = function () {
        this.passwordFieldType = "text";
    };
    UserSigninDialog.prototype.hidePassword = function () {
        this.passwordFieldType = "password";
    };
    UserSigninDialog.prototype.mapGoogleToUser = function (googleUser) {
        var profile = googleUser.getBasicProfile();
        return {
            googleToken: googleUser.getAuthResponse().id_token,
            id: profile.getId(),
            firstName: profile.getName(),
            email: profile.getEmail()
        };
    };
    return UserSigninDialog;
}());
export { UserSigninDialog };
