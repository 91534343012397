import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { VendorService } from "../service/vendor.service";
import { UserService } from "../service/user.service";
var TranslatorSummaryComponent = /** @class */ (function () {
    function TranslatorSummaryComponent(vendorService, userService) {
        this.vendorService = vendorService;
        this.userService = userService;
        this.availCompData = new MatTableDataSource();
        this.availCompCols = ['activity', 'source', 'target', 'count'];
        this.foundCompData = new MatTableDataSource();
        this.foundCompCols = ['firstname', 'lastname', 'price'];
    }
    TranslatorSummaryComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.vendorService.getAvailableCompetences().subscribe(function (r) {
            _this.availCompResults = r;
            _this.availCompData.data = _this.availCompResults;
        });
    };
    TranslatorSummaryComponent.prototype.ngAfterViewInit = function () {
        this.availCompData.sort = this.sort1;
    };
    Object.defineProperty(TranslatorSummaryComponent.prototype, "matSort", {
        set: function (ms) {
            this.sort1 = ms;
            this.availCompData.sort = this.sort1;
        },
        enumerable: true,
        configurable: true
    });
    TranslatorSummaryComponent.prototype.search = function (comp) {
        var _this = this;
        if (comp.activity)
            this.activity = comp.activity;
        else
            this.activity = undefined;
        if (comp.source)
            this.sourceLang = comp.source;
        else
            this.sourceLang = undefined;
        if (comp.target)
            this.targetLang = comp.target;
        else
            this.targetLang = undefined;
        var langPair;
        if (this.sourceLang != undefined && this.targetLang != undefined)
            langPair = this.sourceLang + "_" + this.targetLang;
        else if (this.sourceLang != undefined)
            langPair = this.sourceLang;
        else if (this.targetLang != undefined)
            langPair = this.targetLang;
        this.vendorService.searchCompetences(langPair, this.activity).subscribe(function (r) {
            _this.foundCompResults = r;
            _this.foundCompData.data = _this.foundCompResults;
        });
    };
    TranslatorSummaryComponent.prototype.getPrice = function (vendor) {
        if (vendor.competences && vendor.competences[0])
            return vendor.competences[0].price;
        else
            return undefined;
    };
    return TranslatorSummaryComponent;
}());
export { TranslatorSummaryComponent };
