import { EventEmitter } from "@angular/core";
import { tap } from 'rxjs/operators';
import { UserService } from "../../service/user.service";
import { InvoiceService, PAYMENT_METHOD_BANKTRANSFER, PAYMENT_METHOD_STRIPE } from "../../service/invoice.service";
import { getPaymentImage } from "../../util/userutil";
import { CreditCardComponent } from "./credit-card.component";
import { PricingService } from '../../service/pricing.services';
var ProjectPaymentComponent = /** @class */ (function () {
    function ProjectPaymentComponent(userService, invoiceService, pricingService) {
        this.userService = userService;
        this.invoiceService = invoiceService;
        this.pricingService = pricingService;
        this.paymentMethodEmitter = new EventEmitter();
        this.currentCard = undefined;
        this.payLaterAllowed = false;
        this.paypalAllowed = false;
        this.loaded = false;
    }
    ProjectPaymentComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.initUser();
        if (this.project != null && this.project.paymentmethod === undefined) {
            this.project.paymentmethod = PAYMENT_METHOD_STRIPE;
        }
        this.userService.loginEmitter.subscribe(function () { return _this.initUser(); });
        this.loaded = true;
    };
    ProjectPaymentComponent.prototype.initUser = function () {
        this.user = this.userService.getCurrentUser();
        if (this.user != undefined) {
            this.checkAllowedPaymentMethods();
            this.fetchPaymentMethod();
        }
    };
    ProjectPaymentComponent.prototype.fetchPaymentMethod = function () {
        var _this = this;
        this.invoiceService.findPaymentMethod(this.user.id).subscribe(function (v) {
            if (v != undefined && v.stripe != undefined && v.stripe.sources != undefined && v.stripe.sources.data[0] != undefined) {
                _this.currentCard = v.stripe.sources.data[0];
            }
        });
    };
    ProjectPaymentComponent.prototype.paymentMethodChange = function (event) {
        if (event.target.checked) {
            this.paymentMethodEmitter.emit(this.project.paymentmethod);
        }
    };
    ProjectPaymentComponent.prototype.removeCard = function () {
        this.currentCard = undefined;
    };
    ProjectPaymentComponent.prototype.updateCard = function (card) {
        this.currentCard = card;
    };
    ProjectPaymentComponent.prototype.checkAllowedPaymentMethods = function () {
        var _this = this;
        this.paypalAllowed = false;
        this.payLaterAllowed = false;
        if (this.user.payLaterAllowed != null) {
            this.payLaterAllowed = this.user.payLaterAllowed === 'true';
        }
        if (this.user.paypalAllowed != null) {
            this.paypalAllowed = this.user.paypalAllowed === 'true';
        }
        this.setDefaultPaymentMethod();
        if (this.user.companyId != null) {
            this.userService.findCompanyInfo(this.user.companyId)
                .pipe(tap(function (c) {
                if (!_this.payLaterAllowed) {
                    _this.payLaterAllowed = c.payLaterAllowed === 'true';
                }
            }), tap(function (c) {
                if (!_this.paypalAllowed) {
                    _this.paypalAllowed = c.paypalAllowed === 'true';
                }
            })).subscribe(function () { _this.setDefaultPaymentMethod(); });
        }
    };
    ProjectPaymentComponent.prototype.setDefaultPaymentMethod = function () {
        if (this.payLaterAllowed === true) {
            if (this.project != null) {
                this.project.paymentmethod = PAYMENT_METHOD_BANKTRANSFER;
            }
        }
    };
    ProjectPaymentComponent.prototype.storePaymentDetails = function () {
        if (this.ccComponent !== undefined && (this.project.paymentmethod === PAYMENT_METHOD_STRIPE || this.project.paymentmethod == null)) {
            return this.ccComponent.storeCreditCard();
        }
        else {
            return Promise.resolve();
        }
    };
    ProjectPaymentComponent.getPaymentImageUrl = function (paymentMethod, disabled) {
        if (disabled === void 0) { disabled = false; }
        return './../../assets/paymentmethod/' + getPaymentImage(paymentMethod, disabled);
    };
    return ProjectPaymentComponent;
}());
export { ProjectPaymentComponent };
