export var ACTIVITY_TRANSLATION = 'Translation';
export var ACTIVITY_REVISION = 'Revision';
export var ACTIVITY_SWORN_TRANSLATION = 'Sworn translation';
export var ACTIVITY_EDITING = 'Editing';
export var ACTIVITIES = [
    ACTIVITY_TRANSLATION, ACTIVITY_REVISION, ACTIVITY_SWORN_TRANSLATION, ACTIVITY_EDITING
];
export var SERVICE_TRANSLATION = 'Translation';
export var SERVICE_SWORN_TRANSLATION = 'Sworn translation';
export var SERVICE_EDITING = 'Editing';
export var SERVICES = [SERVICE_TRANSLATION, SERVICE_SWORN_TRANSLATION, SERVICE_EDITING];
export var DEPENDENCY_SOURCE_LANG = 'Source language';
export var DEPENDENCY_TARGET_LANG = 'Target language';
export var DEFAULT_DEPENDENCIES = [DEPENDENCY_SOURCE_LANG, DEPENDENCY_TARGET_LANG];
export var EDITING_DEPENDENCIES = [DEPENDENCY_SOURCE_LANG];
var TranslationParameter = /** @class */ (function () {
    function TranslationParameter() {
    }
    TranslationParameter.prototype.toDataString = function () {
        return this.source + '_' + this.target;
    };
    TranslationParameter.prototype.compare = function (param) {
        if (param instanceof TranslationParameter) {
            return this.target.localeCompare(param.target);
        }
        return -1;
    };
    return TranslationParameter;
}());
export { TranslationParameter };
var EditingParameter = /** @class */ (function () {
    function EditingParameter() {
    }
    EditingParameter.prototype.toDataString = function () {
        return this.source;
    };
    EditingParameter.prototype.compare = function (param) {
        if (param instanceof EditingParameter) {
            return this.source.localeCompare(param.source);
        }
        return -1;
    };
    return EditingParameter;
}());
export { EditingParameter };
var TranslationParameters = /** @class */ (function () {
    function TranslationParameters(sourceLanguage, targetLanguages) {
        if (sourceLanguage === void 0) { sourceLanguage = []; }
        if (targetLanguages === void 0) { targetLanguages = []; }
        this.sourceLanguage = sourceLanguage;
        this.targetLanguages = targetLanguages;
    }
    TranslationParameters.prototype.isValid = function () {
        return this.sourceLanguage[0] != null && this.targetLanguages[0] != null;
    };
    TranslationParameters.prototype.getService = function () {
        return SERVICE_TRANSLATION;
    };
    TranslationParameters.prototype.contains = function (params) {
        if (params['source'] != null && params['target'] != null) {
            return this.sourceLanguage.indexOf(params['source']) >= 0 && this.targetLanguages.indexOf(params['target']) >= 0;
        }
        return false;
    };
    TranslationParameters.prototype.toParamList = function () {
        var _this = this;
        if (this.sourceLanguage[0] == null) {
            return [];
        }
        return this.targetLanguages.map(function (t) {
            var translationParam = new TranslationParameter();
            translationParam.source = _this.sourceLanguage[0];
            translationParam.target = t;
            return translationParam;
        });
    };
    return TranslationParameters;
}());
export { TranslationParameters };
var EditingParameters = /** @class */ (function () {
    function EditingParameters(sourceLanguage) {
        if (sourceLanguage === void 0) { sourceLanguage = []; }
        this.sourceLanguage = sourceLanguage;
    }
    EditingParameters.prototype.isValid = function () {
        return this.sourceLanguage[0] != null;
    };
    EditingParameters.prototype.getService = function () {
        return SERVICE_EDITING;
    };
    EditingParameters.prototype.contains = function (params) {
        if (params['source'] != null) {
            return this.sourceLanguage.indexOf(params['source']) >= 0;
        }
        return false;
    };
    EditingParameters.prototype.toParamList = function () {
        if (this.sourceLanguage[0] == null) {
            return [];
        }
        var param = new EditingParameter();
        param.source = this.sourceLanguage[0];
        return [param];
    };
    return EditingParameters;
}());
export { EditingParameters };
export function initServiceParamForService(service, sourceLang, targetLanguages) {
    switch (service) {
        case SERVICE_EDITING: {
            return new EditingParameters();
        }
        default: {
            var serviceParameter = new TranslationParameters();
            if (serviceParameter['sourceLanguage'] != null && sourceLang != null) {
                serviceParameter['sourceLanguage'] = [sourceLang];
            }
            if (serviceParameter['targetLanguages'] != null && targetLanguages != null) {
                serviceParameter['targetLanguages'] = targetLanguages;
            }
            return serviceParameter;
        }
    }
}
