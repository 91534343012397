import { ElementRef, OnInit, QueryList, Renderer2, SimpleChanges } from "@angular/core";
import { Task } from "../dto/job";
import { AcceptDialog, RejectDialog } from "./task-detail.component";
import { TaskService } from "../service/task.service";
import { ProjectService } from "../service/project.service";
import { MatDialog } from '@angular/material';
import { filter } from 'rxjs/operators';
import { Router } from '@angular/router';
import { SERVICE_SWORN_TRANSLATION } from "../mock-activities";
import { getSourceFiles, getUserName } from "../util/jobutil";
import { LanguageService } from "../service/language.service";
import { StatisticsService } from "../service/statistics.service";
import { getCategoryDesc, StatCategory } from "../util/statutil";
import { Project } from '../dto/project';
var TaskOverviewComponent = /** @class */ (function () {
    function TaskOverviewComponent(taskService, projectService, statisticsService, languageService, dialog, renderer, router) {
        this.taskService = taskService;
        this.projectService = projectService;
        this.statisticsService = statisticsService;
        this.languageService = languageService;
        this.dialog = dialog;
        this.renderer = renderer;
        this.router = router;
        this.revisionNeeded = false;
        this.totalWordcount = undefined;
        this.charBasedSource = false;
        this.flipped = false;
    }
    TaskOverviewComponent.prototype.ngOnInit = function () {
        this.initProject();
        this.determineCharBasedSource();
    };
    TaskOverviewComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        this.calcPaneSizes();
        // Debounce so the view has time to update to proper size
        this.frontPane.changes.debounceTime(50)
            .subscribe(function (p) {
            _this.calcPaneSizes();
        });
    };
    TaskOverviewComponent.prototype.ngOnChanges = function (changes) {
        if (changes.task) {
            this.task = changes.task.currentValue;
            this.determineCharBasedSource();
        }
        if (changes.project) {
            this.project = changes.project.currentValue;
            this.initProject();
        }
    };
    TaskOverviewComponent.prototype.calcPaneSizes = function () {
        var maxHeight = this.frontPane.first.nativeElement.offsetHeight;
        this.setPaneHeight(maxHeight, this.backPane);
        this.setPaneHeight(maxHeight, this.fakePane);
    };
    TaskOverviewComponent.prototype.setPaneHeight = function (height, pane) {
        if (pane.nativeElement.offsetHeight != height) {
            var heightPx = height + "px";
            this.renderer.setStyle(pane.nativeElement, "height", heightPx);
        }
    };
    TaskOverviewComponent.prototype.getTaskDates = function () {
        if (this.task) {
            if (this.task.canceldate) {
                // Task was canceled by customer
                return [{ label: 'Canceled on', value: this.task.canceldate }];
            }
            else if (this.task.completed) {
                // Task is completed: show expected and completion date
                var dates = [{ label: 'To deliver by', value: this.task.expecteddate, color: undefined }];
                if (this.task.completed > this.task.expecteddate)
                    dates.push({ label: 'Delivered on', value: this.task.completed, color: 'orange' });
                else
                    dates.push({ label: 'Delivered on', value: this.task.completed, color: undefined });
                return dates;
            }
            else if (this.task.rejectdate) {
                // Task is rejected: show send and reject date
                return [
                    { label: 'Received on', value: this.task.senddate },
                    { label: 'Declined on', value: this.task.rejectdate }
                ];
            }
            else if (this.task.acceptdate) {
                // Task is accepted: show accept and expected date
                return [
                    { label: 'Accepted on', value: this.task.acceptdate },
                    { label: 'To deliver by', value: this.task.expecteddate }
                ];
            }
            else {
                // Task is not accepted or rejected yet: show send and due date
                return [
                    { label: 'Received on', value: this.task.senddate },
                    { label: 'Project due by', value: this.task.duedate }
                ];
            }
        }
        return [];
    };
    TaskOverviewComponent.prototype.isToAccept = function () {
        return !this.task.acceptdate && !this.task.rejectdate && !this.task.canceldate;
    };
    TaskOverviewComponent.prototype.isAccepted = function () {
        return this.task.acceptdate && !this.task.canceldate;
    };
    TaskOverviewComponent.prototype.isCompleted = function () {
        return this.task.completed && !this.task.canceldate;
    };
    TaskOverviewComponent.prototype.initProject = function () {
        if (this.project) {
            this.initTotalWordcount();
            this.initStats();
        }
        else {
            this.totalWordcount = undefined;
        }
    };
    TaskOverviewComponent.prototype.initTotalWordcount = function () {
        var _this = this;
        this.totalWordcount = 0;
        getSourceFiles(this.project)
            .forEach(function (f) {
            if (f.wordcount != undefined)
                _this.totalWordcount = _this.totalWordcount + parseInt(f.wordcount);
        });
    };
    TaskOverviewComponent.prototype.initStats = function () {
        var _this = this;
        this.statisticsService.getTaskStatistics(this.task.id)
            .subscribe(function (stats) {
            _this.taskStats = stats;
            _this.statArray = [];
            Object.keys(StatCategory)
                .forEach(function (v, i) {
                if (_this.taskStats.get(v) != null) {
                    _this.statArray.push({ label: getCategoryDesc(v), value: _this.taskStats.get(v) });
                }
            });
        });
    };
    TaskOverviewComponent.prototype.determineCharBasedSource = function () {
        var _this = this;
        this.languageService.getCharBasedLanguages().subscribe(function (cbl) {
            if (cbl && _this.task)
                _this.charBasedSource = cbl.indexOf(_this.task.sourcelanguage) >= 0;
            else
                _this.charBasedSource = false;
        });
    };
    TaskOverviewComponent.prototype.getRequesterName = function () {
        return getUserName(this.project.requestedby);
    };
    TaskOverviewComponent.prototype.isSwornTranslation = function () {
        return this.task.service === SERVICE_SWORN_TRANSLATION;
    };
    TaskOverviewComponent.prototype.acceptTask = function () {
        var _this = this;
        if (this.totalWordcount == undefined)
            this.initTotalWordcount();
        var suggestedDate = new Date();
        if (this.totalWordcount != undefined) {
            //Calculated the time needed from now to do the task
            //A translator typically handles 300 words per hour
            var hoursNeeded = Math.ceil(this.totalWordcount / 300);
            //Since they normally only work for 8 hours a day, we need to re calculate the amount of hours
            hoursNeeded = (Math.floor(hoursNeeded / 8) * 24) + (hoursNeeded % 8);
            if (hoursNeeded == 0)
                hoursNeeded = 1;
            //The suggested date is thus the current time + the amount of hours needed
            suggestedDate.setHours(suggestedDate.getHours() + hoursNeeded, suggestedDate.getMinutes(), 0, 0);
        }
        else {
            // Add 48 hours by default if no word count can be found
            suggestedDate.setHours(suggestedDate.getHours() + 48, 0, 0, 0);
        }
        var taskDueDate = new Date(this.task.duedate);
        // Only set the suggested date to the task due date if the latter is not in the past
        // This forces the translator to propose an alternative delivery date if that's the case
        var now = new Date();
        if (suggestedDate > taskDueDate && now < taskDueDate)
            suggestedDate = taskDueDate;
        var dialogRef = this.dialog.open(AcceptDialog, {
            width: '500px',
            maxHeight: '70%',
            data: {
                message: "", date: suggestedDate, dueDate: taskDueDate, swornTranslation: this.isSwornTranslation(),
                revisionNeeded: this.revisionNeeded
            }
        });
        dialogRef.afterClosed().pipe(filter(function (r) { return r === "accept"; })).subscribe(function (r) {
            _this.taskService.acceptTask(_this.task.id, dialogRef.componentInstance.date).subscribe(function (res) {
                _this.task = res;
            });
        });
        dialogRef.afterClosed().pipe(filter(function (r) { return r === "counterproposal"; })).subscribe(function (r) {
            _this.taskService.counterProposeTask(_this.task, dialogRef.componentInstance.proposal)
                .subscribe(function (res) {
                _this.router.navigate(['/tdashboard']);
            });
            console.debug("Counter proposal has been made with: " + JSON.stringify(dialogRef.componentInstance.proposal));
        });
    };
    TaskOverviewComponent.prototype.rejectTask = function () {
        var _this = this;
        var dialogRef = this.dialog.open(RejectDialog, {
            width: '600px',
            height: '475px',
            data: {
                message: "", placeholder: "Bad timing, not my expertise, too small, try my colleague X, ...",
                langpair: this.task.sourcelanguage + "_" + this.task.targetlanguage,
                activity: this.activityName
            }
        });
        dialogRef.afterClosed().pipe(filter(function (t) { return t == "success"; }))
            .subscribe(function (result) {
            _this.taskService.rejectTask(_this.task.id, dialogRef.componentInstance.message, dialogRef.componentInstance.suggestedColleague)
                .subscribe(function (res) {
                _this.task = res;
                _this.router.navigate(['/tdashboard']);
            });
        });
        dialogRef.afterClosed().pipe(filter(function (t) { return t == "counterproposal"; }))
            .subscribe(function (result) {
            _this.taskService.counterProposeTask(_this.task, dialogRef.componentInstance.counterProposal)
                .subscribe(function (res) {
                _this.router.navigate(['/tdashboard']);
            });
            console.debug("Counter proposal has been made with: " + JSON.stringify(dialogRef.componentInstance.counterProposal));
        });
    };
    return TaskOverviewComponent;
}());
export { TaskOverviewComponent };
