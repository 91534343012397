import { OnInit } from "@angular/core";
import { UserService } from "../../service/user.service";
import { MatDialog } from '@angular/material';
import { ConfirmDialog } from "../../confirm.dialog";
import { filter } from 'rxjs/operators';
import { TmService } from "../../service/tm.service";
var TmManagementComponent = /** @class */ (function () {
    function TmManagementComponent(userService, tmService, dialog) {
        this.userService = userService;
        this.tmService = tmService;
        this.dialog = dialog;
        this.userId = "";
        this.loading = false;
    }
    TmManagementComponent.prototype.ngOnInit = function () {
    };
    // todo: test this for customer groups!
    TmManagementComponent.prototype.findTm4Owner = function () {
        var _this = this;
        this.resetMessages();
        if (this.userId != undefined && this.userId.length > 0) {
            this.userService.getTmIndex(this.userId).subscribe(function (d) {
                _this.tmIdxData = d;
                if (_this.tmIdxData == undefined) {
                    _this.errorMsg = "No TM index found for user " + _this.userId + ".";
                }
            }, function (error) {
                console.error("Error retrieving TM index for user " + _this.userId, error);
                _this.errorMsg = "Error retrieving TM index for user " + _this.userId + "! (see console)";
            });
        }
    };
    TmManagementComponent.prototype.uploadTmx = function () {
        var _this = this;
        this.resetMessages();
        if (this.tmxFile != undefined) {
            var dialogRef = this.dialog.open(ConfirmDialog, {
                maxWidth: '60%',
                panelClass: 'confirmDialog',
                data: {
                    confirmMessage: 'Are you sure you wish to upload \'' + this.tmxFile.name + '\' for TM index ' + this.tmIdxData.tmindex + '?',
                    confirmText: 'Yup',
                    cancelText: 'Nah'
                }
            });
            dialogRef.afterClosed().pipe(filter(function (t) { return t === "confirm"; }))
                .subscribe(function (p) {
                var start = Date.now();
                _this.loading = true;
                _this.tmService.uploadTmxFile(_this.tmxFile, _this.tmIdxData.tmindex).subscribe(function (count) {
                    _this.loading = false;
                    _this.successMsg = "TMX file was successfully uploaded: " + count + " segments inserted in " + (Date.now() - start) + " ms.";
                    console.debug(_this.successMsg);
                }, function (error) {
                    _this.loading = false;
                    _this.errorMsg = "Error uploading TMX file! (see console)";
                    console.error("Error uploading TMX file", error);
                });
            });
        }
    };
    TmManagementComponent.prototype.selectTmx = function (event) {
        var fileList = event.target.files;
        if (fileList.length > 0) {
            this.tmxFile = fileList[0];
        }
    };
    TmManagementComponent.prototype.resetMessages = function () {
        this.errorMsg = undefined;
        this.successMsg = undefined;
        this.loading = false;
    };
    return TmManagementComponent;
}());
export { TmManagementComponent };
