import { EventEmitter } from "@angular/core";
import { FileService } from "../service/file.service";
var ProfilePicUploadComponent = /** @class */ (function () {
    function ProfilePicUploadComponent(fileService) {
        this.fileService = fileService;
        this.fileUpload = "";
        this.picEmitter = new EventEmitter();
    }
    ProfilePicUploadComponent.prototype.uploadFiles = function (event) {
        var _this = this;
        var fileList = event.target.files;
        if (fileList.length > 0) {
            var file = fileList[0];
            this.fileService.uploadProfilePicture(file, this.userid)
                .subscribe(function (v) { return _this.picEmitter.next(v); });
        }
    };
    return ProfilePicUploadComponent;
}());
export { ProfilePicUploadComponent };
