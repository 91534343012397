import { filter } from 'rxjs/operators';
import { UserSigninDialog } from "../usercomponent/user.signin.component";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { UserJoinDialog } from "../usercomponent/user.join.component";
import { SimpleDialog } from "../simple-dialog.component";
import { UserService } from "./user.service";
import { JoinDialogOutcome, JoinType } from "../usercomponent/user.join.model";
var LogInService = /** @class */ (function () {
    function LogInService(dialog, router, userService) {
        this.dialog = dialog;
        this.router = router;
        this.userService = userService;
    }
    LogInService.prototype.openSignInPopup = function (callback) {
        var _this = this;
        if (callback === void 0) { callback = undefined; }
        var dialogRef = this.dialog.open(UserSigninDialog, {
            width: '300px'
        });
        dialogRef.afterClosed().pipe(filter(function (r) { return r == "join"; })).subscribe(function (r) { return _this.openJoinDialog(callback); });
        dialogRef.afterClosed().subscribe(function (result) { return console.log(result); });
        return dialogRef;
    };
    LogInService.prototype.openJoinDialog = function (callback, companyToken, groupToken) {
        var _this = this;
        if (callback === undefined || callback.length === 0) {
            callback = this.router.url;
        }
        var dialogRef = this.dialog.open(UserJoinDialog, {
            width: '300px',
            data: { callback: callback, companyToken: companyToken, groupToken: groupToken }
        });
        dialogRef.afterClosed().pipe(filter(function (r) { return r != null; }), filter(function (r) { return r.result == JoinDialogOutcome.SIGN_IN; })).subscribe(function (r) { return _this.openSignInPopup(); });
        dialogRef.afterClosed().pipe(filter(function (r) { return r != null; }), filter(function (r) { return r.result == JoinDialogOutcome.SUCCESS && r.join == JoinType.MAIL; })).subscribe(function (r) {
            var successText = "An email has been sent to your email address.\n" +
                "Please check your inbox and click the link to activate your account.";
            _this.dialog.open(SimpleDialog, {
                width: '300px',
                panelClass: 'simpleDialog',
                data: { dialogMessage: successText }
            });
            _this.checkAndAcceptInvite(r);
        });
        dialogRef.afterClosed().pipe(filter(function (r) { return r != null; }), filter(function (r) { return r.result == JoinDialogOutcome.ERROR; })).subscribe(function (r) {
            var errorText = 'Unable to log in.<br/><small>Are you already registered maybe?</small>';
            _this.dialog.open(SimpleDialog, {
                width: '300px',
                panelClass: 'simpleDialog',
                data: { dialogMessage: errorText }
            });
            console.error("Error during join attempt", r.reason);
        });
        return dialogRef;
    };
    LogInService.prototype.checkAndAcceptInvite = function (dialogResult) {
        // Check whether there's an invite ID, and if so, mark it as accepted
        var inviteId = sessionStorage.getItem("inviteId");
        if (inviteId != undefined && dialogResult.user != undefined && dialogResult.user.id != undefined) {
            this.userService.acceptInvite(inviteId, dialogResult.user.id).subscribe(function (a) { return console.debug("User " + dialogResult.user.id + ": invite accepted", a); });
        }
    };
    return LogInService;
}());
export { LogInService };
