import { empty as observableEmpty, merge as observableMerge, Observable, Subject } from 'rxjs';
import { finalize, map, tap } from 'rxjs/operators';
import { EventEmitter } from '@angular/core';
import { DomSanitizer } from "@angular/platform-browser";
import { Md5 } from "ts-md5/dist/md5";
import { HttpClient, HttpParams } from "@angular/common/http";
import { getBaseUrl } from "../util/httpclient";
import { environment } from "../../environments/environment";
import { CookieService } from 'ngx-cookie-service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "@angular/platform-browser";
import * as i3 from "ngx-cookie-service";
var UserService = /** @class */ (function () {
    function UserService(http, sanitizer, cookieService) {
        var _this = this;
        this.http = http;
        this.sanitizer = sanitizer;
        this.cookieService = cookieService;
        this.url = getBaseUrl();
        this.USER_API = this.url + "/api/v1/users";
        this.FILES_API = this.url + "/api/v1/files";
        this.COMPANY_API = this.url + "/api/v1/companies";
        this.CUSTGROUP_API = this.url + "/api/v1/custgroups";
        //Instead of using a method, it seems that Angular works better on changes on a variable
        this.isCurrentUserTranslator = false;
        this.isCurrentUserInternalTranslator = false;
        this.isCurrentUserAdmin = false;
        this.loginEmitter = new EventEmitter();
        this.userEmitter = new EventEmitter();
        // To propagate changes to for example the mugshot component
        this.userUpdatedSubj = new Subject();
        this.userUpdated$ = this.userUpdatedSubj.asObservable();
        console.debug("UserService initializing => connects to " + this.USER_API);
        this.signInFromLocalStorage().subscribe(function (u) {
            _this.currentUser = u;
            _this.userEmitter.emit(u);
        });
        //Init listener on login for tranlsator boolean
        this.loginEmitter.subscribe(function (v) {
            _this.userEmitter.emit(_this.currentUser);
            _this.updateIsTranslator();
            _this.updateIsAdmin();
            _this.updateCurrency();
        });
        this.updateIsTranslator();
        this.updateIsAdmin();
        this.updateCurrency();
        this.getRemoteUserIp();
    }
    //TODO sign in from local storage should still fetch the db object and store the latest value
    UserService.prototype.signInFromLocalStorage = function () {
        var _this = this;
        var localStorageUser = localStorage.getItem("user");
        if (localStorageUser != null && localStorageUser.toString().length > 0) {
            var localStorageUserJ = JSON.parse(localStorageUser);
            return this.http.get(this.USER_API + "/auth/" + localStorageUserJ.id, { responseType: "json" }).pipe(map(function (r) {
                if (r) {
                    _this.currentUser = r;
                    _this.setLoggedInUser(r);
                    return _this.currentUser;
                }
            }));
        }
        return observableEmpty();
    };
    UserService.prototype.getCurrentUser = function () {
        return this.currentUser;
    };
    UserService.prototype.updateCurrentUser = function (user) {
        if (this.isCurrentUser(user))
            this.currentUser = user;
    };
    UserService.prototype.isLoggedIn = function () {
        return this.getCurrentUser() != undefined;
    };
    UserService.prototype.isCurrentUser = function (user) {
        return this.isCurrentUserId(user.id);
    };
    UserService.prototype.isCurrentUserId = function (userId) {
        return this.currentUser != undefined && userId != undefined && this.currentUser.id === userId;
    };
    UserService.prototype.getUser = function (id) {
        return this.http.get(this.USER_API + "/find/" + id);
    };
    UserService.prototype.getRemoteUserIp = function () {
        var _this = this;
        if (this.remoteUserIp == null) {
            // Using JSONP to avoid CORS issues with contacting 3rd party API
            this.http.jsonp('https://api.ipify.org?format=jsonp', 'callback')
                .subscribe(function (res) {
                var j = JSON.parse(JSON.stringify(res));
                _this.remoteUserIp = j.ip;
                console.log("UserService => detected IP = " + _this.remoteUserIp);
            }, function (error) {
                console.log("Error retrieving user IP", error);
            });
        }
        return this.remoteUserIp;
    };
    UserService.prototype.signInWithAuth = function (data) {
        var _this = this;
        return this.http.post(this.USER_API + "/signin/oauth", JSON.stringify(data), { responseType: 'json' }).pipe(map(function (signInData) {
            _this.setLoggedInUser(signInData);
            return signInData;
        }));
    };
    UserService.prototype.signInWithMail = function (email, password) {
        var _this = this;
        if (email && password) {
            var data = "{'email':'" + email + "', 'password':'" + UserService.encryptPassword(password) + "'}";
            return this.http.post(this.USER_API + "/signin/mail", data, { responseType: 'json' }).pipe(map(function (signInData) { return signInData; }), map(function (signInData) {
                if (signInData && signInData.id) {
                    _this.setLoggedInUser(signInData);
                    return signInData;
                }
                else {
                    throw new Error("Login failed");
                }
            }));
        }
        else if (!email) {
            throw new Error("No email address specified.");
        }
        else if (!password) {
            throw new Error("No password specified.");
        }
        else
            throw new Error("Login failed");
    };
    UserService.prototype.resetPassword = function (email) {
        var data = "{'email':'" + email + "'}";
        return this.http.post(this.USER_API + "/resetpassword", data, { responseType: 'text' });
    };
    UserService.prototype.joinWithAuth2 = function (data) {
        var _this = this;
        return this.http.post(this.USER_API + "/join", JSON.stringify(data), { responseType: 'json' }).pipe(map(function (signInData) {
            _this.setLoggedInUser(signInData);
            return signInData;
        }));
    };
    UserService.prototype.updateLoggedInUser = function (data) {
        // TODO: "refresh" it from the server rather than re-use the given object?
        if (data.id && this.currentUser && data.id == this.currentUser.id) {
            this.setLoggedInUser(data);
        }
    };
    UserService.prototype.setLoggedInUser = function (data) {
        localStorage.setItem("user", JSON.stringify(data));
        sessionStorage.setItem("user", JSON.stringify(data));
        var expireDate = new Date();
        expireDate.setMonth(expireDate.getMonth() + 3);
        //This cookie is used for hubspot to know if they need to redirect when you're on the landing page and if they are a translator or customer.
        //Be warned that changing this values might break the hubspot redirects
        this.cookieService.set("lilousertype", data.isTranslator, expireDate, undefined, "lilo.global");
        this.currentUser = data;
        this.loginEmitter.emit(true);
        this.userUpdatedSubj.next(data);
    };
    UserService.prototype.reloadUser = function () {
        var _this = this;
        if (this.currentUser != null) {
            this.getUser(this.currentUser.id).subscribe(function (u) {
                _this.currentUser = u;
                _this.userUpdatedSubj.next(_this.currentUser);
            });
        }
    };
    UserService.prototype.signOut = function () {
        var user = this.currentUser;
        if (user == undefined)
            user = JSON.parse(localStorage.getItem("user"));
        localStorage.removeItem("user");
        this.currentUser = null;
        this.loginEmitter.emit(false);
        return this.http.post(this.USER_API + "/signout/" + user.id, "", { responseType: 'text' }).pipe(finalize(user = null));
    };
    UserService.prototype.authLinkedIn = function (code) {
        return this.http.get(this.USER_API + "/auth/linkedin/" + code);
    };
    UserService.prototype.linkedInProfile = function (token) {
        var httpParams = new HttpParams().set("token", token);
        return this.http.get(this.USER_API + "/linkedin/profile", { params: httpParams });
    };
    UserService.prototype.openLinkedInAuth = function () {
        var callbackURI = encodeURIComponent(environment.callbackURI);
        return window.open("https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=86ouotrrf9uueg&redirect_uri=" + callbackURI + "&state=fooobar" +
            "&scope=r_liteprofile%20r_emailaddress", "LINKEDIN", "width=500,height=500");
    };
    //getProfilePic is implemented in user.service, if not all components which would need to show the user profile would also require the file.service
    UserService.prototype.getProfilePic = function (id) {
        var _this = this;
        return this.http.get(this.FILES_API + "/users/profile/" + id, { responseType: "blob" }).pipe(map(function (blob) {
            var urlCreator = window.URL;
            return _this.sanitizer.bypassSecurityTrustUrl(urlCreator.createObjectURL(blob));
        }));
    };
    UserService.prototype.getProfileColor = function (id) {
        return this.http.get(this.USER_API + "/profilecolor/" + id, { responseType: 'text' });
    };
    UserService.prototype.updateUserData = function (user) {
        var _this = this;
        return this.http.post(this.USER_API + "/update/profile/" + user.id, user, { responseType: 'text' }).pipe(map(function (u) {
            _this.updateLoggedInUser(user);
            return u;
        }));
    };
    UserService.prototype.updateAcceptedGTAC = function (version) {
        if (version != null) {
            this.cookieService.set('lilogtac', JSON.stringify(version));
        }
        else {
            this.cookieService.delete('lilogtac');
        }
        var user = this.getCurrentUser();
        if (user != null) {
            user.acceptedGTAC = { version: version };
            return this.http.post(this.USER_API + '/update/gtac/' + user.id, JSON.stringify(version), { responseType: 'text' });
        }
        else {
            return Observable.empty();
        }
    };
    UserService.prototype.updateCompetences = function (user) {
        var _this = this;
        // Merge 2 webservice calls in one observable to update the domain and the competences
        // Note that this means that subsequent functions are executed PER result from any of the merged observables:
        // in this case, it means the 'updateLoggedInUser' gets executed twice
        return observableMerge(this.http.post(this.USER_API + "/update/domains/" + user.id, user.domains, { responseType: 'text' }), this.http.post(this.USER_API + "/update/competences/" + user.id, user.competences, { responseType: 'text' })).pipe(map(function (u) {
            _this.updateLoggedInUser(user);
            return u;
        }));
    };
    UserService.prototype.updatePercentageSet = function (user) {
        var _this = this;
        return this.http.post(this.USER_API + "/update/percentageset/" + user.id, user.percentageSet, { responseType: 'text' }).pipe(map(function (u) {
            _this.updateLoggedInUser(user);
            return u;
        }));
    };
    UserService.prototype.updateSwornPricing = function (user) {
        return this.http.post(this.USER_API + '/update/swornpricing/' + user.id, { swornSurplus: user.swornSurplus, postalSurplus: user.postalSurplus }, { responseType: 'text' });
    };
    UserService.prototype.createCompanyInfo = function (company, userToUpdate) {
        var _this = this;
        return this.http.post(this.COMPANY_API + "/insert", company, { responseType: 'text' }).pipe(map(function (r) {
            return r;
        }), tap(function (id) {
            if (userToUpdate) {
                userToUpdate.companyId = id;
                userToUpdate.companyName = company.name;
                _this.updateUserCompanyInfo(userToUpdate).subscribe();
            }
        }));
    };
    UserService.prototype.updateCompanyInfo = function (company) {
        return this.http.post(this.COMPANY_API + "/update/" + company.id, company, { responseType: 'text' });
    };
    UserService.prototype.findCompanyInfo = function (id) {
        return this.http.get(this.COMPANY_API + "/find/" + id);
    };
    UserService.prototype.findCompanyInfoByToken = function (token) {
        return this.http.get(this.COMPANY_API + "/findbytoken/" + token);
    };
    UserService.prototype.findCustomerGroups = function () {
        return this.http.get(this.CUSTGROUP_API + '/findall');
    };
    UserService.prototype.createCustomerGroup = function (custGroup) {
        return this.http.post(this.CUSTGROUP_API + "/insert", custGroup, { responseType: 'text' });
    };
    UserService.prototype.findColleagues = function () {
        if (this.currentUser === undefined) {
            return Observable.empty();
        }
        return this.http.get(this.USER_API + '/findcolleagues/' + this.currentUser.id);
    };
    UserService.prototype.findDefaultShares = function () {
        if (this.currentUser === undefined) {
            return Observable.empty();
        }
        return this.http.get(this.USER_API + '/find/defaultshares/' + this.currentUser.id);
    };
    UserService.prototype.renewCompanyToken = function (id) {
        return this.http.post(this.COMPANY_API + "/renewtoken/" + id, "", { responseType: 'json' });
    };
    UserService.prototype.updateUserCompanyInfo = function (user) {
        return this.http.post(this.USER_API + "/update/company/" + user.id, user, { responseType: 'text' });
    };
    UserService.encryptPassword = function (password) {
        //TODO make something more secure than just an MD5 of the passwords
        return Md5.hashStr(password).toString();
    };
    UserService.prototype.joinWithMail = function (user) {
        // Request to send authentication mail
        return this.http.post(this.USER_API + "/sendauthmail", JSON.stringify(user), { responseType: 'text' });
    };
    UserService.prototype.activateAccount = function (key) {
        var _this = this;
        return this.http.post(this.USER_API + "/activateauth/" + key, "", { responseType: 'json' }).pipe(map(function (u) {
            _this.setLoggedInUser(u);
            return u;
        }));
    };
    UserService.prototype.updatePassword = function (id, password) {
        var data = "{'id':'" + id + "', 'password':'" + UserService.encryptPassword(password) + "'}";
        return this.http.post(this.USER_API + "/updatepw", data, { responseType: 'text' });
    };
    UserService.prototype.updateCurrentPassword = function (id, currentpassword, password) {
        var data = "{'id':'" + id + "', 'currentPassword':'" + UserService.encryptPassword(currentpassword) + "', 'password':'" + UserService.encryptPassword(password) + "'}";
        return this.http.post(this.USER_API + "/updatepw", data, { responseType: 'text' });
    };
    UserService.prototype.saveUnavailabilities = function (user) {
        return this.http.post(this.USER_API + "/update/unavailabilities/" + user.id, user.unavailabilities, { responseType: 'text' });
    };
    //The become a translator button is only shown to users which are not yet translators
    //TODO improve the check for when a user is a translator, currently this is when the user has translation competences.
    UserService.prototype.updateIsTranslator = function () {
        this.isCurrentUserTranslator = this.isUserTranslator(this.currentUser);
        this.isCurrentUserInternalTranslator = this.isUserInternalTranslator(this.currentUser);
    };
    UserService.prototype.isUserTranslator = function (user) {
        return user != undefined && user.competences != undefined;
    };
    UserService.prototype.isUserInternalTranslator = function (user) {
        return user != undefined && user.internalCompetences != undefined;
    };
    UserService.prototype.hasTranslatorRole = function () {
        return this.isCurrentUserInternalTranslator || this.isCurrentUserTranslator;
    };
    UserService.prototype.updateIsAdmin = function () {
        this.isCurrentUserAdmin = this.currentUser && this.currentUser.admin != undefined &&
            (this.currentUser.admin === "true" || this.currentUser.admin === true);
    };
    UserService.prototype.updateCurrency = function () {
        var currency = 'EUR';
        if (this.currentUser && this.currentUser.currency)
            currency = this.currentUser.currency;
        sessionStorage.setItem("currency", currency);
    };
    UserService.prototype.sendCompanyInvite = function (from, inviteMail) {
        var body = { "mail": inviteMail };
        return this.http.post(this.USER_API + "/sendcompanyinvite/" + from, body, { responseType: 'text' });
    };
    UserService.prototype.uploadPictureFromLinkedIn = function (pictureUrl, id) {
        var _this = this;
        this.http.get(pictureUrl, { responseType: "blob" }).pipe(map(function (blob) {
            return blob;
        })).subscribe(function (v) { return _this.uploadProfilePicture(v, id).subscribe(function (v) { return console.debug("Uploaded profile picture from LinkedIn for " + id, v); }, function (e) { return console.error("Error uploading profile picture", e); }); }, function (e1) { return console.error("Error getting profile picture from LinkedIn", e1); });
    };
    UserService.prototype.uploadProfilePicture = function (file, userid) {
        var formData = new FormData();
        formData.append('uploadFile', file);
        formData.append('requestedname', 'profilepic.png');
        return this.http.post(this.FILES_API + "/users/profile/" + userid, formData, { responseType: 'text' });
    };
    UserService.prototype.sendInvites = function (addresses, inviteMessage) {
        var body = {
            addresses: addresses,
            inviteMessage: inviteMessage,
            userId: this.currentUser.id
        };
        return this.http.post(this.USER_API + "/sendinvites", JSON.stringify(body), { responseType: 'text' });
    };
    UserService.prototype.acceptInvite = function (inviteId, userId) {
        return this.http.post(this.USER_API + "/acceptinvite/" + inviteId + "/" + userId, "", { responseType: 'text' });
    };
    UserService.prototype.getInvites = function (userId) {
        return this.http.get(this.USER_API + "/invitesbyinviter/" + userId, { responseType: "json" });
    };
    UserService.prototype.getTotalPendingInviteRewards = function () {
        return this.http.get(this.USER_API + "/pendinginviterewards", { responseType: "json" });
    };
    UserService.prototype.validateVat = function (vat) {
        return this.http.get(this.COMPANY_API + "/vatnumber/isvalid/" + vat, { responseType: "json" });
    };
    UserService.prototype.sendContactRequest = function (userEmail, taskSpec, userId) {
        var data = "'email':'" + userEmail + "', 'task':" + JSON.stringify(taskSpec);
        if (userId != null && userId.length > 0)
            data += ", 'uid': '" + userId + "'";
        return this.http.post(this.USER_API + "/contactreq/missinglang", "{" + data + "}", { responseType: 'text' });
    };
    UserService.prototype.fetchUnavailability = function (userId) {
        return this.http.get(this.USER_API + '/futureunavailabilities/' + userId, { responseType: 'json' })
            .map(function (u) { return u; });
    };
    UserService.prototype.getTmIndex = function (userId) {
        return this.http.get(this.USER_API + "/" + userId + "/tmidx", { responseType: 'json' });
    };
    UserService.prototype.mimicUser = function (mimicRequest) {
        var _this = this;
        return this.http.post(this.USER_API + "/admin/mimic", mimicRequest, { responseType: 'json' })
            .map(function (u) {
            _this.currentUser = u;
            _this.userEmitter.emit(u);
            _this.setLoggedInUser(u);
            return u;
        });
    };
    UserService.gtacversion = '20191223';
    UserService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UserService_Factory() { return new UserService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.DomSanitizer), i0.ɵɵinject(i3.CookieService)); }, token: UserService, providedIn: "root" });
    return UserService;
}());
export { UserService };
