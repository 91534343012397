import { MatDialogRef } from '@angular/material/dialog';
var HelpDialogComponent = /** @class */ (function () {
    function HelpDialogComponent(dialogRef, data) {
        this.dialogRef = dialogRef;
        this.data = data;
    }
    HelpDialogComponent.prototype.ngOnInit = function () {
        if (this.data) {
            this.helpHtmlUrl = this.data.helpHtmlUrl;
            this.helpHtmlContent = this.data.helpHtmlContent;
            this.helpTitle = this.data.helpTitle;
        }
    };
    HelpDialogComponent.prototype.getTitle = function () {
        if (this.helpTitle)
            return this.helpTitle;
        else
            return "Help";
    };
    HelpDialogComponent.prototype.closeDialog = function () {
        this.dialogRef.close();
    };
    return HelpDialogComponent;
}());
export { HelpDialogComponent };
