import { MatDialogRef } from "@angular/material/dialog";
import { UserService } from "../service/user.service";
var UserInviteDialog = /** @class */ (function () {
    function UserInviteDialog(dialogRef, userService) {
        this.dialogRef = dialogRef;
        this.userService = userService;
        //TODO fill in a better text as placeholder
        this.inviteMessage = "Hi, \n" +
            "I would like to invite you to lilo, a translation platform that I use for my translation process.\n" +
            "I hope you can try it out, and would love to receive work from you through this platform.\n\n" +
            "Best,\n";
        this.currentValue = undefined;
        this.addresses = [];
    }
    UserInviteDialog.prototype.removeAddress = function (address) {
        this.addresses.splice(this.addresses.indexOf(address), 1);
    };
    UserInviteDialog.prototype.addAddress = function () {
        if (this.currentValue != undefined)
            this.addresses.push(this.currentValue);
        this.currentValue = undefined;
    };
    UserInviteDialog.prototype.sendInvites = function () {
        var _this = this;
        //Make sure the address which is in the input field is also added to the list of addresses
        this.addAddress();
        this.userService.sendInvites(this.addresses, this.inviteMessage).subscribe(function (a) {
            if (a == "success") {
                console.debug("Invites have been sent.");
                _this.dialogRef.close();
            }
        });
    };
    return UserInviteDialog;
}());
export { UserInviteDialog };
