var ErrorSnackbarComponent = /** @class */ (function () {
    function ErrorSnackbarComponent(data) {
        this.data = data;
    }
    ErrorSnackbarComponent.prototype.ngOnInit = function () {
        this.errorText = this.data.errorText;
    };
    return ErrorSnackbarComponent;
}());
export { ErrorSnackbarComponent };
