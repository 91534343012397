import { UserService } from "../service/user.service";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { LanguageService } from "../service/language.service";
import { CountryService } from "../service/country.service";
import { FileService } from "../service/file.service";
import { FormControl } from "@angular/forms";
import { map, startWith } from "rxjs/operators";
import { ProfileInfoDialogComponent } from "./profile.info.dialog.component";
import { ActivityService } from "../service/activity.service";
var ProfileSettingsComponent = /** @class */ (function () {
    function ProfileSettingsComponent(snackbar, dialog, userService, languageService, activityService, countryService, fileService) {
        this.snackbar = snackbar;
        this.dialog = dialog;
        this.userService = userService;
        this.languageService = languageService;
        this.activityService = activityService;
        this.countryService = countryService;
        this.fileService = fileService;
        this.isTranslator = false;
        this.currencies = [];
        this.timezones = [];
        this.timezoneCtrl = new FormControl();
        this.internalTranslator = false;
    }
    ProfileSettingsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.languageService.getLanguages().subscribe(function (l) { return _this.languages = l; });
        this.languageService.getDialectLanguages().subscribe(function (l) { return _this.dialectLanguages = l; });
        this.activityService.getActivities().subscribe(function (a) { return _this.activities = a; });
        this.countryService.getCountries().subscribe(function (c) { return _this.countries = c; });
        this.countryService.getCurrencies().subscribe(function (c) { return _this.currencies = c; });
        this.countryService.getTimezones()
            .subscribe(function (t) {
            _this.timezones = t;
            _this.prefillFromBrowserData();
            var timeZone = _this.getZoneForId(_this.user.timezone);
            _this.timezoneCtrl.setValue(timeZone);
            _this.filteredTimezones = _this.timezoneCtrl.valueChanges
                .pipe(startWith(''), map(function (timezone) { return typeof timezone === 'string' ? timezone : timezone.value; }), map(function (timezone) {
                var v = timezone ? _this.filterTimezones(timezone) : _this.timezones.slice();
                return v;
            }));
        });
        if (!this.isTranslator)
            this.isTranslator = this.userService.isUserTranslator(this.user);
        this.internalTranslator = this.userService.isUserInternalTranslator(this.user);
    };
    ProfileSettingsComponent.prototype.getSaveObservables = function () {
        if (this.userService.isCurrentUser(this.user))
            sessionStorage.setItem("currency", this.user.currency);
        this.user.isTranslator = this.isTranslator;
        return this.userService.updateUserData(this.user);
    };
    ProfileSettingsComponent.prototype.reloadUser = function (r) {
        // Only need to reload if the current user is the one being edited
        if (this.userService.isCurrentUser(this.user))
            this.userService.reloadUser();
    };
    ProfileSettingsComponent.prototype.prefillFromBrowserData = function () {
        // Check on "startsWith('(GMT')" for the older users that got their TZ saved with 'value' iso 'zone ID' and reset their TZ
        if (this.user.timezone == undefined || this.user.timezone.startsWith("(GMT")) {
            var date = new Date();
            var timezoneOffset = date.getTimezoneOffset();
            if (this.isDayLightSaving(date)) {
                timezoneOffset = timezoneOffset + 60;
            }
            var number_1 = timezoneOffset / 60 * -1; //Convert the timezoneOffset to the GMT code
            var myTimeZones = this.timezones.filter(function (tz) { return tz.code == "" + number_1; }); //Filter only on the timezones which have the same code
            if (myTimeZones.length > 0) {
                this.user.timezone = myTimeZones[0].jzoneid;
            }
            else
                // Default to 'Europe/London' if none other found
                this.user.timezone = "Europe/London";
        }
    };
    ProfileSettingsComponent.prototype.isDayLightSaving = function (date) {
        var dateOffset = date.getTimezoneOffset();
        var dayLightSavingOffset = new Date(date.getYear(), 6).getTimezoneOffset();
        return dateOffset === dayLightSavingOffset;
    };
    ProfileSettingsComponent.prototype.displayTimezone = function (tz) {
        if (tz && !(typeof tz == 'string'))
            return tz.value;
        return tz;
    };
    ProfileSettingsComponent.prototype.getZoneForId = function (tzId) {
        var fv = tzId.toLowerCase();
        var tzones = this.timezones.filter(function (tz) {
            return tz.jzoneid.toLowerCase().indexOf(fv) >= 0;
        });
        if (tzones.length > 0)
            return tzones[0];
        else
            return null;
    };
    ProfileSettingsComponent.prototype.filterTimezones = function (value) {
        var fv = value.toLowerCase();
        return this.timezones.filter(function (tz) {
            return tz.value.toLowerCase().indexOf(fv) >= 0;
        });
    };
    ProfileSettingsComponent.prototype.openDialog = function () {
        var dialogRef = this.dialog.open(ProfileInfoDialogComponent, {
            maxWidth: '90%',
            minWidth: '65%',
            maxHeight: '85%',
            data: { id: this.user.id, isTranslator: this.isTranslator }
        });
    };
    ProfileSettingsComponent.prototype.clearInternalCompetences = function () {
        this.user.internalCompetences = null;
    };
    ProfileSettingsComponent.prototype.removeInternalCompetence = function (competence) {
        this.user.internalCompetences.splice(this.user.internalCompetences.indexOf(competence), 1);
    };
    ProfileSettingsComponent.prototype.addInternalCompetence = function () {
        if (this.activity != undefined && this.source != undefined && this.target != undefined) {
            if (this.user.internalCompetences == undefined)
                this.user.internalCompetences = [];
            var competence = Object();
            competence.activity = this.activity;
            competence.source = this.source;
            competence.target = this.target;
            this.user.internalCompetences.push(competence);
            this.activity = undefined;
            this.source = undefined;
            this.target = undefined;
        }
    };
    ProfileSettingsComponent.prototype.save = function () {
        var _this = this;
        if (this.timezoneCtrl.value && this.timezoneCtrl.value.jzoneid)
            this.user.timezone = this.timezoneCtrl.value.jzoneid;
        //Handle the internal competences before saving
        //In case there is a competence filled in, add that competences, most likely they forgot to click the add
        this.addInternalCompetence();
        //In case the user is not an internal translator (anymore) clear the internal competences
        if (!this.internalTranslator)
            this.clearInternalCompetences();
        //Get the save observable and subscribe to it to actually do the save
        this.getSaveObservables().subscribe(function (result) {
            _this.snackbar.open("Profile updated", '', {
                duration: 2000
            });
        }, function (error) { return console.error("Error updating user " + _this.user.id, error); });
    };
    return ProfileSettingsComponent;
}());
export { ProfileSettingsComponent };
