import { AfterViewInit, ElementRef } from "@angular/core";
import { InvoiceService } from "../service/invoice.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { UserService } from "../service/user.service";
import { getPaymentImage } from "../util/userutil";
var PaymentSettingComponent = /** @class */ (function () {
    function PaymentSettingComponent(invoiceService, userService, snackbar) {
        this.invoiceService = invoiceService;
        this.userService = userService;
        this.snackbar = snackbar;
        this.cardHandler = this.onChange.bind(this);
        this.changeMode = false;
    }
    PaymentSettingComponent.prototype.ngOnInit = function () {
        this.currentCard = undefined;
        this.fetchPaymentMethod();
    };
    PaymentSettingComponent.prototype.fetchPaymentMethod = function () {
        var _this = this;
        this.invoiceService.findPaymentMethod(this.user.id).subscribe(function (v) {
            if (v !== undefined && v.card !== undefined) {
                _this.currentCard = v.card;
                console.debug("User " + _this.user.id + ": current card", _this.currentCard);
                _this.paymentImage = _this.getPaymentImageUrl(_this.currentCard.brand);
            }
        });
    };
    PaymentSettingComponent.prototype.onChange = function (_a) {
        var error = _a.error;
        if (error) {
            this.error = error.message;
        }
        else {
            this.error = null;
        }
    };
    PaymentSettingComponent.prototype.ngAfterViewInit = function () {
        this.card = elements.create('card');
        this.card.mount(this.cardInfo.nativeElement);
        this.card.addEventListener('change', this.cardHandler);
    };
    PaymentSettingComponent.prototype.ngOnDestroy = function () {
        if (this.card != null) {
            this.card.removeEventListener('change', this.cardHandler);
            this.card.destroy();
        }
    };
    PaymentSettingComponent.prototype.clearPaymentMethods = function () {
        var _this = this;
        this.invoiceService.clearPaymentMethod(this.user.id).subscribe(function (v) {
            _this.currentCard = undefined;
            _this.snackbar.open("Payment methods are cleared.", "", { duration: 2000 });
        });
    };
    PaymentSettingComponent.prototype.changePaymentMethods = function () {
        this.changeMode = true;
    };
    PaymentSettingComponent.prototype.onSubmit = function (form) {
        var _this = this;
        this.invoiceService.getPaymentIntent()
            .subscribe(function (sk) {
            var that = _this;
            stripe.handleCardSetup(sk, _this.card).then(function (result) {
                if (result.error) {
                    console.debug("Error setting up card for Stripe", result.error);
                }
                else {
                    that.invoiceService.storeUserPaymentMethod(result).subscribe(function () {
                        that.fetchPaymentMethod();
                        that.snackbar.open('Payment method updated', '', { duration: 2000 });
                    });
                    that.changeMode = false;
                }
            });
        });
    };
    PaymentSettingComponent.prototype.isTranslator = function () {
        return this.userService.isUserTranslator(this.user);
    };
    PaymentSettingComponent.prototype.getPaymentImageUrl = function (paymentMethod) {
        return './../../assets/paymentmethod/' + getPaymentImage(paymentMethod);
    };
    return PaymentSettingComponent;
}());
export { PaymentSettingComponent };
