import { ElementRef, EventEmitter } from "@angular/core";
var NewMessageComponent = /** @class */ (function () {
    function NewMessageComponent() {
        this.sendEmitter = new EventEmitter();
    }
    NewMessageComponent.prototype.ngOnInit = function () {
        if (this.user != undefined) {
            this.userId = this.user.id;
            this.userName = this.user.firstName + " " + this.user.lastName;
        }
    };
    NewMessageComponent.prototype.processInput = function (event, location) {
        if (event.ctrlKey && event.key === 'Enter') {
            // If CTRL + Enter, enter a newline at the specified location (if none, just add it to the back)
            if (location != null && location >= 0 && location < this.message.length) {
                var newText = this.message.substring(0, location);
                newText += '\n';
                newText += this.message.substring(location);
                this.message = newText;
            }
            else
                this.message += '\n';
        }
        else if (event.key === 'Enter') {
            // If normal Enter, "consume" the event and send the message
            event.preventDefault();
            this.sendMessage();
        }
    };
    NewMessageComponent.prototype.sendMessage = function () {
        this.sendEmitter.emit({
            text: this.message,
            user: {
                id: this.userId,
                name: this.userName
            }
        });
        this.message = undefined;
    };
    return NewMessageComponent;
}());
export { NewMessageComponent };
