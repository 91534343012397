import { EventEmitter, OnInit } from "@angular/core";
import { ProjectService } from "../../../service/project.service";
import { FileService } from "../../../service/file.service";
var DocumentationListComponent = /** @class */ (function () {
    function DocumentationListComponent(projectService, fileService) {
        this.projectService = projectService;
        this.fileService = fileService;
        this.files = [];
        this.allowRemoval = false;
        this.entityId = undefined;
        this.entityType = 'project';
        this.fileRemovalEmmit = new EventEmitter();
    }
    DocumentationListComponent.prototype.ngOnInit = function () {
    };
    DocumentationListComponent.prototype.removeFile = function (file) {
        this.files.splice(this.files.indexOf(file), 1);
        this.fileRemovalEmmit.emit(file.name);
        if (this.entityType === 'project') {
            this.projectService.removeDocFile(file.name, this.entityId).subscribe(function (v) {
                // Do nothing
            });
        }
        else {
            this.fileService.removeDocFile(file.name, this.entityId, this.entityType).subscribe(function (v) { return console.debug(v); });
        }
    };
    DocumentationListComponent.prototype.removeDocFile = function (docFileName) {
    };
    return DocumentationListComponent;
}());
export { DocumentationListComponent };
