import { ActivatedRoute } from "@angular/router";
//TODO make normal page (not needing to load angular shizzle)
var OauthCallbackComponent = /** @class */ (function () {
    function OauthCallbackComponent(activeRoute) {
        this.activeRoute = activeRoute;
    }
    OauthCallbackComponent.prototype.ngOnInit = function () {
        this.activeRoute.queryParams
            .subscribe(function (params) {
            //TODO check state
            var code = params['code'];
            localStorage.setItem("linkedInAuthCode", code);
            window.close();
        });
    };
    return OauthCallbackComponent;
}());
export { OauthCallbackComponent };
