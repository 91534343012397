import { EventEmitter } from "@angular/core";
import { LanguageService } from "../../service/language.service";
var LanguageSelectorComponent = /** @class */ (function () {
    function LanguageSelectorComponent(languageService) {
        this.languageService = languageService;
        this.disabled = false;
        this.onLanguageSelect = new EventEmitter();
    }
    LanguageSelectorComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.languageService.getLanguages()
            .subscribe(function (v) {
            _this.langKeys = v;
        });
    };
    LanguageSelectorComponent.prototype.selectLanguage = function () {
        this.onLanguageSelect.emit(this.selectedLang);
    };
    LanguageSelectorComponent.prototype.languageKeys = function () {
        return this.langKeys;
    };
    LanguageSelectorComponent.prototype.getDisabled = function () {
        return this.disabled;
    };
    return LanguageSelectorComponent;
}());
export { LanguageSelectorComponent };
