import { map } from 'rxjs/operators';
import { HttpClient } from "@angular/common/http";
import { getBaseUrl } from "../util/httpclient";
var MtService = /** @class */ (function () {
    function MtService(http) {
        this.http = http;
        this.MT_API = getBaseUrl() + "/api/v1/mt";
    }
    MtService.prototype.isDeepLEnabled = function () {
        return this.http.get(this.MT_API + "/deepl/enabled", { responseType: 'text' }).pipe(map(function (result) {
            return result === "true";
        }));
    };
    MtService.prototype.isSupportedLang4DeepL = function (langCode) {
        var url = this.MT_API + "/deepl/language/" + encodeURIComponent(langCode) + "/valid";
        return this.http.get(url, { responseType: 'text' }).pipe(map(function (result) {
            return result === "true";
        }));
    };
    MtService.prototype.isSupportedLangPair4DeepL = function (langPair) {
        return this.http.get(this.MT_API + "/deepl/langpair/" + encodeURIComponent(langPair) + "/valid", { responseType: 'text' }).pipe(map(function (result) {
            return result === "true";
        }));
    };
    return MtService;
}());
export { MtService };
