import { ElementRef, OnInit } from "@angular/core";
import { UserService } from '../../service/user.service';
var ReviewScrollerComponent = /** @class */ (function () {
    function ReviewScrollerComponent(userService) {
        this.userService = userService;
    }
    ReviewScrollerComponent.prototype.ngOnInit = function () {
        this.sortedReviews = this.reviews.sort(function (s1, s2) {
            if (s1.creationdate < s2.creationdate)
                return 1;
            else if (s1.creationdate > s2.creationdate)
                return -1;
            else
                return 0;
        });
        if (this.sortedReviews.length > 0) {
            this.currentReview = this.sortedReviews[0];
            this.currentIndex = 0;
        }
    };
    ReviewScrollerComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        setTimeout(function () {
            _this.calcItemWidth();
        });
    };
    ReviewScrollerComponent.prototype.calcItemWidth = function () {
        if (this.reviewList != undefined) {
            // Calculate the width of the allocated screen space, so we can stretch the items across it
            var leftArrowWidth = (this.leftArrow != undefined ? this.leftArrow.nativeElement.clientWidth : 0);
            var rightArrowWidth = (this.rightArrow != undefined ? this.rightArrow.nativeElement.clientWidth : 0);
            this.scrollerWidth = this.reviewList.nativeElement.clientWidth - leftArrowWidth - rightArrowWidth;
        }
    };
    ReviewScrollerComponent.prototype.scrollLeft = function () {
        if (this.currentIndex > 0) {
            this.currentIndex = this.currentIndex - 1;
            this.currentReview = this.sortedReviews[this.currentIndex];
        }
    };
    ReviewScrollerComponent.prototype.scrollRight = function () {
        if (this.currentIndex < (this.sortedReviews.length - 1)) {
            this.currentIndex = this.currentIndex + 1;
            this.currentReview = this.sortedReviews[this.currentIndex];
        }
    };
    ReviewScrollerComponent.prototype.getTransX = function () {
        if (this.currentIndex != undefined && this.scrollerWidth != undefined) {
            return -1 * this.currentIndex * this.scrollerWidth;
        }
        else
            return 0;
    };
    return ReviewScrollerComponent;
}());
export { ReviewScrollerComponent };
