import { ChangeDetectorRef, EventEmitter, OnChanges, OnInit } from "@angular/core";
import { ColumnType } from "./dynamic-table.model";
import { DatePipe } from "@angular/common";
var DynamicTableComponent = /** @class */ (function () {
    function DynamicTableComponent(datePipe, cdrRef) {
        this.datePipe = datePipe;
        this.cdrRef = cdrRef;
        this.showRowCount = true;
        this.clickable = false;
        this.selectable = false;
        this.onClick = new EventEmitter();
        this.toggleSelected = new EventEmitter();
        this.tableModel = { headers: [], data: [], selected: [] };
        this.masterCheckboxValue = false;
    }
    DynamicTableComponent.prototype.ngOnInit = function () {
        this.render(this.tableHeaders, this.tableData);
    };
    DynamicTableComponent.prototype.ngOnChanges = function () {
        this.render(this.tableHeaders, this.tableData);
    };
    DynamicTableComponent.prototype.render = function (headers, data) {
        this.tableHeaders = headers.sort(function (x, y) { return x.index - y.index; });
        this.tableModel = {
            headers: headers.filter(function (h) { return h.isSelected == null || h.isSelected; }),
            data: data,
            // todo: provide @Input for 'selected' values
            selected: [],
        };
        this.cdrRef.detectChanges();
    };
    DynamicTableComponent.prototype.getCellValue = function (row, column) {
        var lastVal = row;
        column.key.split('.').forEach(function (keyPart, index) {
            if (lastVal != null)
                lastVal = lastVal[keyPart];
        });
        if (lastVal != null && this.isDateColumn(column)) {
            return this.datePipe.transform(lastVal, 'y/MM/dd, HH:mm');
        }
        else
            return lastVal;
    };
    DynamicTableComponent.prototype.isDateColumn = function (header) {
        return header.type === ColumnType.DATE;
    };
    DynamicTableComponent.prototype.onCellClick = function (data, header) {
        if (this.clickable)
            this.onClick.emit({ header: header, data: data });
    };
    DynamicTableComponent.prototype.toggleRowSelected = function (data, index) {
        this.tableModel.selected[index] = !this.tableModel.selected[index];
        // If the master checkbox was selected, check whether it should be deselected
        if (this.masterCheckboxValue && !this.tableModel.selected[index])
            this.masterCheckboxValue = false;
        // If the master checkbox was not selected, check whether it should be selected
        else if (!this.masterCheckboxValue && this.tableModel.selected[index])
            this.masterCheckboxValue = this.tableModel.selected.filter(function (s) { return !s; }).length == 0;
        this.toggleSelected.emit({ selected: this.tableModel.selected[index], data: data });
    };
    DynamicTableComponent.prototype.toggleAllSelected = function () {
        var _this = this;
        this.masterCheckboxValue = !this.masterCheckboxValue;
        this.tableData.forEach(function (row, index) {
            // Only send event if selection changes
            if (_this.tableModel.selected[index] != _this.masterCheckboxValue) {
                _this.tableModel.selected[index] = _this.masterCheckboxValue;
                _this.toggleSelected.emit({ selected: _this.masterCheckboxValue, data: row });
            }
        });
    };
    DynamicTableComponent.prototype.hasData = function () {
        return this.tableModel.data != null && this.tableModel.data.length > 0;
    };
    return DynamicTableComponent;
}());
export { DynamicTableComponent };
