import { MatDialogRef } from '@angular/material/dialog';
var EditorSettingsComponent = /** @class */ (function () {
    function EditorSettingsComponent(dialogRef, data) {
        this.dialogRef = dialogRef;
        this.data = data;
    }
    EditorSettingsComponent.prototype.ngOnInit = function () {
        this.hideAffix = this.data.hideAffix;
        this.hideNoText = this.data.hideNoText;
    };
    EditorSettingsComponent.prototype.cancelClick = function () {
        this.dialogRef.close("cancel");
    };
    EditorSettingsComponent.prototype.confirmClick = function () {
        this.dialogRef.close("confirm");
    };
    return EditorSettingsComponent;
}());
export { EditorSettingsComponent };
