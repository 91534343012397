import { forkJoin, from as observableFrom } from 'rxjs';
import { map, toArray } from 'rxjs/operators';
import { EventEmitter } from "@angular/core";
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { UserService } from "../service/user.service";
import { getProjectStatus, getStatusColor, getUserName, getUserNameFromProject } from "../util/jobutil";
import { LanguageService } from "../service/language.service";
var ProjectListComponent = /** @class */ (function () {
    function ProjectListComponent(userService, languageService) {
        this.userService = userService;
        this.languageService = languageService;
        this.selectedProject = new EventEmitter();
        this.projectRows = undefined;
        this.projectData = new MatTableDataSource();
        this.availCols = ProjectListComponent.defaultCols;
    }
    ProjectListComponent.prototype.ngOnInit = function () {
        if (this.userService.isCurrentUserAdmin) {
            this.availCols = ProjectListComponent.adminCols;
        }
    };
    ProjectListComponent.prototype.ngAfterViewInit = function () {
        this.projectData.sort = this.sort;
    };
    Object.defineProperty(ProjectListComponent.prototype, "matSort", {
        set: function (ms) {
            this.sort = ms;
            this.projectData.sort = this.sort;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ProjectListComponent.prototype, "projects", {
        get: function () {
            return this._projects;
        },
        set: function (p) {
            this._projects = p;
            this.prepProjectData();
        },
        enumerable: true,
        configurable: true
    });
    ProjectListComponent.prototype.isUserAdmin = function () {
        return this.userService.isCurrentUserAdmin;
    };
    ProjectListComponent.prototype.prepProjectData = function () {
        var _this = this;
        var start = Date.now();
        this.userCache = new Map();
        observableFrom(this.projects).pipe(map(function (p) {
            var row = new ProjectRow();
            row.id = p.id;
            row.title = p.title;
            row.dueDate = p.duedate;
            row.orderedOn = p.startdate;
            if (p.langpairs != null) {
                row.sourceLang = _this.getSourceLang(p);
                row.targetLangCodes = _this.getTargetLangs(p);
                if (row.targetLangCodes.length > 0) {
                    // Get display name so we can sort correctly
                    var obs_1 = [];
                    row.targetLangCodes.forEach(function (tl) { obs_1.push(_this.languageService.codeToDescription(tl)); });
                    forkJoin(obs_1).subscribe(function (data) {
                        row.targetLangs = data.sort(function (a, b) { return a.toUpperCase().localeCompare(b.toUpperCase()); });
                    });
                }
            }
            else {
                row.sourceLang = '';
                row.targetLangCodes = new Array();
                row.targetLangs = new Array();
            }
            if (p.files != null)
                row.fileCount = p.files.length;
            else
                row.fileCount = 0;
            row.status = getProjectStatus(p);
            _this.determineUser(p, row);
            // todo: also 'determine' company (based on user only if draft?)
            row.company = _this.getCompany(p);
            row.price = p.totalprice;
            return row;
        }), toArray()).subscribe(function (rows) {
            _this.projectRows = rows;
            _this.projectData.data = _this.projectRows;
            console.debug("Displaying " + _this.projectRows.length + " projects took " + (Date.now() - start) + " ms.");
        });
    };
    ProjectListComponent.prototype.getProjectStatus = function (element) {
        return getProjectStatus(element);
    };
    ProjectListComponent.prototype.determineUser = function (element, row) {
        var _this = this;
        var userName = getUserNameFromProject(element, !this.isUserAdmin());
        row.user = userName;
        // Check and populate the cache if applicable
        if (element.requestedby != null && element.requestedby.id != null) {
            if (userName.length == 0) {
                if (this.userCache.has(element.requestedby.id)) {
                    row.user = this.userCache.get(element.requestedby.id);
                }
                else
                    this.userService.getUser(element.requestedby.id).subscribe(function (u) {
                        row.user = getUserName(u);
                        _this.userCache.set(element.requestedby.id, row.user);
                    });
            }
            else if (!this.userCache.has(element.requestedby.id)) {
                this.userCache.set(element.requestedby.id, row.user);
            }
        }
    };
    ProjectListComponent.prototype.getCompany = function (element) {
        if (element.requestedby != null) {
            if (element.requestedby.companyName != null)
                return element.requestedby.companyName;
            if (element.requestedby.companyId != null)
                return element.requestedby.companyId;
        }
        return '';
    };
    ProjectListComponent.prototype.getSourceLang = function (element) {
        if (element.langpairs != null && element.langpairs.length > 0) {
            return element.langpairs[0].source;
        }
        else
            return '';
    };
    ProjectListComponent.prototype.getTargetLangs = function (element) {
        var targetLangs = new Array();
        if (element.langpairs != null && element.langpairs.length > 0) {
            element.langpairs.filter(function (v, k) { return v.target != null; }).forEach(function (v, k) { return targetLangs.push(v.target); });
        }
        return targetLangs;
    };
    ProjectListComponent.prototype.getStatusColor = function (element) {
        return getStatusColor(element.status);
    };
    ProjectListComponent.prototype.selectProject = function (id) {
        var p = this._projects.filter(function (v) { return v.id === id; });
        if (p.length > 0)
            this.selectedProject.emit(p[0]);
    };
    // TODO: implement alerts
    ProjectListComponent.defaultCols = ['title', /*'alerts',*/ 'sourceLang', 'targetLangs', 'dueDate', 'user', 'status'];
    ProjectListComponent.adminCols = ProjectListComponent.defaultCols.concat('company', 'fileCount');
    ProjectListComponent.invoiceCols = ['title', 'orderedOn', 'user', 'price'];
    return ProjectListComponent;
}());
export { ProjectListComponent };
// Small data-class that holds the project data for each row
var ProjectRow = /** @class */ (function () {
    function ProjectRow() {
    }
    return ProjectRow;
}());
