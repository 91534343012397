import { OnInit } from '@angular/core';
var TooltipContainerDirective = /** @class */ (function () {
    function TooltipContainerDirective() {
    }
    return TooltipContainerDirective;
}());
export { TooltipContainerDirective };
var TooltipComponent = /** @class */ (function () {
    function TooltipComponent(config) {
        this.config = config;
        this.visible = "hidden";
    }
    TooltipComponent.prototype.ngOnInit = function () {
        var _this = this;
        // We need to surround the code to position the tooltip with setTimeout here, or we do not get the updated and correct
        // dimensions of the "boundingClientRect" (no clue why)
        setTimeout(function () {
            var top = _this.config.host.getBoundingClientRect().top;
            var right = _this.config.host.getBoundingClientRect().right;
            var height = _this.tooltipContainer.nativeElement.getBoundingClientRect().height;
            // We align the tooltip over the top right corner of the element (used for project-card at the moment)
            // TODO: add more "config" options so one can choose the position of the tooltip
            _this.top = top - height + 40 + "px";
            _this.left = right - 40 + "px";
            // Make the tooltip visible at this point
            _this.visible = "visible";
            // });
        }, 500);
    };
    return TooltipComponent;
}());
export { TooltipComponent };
