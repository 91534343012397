import { tap } from 'rxjs/operators';
import { OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { TaskService } from "../service/task.service";
var TaskDashboardComponent = /** @class */ (function () {
    function TaskDashboardComponent(taskService, router) {
        this.taskService = taskService;
        this.router = router;
    }
    TaskDashboardComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.unacceptedTask = this.taskService.getUnacceptedTasks()
            .pipe(tap(function (tasks) { return _this.hasUnacceptedTask = tasks.length > 0; }));
        this.acceptedTask = this.taskService.getAcceptedTasks()
            .pipe(tap(function (tasks) { return _this.hasAcceptedTask = tasks.length > 0; }));
        this.completedTask = this.taskService.getCompletedTasks()
            .pipe(tap(function (tasks) { return _this.hasCompletedTask = tasks.length > 0; }));
    };
    TaskDashboardComponent.prototype.gotoTask = function (id) {
        this.router.navigate(['/tdetail', id]);
    };
    return TaskDashboardComponent;
}());
export { TaskDashboardComponent };
