import { AfterViewInit, EventEmitter, OnInit } from "@angular/core";
import { LanguageService } from "../service/language.service";
import { filter, flatMap, map, switchMap } from "rxjs/operators";
var LangSelectListComponent = /** @class */ (function () {
    function LangSelectListComponent(languageService) {
        this.languageService = languageService;
        this.selectedLangs = [];
        this.languages = [];
        this.dialect = true;
        this.searchText = undefined;
        this.eventEmitter = new EventEmitter();
        this.filteredLangs = [];
    }
    LangSelectListComponent.prototype.ngOnInit = function () {
        var _this = this;
        var languageObservable;
        if (this.dialect)
            languageObservable = this.languageService.getDialectLanguages();
        else
            languageObservable = this.languageService.getLanguages();
        languageObservable.subscribe(function (a) {
            _this.filteredLangs = a;
            _this.languages = a;
        });
        this.filteredLangsObservable = languageObservable
            .pipe(flatMap(function (a) { return a; }), map(function (a) { return a; }), switchMap(function (a) { return _this.languageService.codeToDescription(a).map(function (desc) { return [a, desc]; }); }), filter(function (a) {
            if (_this.searchText === undefined)
                return true;
            return a[1].toLocaleLowerCase().indexOf(_this.searchText.toLocaleLowerCase()) >= 0;
        }), map(function (a) { return a[0]; })).toArray();
        if (this.selectedLangs == undefined)
            this.selectedLangs = [];
    };
    LangSelectListComponent.prototype.ngAfterViewInit = function () {
        this.focusOnSearchInput();
    };
    LangSelectListComponent.prototype.focusOnSearchInput = function () {
        document.getElementById("languageSearchInput").focus();
    };
    LangSelectListComponent.prototype.toggleLang = function (language) {
        var index = this.selectedLangs.indexOf(language);
        if (index >= 0) {
            this.onRemove(language);
        }
        else {
            this.onSelect(language);
        }
    };
    //No emitting needed because the array is the same object through the components
    LangSelectListComponent.prototype.onSelect = function (language) {
        this.selectedLangs.push(language);
        this.eventEmitter.emit({ language: language, type: 'add' });
        this.searchText = undefined;
        this.focusOnSearchInput();
    };
    //No emitting needed because the array is the same object through the components
    LangSelectListComponent.prototype.onRemove = function (language) {
        this.selectedLangs.splice(this.selectedLangs.indexOf(language), 1);
        this.eventEmitter.emit({ language: language, type: 'remove' });
    };
    LangSelectListComponent.prototype.isSelected = function (language) {
        return this.selectedLangs.indexOf(language) >= 0;
    };
    LangSelectListComponent.prototype.filterLangs = function () {
        var _this = this;
        if (this.searchText === undefined)
            this.filteredLangs = this.languages;
        else {
            this.filteredLangs = [];
            this.filteredLangsObservable.subscribe(function (a) {
                _this.filteredLangs = a;
            });
        }
    };
    return LangSelectListComponent;
}());
export { LangSelectListComponent };
