import { EventEmitter } from "@angular/core";
import { ProjectService } from "../service/project.service";
import { ActivatedRoute, Router } from "@angular/router";
import { EditorService } from "./service/editor.service";
import { EditorComponent } from "./editor.component";
import { SimpleDialog } from "../simple-dialog.component";
import { MatDialog } from "@angular/material/dialog";
var FinalCheckEditorComponent = /** @class */ (function () {
    function FinalCheckEditorComponent(router, route, projectService, editorService, dialog) {
        this.router = router;
        this.route = route;
        this.projectService = projectService;
        this.editorService = editorService;
        this.dialog = dialog;
        this.fileLoc = undefined;
        this.fileGroupId = undefined;
        this.finalizeEmitter = new EventEmitter();
        this.changeEmitter = new EventEmitter();
    }
    FinalCheckEditorComponent.prototype.ngOnInit = function () {
        this.fileGroupId = this.pId + "_" + this.source + "_" + this.target + "_FDC";
        this.loadFile();
    };
    FinalCheckEditorComponent.prototype.loadFile = function () {
        var _this = this;
        return this.editorService.getFinalCheckFile(this.pId, this.source, this.target, this.filename)
            .subscribe(function (data) {
            _this.fileLoc = data.filelocation;
        });
    };
    FinalCheckEditorComponent.prototype.saveFinalCheckFile = function () {
        var _this = this;
        this.editorService.completeFinalCheckFile(this.fileLoc, this.fileGroupId, this.filename, this.pId, this.source + "_" + this.target)
            .subscribe(function (data) {
            if (JSON.parse(data)) {
                //Successfully saved
                var dialogRef = _this.dialog.open(SimpleDialog, {
                    width: "400px",
                    panelClass: 'simpleDialog',
                    data: { dialogMessage: _this.filename + " is successfully finalized. We're preparing the final file now." }
                });
            }
            else {
                //No changes detected
                var dialogRef = _this.dialog.open(SimpleDialog, {
                    width: "400px",
                    panelClass: 'simpleDialog',
                    data: { dialogMessage: _this.filename + " is successfully finalized. No changes were detected." }
                });
            }
            _this.finalizeEmitter.emit("finalized");
        });
    };
    FinalCheckEditorComponent.prototype.change = function (event) {
        this.changeEmitter.emit(event);
    };
    return FinalCheckEditorComponent;
}());
export { FinalCheckEditorComponent };
