import { filter, map } from 'rxjs/operators';
import { UserService } from "../service/user.service";
import { LogInService } from "../service/login.service";
import { ActivatedRoute } from "@angular/router";
import { JoinDialogOutcome, JoinType } from "./user.join.model";
var InvitedComponent = /** @class */ (function () {
    function InvitedComponent(route, userService, loginService) {
        this.route = route;
        this.userService = userService;
        this.loginService = loginService;
        this.joinWithMailSuccess = false;
    }
    InvitedComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.route.params.pipe(map(function (params) { return params['inviteid']; }), filter(function (key) { return key != undefined; }))
            .subscribe(function (key) {
            sessionStorage.setItem("inviteId", key);
            var dialogRef = _this.loginService.openJoinDialog("newproject");
            _this.listenForDialogResult(dialogRef);
        });
        this.route.queryParams
            .subscribe(function (params) {
            var dialogRef = _this.loginService.openJoinDialog("newproject", params['companytoken'], params['grouptoken']);
            _this.listenForDialogResult(dialogRef);
        });
    };
    InvitedComponent.prototype.listenForDialogResult = function (dialogRef) {
        var _this = this;
        dialogRef.afterClosed().pipe(filter(function (r) { return r != null; }), filter(function (r) { return r.result == JoinDialogOutcome.SUCCESS && r.join == JoinType.MAIL; })).subscribe(function () { return _this.joinWithMailSuccess = true; });
    };
    return InvitedComponent;
}());
export { InvitedComponent };
