import { filter, map, publish } from 'rxjs/operators';
import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { IntervalObservable } from "rxjs/observable/IntervalObservable";
import { getBaseSocketUrl, getBaseUrl } from "../util/httpclient";
var LoggerService = /** @class */ (function () {
    function LoggerService(http) {
        this.http = http;
        this.LOGGER_API = getBaseUrl() + "/api/v1/logger";
        this.LOGGER_SOCKET_URL = getBaseSocketUrl() + "/logger/feed";
    }
    LoggerService.prototype.initLoggerFeed = function () {
        var _this = this;
        this.ws = Observable.webSocket(this.LOGGER_SOCKET_URL);
        //Keep the socket alive (every 4 minutes, server timeout is set to 5 minutes)
        IntervalObservable.create(30000).subscribe(function (o) {
            return _this.ws.next({ 'action': 'keepalive' });
        });
        this.messages = this.makeHot(this.ws).pipe(filter(function (m) { return m != null; }));
        // this.messages.subscribe(msg => console.debug("> Received " + JSON.stringify(msg) + " @" + this.LOGGER_SOCKET_URL),
        //   error => console.error(error));
    };
    LoggerService.prototype.makeHot = function (cold) {
        var obs = cold.pipe(publish());
        obs.connect();
        return obs;
    };
    /**
     * Enrolling will update the websocket-sender, so that it will send messages to your session on the objects you have enrolled in.
     * Once enrolled, an observable is created to filter out the messages for which you have enrolled but are not for the given object.
     */
    LoggerService.prototype.enrollForProject = function (pId) {
        if (!this.ws || this.ws.closed)
            this.initLoggerFeed();
        //To enroll you have to send a message to the socket with the action "enroll" and to which objects you want to enroll
        this.ws.next({ 'action': 'enroll', 'pid': pId });
        //The JSON structure of the message normally has the objects parameters to identify and a "data" object which contains the actual information
        //E.g. {"pid":"10", "filename":"damocles.docx", "data":{"type":"sourcelang", "value":"en"}}
        return this.messages.pipe(filter(function (msg) {
            return msg.pid == pId;
        }), map(function (msg) { return msg.data; }));
    };
    LoggerService.prototype.getLogs = function (pId, fileName, langPair) {
        var queryParams = "";
        if (fileName)
            queryParams += (((queryParams.length == 0) ? "?" : "&") + "fname=" + encodeURIComponent(fileName));
        if (langPair)
            queryParams += (((queryParams.length == 0) ? "?" : "&") + "lp=" + encodeURIComponent(langPair));
        return this.http.get(this.LOGGER_API + "/find/" + pId + queryParams, { responseType: 'json' });
    };
    LoggerService.prototype.getFileCounts = function (pId, langPair) {
        return this.http.get(this.LOGGER_API + "/counts/" + pId + "/" + encodeURIComponent(langPair), { responseType: 'json' });
    };
    return LoggerService;
}());
export { LoggerService };
