import { UserService } from "../service/user.service";
import { MatSnackBar } from "@angular/material/snack-bar";
var PasswordSettingComponent = /** @class */ (function () {
    function PasswordSettingComponent(userService, snackbar) {
        this.userService = userService;
        this.snackbar = snackbar;
        this.wrongPassword = false;
    }
    PasswordSettingComponent.prototype.updatePassword = function () {
        var _this = this;
        if (this.user && this.checkPasswordEqual())
            this.userService.updateCurrentPassword(this.user.id, this.currentPw, this.confirmPassword).subscribe(function (next) {
                if (next == "success") {
                    _this.snackbar.open("Password successfully updated", '', {
                        duration: 2000
                    });
                }
            }, function (error) {
                console.error("Error updating password", error);
                _this.wrongPassword = true;
            });
    };
    PasswordSettingComponent.prototype.checkPasswordEqual = function () {
        return this.password && this.password.length > 0 && this.password == this.confirmPassword;
    };
    PasswordSettingComponent.prototype.isPasswordWrong = function () {
        return this.wrongPassword;
    };
    return PasswordSettingComponent;
}());
export { PasswordSettingComponent };
