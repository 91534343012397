import * as tslib_1 from "tslib";
import { ActivatedRoute } from "@angular/router";
import { VendorService } from "../service/vendor.service";
import { existsAndNotEmpty } from "../util/apputil";
import { getUserName, isRevisionActivity, isSwornTranslationActivity, isTranslationActivity, sortActivities } from 'app/util/jobutil';
import { forkJoin } from 'rxjs';
import { LanguageService } from "../service/language.service";
import { ReviewService } from "../service/review.service";
var UserProfileComponent = /** @class */ (function () {
    function UserProfileComponent(route, vendorService, languageService, reviewService) {
        this.route = route;
        this.vendorService = vendorService;
        this.languageService = languageService;
        this.reviewService = reviewService;
        this.taskActivities = undefined;
        this.competenceGroups = [];
    }
    UserProfileComponent.prototype.ngOnInit = function () {
        var _this = this;
        if (!this.userId) {
            this.route.params
                .forEach(function (params) {
                if (params['id']) {
                    _this.userId = params['id'];
                    _this.setUser();
                }
            });
        }
        else
            this.setUser();
    };
    UserProfileComponent.prototype.setUser = function () {
        var _this = this;
        this.vendorService.getVendor(this.userId).subscribe(function (v) {
            _this.user = v;
            // console.debug("user", this.user);
            _this.initCompetenceData();
            _this.setReviews(_this.user.id);
        });
    };
    UserProfileComponent.prototype.initCompetenceData = function () {
        var _this = this;
        this.taskActivities = tslib_1.__spread(new Set(sortActivities(this.user.competences.map(function (c) { return c.activity; }))));
        this.taskActivities.forEach(function (a) {
            var group = new CompetenceGroup();
            group.activity = a;
            _this.initLangPairMap(group);
            _this.competenceGroups.push(group);
        });
    };
    UserProfileComponent.prototype.initLangPairMap = function (group) {
        var _this = this;
        // First, group by source language
        var temp = new Map();
        this.user.competences.filter(function (c) { return c.activity === group.activity; }).forEach(function (c) {
            var t = temp.get(c.source);
            if (t === undefined)
                t = [];
            t.push(c.target);
            temp.set(c.source, t);
        });
        // Then retrieve the actual language name for each target language so we can properly sort them
        var langpairMap = new Map();
        temp.forEach(function (tls, sl) {
            var obs = [];
            tls.forEach(function (tl) {
                obs.push(_this.getLangNameObs(tl));
            });
            forkJoin(obs).subscribe(function (data) {
                langpairMap.set(sl, data.sort(function (t1, t2) { return t1.name.localeCompare(t2.name); }));
                group.targetLangMap = langpairMap;
                _this.initSourceLangs(group);
            });
        });
        return langpairMap;
    };
    UserProfileComponent.prototype.initSourceLangs = function (group) {
        var _this = this;
        var sourceLangs = [];
        // Retrieve source language names and sort them
        var obs = [];
        group.targetLangMap.forEach(function (v, k) {
            obs.push(_this.getLangNameObs(k));
        });
        forkJoin(obs).subscribe(function (data) {
            sourceLangs = data.sort(function (s1, s2) { return s1.name.localeCompare(s2.name); });
            group.sourceLangs = sourceLangs;
        });
        return sourceLangs;
    };
    UserProfileComponent.prototype.getUserName = function () {
        return getUserName(this.user);
    };
    UserProfileComponent.prototype.showDescription = function () {
        return existsAndNotEmpty(this.user.description);
    };
    UserProfileComponent.prototype.showLocation = function () {
        return this.hasMotherTongue() || this.hasLocation();
    };
    UserProfileComponent.prototype.hasLocation = function () {
        return existsAndNotEmpty(this.user.country);
    };
    UserProfileComponent.prototype.hasMotherTongue = function () {
        return existsAndNotEmpty(this.user.motherTongue);
    };
    UserProfileComponent.prototype.getTaskActivities = function () {
        return this.taskActivities;
    };
    UserProfileComponent.prototype.getSourceLanguages = function (activity) {
        return this.competenceGroups.filter(function (g) { return g.activity === activity; })[0].sourceLangs;
    };
    UserProfileComponent.prototype.getTargetLanguages = function (activity, source) {
        return this.getLanguagePairs(activity).get(source);
    };
    UserProfileComponent.prototype.getLanguagePairs = function (activity) {
        return this.competenceGroups.filter(function (g) { return g.activity === activity; })[0].targetLangMap;
    };
    UserProfileComponent.prototype.getLangNameObs = function (langCode) {
        return this.languageService.codeToDescription(langCode)
            .map(function (v) {
            // Map to object so we still know which code relates to which name
            var lang = Object();
            lang.code = langCode;
            lang.name = v;
            return lang;
        });
    };
    UserProfileComponent.prototype.getActivityVerb = function (type) {
        if (isTranslationActivity(type))
            return "translate";
        else if (isRevisionActivity(type))
            return "revise";
        if (isSwornTranslationActivity(type))
            return "do sworn translations";
        else
            return "process content";
    };
    UserProfileComponent.prototype.setReviews = function (userId) {
        var _this = this;
        this.reviewService.getReviewsForUser(userId)
            .subscribe(function (reviews) {
            _this.reviews = reviews;
            _this.calcGeneralScore();
        });
    };
    UserProfileComponent.prototype.calcGeneralScore = function () {
        var e_1, _a, e_2, _b;
        if (this.reviews) {
            var count = 0;
            var total = 0;
            try {
                for (var _c = tslib_1.__values(this.reviews), _d = _c.next(); !_d.done; _d = _c.next()) {
                    var rev = _d.value;
                    try {
                        for (var _e = (e_2 = void 0, tslib_1.__values(rev.scores)), _f = _e.next(); !_f.done; _f = _e.next()) {
                            var score = _f.value;
                            count++;
                            total += score.rate;
                        }
                    }
                    catch (e_2_1) { e_2 = { error: e_2_1 }; }
                    finally {
                        try {
                            if (_f && !_f.done && (_b = _e.return)) _b.call(_e);
                        }
                        finally { if (e_2) throw e_2.error; }
                    }
                }
            }
            catch (e_1_1) { e_1 = { error: e_1_1 }; }
            finally {
                try {
                    if (_d && !_d.done && (_a = _c.return)) _a.call(_c);
                }
                finally { if (e_1) throw e_1.error; }
            }
            if (count > 0)
                this.generalScore = total / count;
            else
                this.generalScore = 0;
        }
        else
            this.generalScore = 0;
    };
    return UserProfileComponent;
}());
export { UserProfileComponent };
/**
 * Data class to organize competence data per activity.
 */
var CompetenceGroup = /** @class */ (function () {
    function CompetenceGroup() {
    }
    return CompetenceGroup;
}());
export { CompetenceGroup };
