import { Observable, throwError as observableThrowError } from 'rxjs';
import { catchError, filter, last, map, publish, tap } from 'rxjs/operators';
import { DomSanitizer } from '@angular/platform-browser';
import { ProjectService } from "./project.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { HttpClient, HttpEventType, HttpParams, HttpRequest } from "@angular/common/http";
import { IntervalObservable } from "rxjs/observable/IntervalObservable";
import { getBaseSocketUrl, getBaseUrl, normalizeFileName } from "../util/httpclient";
import { environment } from '../../environments/environment';
var FileService = /** @class */ (function () {
    function FileService(http, sanitizer, projectService, snackbar) {
        this.http = http;
        this.sanitizer = sanitizer;
        this.projectService = projectService;
        this.snackbar = snackbar;
        this.FILES_SOCKET_URL = getBaseSocketUrl() + "/files/feed";
        this.FILES_API = getBaseUrl() + "/api/v1/files";
        console.debug("FileService initializing => connects to " + this.FILES_API);
    }
    FileService.prototype.initFileFeed = function () {
        var _this = this;
        this.ws = Observable.webSocket(this.FILES_SOCKET_URL);
        //Keep the socket alive (every 4 minutes, server timeout is set to 5 minutes)
        IntervalObservable.create(30000).subscribe(function (o) {
            return _this.ws.next({ 'action': 'keepalive' });
        });
        this.messages = this.makeHot(this.ws).pipe(filter(function (m) { return m != null; }));
        // this.messages.subscribe(msg => console.log("> Received " + JSON.stringify(msg) + " @" + this.FILES_SOCKET_URL),
        //   error => console.error(error));
        //Subscribe for the snackbar
        if (environment.isDebug) {
            this.messages.pipe(map(function (m) { return m.data; }), map(function (m) {
                var filename = m.filename;
                var langpair = m.langpair;
                var generatedFor = "";
                if (filename)
                    generatedFor += " " + filename;
                if (langpair) {
                    generatedFor += " " + langpair;
                }
                return m.action + " for: " + generatedFor;
            }))
                .subscribe(function (v) { return _this.snackbar.open(v, '', {
                duration: 2000
            }); });
        }
    };
    //For more info see analyses.service.ts
    FileService.prototype.makeHot = function (cold) {
        var obs = cold.pipe(publish());
        obs.connect();
        return obs;
    };
    FileService.prototype.enrollForProject = function (pId) {
        return this.enroll(pId)
            .map(function (msg) { return msg.data; });
    };
    FileService.prototype.enrollForProjectAndFile = function (pId, fileName) {
        return this.enroll(pId).pipe(filter(function (msg) {
            return msg.filename === fileName;
        }), map(function (msg) { return msg.data; }));
    };
    FileService.prototype.enroll = function (pId) {
        if (!this.ws || this.ws.closed)
            this.initFileFeed();
        //To enroll you have to send a message to the socket with the action "enroll" and to which objects you want to enroll
        this.ws.next({ 'action': 'enroll', 'pid': pId });
        //The JSON structure of the message normally has the objects parameters to identify and a "data" object which contains the actual information
        //E.g. {"pid":"10", "data":{"action":"PDF", "langname":"damocles.docx"}}
        return this.messages.pipe(filter(function (msg) {
            return msg.pid == pId;
        }));
    };
    FileService.prototype.uploadProjectFile = function (file, pId, type, extraParams) {
        if (extraParams === void 0) { extraParams = null; }
        var formData = new FormData();
        var fileName = normalizeFileName(file.name);
        formData.append('uploadFile', file, fileName);
        formData.append('title', fileName);
        var params = new HttpParams();
        if (type != null && type.length > 0)
            params = params.append("usage", type);
        // Add extra params if specified
        if (extraParams != null)
            extraParams.forEach(function (v, k) { return params = params.append(k, v); });
        return this.http.post(this.FILES_API + "/project/upload/" + pId, formData, {
            responseType: 'text',
            params: params
        });
    };
    FileService.prototype.uploadProjectFileWithProgress = function (file, pId, type, progress, extraParams) {
        if (extraParams === void 0) { extraParams = null; }
        var formData = new FormData();
        var fileName = normalizeFileName(file.name);
        formData.append('uploadFile', file, fileName);
        formData.append('title', fileName);
        var params = new HttpParams();
        if (type != null && type.length > 0)
            params = params.append("usage", type);
        // Add extra params if specified
        if (extraParams != null)
            extraParams.forEach(function (v, k) { return params = params.append(k, v); });
        var req = new HttpRequest('POST', this.FILES_API + "/project/upload/" + pId, formData, {
            reportProgress: true,
            responseType: 'text',
            params: params
        });
        return this.http.request(req).pipe(map(function (event) {
            console.debug("Uploading file " + file.name + " =>", event);
            // Determine upload percentage based on the event
            if (event.type == HttpEventType.UploadProgress)
                return Math.round(100 * event.loaded / event.total);
            else if (event.type == HttpEventType.Response)
                return 100;
            else
                return -1;
        }), tap(function (percent) {
            // Signal the progress percentage to the subject
            if (percent >= 0)
                progress.next(percent);
        }), last(), // (simply returns last value)
        catchError(function (error) {
            console.error("Error during upload of file " + file.name + " => ", error);
            var msg = error.status + " " + error.statusText + " - " + error.url;
            return observableThrowError(new Error(msg));
        }));
    };
    FileService.prototype.getProfilePic = function (id) {
        return Observable.empty();
        /*return this.http.get(this.FILES_API + "/users/profile/" + id, {responseType: "blob"}).pipe(
          map(blob => {
            let urlCreator = window.URL;
            return this.sanitizer.bypassSecurityTrustUrl(urlCreator.createObjectURL(blob));
          }));*/
    };
    FileService.prototype.uploadProfilePicture = function (file, userid) {
        var formData = new FormData();
        formData.append('uploadFile', file, normalizeFileName(file.name));
        formData.append('requestedname', 'profilepic.png');
        return this.http.post(this.FILES_API + "/users/profile/" + userid, formData, { responseType: 'text' });
    };
    FileService.prototype.getCompanyDocumentationList = function (id) {
        return this.fileContent('company', id, 'documentation');
    };
    FileService.prototype.uploadCompanyDocumentation = function (file, companyId) {
        var formData = new FormData();
        formData.append('uploadFile', file, normalizeFileName(file.name));
        return this.http.post(this.FILES_API + '/company/documentation/' + companyId, formData, { responseType: 'text' });
    };
    FileService.prototype.uploadFile = function (file, pId) {
        return this.uploadProjectFile(file, pId, null);
    };
    FileService.prototype.uploadDocFile = function (file, entityId, entityType) {
        if (entityType === void 0) { entityType = 'project'; }
        if (entityType === 'project') {
            return this.uploadProjectFile(file, entityId, 'documentation');
        }
        else if (entityType === 'company') {
            return this.uploadCompanyDocumentation(file, entityId);
        }
        else {
            throw Error('Entity type ' + entityType + ' is not recognized');
        }
    };
    // TODO check why delete cannot be used "CROSS-error"
    FileService.prototype.removeDocFile = function (filename, entityId, entityType) {
        var params = new HttpParams().set('filename', filename);
        return this.http.post(this.FILES_API + '/' + entityType + '/remove/documentation/' + entityId, undefined, { responseType: 'text', params: params });
    };
    FileService.prototype.uploadPdfDocFile = function (file, pId, source4pdf) {
        var extraParam = new Map();
        extraParam.set("source4pdf", source4pdf);
        return this.uploadProjectFile(file, pId, "docpdf", extraParam);
    };
    FileService.prototype.uploadPDFForInvoice = function (invoice, file) {
        var formData = new FormData();
        formData.append('uploadFile', file, normalizeFileName(file.name));
        return this.http.post(this.FILES_API + "/invoice/upload/" + invoice.company.id + "/" + invoice.id, formData, { responseType: 'text' });
    };
    FileService.prototype.uploadTranslation = function (file, fileName, task, processType) {
        var formData = new FormData();
        var transFileName = normalizeFileName(file.name);
        formData.append('uploadFile', file, transFileName);
        formData.append('title', transFileName);
        var extentionRegex = /(?:\.([^.]+))?$/;
        var params = new HttpParams().set("processType", processType)
            .set("extension", "." + extentionRegex.exec(transFileName)[1]);
        return this.http.post(this.FILES_API + "/task/upload/" + task.id + "/" + encodeURIComponent(fileName), formData, {
            responseType: 'text',
            params: params
        });
    };
    FileService.prototype.confirmTranslation = function (downloadPath, fileName, task, processType) {
        return this.http.post(this.FILES_API + "/task/confirm/" + task.id + "/" + encodeURIComponent(fileName), downloadPath, { responseType: 'text' });
    };
    FileService.prototype.getFrontpage = function (pId, fileName) {
        var _this = this;
        return this.http.get(this.FILES_API + "/frontpage/" + pId + "/" + encodeURIComponent(fileName), { responseType: "blob" }).pipe(map(function (blob) {
            var urlCreator = window.URL;
            return _this.sanitizer.bypassSecurityTrustUrl(urlCreator.createObjectURL(blob));
        }));
    };
    FileService.prototype.downloadSource = function (pId, fileName) {
        return this.http.get(this.FILES_API + "/dl/project/" + pId + "/" + encodeURIComponent(fileName), { responseType: "blob" });
    };
    FileService.prototype.downloadDoc = function (entityId, fileName, type) {
        if (type === void 0) { type = 'project'; }
        var params = new HttpParams().set("usage", "documentation");
        return this.http.get(this.FILES_API + '/dl/' + type + '/' + entityId + '/' + encodeURIComponent(fileName), {
            responseType: 'blob',
            params: params
        });
    };
    FileService.prototype.downloadXliff = function (fileName, task) {
        return this.http.get(this.FILES_API + "/bestxliff/" + task.projectId + "/" +
            encodeURIComponent(task.sourcelanguage + "_" + task.targetlanguage) + "/" +
            encodeURIComponent(fileName), { responseType: "blob" });
    };
    FileService.prototype.downloadSDLXliff = function (fileName, task) {
        return this.http.get(this.FILES_API + "/bestxliff/sdl/" + task.projectId + "/" +
            encodeURIComponent(task.sourcelanguage + "_" + task.targetlanguage) + "/" +
            encodeURIComponent(fileName), { responseType: "blob" });
    };
    FileService.prototype.downloadTMX = function (fileName, task) {
        return this.http.get(this.FILES_API + "/tmx/" + task.projectId + "/" +
            encodeURIComponent(task.sourcelanguage + "_" + task.targetlanguage) + "/" +
            encodeURIComponent(fileName), { responseType: "blob" });
    };
    FileService.prototype.downloadPDF = function (fileName, task) {
        return this.http.get(this.FILES_API + "/pdf/" + task.projectId + "/" + encodeURIComponent(fileName), { responseType: "blob" });
    };
    FileService.prototype.downloadSegHtml = function (projectId, fileName) {
        return this.http.get(this.FILES_API + "/seghtml/" + projectId + "/" + encodeURIComponent(fileName), { responseType: "blob" });
    };
    FileService.prototype.isSegHtmlAvailable = function (projectId, fileName) {
        return this.http.get(this.FILES_API + "/available/" + projectId + "/seg2html?filename=" + encodeURIComponent(fileName), { responseType: "json" }).pipe(map(function (l) { return l; }), map(function (a) { return a.length > 0; }));
    };
    FileService.prototype.downloadFinal = function (pId, langPair, fileName) {
        return this.http.get(this.FILES_API + "/final/" + pId + "/" + encodeURIComponent(langPair) + "/" + encodeURIComponent(fileName), { responseType: "blob" });
    };
    FileService.prototype.fetchAvailableFinals = function (pId, langPair) {
        return this.http.get(this.FILES_API + "/available/" + pId + "/" + encodeURIComponent(langPair) + "/2orig", { responseType: "json" });
    };
    FileService.prototype.downloadInvoicePDF = function (entityId, invoiceId) {
        return this.http.get(this.FILES_API + "/dl/invoice/" + entityId + "/" + invoiceId, { responseType: "blob" });
    };
    FileService.prototype.availableXliffs = function (task) {
        return this.http.get(this.FILES_API + "/availablefortask/" + task.id, { responseType: "json" });
    };
    FileService.prototype.availablePdfs = function (task) {
        return this.http.get(this.FILES_API + "/available/" + task.projectId + "/pdf", { responseType: "json" });
    };
    FileService.prototype.availableTMXs = function (task) {
        var languagepair = task.sourcelanguage + "_" + task.targetlanguage;
        var params = new HttpParams().set("usage", "tmx");
        return this.http.get(this.FILES_API + "/available/" + task.projectId + "/" + encodeURIComponent(languagepair) + "/tm", {
            responseType: "json",
            params: params
        });
    };
    FileService.prototype.fileContent = function (type, id, subDir) {
        console.debug(type);
        var qryParams = (subDir != null ? "?subdir=" + encodeURIComponent(subDir) : "");
        return this.http.get(this.FILES_API + "/view/" + type.toLowerCase() + "/" + id + qryParams, { responseType: "json" });
    };
    FileService.prototype.download = function (type, id, subDir, fileName) {
        var qryParams = (subDir != null ? "?subdir=" + encodeURIComponent(subDir) : "");
        return this.http.get(this.FILES_API + "/admin/dl/" + type.toLowerCase() + "/" + id + "/" + encodeURIComponent(fileName) + qryParams, { responseType: "blob" });
    };
    FileService.prototype.fileExists = function (type, id, subDir, fileName) {
        var qryParams = (subDir != null ? "?subdir=" + encodeURIComponent(subDir) : "");
        return this.http.get(this.FILES_API + "/admin/check/" + type.toLowerCase() + "/" + id + "/" + encodeURIComponent(fileName) + qryParams, { responseType: "text" });
    };
    FileService.prototype.addFile = function (fileToAdd, type, id, subDir, confirmedReplace) {
        var formData = new FormData();
        formData.append('uploadFile', fileToAdd, fileToAdd.name);
        var params = new HttpParams();
        params = params.append("confirmedReplace", confirmedReplace ? "true" : "false");
        if (subDir != null && subDir.length > 0) {
            params = params.append("subDir", subDir);
        }
        return this.http.post(this.FILES_API + "/admin/add/" + type + "/" + id, formData, {
            responseType: 'text',
            params: params
        });
    };
    FileService.prototype.removeFile = function (files, type, id, subDir) {
        var fileNamesToRemove = new Array();
        files.forEach(function (f) {
            fileNamesToRemove.push(f.name);
        });
        var params = new HttpParams();
        if (subDir != null && subDir.length > 0)
            params = params.append("subDir", subDir);
        return this.http.post(this.FILES_API + "/admin/remove/" + type + "/" + id, JSON.stringify(fileNamesToRemove), {
            responseType: 'text',
            params: params
        });
    };
    FileService.prototype.renameFile = function (newName, type, id, subDir, fileName) {
        var params = new HttpParams();
        if (subDir != null && subDir.length > 0)
            params = params.append("subDir", subDir);
        return this.http.post(this.FILES_API + "/admin/rename/" + type + "/" + id + "/" + encodeURIComponent(fileName), newName, {
            responseType: 'text',
            params: params
        });
    };
    FileService.prototype.createDir = function (folderName, type, id, subDir) {
        var params = new HttpParams();
        if (subDir != null && subDir.length > 0)
            params = params.append("subDir", subDir);
        return this.http.post(this.FILES_API + "/admin/createdir/" + type + "/" + id, folderName, {
            responseType: 'text',
            params: params
        });
    };
    return FileService;
}());
export { FileService };
