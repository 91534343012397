import { OnInit } from "@angular/core";
import { ChatService } from '../../service/chat.service';
import { UserService } from '../../service/user.service';
import { ChatComponent } from '../../chatcomponent/chat.component';
var ProjectChatComponent = /** @class */ (function () {
    function ProjectChatComponent(userService, chatService) {
        this.userService = userService;
        this.chatService = chatService;
        this.unreadCount = undefined;
        this.isChatExpanded = false;
    }
    ProjectChatComponent.prototype.chatMarkAsRead = function () {
        if (this.chatComponent) {
            this.chatComponent.markAllAsRead();
        }
    };
    ProjectChatComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.setUser();
        this.userService.loginEmitter.subscribe(function (_) { return _this.setUser(); });
    };
    ProjectChatComponent.prototype.setUser = function () {
        var _this = this;
        if (this.userService.isLoggedIn()) {
            this.chatService.fetchUnreadChatCount(this.projectId, this.userService.getCurrentUser().id)
                .subscribe(function (unreadCount) {
                _this.unreadCount = unreadCount;
                if (_this.unreadCount > 0) {
                    _this.isChatExpanded = true;
                }
            });
        }
    };
    ProjectChatComponent.prototype.toggleCollapse = function () {
        this.isChatExpanded = !this.isChatExpanded;
        if (this.isChatExpanded) {
            this.chatMarkAsRead();
        }
    };
    return ProjectChatComponent;
}());
export { ProjectChatComponent };
