import { PipeTransform } from '@angular/core';
import { DomSanitizer } from "@angular/platform-browser";
/**
 * Pipe to "bypass" the sanitizer.
 * Only use this if you're sure the HTML you're displaying is safe (and contains no malicious scripts, for example).
 *
 * Code copied from <a href="https://medium.com/@ahmedhamedTN/make-styles-work-when-dealing-with-innerhtml-in-angular-ac2d524ba001">
 *     https://medium.com/@ahmedhamedTN/make-styles-work-when-dealing-with-innerhtml-in-angular-ac2d524ba001</a>.
 */
var SafeHtmlPipe = /** @class */ (function () {
    function SafeHtmlPipe(sanitizer) {
        this.sanitizer = sanitizer;
    }
    SafeHtmlPipe.prototype.transform = function (html) {
        return this.sanitizer.bypassSecurityTrustHtml(html);
    };
    return SafeHtmlPipe;
}());
export { SafeHtmlPipe };
