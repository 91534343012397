import { EventEmitter } from "@angular/core";
import { FileService } from "../../service/file.service";
var DocumentationFileAddComponent = /** @class */ (function () {
    function DocumentationFileAddComponent(fileService) {
        this.fileService = fileService;
        this.uploadedFiles = [];
        this.onFileUpload = new EventEmitter();
        this.infoText = 'Reference material, Terminology, Product information, ...';
    }
    DocumentationFileAddComponent.prototype.ngOnInit = function () {
        if (this.uploadedFiles != null && this.uploadedFiles.length > 0) {
            this.infoText = null;
        }
    };
    DocumentationFileAddComponent.prototype.uploadFiles = function (event) {
        if (this.uploadedFiles == null) {
            this.uploadedFiles = [];
        }
        var fileList = event.target.files;
        if (fileList.length > 0) {
            // Loop and upload the files
            for (var i = 0; i < fileList.length; i++) {
                var file = fileList[i];
                this.fileService.uploadDocFile(file, this.entityId, this.entityType).subscribe();
                this.onFileUpload.emit({ docfile: file });
                this.infoText = null;
            }
        }
    };
    return DocumentationFileAddComponent;
}());
export { DocumentationFileAddComponent };
