import { map } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { getBaseUrl } from "../util/httpclient";
import { UserService } from "./user.service";
var HubspotService = /** @class */ (function () {
    function HubspotService(http, userService) {
        this.http = http;
        this.userService = userService;
        this.HUBSPOT_API = getBaseUrl() + "/api/v1/hubspot";
    }
    HubspotService.prototype.testHubspotConnection = function (testKey) {
        return this.http.get(this.HUBSPOT_API + "/connectiontest/" + testKey, { headers: this.createHeader() }).pipe(map(function (a) {
            if (a == true)
                return "success";
            return "failed";
        }));
    };
    HubspotService.prototype.getPages = function () {
        return this.http.get(this.HUBSPOT_API + "/pages", { headers: this.createHeader() });
    };
    HubspotService.prototype.createProject = function (selectedPages) {
        return this.http.post(this.HUBSPOT_API + "/project", selectedPages, { headers: this.createHeader() });
    };
    HubspotService.prototype.createHeader = function () {
        return new HttpHeaders({
            "X-API-Key": this.userService.getCurrentUser().publicApiKey
        });
    };
    return HubspotService;
}());
export { HubspotService };
