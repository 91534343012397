import { map } from 'rxjs/operators';
import { OnDestroy } from "@angular/core";
import { UserService } from "../service/user.service";
import { ChatService } from "../service/chat.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Router } from "@angular/router";
import { scrollToBottom } from "../util/apputil";
import { SubscriptionHandler } from '../util/subscription.handler';
var ChatComponent = /** @class */ (function () {
    function ChatComponent(userService, chatService, snackbar, router) {
        this.userService = userService;
        this.chatService = chatService;
        this.snackbar = snackbar;
        this.router = router;
        this.newMessage = undefined;
        this.currentRoute = undefined;
        this.subscriptionHandler = new SubscriptionHandler(this);
    }
    ChatComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.user = this.userService.getCurrentUser();
        this.userService.userEmitter.subscribe(function (v) {
            return _this.user = v;
        });
        this.currentRoute = this.router.url;
        if (this.projectId) {
            this.chatService.fetchChatMessages(this.projectId).subscribe(function (m) {
                _this.messages = m;
                _this.sortMessages();
                scrollToBottom("messagesDiv");
            });
            //add a link to the current url (as the user might already be in another project or task) (custom component is required)
            var chatSubscription = this.chatService.enrollForProjectChat(this.projectId)
                .subscribe(function (m) {
                var ref = _this.snackbar.openFromComponent(ChatNotification, {
                    duration: 5000,
                    data: { url: _this.currentRoute, messageContent: m.text }
                });
                _this.messages.push(m.message);
                _this.sortMessages();
                scrollToBottom("messagesDiv");
            });
            this.subscriptionHandler.push(chatSubscription);
        }
    };
    ChatComponent.prototype.ngOnDestroy = function () {
    };
    ChatComponent.prototype.sortMessages = function (desc) {
        if (desc === void 0) { desc = false; }
        this.messages = this.messages.sort(function (m1, m2) {
            var mod = desc ? -1 : 1;
            if (m1.date > m2.date) {
                return mod * 1;
            }
            else if (m1.date < m2.date) {
                return mod * -1;
            }
            else
                return 0;
        });
    };
    ChatComponent.prototype.getUser = function () {
        return this.userService.getCurrentUser();
    };
    ChatComponent.prototype.addNewMessage = function (message) {
        var _this = this;
        this.newMessage = message;
        this.newMessage.pId = this.projectId;
        scrollToBottom("messagesDiv");
        this.chatService.createNewMessage(this.newMessage).pipe(map(function (res) {
            return res;
        }), map(function (res) { return res.data; }))
            .subscribe(function (msg) {
            _this.newMessage = undefined;
            _this.messages.push(msg);
            _this.sortMessages();
            scrollToBottom("messagesDiv");
        }, function (error) {
            console.debug("Error sending message", _this.newMessage);
            _this.newMessage = undefined;
        }, function () {
        });
        // When an answer is given to a message mark all messages as read.
        this.markAllAsRead();
    };
    ChatComponent.prototype.markAllAsRead = function () {
        if (this.getUser() != null) {
            this.markMessagesAsRead(this.messages, this.getUser().id);
        }
    };
    ChatComponent.prototype.markMessagesAsRead = function (messages, userId) {
        var _this = this;
        if (messages != null) {
            messages
                .map(function (m) {
                if (m.replies != null) {
                    _this.markMessagesAsRead(m.replies, userId);
                }
                return m;
            })
                .filter(function (m) { return !ChatComponent.isReadMessage(m, userId); })
                .forEach(function (m) {
                return _this.chatService.markAsRead(m.id, userId).subscribe();
            });
        }
    };
    ChatComponent.prototype.isRead = function (message) {
        if (this.getUser() == null)
            return true;
        return ChatComponent.isReadMessage(message, this.getUser().id);
    };
    ChatComponent.isReadMessage = function (message, userId) {
        // If the given user sent the message, then always assume it is "read"
        if (message.user.id == userId)
            return true;
        if (message.readBy == undefined)
            return false;
        if (message.readBy instanceof Array) {
            return message.readBy
                .map(function (r) { return r.id; })
                .indexOf(userId) >= 0;
        }
        return true;
    };
    return ChatComponent;
}());
export { ChatComponent };
var ChatNotification = /** @class */ (function () {
    function ChatNotification(data) {
        this.data = data;
        this.messageContent = undefined;
        this.url = undefined;
    }
    ChatNotification.prototype.ngOnInit = function () {
        this.messageContent = this.data.messageContent;
        this.url = this.data.url;
    };
    return ChatNotification;
}());
export { ChatNotification };
