import { OnInit } from "@angular/core";
import { UserService } from "../../../service/user.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ColumnType } from "../../../util/component/dynamic-table/dynamic-table.model";
import { environment } from "../../../../environments/environment";
var CustomerGroupListComponent = /** @class */ (function () {
    function CustomerGroupListComponent(userService, snackbar) {
        this.userService = userService;
        this.snackbar = snackbar;
        this.customerGroups = null;
        this.headers = [
            {
                key: 'id',
                value: 'ID',
            },
            {
                key: 'name',
                value: 'Name',
            },
            {
                key: 'token',
                value: 'Token',
            },
            {
                key: 'tm.tmIdx',
                value: 'TM',
            },
            {
                key: 'creationdate',
                value: 'Created on',
                type: ColumnType.DATE,
            },
        ];
        this.newGroupName = "";
        this.host = "";
        this.host = environment.appURI;
    }
    CustomerGroupListComponent.prototype.ngOnInit = function () {
        this.retrieveGroups();
    };
    CustomerGroupListComponent.prototype.retrieveGroups = function () {
        var _this = this;
        this.userService.findCustomerGroups().subscribe(function (groups) {
            _this.customerGroups = groups;
        });
    };
    CustomerGroupListComponent.prototype.isNewButtonValid = function () {
        return this.newGroupName != null && this.newGroupName.trim().length > 0;
    };
    CustomerGroupListComponent.prototype.createNew = function () {
        var _this = this;
        var custGroup = { name: this.newGroupName.trim() };
        this.userService.createCustomerGroup(custGroup).subscribe(function (r) {
            _this.snackbar.open("Customer group created", '', { duration: 2000 });
            _this.retrieveGroups();
        });
    };
    CustomerGroupListComponent.prototype.groupSelected = function (event) {
        // TODO: open page with customer group details
    };
    return CustomerGroupListComponent;
}());
export { CustomerGroupListComponent };
