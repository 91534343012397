import { PricingService } from "../service/pricing.services";
var CreditSettingComponent = /** @class */ (function () {
    function CreditSettingComponent(pricingService) {
        this.pricingService = pricingService;
    }
    CreditSettingComponent.prototype.ngOnInit = function () {
        this.initCredits();
    };
    CreditSettingComponent.prototype.initCredits = function () {
        var _this = this;
        this.pricingService.getCreditsOverview(this.user.id)
            .subscribe(function (credits) { return _this.credits = credits; });
    };
    CreditSettingComponent.prototype.validateToken = function () {
        var _this = this;
        this.pricingService.validateCredit(this.user.id, this.validationToken).subscribe(function (r) {
            if (r == "success")
                _this.initCredits();
        });
    };
    return CreditSettingComponent;
}());
export { CreditSettingComponent };
