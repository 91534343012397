import { filter, isEmpty, map, mergeMap, switchMap } from 'rxjs/operators';
import { OnDestroy } from "@angular/core";
import * as FileSaver from "file-saver";
import { Task } from "../dto/job";
import { TaskService } from "../service/task.service";
import { ActivatedRoute, Router } from "@angular/router";
import { ProjectService } from "../service/project.service";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { FileService } from "../service/file.service";
import { UserService } from "../service/user.service";
import { LogInService } from "../service/login.service";
import { environment } from "../../environments/environment";
import { HelpDialogComponent } from "../util/help.dialog.component";
import { Vendor } from "../dto/vendor";
import { VendorSelectorDialog } from "../projectcomponent/util/vendor-selector.component";
import { ChatService } from "../service/chat.service";
import { HttpClient } from "@angular/common/http";
import { getDocFiles, getFileExtension, getGeneralDocFiles, getSourceFiles, isTranslationTask } from "../util/jobutil";
import { SERVICE_EDITING, SERVICE_SWORN_TRANSLATION } from '../mock-activities';
import { SubscriptionHandler } from '../util/subscription.handler';
import { ENABLE_QUOTE } from "../dto/project";
/**
 * Created by jefv on 3/11/2017.
 */
var TaskDetailComponent = /** @class */ (function () {
    function TaskDetailComponent(taskService, projectService, userService, route, dialog, loginService, fileService, chatService, router, http) {
        this.taskService = taskService;
        this.projectService = projectService;
        this.userService = userService;
        this.route = route;
        this.dialog = dialog;
        this.loginService = loginService;
        this.fileService = fileService;
        this.chatService = chatService;
        this.router = router;
        this.http = http;
        this.revisionNeeded = false;
        this.isDebug = environment.isDebug;
        this.subscriptionHandler = new SubscriptionHandler(this);
    }
    TaskDetailComponent.prototype.ngOnInit = function () {
        var _this = this;
        if (!this.userService.hasTranslatorRole()) {
            this.userService.signInFromLocalStorage().pipe(isEmpty(), filter(function (v) { return v; }))
                .subscribe(function () { return Promise.resolve().then(function () { return _this.loginService.openSignInPopup(); }); });
        }
        this.route.params.pipe(switchMap(function (params) { return _this.taskService.getTask(params['id']); }))
            .subscribe(function (task) {
            _this.setTask(task);
            _this.initProjectData();
            // Listen for task updates
            var taskSubscription = _this.taskService.enrollForTask(task.id).pipe(map(function (m) { return m.task_id; }), mergeMap(function (t) { return _this.taskService.getTask(t); }))
                .subscribe(function (nt) {
                if (nt.id == task.id)
                    _this.setTask(nt);
            });
            _this.subscriptionHandler.push(taskSubscription);
        });
    };
    TaskDetailComponent.prototype.ngOnDestroy = function () {
    };
    TaskDetailComponent.prototype.setTask = function (task) {
        var _this = this;
        this.task = task;
        this.activityName = this.isServiceSpecificTranslation() ? this.task.service : this.task.activity;
        if (isTranslationTask(this.task)) {
            this.taskService.hasNextTask(this.task).subscribe(function (b) { return _this.revisionNeeded = b; });
        }
    };
    TaskDetailComponent.prototype.isServiceSpecificTranslation = function () {
        return this.task.service === SERVICE_SWORN_TRANSLATION
            || this.task.service === SERVICE_EDITING;
    };
    TaskDetailComponent.prototype.initProjectData = function () {
        var _this = this;
        this.projectService.getProject(this.task.projectId).subscribe(function (res) {
            _this.project = res;
            _this.sourceFiles = getSourceFiles(_this.project);
            _this.docFiles = getDocFiles(_this.project);
            // Filter out the custom documentation PDFs, because they are displayed separately
            _this.generalDocFiles = getGeneralDocFiles(_this.project);
            _this.projectDueDate = res.duedate;
            _this.requestedBy = res.requestedby;
            // this.initTotalWordcount();
        });
    };
    TaskDetailComponent.prototype.getPdf4Source = function (fileName) {
        if (this.docFiles) {
            return this.docFiles.filter(function (f) { return f.docinfo != null && f.docinfo.source4pdf != null && f.docinfo.source4pdf === fileName; })[0];
        }
        else
            return null;
    };
    TaskDetailComponent.prototype.downloadDoc = function (file) {
        this.fileService.downloadDoc(this.task.projectId, file.name).subscribe(function (n) {
            FileSaver.saveAs(n, file.name, true);
        });
    };
    TaskDetailComponent.prototype.openTaskHelp = function () {
        var _this = this;
        this.http.get('assets/help/task-help.html', { responseType: 'text' })
            .subscribe(function (helptext) {
            var dialogRef = _this.dialog.open(HelpDialogComponent, {
                maxWidth: '700px',
                maxHeight: '85%',
                data: { helpTitle: "How to handle a task?", helpHtmlContent: helptext }
            });
        });
    };
    TaskDetailComponent.prototype.openDocHelp = function () {
        var helpText = "\n      <p>Below you will find a list of documentation files the customer has provided with the project, for your convenience.</p>\n      <p>This could be a wide range of information that could aid you with your task, such as:\n      <ul>\n        <li>Terminology lists;</li>\n        <li>Previous versions of similar documents;</li>\n        <li>Relevant reference material.</li>\n      </ul>\n      Click a file to download it.</p>\n      <p style=\"font-weight: bold; color: var( --alternative-color);\">Please note these files are strictly for your\n      reference and DO NOT NEED TO BE TRANSLATED.</p>\n    ";
        var dialogRef = this.dialog.open(HelpDialogComponent, {
            width: '700px',
            height: '400px',
            data: { helpTitle: "Documentation files", helpHtmlContent: helpText }
        });
    };
    return TaskDetailComponent;
}());
export { TaskDetailComponent };
var TranslationUploadDialog = /** @class */ (function () {
    function TranslationUploadDialog(dialogRef, data, fileService) {
        this.dialogRef = dialogRef;
        this.data = data;
        this.fileService = fileService;
        this.expectedFileTypes = ".xlf, .xlif, .xliff";
        this.uploading = false;
        this.taskFilePath = null;
        this.uploadError = null;
    }
    TranslationUploadDialog.prototype.ngOnInit = function () {
        if (this.data) {
            this.task = this.data.task;
            this.taskFile = this.data.taskFile;
            if (this.taskFile.processType == 'MANUAL')
                this.processType = this.taskFile.processType;
            if (this.data.expectedFileTypes)
                this.expectedFileTypes = this.data.expectedFileTypes;
        }
    };
    TranslationUploadDialog.prototype.upload = function (event) {
        var _this = this;
        var fileList = event.target.files;
        if (fileList.length > 0) {
            this.uploadError = null;
            this.selectedFile = fileList[0];
            if (this.processType == null || this.processType != 'MANUAL') {
                // If the process type is not MANUAL, we expect the translator to upload an XLIFF
                var currentExt = getFileExtension(this.selectedFile.name).toLowerCase();
                if (currentExt !== "xlf" && currentExt !== "xlif" && currentExt !== "xliff")
                    this.uploadError = "noxliff";
            }
            if (this.uploadError == null) {
                this.uploading = true;
                this.fileService.uploadTranslation(this.selectedFile, this.taskFile.name, this.task, this.processType)
                    .subscribe(function (data) {
                    console.log('Uploaded translation of ' + _this.taskFile.name + ' for task ' + _this.task.id, data);
                    _this.taskFilePath = data;
                }, function (error) {
                    console.error("Error uploading " + _this.selectedFile.name, error);
                    _this.uploadError = "other";
                    _this.uploading = false;
                }, function () { return _this.uploading = false; });
            }
        }
    };
    TranslationUploadDialog.prototype.confirmUpload = function () {
        this.dialogRef.close(this.taskFilePath);
    };
    return TranslationUploadDialog;
}());
export { TranslationUploadDialog };
var RejectDialog = /** @class */ (function () {
    function RejectDialog(dialogRef, dialog, data) {
        this.dialogRef = dialogRef;
        this.dialog = dialog;
        this.data = data;
        this.suggestedColleague = undefined;
        this.counterProposal = { date: undefined, text: undefined };
        this.possibleDeadlineIssues = false;
    }
    RejectDialog.prototype.ngOnInit = function () {
        if (this.data) {
            this.message = this.data.message;
            this.placeholder = this.data.placeholder;
            this.langpair = this.data.langpair;
            this.activity = this.data.activity;
        }
    };
    RejectDialog.prototype.closeDialog = function () {
        this.dialogRef.close("success");
    };
    RejectDialog.prototype.openColleagues = function () {
        var _this = this;
        var dialogRef = this.dialog.open(VendorSelectorDialog, {
            width: '80%',
            height: '80%',
            data: { langpair: this.langpair, activity: this.activity, showPricing: false }
        });
        dialogRef.afterClosed().subscribe(function (result) {
            if (result instanceof Vendor) {
                _this.selectColleague(result);
            }
        });
    };
    RejectDialog.prototype.selectColleague = function (colleague) {
        this.suggestedColleague = colleague;
    };
    RejectDialog.prototype.checkDeadline = function (event) {
        var checkString = this.message.toLowerCase();
        //TODO improve this matching or find a smarter way to handle this.
        this.possibleDeadlineIssues = checkString.includes("duedate")
            || checkString.includes("due date")
            || checkString.includes("deadline")
            || checkString.includes("no time");
    };
    RejectDialog.prototype.submitProposal = function () {
        this.dialogRef.close("counterproposal");
    };
    return RejectDialog;
}());
export { RejectDialog };
var AcceptDialog = /** @class */ (function () {
    function AcceptDialog(dialogRef, data) {
        this.dialogRef = dialogRef;
        this.data = data;
        this.minDueDate = new Date();
        this.tooLate = false;
        this.invalidDate = false;
        this.isSworn = false;
        this.proposal = { date: undefined, text: undefined, extraCost: undefined };
        this.revisionNeeded = false;
    }
    AcceptDialog.prototype.ngOnInit = function () {
        if (this.data) {
            this.message = this.data.message;
            this.date = this.data.date;
            this.dueDate = this.data.dueDate;
            this.revisionNeeded = this.data.revisionNeeded;
            this.tooLate = this.date > this.dueDate;
            this.invalidDate = this.date < new Date();
            this.isSworn = ENABLE_QUOTE && this.data.swornTranslation;
        }
    };
    AcceptDialog.prototype.setDate = function (dd) {
        if (dd) {
            this.date = dd;
            this.tooLate = this.date > this.dueDate;
            this.setInvalidDate(false);
        }
    };
    AcceptDialog.prototype.closeDialog = function () {
        this.dialogRef.close("accept");
    };
    AcceptDialog.prototype.counterPropose = function () {
        this.proposal.date = this.date;
        this.dialogRef.close("counterproposal");
    };
    AcceptDialog.prototype.setInvalidDate = function (invalid) {
        this.invalidDate = invalid;
    };
    return AcceptDialog;
}());
export { AcceptDialog };
