import { sampleTime } from 'rxjs/operators';
import { OnChanges, OnDestroy } from "@angular/core";
import { Vendor } from "../../dto/vendor";
import { VendorService } from "../../service/vendor.service";
import { Router } from "@angular/router";
import { UserService } from "../../service/user.service";
import { PricingService } from "../../service/pricing.services";
import { StatisticsService } from "../../service/statistics.service";
import { SubscriptionHandler } from '../../util/subscription.handler';
var VendorProfileComponent = /** @class */ (function () {
    function VendorProfileComponent(vendorService, userService, pricingService, statisticsService, router) {
        this.vendorService = vendorService;
        this.userService = userService;
        this.pricingService = pricingService;
        this.statisticsService = statisticsService;
        this.router = router;
        this.basePrice = 0;
        this.showPricing = false;
        this.profilePicExists = false;
        this.descriptionText = "";
        this.maxDescriptionSize = undefined;
        this.maxDomainCount = undefined;
        this.price = 0;
        this.totalPrice = undefined;
        this.subscriptionHandler = new SubscriptionHandler(this);
    }
    VendorProfileComponent.prototype.ngOnInit = function () {
        var _this = this;
        if (!this.profile) {
            this.vendorService.getVendor(this.vendorId).subscribe(function (v) { return _this.initProfile(v); });
        }
        else {
            this.initProfile(this.profile);
        }
    };
    VendorProfileComponent.prototype.ngOnDestroy = function () {
    };
    VendorProfileComponent.prototype.ngOnChanges = function () {
        this.calcTotalPrice();
    };
    VendorProfileComponent.prototype.initProfile = function (v) {
        this.profile = v;
        this.vendorId = v.id;
        this.loadProfilePic();
        this.loadProfileDescription();
        if (this.taskSpec) {
            this.statObserver = this.createObserver(this.taskSpec.projectId, this.taskSpec.sourcelanguage + "_" + this.taskSpec.targetlanguage);
        }
    };
    VendorProfileComponent.prototype.isInternal = function () {
        if (this.profile != null && this.userService.isLoggedIn())
            return this.profile.internal &&
                this.profile.companyId != null &&
                this.profile.companyId === this.userService.getCurrentUser().companyId;
        else
            return false;
    };
    VendorProfileComponent.prototype.createObserver = function (pId, langPair) {
        var _this = this;
        // For every update of the project price that comes in we'll need to recalculate it for this vendor
        // To make this code less overkill we build in a sampleTime of a second
        var pricingSubscription = this.pricingService.enrollForProject(pId).pipe(sampleTime(1000))
            .subscribe(function (c) { return _this.calcTotalPrice(); });
        this.subscriptionHandler.push(pricingSubscription);
    };
    VendorProfileComponent.prototype.openProfileInfo = function (vendor) {
        this.router.navigate(['/profileinfo', vendor.id]);
    };
    VendorProfileComponent.prototype.loadProfilePic = function () {
        var _this = this;
        this.userService.getProfilePic(this.vendorId)
            .subscribe(function (next) {
            _this.profilePic = next;
            _this.profilePicExists = true;
        }, function (error) { return _this.profilePicExists = false; });
    };
    VendorProfileComponent.prototype.loadProfileDescription = function () {
        if (this.profile && this.profile.description) {
            // if (this.profile.description.length > 120) {
            //   this.descriptionText = this.profile.description.substr(0, 117) + "..."
            // } else {
            this.descriptionText = this.profile.description;
            // }
        }
        else {
            this.descriptionText = "";
        }
    };
    VendorProfileComponent.prototype.offersMtRebate = function () {
        if (this.profile && this.profile.percentageSet && this.profile.percentageSet.mt_match != null)
            return this.profile.percentageSet.mt_match > 0 && this.profile.percentageSet.mt_match < 100;
        return false;
    };
    VendorProfileComponent.prototype.calcTotalPrice = function () {
        var _this = this;
        if (this.taskSpec && this.taskSpec.projectId) {
            this.pricingService.getPriceForTaskSpecAndVendor(this.taskSpec, this.profile.id, this.basePrice)
                .subscribe(function (p) {
                _this.totalPrice = p;
            });
        }
    };
    return VendorProfileComponent;
}());
export { VendorProfileComponent };
