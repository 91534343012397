import * as tslib_1 from "tslib";
import { Observable, } from 'rxjs';
import { debounceTime, filter, map, share, tap } from 'rxjs/operators';
import { AfterViewInit, OnDestroy, OnInit } from "@angular/core";
import { ENABLE_QUOTE, Project } from "../dto/project";
import { ActivatedRoute, Router } from "@angular/router";
import { Location } from "@angular/common";
import { ProjectService } from "../service/project.service";
import "rxjs/Rx";
import "rxjs/add/operator/map";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { SimpleDialog } from "../simple-dialog.component";
import { UserService } from "../service/user.service";
import { PricingService } from "../service/pricing.services";
import { LanguageService } from "../service/language.service";
import { StatisticsService } from "../service/statistics.service";
import { ConfirmDialog } from "../confirm.dialog";
import { LogInService } from "../service/login.service";
import { FileService } from "../service/file.service";
import { FileMetaDataDialog } from "./util/file-meta-data.dialog";
import { environment } from "../../environments/environment";
import { MtService } from "../service/mt.service";
import { ChatService } from "../service/chat.service";
import { TaskService } from "../service/task.service";
import { CookieService } from "ngx-cookie-service";
import { LangpairSelectComponent } from "./langselectcomponent/langpair-select.component";
import { VendorService } from "../service/vendor.service";
import { ProjectPaymentComponent } from "./paymentcomponents/project-payment.component";
import { getGeneralDocFiles, getSourceFiles, toLangPairString } from "../util/jobutil";
import { EditingParameter, initServiceParamForService, SERVICE_TRANSLATION, SERVICES, TranslationParameter } from '../mock-activities';
import { existsAndNotEmpty } from "../util/apputil";
import { GTACInputComponent } from '../util/gtac-input.component';
import { SubscriptionHandler } from '../util/subscription.handler';
import { HelpDialogComponent } from '../util/help.dialog.component';
import { HttpClient } from '@angular/common/http';
/* tslint:disable */
/**
 * Created by jefv on 27/07/2017.
 */
var ProjectCreateComponent = /** @class */ (function () {
    function ProjectCreateComponent(projectService, userService, pricingService, languageService, statisticService, fileservice, mtservice, taskService, vendorService, route, location, router, dialog, snackbar, cookieService, loginService, chatService, http) {
        var _this = this;
        this.projectService = projectService;
        this.userService = userService;
        this.pricingService = pricingService;
        this.languageService = languageService;
        this.statisticService = statisticService;
        this.fileservice = fileservice;
        this.mtservice = mtservice;
        this.taskService = taskService;
        this.vendorService = vendorService;
        this.route = route;
        this.location = location;
        this.router = router;
        this.dialog = dialog;
        this.snackbar = snackbar;
        this.cookieService = cookieService;
        this.loginService = loginService;
        this.chatService = chatService;
        this.http = http;
        this.isDebug = environment.isDebug;
        this.section = undefined;
        this.noForceRequester = undefined;
        this.services = SERVICES;
        this.sourceLang = undefined;
        this.sourceFiles = [];
        this.docFiles = [];
        this.proposedDueDate = undefined;
        this.saveSharesAsDefault = false;
        this.defaultShares = [];
        this.calculatedTotalPrice = undefined;
        this.xliffFailSubscription = undefined;
        this.xliffBetaSubscription = undefined;
        this.taskCountSubscription = undefined;
        this.statsSubscription = undefined;
        this.isDraftProject = false;
        this.hasManualFiles = false;
        this.tmStatsComplete = false;
        this.deepLEnabled = false;
        this.mtValid = false;
        this.languageSectionEnabled = false;
        this.translatorSectionEnabled = false;
        this.projectInfoSectionEnabled = false;
        this.custDetailsSectionEnabled = false;
        this.paymentSectionEnabled = false;
        this.orderSectionEnabled = false;
        this.gtacVersion = UserService.gtacversion;
        this.subscriptionHandler = new SubscriptionHandler(this);
        this.orderSubmitting = false;
        this.altFileRequestDialogOpenMap = {};
        // tslint:disable-next-line: member-ordering
        this.selectingService = false;
        this.loadedTranslators = {};
        this.loadedRevisers = {};
        this.openLanguageBar = undefined;
        this.fileObservable = new Observable(function (observer) { return _this.fileObserver = observer; }).pipe(share());
        this.fileObservable.subscribe();
        this.langObservable = new Observable(function (observer) { return _this.langObserver = observer; }).pipe(share());
        this.langObservable.subscribe();
    }
    ProjectCreateComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.checkDeepLEnabled();
        this.setProject(this.project);
        this.setDefaultShares();
        //Listen on login events so that when the user logs in, we can set the requested by
        this.userService.loginEmitter.subscribe(function (v) {
            if (v === true) {
                _this.setDefaultShares();
                _this.checkAndUpdateRequestedBy();
            }
        });
        // Listen to changes in the selected languages, to re-check whether MT is a valid option or no
        this.langObservable.subscribe(function (v) {
            _this.checkMtValid();
        });
    };
    ProjectCreateComponent.prototype.ngOnDestroy = function () {
    };
    ProjectCreateComponent.prototype.ngAfterViewInit = function () {
        this.scrollToSection(this.section);
        this.section = undefined;
    };
    ProjectCreateComponent.prototype.checkAndUpdateRequestedBy = function () {
        var _this = this;
        if (!this.isNoForceRequester() && this.project && this.project.requestedby === undefined && this.userService.isLoggedIn()) {
            this.projectService.setRequestedBy(this.project, this.userService.getCurrentUser()).subscribe(function (v) {
                //If the update was successful, update the current instance of the project with the current user, so that it is "up to date"
                if (v === "success") {
                    _this.project.requestedby = _this.userService.getCurrentUser();
                }
            });
        }
    };
    ProjectCreateComponent.prototype.isNoForceRequester = function () {
        // Don't force the logged in user as requester for this project (if the flag is true) simply for opening the project
        // (Note that when place order is pressed, the current user is still set as requester though)
        return this.noForceRequester != undefined && this.noForceRequester === "y";
    };
    ProjectCreateComponent.prototype.setDefaultShares = function () {
        var _this = this;
        if (this.userService.isLoggedIn()) {
            this.userService.findDefaultShares().subscribe(function (v) { return _this.defaultShares = v; });
        }
        else
            this.defaultShares = [];
    };
    ProjectCreateComponent.prototype.setProject = function (project) {
        var _this = this;
        this.project = project;
        this.project.isQuote = ENABLE_QUOTE && project.service === "Sworn translation";
        this.isDraftProject = project.startdate === undefined;
        //Make sure the project's duedate is a date instead of a string
        if (typeof this.project.duedate == "string") {
            this.project.duedate = new Date(this.project.duedate);
        }
        //If there is no value yet for the project's service initialize it to Translation
        if (this.project.service === undefined) {
            this.project.service = SERVICE_TRANSLATION;
        }
        this.initFiles();
        this.serviceParameter = initServiceParamForService(this.project.service, undefined, undefined);
        var sourceLanguageObs = this.projectService.getSourceLanguageObs(project)
            .pipe(filter(function (l) { return l != null; }), tap(function (l) { return _this.sourceLang = l; }), tap(function (l) {
            if (_this.serviceParameter['sourceLanguage'] != null) {
                _this.serviceParameter['sourceLanguage'][0] = l;
            }
        }));
        var targetLanguagesObs = this.projectService.getTargetLanguagesObs(project)
            .pipe(tap(function (l) {
            _this.targetLanguages = l;
        }), tap(function (l) {
            if (_this.serviceParameter['targetLanguages'] != null) {
                _this.serviceParameter['targetLanguages'] = l;
            }
        }), tap(function () {
            if (_this.serviceParameter.isValid()) {
                _this.serviceParameter.toParamList().forEach(function (serviceParam) { return _this.loadTranslators(serviceParam); });
            }
        }));
        sourceLanguageObs.merge(targetLanguagesObs)
            .subscribe(function () {
        }, function (error) { return console.error("Error getting languages", error); }, function () {
            _this.sortServiceParams();
            _this.checkTranslatorSelectionEnable();
        });
        // Check if there is already a subscription so we don't reload the project unnecessary
        // The projectSocketObservable will listen to the project socket and will reload the project if anything comes in.
        // TODO only reload on specific actions, filter implementation needed
        if (!this.projectSocketObservable) {
            this.projectSocketObservable = this.projectService.enrollForProject(this.project.id).pipe(filter(function (m) {
                var obj = undefined;
                if (m.action === 'SOURCE_UPDATE' && m.text !== undefined) {
                    obj = JSON.parse(m.text);
                }
                return m.action !== 'SOURCE_UPDATE' || (obj !== undefined && obj.key === 'sourcelang' && _this.sourceLang === undefined);
            }))
                .subscribe(function (m) {
                _this.reloadProject(m.action);
            });
            this.subscriptionHandler.push(this.projectSocketObservable);
        }
        this.checkTMStats();
        this.checkMtValid();
        // this.todo = this.analysesService.getAnalysesForProject(this.project.id);
        //Also when a logged in user visits the project and it is not yet requested by anyone, fill in the requested by
        this.checkAndUpdateRequestedBy();
        if (this.userService.isLoggedIn())
            this.handleSetUser();
        else {
            this.userService.loginEmitter.subscribe(function (v) {
                if (v === true)
                    _this.handleSetUser();
            });
        }
        this.initFiles();
        this.updateTaskCount();
        this.updateProposedDueDate("");
        this.enrollToFeeds();
    };
    ProjectCreateComponent.prototype.handleSetUser = function () {
        //Check if the payment section can become enabled
        this.paymentSectionEnabled = (this.custDetailsSectionEnabled && this.userService.isLoggedIn());
        this.orderSectionEnabled = this.checkOrderSectionEnable();
        console.debug(this.gtac);
    };
    ProjectCreateComponent.prototype.enrollToFeeds = function () {
        var _this = this;
        //Enroll for file feed and check if there is a convertion issue, if so,
        // show a popup where the user needs to fill in a wordcount and a warning that TM isn't an option for this project.
        if (this.xliffFailSubscription === undefined) {
            this.xliffFailSubscription = this.fileservice.enrollForProject(this.project.id).pipe(filter(function (data) { return data.action == "JOB_XLIFF_FAIL"; }))
                .subscribe(function (data) {
                _this.showConversionFailDialog(data.filename);
            }); //Enroll for file feed and check if there is a convertion issue, if so,
            this.subscriptionHandler.push(this.xliffFailSubscription);
        }
        // show a popup where the user needs to fill in a wordcount and a warning that TM isn't an option for this project.
        if (this.xliffBetaSubscription === undefined) {
            this.xliffBetaSubscription = this.fileservice.enrollForProject(this.project.id).pipe(filter(function (data) { return data.action == "JOB_XLIFF_BETA"; }))
                .subscribe(function (data) {
                if (data.filename.endsWith(".pdf")) {
                    //PDF dialog is triggered on other events (load of project e.g.)
                }
                else {
                    _this.showConversionFailDialog(data.filename);
                }
            });
            this.subscriptionHandler.push(this.xliffBetaSubscription);
        }
        if (this.taskCountSubscription === undefined) {
            this.taskCountSubscription = this.taskService.enrollForProject(this.project.id).subscribe(function (a) { return _this.updateTaskCount(); });
            this.subscriptionHandler.push(this.taskCountSubscription);
        }
        if (this.statsSubscription === undefined) {
            this.statsSubscription = this.statisticService.enrollForProject(this.project.id).pipe(debounceTime(500), tap(function (d) { return _this.updateProposedDueDate(d); })).subscribe();
            this.subscriptionHandler.push(this.statsSubscription);
        }
    };
    /**
     * This method is called to check if all the statistics from TM are in.
     * The method is triggered when setting the project and when the pricingComponents emits pricing changes.
     */
    ProjectCreateComponent.prototype.checkTMStats = function () {
        var _this = this;
        this.statisticService.hasAllTMStats(this.project.id).subscribe(function (v) {
            _this.tmStatsComplete = v;
        });
    };
    ProjectCreateComponent.prototype.initFiles = function () {
        var _this = this;
        if (this.project.files != undefined) {
            this.sourceFiles = getSourceFiles(this.project)
                .map(function (f) {
                if (f.sourcelang != undefined && _this.sourceLang == undefined)
                    _this.sourceLang = f.sourcelang;
                return f;
            });
            this.docFiles = getGeneralDocFiles(this.project);
            this.hasManualFiles = this.sourceFiles.filter(function (v) { return v.processType != null && v.processType === "MANUAL"; }).length > 0;
            if (this.sourceFiles === undefined) {
                this.sourceFiles = [];
            }
            //Enable the languageSection
            this.languageSectionEnabled = this.sourceFiles != undefined &&
                this.sourceFiles.length > 0 &&
                this.manualFilesComplete();
            if (!existsAndNotEmpty(this.project.title)) {
                this.project.title = this.getTitleFromFiles();
            }
            this.sourceFiles
                .map(function (f) { return f.name; })
                .filter(function (f) { return f.endsWith('.pdf'); })
                .forEach(function (f) { return _this.showPDFDialog(f); });
            var exportRequestFormats_1 = ['.pages', '.key', '.numbers'];
            this.sourceFiles
                .map(function (f) { return f.name; })
                .filter(function (f) {
                return exportRequestFormats_1.indexOf(f.substring(f.lastIndexOf('.'))) > -1;
            })
                .forEach(function (f) { return _this.showExportRequestDialog(f); });
        }
    };
    ProjectCreateComponent.prototype.manualFilesComplete = function () {
        var res = this.sourceFiles
            .filter(function (v) { return v.processType === "MANUAL"; })
            .filter(function (v) { return v.wordcount === undefined; })
            .length === 0;
        return res;
    };
    ProjectCreateComponent.prototype.updateTaskCount = function () {
        var _this = this;
        this.taskService.getTaskCountPerLangPair(this.project.id)
            .map(function (m) {
            return Object.values(m).filter(function (e) { return e > 0; });
        })
            .map(function (lps) {
            return lps.length;
        })
            .subscribe(function (lpCount) {
            if (_this.project.langpairs !== undefined && lpCount === _this.project.langpairs.length) {
                _this.projectInfoSectionEnabled = true;
                _this.custDetailsSectionEnabled = true;
                _this.paymentSectionEnabled = _this.userService.isLoggedIn();
                _this.orderSectionEnabled = _this.checkOrderSectionEnable();
            }
            else {
                _this.orderSectionEnabled = false;
            }
        });
    };
    ProjectCreateComponent.prototype.reloadProject = function (action) {
        var _this = this;
        console.debug("Project " + this.project.id + ": reloading because of " + action);
        this.projectService.getProject(this.project.id + "").subscribe(function (project) {
            _this.setProject(project);
        });
    };
    //SetLangpairs is done when the languagepair selection's next button is clicked
    ProjectCreateComponent.prototype.setServiceParams = function (params) {
        var _this = this;
        var origLanpair = [];
        if (this.project.langpairs != undefined)
            origLanpair = Array.from(this.project.langpairs).map(function (l) { return _this.toServiceMap(l); });
        //Search for all the languagepairs which are not in the new selection anymore
        var removalObservable = Observable.empty();
        if (this.project.langpairs != undefined) {
            removalObservable = Observable.from(origLanpair).pipe(map(function (lp) { return _this.toServiceMap(lp); }), filter(function (oldLp) { return !params.contains(oldLp); }), map(function (lp) { return lp.toDataString(); }), map(function (lp) { return _this.projectService.removeLangPair(_this.project.id, lp); })).mergeAll();
        }
        //Search for all the language pairs which are not yet set on the project
        var toAddObservable = Observable.from(params.toParamList()).pipe(filter(function (lp) { return origLanpair === undefined || origLanpair.find(function (alp) { return alp.toDataString() === lp.toDataString(); }) === undefined; }), map(function (lp) { return _this.projectService.addLangPairData(lp, _this.project.id); })).mergeAll();
        removalObservable.merge(toAddObservable)
            .subscribe(function (a) { return console.debug("Project " + _this.project.id + ": language pairs updated", a); });
        this.project.langpairs = params.toParamList();
        this.project.isQuote = ENABLE_QUOTE && this.project.service == "Sworn translation";
        this.sortServiceParams();
        this.checkTranslatorSelectionEnable();
        this.updateTaskCount();
        this.checkMtValid();
        this.openNextLangPair(undefined);
    };
    ProjectCreateComponent.prototype.checkTranslatorSelectionEnable = function () {
        var hasLanpairs = this.project.langpairs != undefined && this.project.langpairs.length > 0;
        var hasChanges = false;
        if (this.langpairSelectComponent != undefined) {
            hasChanges = this.langpairSelectComponent.hasChanges;
        }
        this.translatorSectionEnabled = hasLanpairs && !hasChanges;
    };
    ProjectCreateComponent.prototype.checkOrderSectionEnable = function () {
        var checkOrderSectionEnable = (this.custDetailsSectionEnabled && this.languageSectionEnabled && this.userService.isLoggedIn()
            && (this.gtac == null || this.gtac.isAccepted)
            && (this.project.isQuote || (this.calculatedTotalPrice != null && this.calculatedTotalPrice > 0)));
        return checkOrderSectionEnable;
    };
    ProjectCreateComponent.prototype.onFileUploaded = function (file) {
        var _this = this;
        console.debug("OnFileUpload");
        if (file != null) {
            this.projectService.addSourceFileData(file.name, this.project.id + "").subscribe(function (n) {
                return _this.reloadProject("SOURCE FILE ADDED");
            } //TODO find and alternative way instead of a complete refresh as it is a bit too heavy
            );
        }
    };
    ProjectCreateComponent.prototype.onDocFileUploaded = function (docInfo) {
        var _this = this;
        if (docInfo != null && docInfo.docfile != null) {
            this.projectService.addDocFileData(docInfo.docfile.name, docInfo.source4pdf, this.project.id).subscribe(function (data) {
                _this.docFiles.push(data);
            }, function (error) {
                _this.snackbar.open("Error adding documentation file " + docInfo.docfile.name + ": " +
                    error.status + " " + error.statusText, "", {
                    duration: 2000
                });
            });
        }
    };
    /*
    removeDocFile(docFile: any) {
      if (docFile != null) {
        this.removingDocFile = docFile;
        this.projectService.removeDocFile(docFile.name, this.project.id + "")
          .subscribe(
            v => {
              console.log("Project " + this.project.id + ": removed documentation file '" + docFile.name + "'");
              this.snackbar.open("Removed documentation file " + docFile.name + ". ", "", {
                duration: 2000
              });
            },
            error => {
              this.removingDocFile = null;
              console.error("Error removing documentation file", error);
              this.snackbar.open("Unable to remove documentation file " + docFile.name + ": " +
                error.status + " " + error.statusText, "", {
                duration: 2000
              });
            },
            // Remove the documentation file from the collection
            () => {
              this.removingDocFile = null;
              let removed = this.docFiles.filter(v => v.name.indexOf(docFile.name) >= 0);
              this.docFiles.splice(this.docFiles.indexOf(removed), 1);
            }
          )
      }
    }
  
    isRemovingDocFile(file: any) {
      // Used to display loading icon during documentation file removal
      return this.removingDocFile && this.removingDocFile == file;
    }*/
    ProjectCreateComponent.prototype.onFileRemove = function (file) {
        var _this = this;
        if (file != null) {
            this.projectService.removeSourceFile(file.name, this.project.id + "")
                .subscribe(function (v) {
                console.log("Project " + _this.project.id + ": removed source file '" + file.name + "'");
            }, function (error) {
                console.error("Error removing source file", error);
                _this.snackbar.open("Unable to remove file " + file.name + ". " +
                    "Make sure there are no accepted tasks for this file.", "", {
                    duration: 2000
                });
                _this.reloadProject("SOURCE_FILE_REMOVED");
            }, 
            // Reload the screen so that all files & tasks are up to date.
            function () { return _this.reloadProject("SOURCE_FILE_REMOVED"); });
        }
    };
    ProjectCreateComponent.prototype.onFileMetaUpdated = function (fileData) {
        if (fileData.hasOwnProperty("filename")) {
            // Update the meta data on the relevant files
            getSourceFiles(this.project)
                .filter(function (f) { return fileData.filename == f.name; })
                .forEach(function (f) {
                if (fileData.hasOwnProperty("wordcount")) {
                    f.wordcount = fileData.wordcount;
                }
            });
            // Re-init files so anything dependent on wordcount being present (such as whether or not to enable the
            // language selection) gets re-triggered
            this.initFiles();
        }
    };
    ProjectCreateComponent.prototype.placeOrder = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var userId, error;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.orderSubmitting = true;
                        if (!!this.userService.isLoggedIn()) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.openSignInPopupAsync()];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2:
                        if (!this.userService.isLoggedIn()) {
                            this.orderSubmitting = false;
                            return [2 /*return*/];
                        }
                        userId = this.userService.getCurrentUser().id;
                        return [4 /*yield*/, this.paymentComponent.storePaymentDetails()
                                .then(function () {
                                // If the project still has no duedate, use the proposed duedate that is calculated
                                if (_this.project.duedate === undefined) {
                                    _this.project.duedate = _this.proposedDueDate;
                                }
                                // Calculate the pricing again and when that is done, start the project
                                // Note that if we manually added the pricingElement to the project, it will not be overwritten
                                _this.pricingService.getTotalProjectPrice(_this.project.id)
                                    .switchMap(function (pricing) {
                                    console.debug("Project " + _this.project.id + ": STARTING", pricing);
                                    return _this.projectService.startProject(_this.project, userId, pricing, _this.saveSharesAsDefault, _this.userService.getRemoteUserIp());
                                })
                                    .subscribe(function (v) {
                                    return _this.router.navigate(['/pdashboard'], { queryParams: { 'createdId': _this.project.id } });
                                }); // redirect to project dashboard
                                _this.cookieService.set('targets', JSON.stringify(_this.project.langpairs.map(function (v) { return v.target; })));
                            })
                                .catch(function (err) {
                                console.error("Error storing payment details", err);
                                error = err;
                            })];
                    case 3:
                        _a.sent();
                        // If there is an error for storing the payment, it will be displayed on the Stripe component and we need to stop the order placing
                        if (error) {
                            console.error("Error starting project " + this.project.id, error);
                            this.orderSubmitting = false;
                            return [2 /*return*/];
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    ProjectCreateComponent.prototype.cancelProject = function () {
        var _this = this;
        var dialogRef = this.dialog.open(ConfirmDialog, {
            width: '450px',
            panelClass: 'confirmDialog',
            data: {
                confirmTitle: 'Are you sure?',
                confirmText: 'Yes, cancel project',
                cancelText: 'No, take me back',
                invertStyle: true
            }
        });
        dialogRef.afterClosed().pipe(filter(function (t) { return t === "confirm"; }))
            .subscribe(function (p) {
            _this.projectService.cancelProject(_this.project).subscribe(function (v) {
                var successDialog = _this.dialog.open(SimpleDialog, {
                    width: '400px',
                    panelClass: 'simpleDialog',
                    data: { dialogMessage: 'Your project has been canceled.' }
                });
                successDialog.afterOpen().subscribe(function (v) {
                    return _this.router.navigateByUrl("");
                });
            });
        });
    };
    ProjectCreateComponent.prototype.getTitleFromFiles = function () {
        if (this.sourceFiles && this.sourceFiles.length > 0) {
            var file = this.sourceFiles[0];
            var i = file.name.lastIndexOf(".");
            if (i > 0)
                return file.name.substring(0, i);
            else
                return file.name;
        }
        return null;
    };
    ProjectCreateComponent.prototype.openSignInPopupAsync = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.loginService.openSignInPopup("pdetail/" + this.project.id)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    ProjectCreateComponent.prototype.openJoin = function () {
        this.loginService.openJoinDialog("pdetail/" + this.project.id);
    };
    ProjectCreateComponent.prototype.openSignIn = function () {
        this.loginService.openSignInPopup("pdetail/" + this.project.id);
    };
    ProjectCreateComponent.prototype.showPDFDialog = function (filename) {
        var _this = this;
        var dialogData = {
            confirmTitle: 'PDF detected!',
            confirmHtml: 'We detected that <i>' + filename + '</i> is a PDF file. <br/><br/>' +
                'We <b>can</b> process these, but unfortunately cannot guarantee that the layout of the translated file will correctly reflect the original. <br/>' +
                'If you have the file in its original format (docx, html, ...) we <b>strongly advise</b> to use that instead.' +
                'If not, and if you are okay with potentially needing to re-adjust the layout afterward, you can proceed with the PDF.',
            confirmText: 'Continue with PDF',
            cancelText: 'Remove PDF & upload original',
            invertStyle: true
        };
        setTimeout(function () {
            var obs = _this.showAlternativeFileRequestDialog(filename, dialogData);
            if (obs != null)
                obs.subscribe(function (a) { return console.debug(a); });
        }, 2000);
    };
    ProjectCreateComponent.prototype.showExportRequestDialog = function (filename) {
        var _this = this;
        var dialogData = {
            confirmTitle: 'Unsupported file detected!',
            confirmHtml: '<i>' + filename + '</i> has a format we do not fully support.<br/><br/>' +
                'We <b>can</b> process these, but unfortunately cannot apply analysis, translation memory and machine translation on it. <br/>' +
                'You\'ll need to fill in the wordcount and source language manually. <br/>' +
                'If you have the file, or can export to, another format (docx, html, ...) we <b>strongly advise</b> to use that instead.' +
                'If not, and if you are okay with potentially needing to re-adjust the layout afterward, you can proceed with this file.',
            confirmText: 'Continue',
            cancelText: 'Remove file & upload alternative format',
            invertStyle: true
        };
        setTimeout(function () {
            var obs = _this.showAlternativeFileRequestDialog(filename, dialogData);
            if (obs != null)
                obs.subscribe(function (a) {
                    if (a === 'confirm') {
                        _this.showConversionFailDialog(filename);
                    }
                });
        }, 2000);
    };
    ProjectCreateComponent.prototype.showAlternativeFileRequestDialog = function (filename, dialogData) {
        var _this = this;
        //Check if the dialog is already open for this file
        if (this.altFileRequestDialogOpenMap[filename] != null) {
            return;
        }
        else {
            this.altFileRequestDialogOpenMap[filename] = "OPEN";
        }
        //Check if the source file isn't already removed or marked as MANUAL
        var file = getSourceFiles(this.project).filter(function (f) { return f.name === filename; })[0];
        console.debug(file, file.processType, file.altfileasked);
        if (file == null || file.altfileasked != null)
            return;
        //show the dialog
        this.projectService.setAltFileTypeAsked(this.project.id, filename).subscribe();
        var dialogRef = this.dialog.open(ConfirmDialog, {
            width: '550px',
            panelClass: 'confirmDialog',
            data: dialogData
        });
        return dialogRef.afterClosed().pipe(tap(function (a) {
            console.debug("dialogclose", a);
            if (a === 'confirm') {
                file.processType = 'MANUAL';
                _this.projectService.setFileProcessType(_this.project.id, filename, 'MANUAL').subscribe();
            }
            else {
                _this.projectService.removeSourceFile(filename, _this.project.id).subscribe(function (v) {
                    return _this.reloadProject("source file remove");
                });
            }
            _this.altFileRequestDialogOpenMap[filename] = undefined;
        }));
    };
    ProjectCreateComponent.prototype.showConversionFailDialog = function (filename) {
        var _this = this;
        var projectFile;
        this.sourceFiles.filter(function (f) { return f.name === filename; }).slice(0, 1).forEach(function (f) {
            projectFile = f;
        });
        var dialogRef = this.dialog.open(FileMetaDataDialog, {
            panelClass: 'simpleDialog',
            width: "550px",
            data: { projectId: this.project.id, file: projectFile }
        });
        dialogRef.afterClosed().subscribe(function (a) {
            if (a == "success" && _this.project.files) {
                _this.sourceFiles.filter(function (f) { return f.name === filename; })
                    .forEach(function (f) {
                    f.wordcount = dialogRef.componentInstance.words;
                });
            }
        });
    };
    ProjectCreateComponent.prototype.setDeepLEnabled = function (enabled) {
        this.deepLEnabled = enabled;
        this.checkMtValid();
    };
    ProjectCreateComponent.prototype.checkMtValid = function () {
        var _this = this;
        // Check whether MT is enabled
        // TODO: depends on what MT impl is chosen (once we have multiple)
        if (this.deepLEnabled && this.sourceLang && this.targetLanguages && this.targetLanguages.length > 0) {
            // If MT is enabled, check whether the source language is valid
            this.mtservice.isSupportedLang4DeepL(this.sourceLang).subscribe(function (b) {
                _this.mtValid = b;
                if (b) {
                    // Set MT valid to false first
                    _this.mtValid = false;
                    _this.targetLanguages.forEach(function (l) {
                        // Check for each target language whether it is valid: if at least one is, then MT can be chosen
                        _this.mtservice.isSupportedLang4DeepL(l).subscribe(function (b) {
                            if (b)
                                _this.mtValid = b;
                        });
                    });
                }
            });
        }
        else
            this.mtValid = false;
    };
    ProjectCreateComponent.prototype.updateProposedDueDate = function (event) {
        var _this = this;
        if (this.project.startdate == null) {
            this.projectService.calculateProposedDueDate(this.project.id)
                .pipe(map(function (d) { return new Date(Date.now() + d * 60 * 60 * 1000); }), tap(function (d) { return _this.proposedDueDate = d; }))
                .subscribe(function (d) {
            }, function (error) {
                _this.proposedDueDate = undefined;
            });
        }
    };
    ProjectCreateComponent.prototype.updatePaymentMethod = function (value) {
        this.projectService.setPaymentMethod(this.project, value).subscribe();
    };
    ProjectCreateComponent.prototype.checkDeepLEnabled = function () {
        var _this = this;
        this.mtservice.isDeepLEnabled().subscribe(function (b) {
            _this.setDeepLEnabled(b);
        });
    };
    ProjectCreateComponent.prototype.onServiceSelectionClick = function () {
        this.selectingService = !this.selectingService;
    };
    ProjectCreateComponent.prototype.onServiceSelectionChange = function (service) {
        var _this = this;
        this.project.service = service;
        this.projectService.setService(this.project).subscribe();
        this.serviceParameter = initServiceParamForService(service, this.sourceLang, this.targetLanguages);
        this.serviceParameter.toParamList().forEach(function (p) { return _this.loadTranslators(p); });
    };
    ProjectCreateComponent.prototype.openServicesHelp = function (event) {
        var _this = this;
        event.stopPropagation();
        this.http.get('assets/help/services-help.html', { responseType: 'text' })
            .subscribe(function (helptext) {
            _this.dialog.open(HelpDialogComponent, {
                width: '700px',
                maxHeight: '65%',
                data: { helpTitle: "Services", helpHtmlContent: helptext }
            });
        });
    };
    ProjectCreateComponent.prototype.onLanguageSelectionChange = function (event) {
        var _this = this;
        this.checkTranslatorSelectionEnable();
        // if (event.type === "add" && event.target === true)
        //   this.loadTranslators(event.language);
        if (event.type === "add" && event.source === true)
            this.sourceLang = event.language;
        if (event.type === 'add') {
            this.serviceParameter.toParamList()
                .filter(function (p) {
                return (event.source === true && p['source'] === event.language) || (event.target === true && p['target'] === event.language);
            })
                .forEach(function (p) { return _this.loadTranslators(p); });
        }
    };
    ProjectCreateComponent.prototype.loadTranslators = function (serviceParam) {
        var _this = this;
        if (this.sourceLang != undefined) {
            var searchString_1 = serviceParam.toDataString();
            if (this.loadedTranslators[searchString_1] == undefined) {
                this.vendorService.getBestProjectCompetences(this.project.id, searchString_1, this.serviceParameter.getService(), this.project.service)
                    .subscribe(function (translators) {
                    if (translators.length > 0) {
                        translators[0]["isSelected"] = true;
                        translators[0]["isRecommended"] = true;
                    }
                    _this.loadedTranslators[searchString_1] = translators;
                });
            }
            if (this.project.service === 'Translation') {
                if (this.loadedRevisers[searchString_1] == undefined) {
                    this.vendorService.getBestProjectCompetences(this.project.id, searchString_1, "Revision", this.project.service)
                        .subscribe(function (translators) {
                        if (translators.length > 0) {
                            translators[0]["isRecommended"] = true;
                        }
                        _this.loadedRevisers[searchString_1] = translators;
                    });
                }
            }
        }
    };
    ProjectCreateComponent.prototype.getSearchStringForParam = function (langpair) {
        var source = langpair['source'];
        var target = langpair['target'];
        if (target != null) {
            return source + '_' + target;
        }
        return source;
    };
    //TODO make method more general
    ProjectCreateComponent.prototype.toServiceMap = function (lp) {
        if (lp.target != null) {
            var serviceParam = new TranslationParameter();
            serviceParam.source = lp.source;
            serviceParam.target = lp.target;
            return serviceParam;
        }
        else {
            var serviceParam = new EditingParameter();
            serviceParam.source = lp.source;
            return serviceParam;
        }
    };
    ProjectCreateComponent.prototype.openNextLangPair = function (langPair) {
        if (this.project != undefined && this.project.langpairs != undefined && this.project.langpairs[0] != undefined) {
            if (langPair === undefined) {
                this.setOpenLanguageBar(this.project.langpairs[0]);
            }
            else {
                var currentIndex = this.project.langpairs.findIndex(function (l) { return l.target == langPair.target; });
                var nextPair = this.project.langpairs[currentIndex + 1];
                this.setOpenLanguageBar(nextPair);
                if (nextPair === undefined) {
                    this.projectInfoSectionEnabled = true;
                    this.custDetailsSectionEnabled = true;
                }
            }
        }
        else
            this.setOpenLanguageBar(undefined);
    };
    ProjectCreateComponent.prototype.setOpenLanguageBar = function (langPair) {
        if (langPair != undefined) {
            this.openLanguageBar = langPair.target;
        }
        else {
            this.openLanguageBar = undefined;
        }
        //Delay a bit to make sure the language bar is expanded
        this.scrollToSection(this.openLanguageBar != undefined ? "lb_" + this.openLanguageBar : "projectInfoSection");
    };
    ProjectCreateComponent.prototype.priceUpdated = function (langpair, event) {
        // We use this variable to trigger the price overview component to reload
        // todo: find a better way to do this, without a dummy variable
        this.updatedLangPrices = { langpair: langpair.source + "_" + langpair.target, price: event };
    };
    ProjectCreateComponent.prototype.priceTotalCalculated = function (priceDetails) {
        this.calculatedTotalPrice = priceDetails.total(false);
        console.debug('priceTotalCalculated');
        this.orderSectionEnabled = this.checkOrderSectionEnable();
    };
    ProjectCreateComponent.prototype.userInfoStored = function () {
        this.reloadProject("USER_INFO_CHANGE");
        this.scrollToSection("paymentSection");
    };
    ProjectCreateComponent.prototype.scrollToSection = function (section) {
        var _this = this;
        Observable.create(function (obs) {
            setTimeout(function () {
                obs.next(_this.openLanguageBar);
            }, 100);
        })
            .pipe(map(function (s) { return document.getElementById(section); }))
            .subscribe(function (element) {
            if (element != undefined) {
                // Align the language section with the bottom of the screen
                // This way, when the user gets here from the index page the source files he added are in view
                if (section === "languageSelectionSection") {
                    element.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
                }
                else {
                    element.scrollIntoView({ behavior: "smooth" });
                }
            }
        });
    };
    ProjectCreateComponent.prototype.sortServiceParams = function () {
        if (this.project === undefined || this.project.langpairs === undefined || this.project.langpairs.length === 0) {
            return;
        }
        this.project.langpairs.map(function (a) { return toLangPairString(a); })
            .sort(function (a, b) {
            return a.localeCompare(b);
        });
    };
    ProjectCreateComponent.prototype.updateSaveSharesAsDefault = function (saveShares) {
        this.saveSharesAsDefault = saveShares;
    };
    return ProjectCreateComponent;
}());
export { ProjectCreateComponent };
