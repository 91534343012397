import { EventEmitter } from '@angular/core';
import { FileService } from '../../../service/file.service';
import * as FileSaver from "file-saver";
var DocumentationFileComponent = /** @class */ (function () {
    function DocumentationFileComponent(fileservice) {
        this.fileservice = fileservice;
        this.file = undefined;
        this.allowRemoval = false;
        this.removeEmitter = new EventEmitter();
    }
    DocumentationFileComponent.prototype.emitFileRemove = function () {
        this.removeEmitter.emit(this.file);
    };
    DocumentationFileComponent.prototype.download = function () {
        var _this = this;
        this.fileservice.downloadDoc(this.entityId, this.file.name, this.entityType).subscribe(function (n) {
            FileSaver.saveAs(n, _this.file.name, true);
        });
    };
    return DocumentationFileComponent;
}());
export { DocumentationFileComponent };
