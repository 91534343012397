import * as tslib_1 from "tslib";
import { MatDialogRef } from '@angular/material/dialog';
var EditorSaveComponent = /** @class */ (function () {
    function EditorSaveComponent(dialogRef, data) {
        this.dialogRef = dialogRef;
        this.data = data;
    }
    EditorSaveComponent.prototype.ngOnInit = function () {
        var e_1, _a;
        this.task = this.data.task;
        this.totalCount = this.data.rows.length;
        this.modifiedCount = 0;
        try {
            for (var _b = tslib_1.__values(this.data.rows), _c = _b.next(); !_c.done; _c = _b.next()) {
                var row = _c.value;
                if (row.modified)
                    this.modifiedCount++;
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_1) throw e_1.error; }
        }
        this.setRowsWithMissingRefs(this.data.rows);
    };
    EditorSaveComponent.prototype.setRowsWithMissingRefs = function (rows) {
        var _this = this;
        this.rowsWithMissingRefs = Array();
        if (rows) {
            rows.forEach(function (r) {
                if (r.missingRefs && r.missingRefs.length > 0) {
                    _this.rowsWithMissingRefs.push(r);
                    if (_this.tooltipMissingRefs == null)
                        _this.tooltipMissingRefs = "Rows with missing reference(s):";
                    _this.tooltipMissingRefs += "\n * Row " + r.id + ": " + r.missingRefs.length + " missing";
                }
            });
        }
        //  console.log("rowsWithMissingRefs", this.rowsWithMissingRefs)
    };
    EditorSaveComponent.prototype.hasDueDate = function () {
        return this.data.task.duedate;
    };
    EditorSaveComponent.prototype.onTime = function () {
        return Date.parse(this.data.task.duedate) > new Date().getMilliseconds();
    };
    EditorSaveComponent.prototype.cancelClick = function () {
        this.dialogRef.close("cancel");
    };
    EditorSaveComponent.prototype.confirmClick = function () {
        this.dialogRef.close("confirm");
    };
    EditorSaveComponent.prototype.enableConfirm = function () {
        return !this.rowsWithMissingRefs || this.rowsWithMissingRefs.length == 0;
    };
    return EditorSaveComponent;
}());
export { EditorSaveComponent };
