import { EventEmitter } from "@angular/core";
import { switchMap, tap } from 'rxjs/operators';
import { UserService } from "../../service/user.service";
import { CountryService } from "../../service/country.service";
import { LogInService } from "../../service/login.service";
var ProjectUserInfoComponent = /** @class */ (function () {
    function ProjectUserInfoComponent(userService, countryService, loginService) {
        this.userService = userService;
        this.countryService = countryService;
        this.loginService = loginService;
        this.eventEmitter = new EventEmitter();
        this.isNewCompany = false;
        this.edit = false;
        this.countries = [];
    }
    ProjectUserInfoComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.userService.loginEmitter.subscribe(function () { return _this.setUserAndCompany(); });
        this.setUserAndCompany();
        this.countryService.getCountries().subscribe(function (countries) { return _this.countries = countries; });
    };
    ProjectUserInfoComponent.prototype.setUserAndCompany = function () {
        var _this = this;
        this.user = this.userService.getCurrentUser();
        if (this.user != undefined) {
            if (this.user.companyId != undefined)
                this.userService.findCompanyInfo(this.user.companyId)
                    .subscribe(function (c) {
                    _this.company = c;
                    _this.isNewCompany = false;
                });
            else {
                this.company = {};
                this.isNewCompany = true;
                this.edit = true;
            }
        }
    };
    ProjectUserInfoComponent.prototype.storeUserInfo = function () {
        var _this = this;
        var companyObs = this.company.id != undefined ?
            this.userService.updateCompanyInfo(this.company).map(function (r) { return _this.company.id; }) :
            this.userService.createCompanyInfo(this.company, this.user);
        this.userService.updateUserData(this.user)
            .pipe(tap(function (v) { return _this.userService.getUser(_this.user.id).subscribe(function (u) {
            if (_this.userService.isCurrentUser(u))
                _this.userService.updateCurrentUser(u);
        }); }))
            .merge(companyObs
            .pipe(switchMap(function (c) {
            return _this.userService.findCompanyInfo(c);
        }), tap(function (company) { return _this.company = company; }), switchMap(function (c) {
            _this.user.companyName = c.name;
            _this.user.companyId = c.id;
            return _this.userService.updateUserCompanyInfo(_this.user);
        })))
            .subscribe(function () { return _this.edit = false; });
        this.eventEmitter.emit("INFO STORED");
    };
    ProjectUserInfoComponent.prototype.openJoin = function () {
        this.loginService.openJoinDialog("");
    };
    ProjectUserInfoComponent.prototype.openSignIn = function () {
        this.loginService.openSignInPopup("");
    };
    return ProjectUserInfoComponent;
}());
export { ProjectUserInfoComponent };
