import * as tslib_1 from "tslib";
import { EventEmitter } from "@angular/core";
import { ErrorSnackbarComponent } from "../../error.snackbar.component";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Subject } from "rxjs";
import { FileService } from "../../service/file.service";
import { UploadDataService } from "../util/upload-data.service";
var SourceFileAddComponent = /** @class */ (function () {
    function SourceFileAddComponent(fileService, uploadDataService, snackbar) {
        this.fileService = fileService;
        this.uploadDataService = uploadDataService;
        this.snackbar = snackbar;
        this.uploadedFiles = [];
        this.onFileUpload = new EventEmitter();
    }
    SourceFileAddComponent.prototype.uploadFiles = function (event) {
        var _this = this;
        if (this.uploadedFiles == undefined)
            this.uploadedFiles = [];
        var fileList = event.target.files;
        if (fileList.length > 0) {
            var _loop_1 = function () {
                var file = fileList[i];
                // Check if there is already a file with the same name in the project and show an error snackbar
                if (this_1.uploadedFiles.find(function (v) { return v.name === file.name; })) {
                    var ref = this_1.snackbar.openFromComponent(ErrorSnackbarComponent, {
                        duration: 3000,
                        verticalPosition: "top",
                        data: {
                            errorText: "\n                Sorry, you cannot add 2 files with the same name. <br/>\n                A file with name <span style=\"font-weight: bold\">" + file.name + "</span> already exists."
                        }
                    });
                    return { value: void 0 };
                }
            };
            var this_1 = this;
            // Check for duplicate files
            for (var i = 0; i < fileList.length; i++) {
                var state_1 = _loop_1();
                if (typeof state_1 === "object")
                    return state_1.value;
            }
            var _loop_2 = function () {
                var file = fileList[i];
                // Create a subject to send progress events to
                var progress = new Subject();
                // Store the subject as observable in a data provider, so we keep track of it even when navigating away from the page
                this_2.uploadDataService.addUploadData(this_2.pId, file.name, progress);
                // We are uploading the source file in an async block so the rest of the application does not wait for the
                // upload to complete
                (function () { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                    var start;
                    var _this = this;
                    return tslib_1.__generator(this, function (_a) {
                        start = new Date().getTime();
                        this.fileService.uploadProjectFileWithProgress(file, this.pId, null, progress).subscribe(function (data) {
                            var end = new Date();
                            console.log("Project " + _this.pId + ": upload of " + file.name + " in " + (end.getTime() - start) + " ms");
                        }, function (error) {
                            console.log("Error uploading source file " + file.name + " => ", error);
                            var ref = _this.snackbar.openFromComponent(ErrorSnackbarComponent, {
                                duration: 3000,
                                verticalPosition: "top",
                                data: {
                                    errorText: "\n                Uh oh, there was an error uploading file <span style=\"font-weight: bold\">" + file.name + "</span>. <br/>\n                Please remove it and try again."
                                }
                            });
                        }, function () {
                            progress.complete();
                            _this.uploadDataService.removeUploadData(_this.pId, file.name);
                        });
                        return [2 /*return*/];
                    });
                }); })();
                this_2.onFileUpload.emit(file);
                this_2.uploadedFiles.push(file);
            };
            var this_2 = this;
            // Loop and upload the files
            for (var i = 0; i < fileList.length; i++) {
                _loop_2();
            }
        }
    };
    SourceFileAddComponent.prototype.uploadDocumentationFiles = function (event) {
        console.debug(event, 'Documentaion file upload');
    };
    return SourceFileAddComponent;
}());
export { SourceFileAddComponent };
