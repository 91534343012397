var TransUnit = /** @class */ (function () {
    function TransUnit() {
        this.segments = [];
    }
    return TransUnit;
}());
export { TransUnit };
var Segment = /** @class */ (function () {
    function Segment() {
    }
    return Segment;
}());
export { Segment };
var Reference = /** @class */ (function () {
    function Reference() {
    }
    return Reference;
}());
export { Reference };
var EditorRow = /** @class */ (function () {
    function EditorRow() {
        this.noText = false;
    }
    return EditorRow;
}());
export { EditorRow };
