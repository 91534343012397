import { ProjectService } from '../service/project.service';
import { PricingService } from '../service/pricing.services';
import { Observable } from 'rxjs';
import { debounceTime, tap, filter, map, flatMap } from 'rxjs/operators';
import { TaskService } from '../service/task.service';
import { FileService } from '../service/file.service';
import { AnalysisService } from '../service/analysis.service';
import { VendorService } from '../service/vendor.service';
import { TaskSpec } from '../dto/job';
import { TranslationParameter } from '../mock-activities';
var CostCalcComponent = /** @class */ (function () {
    function CostCalcComponent(projectService, pricingService, taskService, fileService, analysisService, vendorService) {
        this.projectService = projectService;
        this.pricingService = pricingService;
        this.taskService = taskService;
        this.fileService = fileService;
        this.analysisService = analysisService;
        this.vendorService = vendorService;
        this.targetLanguages = ['en-GB', 'nl-BE', 'fr-BE', 'de-DE'];
        this.prices = {};
        this.lowestPrices = {};
        this.calculatingPrices = false;
    }
    CostCalcComponent.prototype.setProjectId = function (projectId) {
        var _this = this;
        console.debug("Following up on project ", projectId);
        this.projectId = projectId;
        this.projectService.getProject(projectId).subscribe(function (p) {
            _this.project = p;
            if (_this.project.files !== undefined && _this.project.files[0] !== undefined) {
                _this.enrollToAnalysisFeed(_this.project.files[0].name);
                if (_this.project.files[0].sourcelang !== undefined) {
                    _this.setSourceLanguage(_this.project.files[0].sourcelang);
                }
            }
        });
    };
    CostCalcComponent.prototype.setSourceLanguage = function (sourcelang) {
        var _this = this;
        this.sourcelang = sourcelang;
        Observable.from(this.targetLanguages)
            .pipe(filter(function (a) { return !a.startsWith(_this.sourcelang); }), map(function (l) {
            var serviceParam = new TranslationParameter();
            serviceParam.source = _this.sourcelang;
            serviceParam.target = l;
            return serviceParam;
        }))
            .concatMap(function (a) { return _this.projectService.addLangPairData(a, _this.projectId); })
            .subscribe(function (a) { }, function (error) { }, function () { return _this.enrollToProjectFeed(); });
    };
    CostCalcComponent.prototype.setFile = function (file) {
        if (this.projectId !== undefined) {
            this.enrollToAnalysisFeed(file);
        }
    };
    CostCalcComponent.prototype.enrollToAnalysisFeed = function (filename) {
        var _this = this;
        this.analysisService.enrollForFile(this.projectId, filename)
            .pipe(filter(function (a) { return a.type === 'detectedsourcelang' && _this.sourcelang === undefined; }), map(function (a) { return a.value; }), tap(function (a) { return _this.setSourceLanguage(a); })).subscribe();
    };
    CostCalcComponent.prototype.enrollToProjectFeed = function () {
        var _this = this;
        this.pricingObs = this.pricingService.enrollForProject(this.projectId);
        this.pricingObs.pipe(debounceTime(500), tap(function (a) { return _this.caclPrices(); })).subscribe();
    };
    CostCalcComponent.prototype.caclPrices = function () {
        var _this = this;
        Observable.from(this.targetLanguages)
            .pipe(filter(function (t) { return !t.startsWith(_this.sourcelang); }))
            .subscribe(function (l) { return _this.calcPriceForLanguage(l); });
    };
    CostCalcComponent.prototype.calcPriceForLanguage = function (l) {
        var _this = this;
        var langpair = this.sourcelang + '_' + l;
        var taskSpec = new TaskSpec();
        taskSpec.activity = 'Translation';
        taskSpec.service = 'Translation';
        taskSpec.projectId = this.projectId;
        taskSpec.sourcelanguage = this.sourcelang;
        taskSpec.targetlanguage = l;
        var priceCount = 0;
        this.vendorService.getBestProjectCompetences(this.projectId, langpair, 'Translation', 'Translation', undefined)
            .pipe(tap(function (a) { return console.debug('vendors ', a); }), map(function (a) { return a; }))
            .switchMap(function (vendors) { return _this.pricingService.getPriceForTaskSpecAndVendors(taskSpec, vendors); })
            .pipe(tap(function (prices) { return priceCount = prices.length; }), flatMap(function (prices) { return prices; }), map(function (price) { return price['price']; }), tap(function (price) {
            if (_this.lowestPrices[l] === undefined || _this.lowestPrices[l] > price) {
                _this.lowestPrices[l] = price;
            }
        }))
            .reduce(function (a, b) {
            return a + b;
        })
            .map(function (priceSum) { return priceSum / priceCount; })
            .subscribe(function (price) { return _this.prices[l] = price; });
    };
    return CostCalcComponent;
}());
export { CostCalcComponent };
