import { map } from 'rxjs/operators';
import { Router } from "@angular/router";
import { HubspotService } from "../service/hubspot.service";
import { UserService } from "../service/user.service";
var HubspotProjectCreateComponent = /** @class */ (function () {
    function HubspotProjectCreateComponent(userService, hubspotService, router) {
        this.userService = userService;
        this.hubspotService = hubspotService;
        this.router = router;
        this.creatingProject = false;
        this.selectedPages = [];
    }
    HubspotProjectCreateComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.hubspotService.getPages().pipe(map(function (pages) {
            console.debug(pages);
            return pages;
        }))
            .subscribe(function (pages) { return _this.pages = pages; });
    };
    HubspotProjectCreateComponent.prototype.pageClick = function (event) {
        var sEvent = JSON.parse(event);
        var pageId = sEvent.pageId;
        var selected = sEvent.selected;
        if (!selected) {
            var pageIndex = this.selectedPages.indexOf(pageId);
            if (pageIndex >= 0)
                this.selectedPages.splice(pageIndex, 1);
        }
        else
            this.selectedPages.push(pageId);
        console.debug(this.selectedPages);
    };
    HubspotProjectCreateComponent.prototype.languageSelected = function (language) {
        this.filterLanguage = language;
        this.selectedPages = [];
    };
    HubspotProjectCreateComponent.prototype.submit = function () {
        var _this = this;
        this.creatingProject = true;
        this.hubspotService.createProject(this.selectedPages).pipe(map(function (res) { return res["projectId"]; }))
            .subscribe(function (res) {
            _this.creatingProject = false;
            _this.router.navigate(["pdetail", res]);
        });
    };
    return HubspotProjectCreateComponent;
}());
export { HubspotProjectCreateComponent };
