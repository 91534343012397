import { OnInit } from "@angular/core";
import { UserService } from "../../../service/user.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ColumnType } from "../../../util/component/dynamic-table/dynamic-table.model";
import { ProjectService } from "../../../service/project.service";
import { ConfirmDialog } from "../../../confirm.dialog";
import { filter, tap } from "rxjs/operators";
import { MatDialog } from "@angular/material/dialog";
var ProjectCleanupComponent = /** @class */ (function () {
    function ProjectCleanupComponent(userService, projectService, dialog, snackbar) {
        this.userService = userService;
        this.projectService = projectService;
        this.dialog = dialog;
        this.snackbar = snackbar;
        this.headers = [
            {
                key: 'id',
                value: 'ID',
            },
            {
                key: 'title',
                value: 'Title',
            },
            {
                key: 'initDate',
                value: 'Init date',
                type: ColumnType.DATE,
            },
            {
                key: 'creationdate',
                value: 'Creation date',
                type: ColumnType.DATE,
            },
            {
                key: 'invoicedate',
                value: 'Invoice date',
                type: ColumnType.DATE,
            },
            {
                key: 'canceled',
                value: 'Cancel date',
                type: ColumnType.DATE,
            },
            {
                key: 'requestedby.firstName',
                value: 'First name',
            },
            {
                key: 'requestedby.lastName',
                value: 'Last name',
            },
            {
                key: 'requestedby.companyName',
                value: 'Company',
            },
        ];
        this.statuses = ["INIT", "DRAFT", "CANCELED"];
        this.projects = null;
        this.otmnths = 12;
        this.selectedStatus = 'INIT';
        this.removedCount = 0;
        this.totalCount = 0;
        this.cleanupDone = false;
    }
    ProjectCleanupComponent.prototype.ngOnInit = function () {
        this.getProjectsForCleanup();
    };
    ProjectCleanupComponent.prototype.getProjectsForCleanup = function () {
        var _this = this;
        this.projectService.getProjectsForCleanup(this.otmnths, this.selectedStatus).subscribe(function (p) {
            _this.projects = p;
            _this.snackbar.open(_this.selectedStatus + " projects retrieved", "", {
                duration: 2000
            });
        });
    };
    ProjectCleanupComponent.prototype.cleanup = function () {
        var _this = this;
        var confDialog = this.dialog.open(ConfirmDialog, {
            width: '400px',
            panelClass: 'confirmDialog',
            data: {
                confirmTitle: 'Are you sure?',
                confirmHtml: '<p>This will remove ALL data for these ' + this.projects.length + ' projects.</p>' +
                    '<p style="font-weight: bold; color: var(--warning-color);">This action can is final and can not be reversed!</p>',
                confirmText: 'Yup, I know what I\'m doing',
                cancelText: 'Naaah, this is way too scary'
            }
        });
        confDialog.afterClosed().pipe(filter(function (t) { return t === "confirm"; }), tap(function () { return _this.totalCount = _this.projects.length; })).subscribe(function (p) {
            _this.projectService.cleanupProjects(_this.otmnths, _this.selectedStatus).subscribe(function (c) {
                _this.getProjectsForCleanup();
                _this.removedCount = c;
                _this.cleanupDone = true;
                _this.snackbar.open(_this.selectedStatus + " project clean-up done!", "", {
                    duration: 2000
                });
            });
        });
    };
    return ProjectCleanupComponent;
}());
export { ProjectCleanupComponent };
