import { EventEmitter, OnInit, OnDestroy } from "@angular/core";
import { debounceTime, switchMap } from 'rxjs/operators';
import { PricingService } from "../../service/pricing.services";
import { Project } from "../../dto/project";
import { InvoiceService } from "../../service/invoice.service";
import { SubscriptionHandler } from '../../util/subscription.handler';
var ProjectUpdateOverviewComponent = /** @class */ (function () {
    function ProjectUpdateOverviewComponent(pricingService, invoiceService) {
        this.pricingService = pricingService;
        this.invoiceService = invoiceService;
        this.canUpdate = false;
        this.updateOrderEmitter = new EventEmitter();
        this.pricing = undefined;
        this.priceOrig = 0;
        this.priceDiff = 0;
        this.subscriptionHandler = new SubscriptionHandler(this);
    }
    ProjectUpdateOverviewComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.pricingService.getTotalProjectPrice(this.project.id)
            .subscribe(function (p) {
            _this.pricing = p;
            _this.setOrigPrice();
        });
        var pricingSubscriptions = this.pricingService.enrollForProject(this.project.id)
            .pipe(debounceTime(500), switchMap(function (p) { return _this.pricingService.getTotalProjectPrice(_this.project.id); })).subscribe(function (p) {
            _this.pricing = p;
            _this.updatePriceDiff();
        });
        this.subscriptionHandler.push(pricingSubscriptions);
        if (this.project.chargeid != null) {
            this.invoiceService.findRegisteredProjectSalesInvoices(this.project.id)
                .subscribe(function (invs) {
                _this.projectInvoices = invs;
            }, function (error) { return console.error("Error retrieving project invoices", error); });
        }
    };
    ProjectUpdateOverviewComponent.prototype.ngOnDestroy = function () {
    };
    ProjectUpdateOverviewComponent.prototype.setOrigPrice = function () {
        if (this.project.startdate != undefined)
            this.priceOrig = this.pricing.totalIncVat();
        else if (this.project.checkoutarchs != undefined && this.project.checkoutarchs.length > 0) {
            var sortedArchs = this.project.checkoutarchs
                .sort(function (v) { return v.archivedate; })
                .reverse();
            // this total price includes VAT
            this.priceOrig = sortedArchs[0].totalprice;
        }
        this.updatePriceDiff();
    };
    ProjectUpdateOverviewComponent.prototype.updatePriceDiff = function () {
        // Determine difference between new and old price, to display to user
        if (this.priceOrig != null && this.pricing != null) {
            this.priceDiff = this.pricing.totalIncVat() - this.priceOrig;
        }
    };
    ProjectUpdateOverviewComponent.prototype.updateOrder = function () {
        this.updateOrderEmitter.emit("updateOrder");
    };
    return ProjectUpdateOverviewComponent;
}());
export { ProjectUpdateOverviewComponent };
