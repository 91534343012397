import { OnChanges, SimpleChanges } from '@angular/core';
import { UserService } from "../service/user.service";
import { getUserInitials } from "../util/jobutil";
import { getTextColor } from "../util/userutil";
var MugshotComponent = /** @class */ (function () {
    function MugshotComponent(userService) {
        this.userService = userService;
        this.showIncomplete = true;
        this.profileBgColor = "#4ebdcc";
        this.profileTextColor = "white";
        this.profilePicExists = false;
        this.profileComplete = true;
    }
    MugshotComponent.prototype.ngOnInit = function () {
        var _this = this;
        // Adjust the font size based on the given dimensions
        if (this.height == null)
            this.height = "100%";
        else
            this.determineFontSize(this.height);
        if (this.width == null)
            this.width = "100%";
        else
            this.determineFontSize(this.width);
        // Set user
        if (this.user != null)
            this.setUser();
        // Monitor changes to the displayed user
        this.userService.userUpdated$.subscribe(function (u) {
            if (_this.user != null && u.id === _this.user.id) {
                _this.user = u;
                _this.setUser();
            }
        });
    };
    /**
     * Scales the font size based on the size of the container (if any was given).
     */
    MugshotComponent.prototype.determineFontSize = function (dimension) {
        if (this.fontSize == null && dimension.indexOf("px") > 0) {
            var size = Number(dimension.slice(0, dimension.length - 2));
            this.fontSize = size / 2 + "px";
        }
    };
    MugshotComponent.prototype.ngOnChanges = function (changes) {
        if (changes.hasOwnProperty('user')) {
            if (JSON.stringify(changes.user.currentValue) !== JSON.stringify(changes.user.previousValue)) {
                this.user = changes.user.currentValue;
                this.setUser();
            }
        }
    };
    MugshotComponent.prototype.setUser = function () {
        this.userInitials = getUserInitials(this.user);
        this.setProfileColor(this.user);
        this.loadProfilePic();
        this.calcProfileComplete();
    };
    MugshotComponent.prototype.setProfileColor = function (user) {
        var _this = this;
        if (user.pcolor != null && user.pcolor.length > 0) {
            this.profileBgColor = user.pcolor;
            this.profileTextColor = getTextColor(this.profileBgColor);
        }
        else {
            this.userService.getProfileColor(user.id).subscribe(function (c) {
                _this.profileBgColor = c;
                _this.profileTextColor = getTextColor(_this.profileBgColor);
            }, function (error) {
                console.log("Error getting color", error);
            });
        }
    };
    MugshotComponent.prototype.loadProfilePic = function () {
        var _this = this;
        if (this.user.id) {
            this.userService.getProfilePic(this.user.id)
                .subscribe(function (next) {
                _this.profilePic = next;
                _this.profilePicExists = true;
            }, function (error) { return _this.profilePicExists = false; });
        }
        else {
            this.profilePicExists = false;
        }
    };
    MugshotComponent.prototype.calcProfileComplete = function () {
        if (this.user != null) {
            this.profileComplete = this.user.id != null &&
                this.user.firstName != null &&
                this.user.lastName != null &&
                this.user.email != null;
        }
        else
            this.profileComplete = true;
    };
    return MugshotComponent;
}());
export { MugshotComponent };
