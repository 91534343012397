import { PipeTransform } from '@angular/core';
var ReturnToBreakPipe = /** @class */ (function () {
    function ReturnToBreakPipe() {
    }
    ReturnToBreakPipe.prototype.transform = function (value) {
        if (value === undefined)
            return undefined;
        return value.replace(/\n/g, "<br/>");
    };
    return ReturnToBreakPipe;
}());
export { ReturnToBreakPipe };
