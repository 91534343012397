import * as FileSaver from "file-saver";
import { FileService } from "../service/file.service";
import { LanguageService } from "../service/language.service";
import { SimpleDialog } from "../simple-dialog.component";
import { HelpDialogComponent } from "../util/help.dialog.component";
import { TranslationUploadDialog } from "./task-detail.component";
import { MatDialog, MatSnackBar } from '@angular/material';
import { Router } from '@angular/router';
import { TaskService } from "../service/task.service";
import { UserService } from "../service/user.service";
var TaskFileComponent = /** @class */ (function () {
    function TaskFileComponent(taskService, fileService, languageService, userService, dialog, snackbar, router) {
        this.taskService = taskService;
        this.fileService = fileService;
        this.languageService = languageService;
        this.userService = userService;
        this.dialog = dialog;
        this.snackbar = snackbar;
        this.router = router;
        this.isJobCompleted = false;
        this.isJobAvailable = undefined;
        this.isXliffAvailable = false;
        this.isPDFAvailable = false;
        this.isTMXAvailable = false;
    }
    TaskFileComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.languageService.getRight2LeftLanguages().subscribe(function (r2l) { return _this.right2LeftLangs = r2l; });
        this.languageService.getCharBasedLanguages().subscribe(function (cbl) { return _this.charBasedLangs = cbl; });
        this.setTaskJob();
    };
    TaskFileComponent.prototype.ngDoCheck = function () {
        var _this = this;
        this.isJobCompleted = this.task.jobs.find(function (j) { return j.file == _this.file.name && j.completed != null; }) != undefined;
        this.isJobAvailable = this.availableJobs != null && this.availableJobs.find(function (f) { return f.startsWith(_this.file.name); }) != undefined;
        this.isXliffAvailable = this.availableXliffs != null && this.availableXliffs.find(function (f) { return f.startsWith(_this.file.name); }) != undefined;
        this.isPDFAvailable = this.source4pdf != null ||
            (this.availablePDFs != null && this.availablePDFs.find(function (f) { return f.startsWith(_this.file.name); }) != undefined);
        this.isTMXAvailable = this.availableTMXs != null && this.availableTMXs.find(function (f) { return f.startsWith(_this.file.name); }) != undefined;
        /*console.debug("task " + this.task.id + ", file " + this.file + " => " +
          "job complete? " + this.isJobCompleted + " - " +
          "job available? " + this.isJobAvailable + " - " +
          "xliff available? " + this.isXliffAvailable);*/
    };
    TaskFileComponent.prototype.setTaskJob = function () {
        var _this = this;
        this.taskJob = this.task.jobs.find(function (f) { return f.file.indexOf(_this.file.name) >= 0; });
    };
    TaskFileComponent.prototype.isManual = function () {
        return this.file.processType != undefined && this.file.processType == 'MANUAL';
    };
    TaskFileComponent.prototype.getWordCount = function () {
        return this.file.wordcount;
    };
    TaskFileComponent.prototype.allowOrigFileDownload = function () {
        // Only show if not manual and pdf is not available
        // If manual, then the file will already be available as "main" download (so no need to add it to the additional files)
        return !this.isManual() && !this.isPDFAvailable;
    };
    TaskFileComponent.prototype.enableEditor = function () {
        return !(this.task.rejectdate != undefined) && !this.isRight2LeftLanguage();
    };
    TaskFileComponent.prototype.isRight2LeftLanguage = function () {
        return this.right2LeftLangs != undefined &&
            (this.right2LeftLangs.indexOf(this.task.sourcelanguage) >= 0 || this.right2LeftLangs.indexOf(this.task.targetlanguage) >= 0);
    };
    TaskFileComponent.prototype.isCharBasedSource = function () {
        if (this.charBasedLangs)
            return this.charBasedLangs.indexOf(this.task.sourcelanguage) >= 0;
        return false;
    };
    TaskFileComponent.prototype.downloadOrig = function () {
        var _this = this;
        if (!this.isManual()) {
            var dialogRef = this.dialog.open(SimpleDialog, {
                width: '400px',
                panelClass: 'simpleDialog',
                data: {
                    dialogHtml: "<p>" +
                        "You are downloading the original source file. This file should <strong>not be used to translate with</strong>. <br/>" +
                        "<br/>" +
                        "To translate, use the available XLIFF instead.</p>",
                    dialogButtonName: "Got it!"
                }
            });
            dialogRef.afterClosed().subscribe(function (a) {
                _this.fileService.downloadSource(_this.task.projectId, _this.file.name).subscribe(function (n) {
                    FileSaver.saveAs(n, _this.file.name, true);
                });
            });
        }
        else {
            this.fileService.downloadSource(this.task.projectId, this.file.name).subscribe(function (n) {
                _this.setJobDownloadDate();
                FileSaver.saveAs(n, _this.file.name, true);
            });
        }
    };
    TaskFileComponent.prototype.openInEditor = function () {
        this.router.navigate(['/editor', this.task.id, this.file.name]);
    };
    TaskFileComponent.prototype.downloadXliff = function () {
        var _this = this;
        this.fileService.downloadXliff(this.file.name, this.task).subscribe(function (n) {
            _this.setJobDownloadDate();
            FileSaver.saveAs(n, _this.file.name + ".xlf", true);
        });
    };
    TaskFileComponent.prototype.setJobDownloadDate = function () {
        // Flag that the file was downloaded, so we can indicate this in the task page (only if currently logged in user is
        // effectively the assigned translator)
        if (this.userService.isLoggedIn() && this.userService.isCurrentUserId(this.task.vendorId))
            try {
                this.taskService.setJobDownloadDate(this.task.id, this.file.name).subscribe();
            }
            catch (e) {
                console.error("Error setting job download date for task " + this.task.id + " and file " + this.file.name);
            }
    };
    TaskFileComponent.prototype.downloadSDLXliff = function () {
        var _this = this;
        this.fileService.downloadSDLXliff(this.file.name, this.task).subscribe(function (n) {
            FileSaver.saveAs(n, _this.file.name + ".sdlxliff", true);
        });
    };
    TaskFileComponent.prototype.downloadTMX = function () {
        var _this = this;
        this.fileService.downloadTMX(this.file.name, this.task).subscribe(function (n) {
            FileSaver.saveAs(n, _this.file.name + ".tmx", true);
        });
    };
    TaskFileComponent.prototype.downloadPDF = function () {
        var _this = this;
        if (this.source4pdf != null) {
            this.fileService.downloadDoc(this.task.projectId, this.source4pdf.name).subscribe(function (n) {
                FileSaver.saveAs(n, _this.file.name + "_custom.pdf", true);
            });
        }
        else
            this.fileService.downloadPDF(this.file.name, this.task).subscribe(function (n) {
                FileSaver.saveAs(n, _this.file.name + ".pdf", true);
            });
    };
    TaskFileComponent.prototype.openTradosHelpDialog = function () {
        var helpText = "\n      <p>Do you want to handle the file with Trados? Great!</p>\n      <p>We have already prepared the file so it can be easily opened with SDL Trados Studio.<br/>\n         First, click \"Download for Trados\", which will download the SDLXliff file we have prepared for you.\n         If Trados is properly installed, you can then open it by double clicking the downloaded file.</p>\n      <p>When the translation is completed, you can just upload the SDLXliff file again (please only upload <i>.sdlxliff</i> file, not the <i>.sdlproj</i> file).</p>\n      <p>If there is a TMX file available (see \"Additional file(s)\"), don't forget to download and import it into your Trados project to gain the leverage from the customer's translation memory.</p>\n    ";
        var dialogRef = this.dialog.open(HelpDialogComponent, {
            width: '700px',
            height: '500px',
            data: { helpTitle: "How to handle a file with Trados?", helpHtmlContent: helpText }
        });
    };
    TaskFileComponent.prototype.getLangPair = function () {
        return this.task.sourcelanguage + "_" + this.task.targetlanguage;
    };
    TaskFileComponent.prototype.openUploadDialog = function () {
        var _this = this;
        var dialogRef = this.dialog.open(TranslationUploadDialog, {
            width: '550px',
            data: { task: this.task, taskFile: this.file }
        });
        dialogRef.afterClosed().subscribe(function (filePath) { return _this.confirmTaskUpload(filePath, null); });
    };
    TaskFileComponent.prototype.openManualUploadDialog = function () {
        var _this = this;
        var dialogRef = this.dialog.open(TranslationUploadDialog, {
            width: '550px',
            data: {
                task: this.task, taskFile: this.file,
                expectedFileTypes: /(?:\.([^.]+))?$/.exec(this.file.name).toString()
            }
        });
        dialogRef.afterClosed().subscribe(function (filePath) { return _this.confirmTaskUpload(filePath, "MANUAL"); });
    };
    TaskFileComponent.prototype.confirmTaskUpload = function (filePath, processType) {
        var _this = this;
        if (filePath != null) {
            this.fileService.confirmTranslation(filePath, this.file.name, this.task, processType).subscribe(function (data) {
                console.debug('Confirmed translation of ' + _this.file.name + ' for task ' + _this.task.id, data);
            }, function (error) {
                console.error('Unable to confirm translation file upload of ' + _this.file.name + ' for task ' + _this.task.id, error);
                _this.snackbar.open("Error uploading translation. Please try again.", "", {
                    duration: 4000
                });
            });
        }
    };
    TaskFileComponent.prototype.hasBegunTask = function () {
        return this.task.acceptdate != undefined && this.taskJob != null &&
            (this.taskJob.lasteditoraccessdate != undefined || this.taskJob.lastdownloaddate != undefined);
    };
    TaskFileComponent.prototype.hasDownloadedLast = function () {
        // Determine whether the user downloaded an XLIFF last (return true) or opened the editor (return false)
        // If neither, undefined is returned
        if (this.taskJob != undefined) {
            if (this.taskJob.lasteditoraccessdate != undefined && this.taskJob.lastdownloaddate != undefined) {
                return this.taskJob.lasteditoraccessdate < this.taskJob.lastdownloaddate;
            }
            else if (this.taskJob.lasteditoraccessdate != undefined) {
                return false;
            }
            else if (this.taskJob.lastdownloaddate != undefined) {
                return true;
            }
            else {
                return undefined;
            }
        }
    };
    return TaskFileComponent;
}());
export { TaskFileComponent };
