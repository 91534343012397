import { filter, isEmpty } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { TaskService } from "../service/task.service";
import { ProjectService } from "../service/project.service";
import { UserService } from "../service/user.service";
import { ActivatedRoute, Router } from "@angular/router";
import { LogInService } from "../service/login.service";
import { EditorService } from "./service/editor.service";
import { EditorSaveComponent } from "./editor-save.component";
import { EditorComponent } from "./editor.component";
var TaskEditorComponent = /** @class */ (function () {
    function TaskEditorComponent(router, route, taskService, projectService, userService, loginService, editorService, dialog) {
        this.router = router;
        this.route = route;
        this.taskService = taskService;
        this.projectService = projectService;
        this.userService = userService;
        this.loginService = loginService;
        this.editorService = editorService;
        this.dialog = dialog;
    }
    TaskEditorComponent.prototype.ngOnInit = function () {
        var _this = this;
        if (!this.userService.hasTranslatorRole()) {
            this.userService.signInFromLocalStorage().pipe(isEmpty(), filter(function (v) { return v; }))
                .subscribe(function () { return Promise.resolve().then(function () { return _this.loginService.openSignInPopup(); }); });
        }
        this.route.params
            .subscribe(function (params) {
            _this.taskId = params['id'];
            _this.editorService.getTaskFile(params['id'], params['file']).subscribe(function (data) {
                _this.setTaskFile(data);
            });
        });
    };
    TaskEditorComponent.prototype.setTaskFile = function (taskFile) {
        this.taskFile = taskFile;
        this.warning = undefined;
        if (this.isTaskCancelled())
            this.warning = "This task has been canceled and is not editable.";
        else if (this.isTaskCompleted())
            this.warning = "This task has been completed and is no longer editable.";
        else if (!this.isTaskAccepted())
            this.warning = "This task has not yet been accepted and is therefor not yet editable. \n" +
                "You must accept the task (on the task detail page) before you are able to start it.";
        // console.debug("TASK ==> \n" + JSON.stringify(taskFile, null, 2));
        // Flag editor access, so we can indicate this in the task page (only if currently logged in user is effectively
        // the assigned translator)
        if (this.userService.isLoggedIn() && this.userService.isCurrentUserId(this.taskFile.task.vendorId))
            this.taskService.setEditorAccessDate(this.taskFile.task.id, this.taskFile.filename).subscribe();
    };
    TaskEditorComponent.prototype.isTaskAccepted = function () {
        return this.taskFile && this.taskFile.task.acceptdate;
    };
    TaskEditorComponent.prototype.isTaskCompleted = function () {
        return this.taskFile && this.taskFile.task.completed;
    };
    TaskEditorComponent.prototype.isTaskCancelled = function () {
        return this.taskFile && this.taskFile.task.canceldate;
    };
    TaskEditorComponent.prototype.editable = function () {
        return this.isTaskAccepted() &&
            !this.isTaskCompleted() &&
            !this.isTaskCancelled();
    };
    TaskEditorComponent.prototype.enableFinalize = function () {
        return this.editor && this.editor.content &&
            this.editable();
    };
    TaskEditorComponent.prototype.backToTask = function () {
        this.router.navigate(['/tdetail', this.taskId]);
    };
    TaskEditorComponent.prototype.saveTaskFile = function () {
        var _this = this;
        var dialogRef = this.dialog.open(EditorSaveComponent, {
            width: '500px',
            height: '50%',
            data: { task: this.taskFile.task, rows: this.editor.rows }
        });
        dialogRef.afterClosed().subscribe(function (r) {
            if (r === "confirm") {
                _this.editorService.completeTaskFile(_this.taskFile.filelocation, _this.taskId, _this.taskFile.filename)
                    .subscribe(function (data) {
                    //          console.log('Task file completion result: ' + data);
                    if (Boolean(data)) {
                        // Redirect back to task page if successful
                        _this.router.navigate(['/tdetail', _this.taskId]);
                    }
                });
            }
        });
    };
    return TaskEditorComponent;
}());
export { TaskEditorComponent };
