import * as tslib_1 from "tslib";
import { from as observableFrom, Subject } from 'rxjs';
import { ElementRef, EventEmitter, OnInit } from "@angular/core";
import { Project } from "../dto/project";
import { ProjectService } from "../service/project.service";
import { FileService } from "../service/file.service";
import { UploadDataService } from "../projectcomponent/util/upload-data.service";
import { Router } from "@angular/router";
import { environment } from "../../environments/environment";
import { UserService } from "../service/user.service";
import { ConverterService } from '../service/converter.service';
import { scrollToBottom } from "../util/apputil";
var PreUploadComponent = /** @class */ (function () {
    function PreUploadComponent(projectService, fileService, uploadDataService, userService, converterService, router) {
        this.projectService = projectService;
        this.fileService = fileService;
        this.uploadDataService = uploadDataService;
        this.userService = userService;
        this.converterService = converterService;
        this.router = router;
        this.projectCreatedEmitter = new EventEmitter();
        this.fileUploadedEmitter = new EventEmitter();
        this.loading = false;
        this.files = [];
        this.projectId = undefined;
        this.convertableFormats = [];
    }
    PreUploadComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.converterService.getConvertableFileFormats()
            .subscribe(function (v) { return _this.convertableFormats = v; });
        if (this.router.url.endsWith("newproject"))
            this.openFileChooser();
    };
    PreUploadComponent.prototype.openFileChooser = function () {
        if (this.uploadInput != undefined) {
            var evt = document.createEvent("MouseEvents");
            evt.initEvent("click", true, false);
            this.uploadInput.nativeElement.dispatchEvent(evt);
        }
    };
    PreUploadComponent.prototype.uploadFiles = function (event) {
        var fileList = event.target.files;
        this.onFileChange(fileList);
    };
    PreUploadComponent.prototype.onFileChange = function (files) {
        var _this = this;
        this.files = this.files.concat(toArray(files)
            .map(function (f) { return _this.checkWarning(f); }));
        scrollToBottom("uploadedFilesDiv");
        var p = new Project();
        // Determine the title based on the first file's name
        p.title = this.getTitleFromFiles(files);
        // Create the project
        if (this.projectId === undefined) {
            this.loading = true;
            this.projectService.initProject(p, this.userService.getRemoteUserIp()).subscribe(function (result) {
                _this.projectId = result.id;
                _this.addFilesToProject(files);
                _this.projectCreatedEmitter.emit(result.id);
            }, function (err) {
                console.error("Error initializing project", err);
            }, function () { return _this.loading = false; });
        }
        else {
            this.addFilesToProject(files);
        }
    };
    PreUploadComponent.prototype.addFilesToProject = function (files) {
        var _this = this;
        var pId = this.projectId;
        var fileNames = [];
        for (var i = 0; i < files.length; i++) {
            fileNames.push(files[i].name);
        }
        // Add the data of the source files to the project
        this.projectService.addSourceFilesData(fileNames, this.projectId).subscribe(function (n) {
            // We are uploading the source file in an async block so the rest of the application does not wait for the
            // upload to complete
            _this.doFileUpload(files, _this.projectId);
        }, function (e) {
            console.error("Error adding source file data", e);
        }, function () {
        });
        fileNames.forEach(function (f) { return _this.fileUploadedEmitter.next(f); });
    };
    PreUploadComponent.prototype.getTitleFromFiles = function (files) {
        if (files && files.length > 0) {
            var file = files[0];
            var i = file.name.lastIndexOf(".");
            if (i > 0)
                return file.name.substring(0, i);
            else
                return file.name;
        }
        return null;
    };
    PreUploadComponent.prototype.doFileUpload = function (files, pId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                observableFrom(files).subscribe(function (file) {
                    // Create a subject to send progress events to
                    var progress = new Subject();
                    // Store the subject as observable in a data provider, so we keep track of it even when navigating away from the page
                    _this.uploadDataService.addUploadData(pId, file.name, progress);
                    // Upload the file itself through the file service
                    var start = new Date().getTime();
                    _this.fileService.uploadProjectFileWithProgress(file, pId, null, progress).subscribe(function (data) {
                        var end = new Date();
                        console.log("Project " + pId + ": upload of " + file.name + " in " + (end.getTime() - start) + " ms");
                    }, function (error) { return console.error("Error uploading source file", error); }, function () {
                        progress.complete();
                        _this.uploadDataService.removeUploadData(pId, file.name);
                    });
                    progress.subscribe(function (percent) { return _this.loading = true; }, function (error) { return _this.loading = false; }, function () { return _this.loading = false; });
                });
                return [2 /*return*/];
            });
        });
    };
    PreUploadComponent.prototype.draftProject = function () {
        var _this = this;
        this.loading = true;
        this.projectService.draftProject(this.projectId, this.userService.getRemoteUserIp()).subscribe(function (result) {
            var hasWarnings = _this.hasWarnings();
            if (top.location != self.location) {
                top.location.href = environment.appURI + '/pdetail/' + _this.projectId + (!hasWarnings ? '?section=languageSelectionSection' : "");
            }
            else {
                _this.router.navigate(['/pdetail', _this.projectId], (!hasWarnings ? { queryParams: { 'section': 'languageSelectionSection' } } : {}));
            }
        }, function (error) {
            console.error("Error drafting project", error);
        }, function () { return _this.loading = false; });
    };
    PreUploadComponent.prototype.removeFile = function (file) {
        // TODO
        this.files.splice(this.files.indexOf(file), 1);
        this.projectService.removeSourceFile(file.name, this.projectId).subscribe(function (v) {
            //Do nothing
        });
    };
    PreUploadComponent.prototype.checkWarning = function (file) {
        var extension = file.name.slice(file.name.lastIndexOf(".") + 1).toLocaleLowerCase();
        if (this.convertableFormats.indexOf(extension) < 0) {
            file["warning"] = "UNCONVERTABLE";
        }
        return file;
    };
    PreUploadComponent.prototype.hasWarnings = function () {
        return this.files
            .filter(function (f) { return f.warning != undefined; })
            .length > 0;
    };
    return PreUploadComponent;
}());
export { PreUploadComponent };
function toArray(fileList) {
    return Array.prototype.slice.call(fileList);
}
