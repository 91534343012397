import * as tslib_1 from "tslib";
import { filter, map } from 'rxjs/operators';
import { Vendor } from "../dto/vendor";
import { HttpClient, HttpParams } from "@angular/common/http";
import { getBaseUrl } from "../util/httpclient";
import { SERVICE_TRANSLATION } from '../mock-activities';
var VendorService = /** @class */ (function () {
    function VendorService(http) {
        this.http = http;
        this.USER_API = getBaseUrl() + "/api/v1/users";
        this.COMPETENCE_API = getBaseUrl() + "/api/v1/competences";
    }
    VendorService.prototype.getAvailableCompetences = function () {
        return this.http.get(this.COMPETENCE_API + "/find/langpair/available").pipe(map(function (a) { return a; }));
    };
    VendorService.prototype.searchCompetences = function (languagePair, activity, userId) {
        if (userId === void 0) { userId = null; }
        var params = new HttpParams().set("userId", userId);
        return this.http.get(this.COMPETENCE_API + "/find/langpair/" + activity + "/" + encodeURIComponent(languagePair), { params: params }).pipe(map(function (a) { return a; }));
    };
    VendorService.prototype.getCompetences = function (languagePair, activity, userId) {
        var _this = this;
        if (userId === void 0) { userId = null; }
        return this.searchCompetences(languagePair, activity, userId).pipe(map(function (a) { return a.map(function (v) { return _this.extractToVendor(v); }); }));
    };
    VendorService.prototype.getBestCompetences = function (languagePair, activity, userId) {
        var _this = this;
        var params = new HttpParams().set("userId", userId)
            .append("limit", "3");
        return this.http.get(this.COMPETENCE_API + "/bestmatches/" + activity + "/" + encodeURIComponent(languagePair), { params: params }).pipe(map(function (a) { return a; }), map(function (a) { return a.map(function (v) { return _this.extractToVendor(v); }); }));
    };
    VendorService.prototype.getBestProjectCompetences = function (projectId, languagePair, activity, service, count) {
        var _this = this;
        if (service === void 0) { service = SERVICE_TRANSLATION; }
        if (count === void 0) { count = undefined; }
        var params = new HttpParams().set('limit', count)
            .set('activity', activity)
            .set('service', service)
            .set('langpair', encodeURIComponent(languagePair))
            .set('pid', projectId);
        if (service == null) {
            params = params.set('service', SERVICE_TRANSLATION);
        }
        return this.http.get(this.COMPETENCE_API + '/search', { params: params }).pipe(map(function (a) { return a; }), map(function (a) { return a.map(function (v) { return _this.extractToVendor(v); }); }));
    };
    VendorService.prototype.getVendor = function (id) {
        var _this = this;
        return this.http.get(this.USER_API + "/find/" + id).pipe(filter(function (a) { return a != null; }), map(function (v) { return _this.extractToVendor(v); }));
    };
    VendorService.prototype.extractToVendor = function (json) {
        // console.debug("vendor from server", json);
        //TODO complete additional data or don't work with the Vendor object anymore and just with "any"
        var rating = 0;
        var reviewcount = 0;
        var reviewsum = json.reviewsummary;
        if (reviewsum != null) {
            var scores = reviewsum.scores;
            rating = this.calcRatingFromScores(scores);
            reviewcount = reviewsum.count;
        }
        if (json.internalCompetences != null) {
            json.competences = json.internalCompetences;
        }
        var responsivety;
        if (json.responsiveness != null && json.responsiveness.average != null) {
            var average = json.responsiveness.average;
            if (average < 60) {
                responsivety = '1 hour';
            }
            else if (average / 60 < 24) {
                responsivety = (Number(average / 60) + 1).toFixed(0) + ' hours';
            }
            else {
                responsivety = (Number(average / 1440) + 1).toFixed(0) + ' days';
            }
        }
        var price = 0;
        if (json.competences && json.competences[0])
            price = json.competences[0].price;
        return new Vendor(json.id, json.firstName, json.lastName, "", json.description, json.language, json.city, json.location, rating, reviewcount, responsivety, price, json.competences, json.domains, json.percentageSet, json.internalCompetences != undefined, json.companyId);
    };
    VendorService.prototype.calcRatingFromScores = function (scores) {
        var e_1, _a;
        var rating = 0;
        try {
            for (var scores_1 = tslib_1.__values(scores), scores_1_1 = scores_1.next(); !scores_1_1.done; scores_1_1 = scores_1.next()) {
                var score = scores_1_1.value;
                rating += score.rate;
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (scores_1_1 && !scores_1_1.done && (_a = scores_1.return)) _a.call(scores_1);
            }
            finally { if (e_1) throw e_1.error; }
        }
        return rating / scores.length;
    };
    return VendorService;
}());
export { VendorService };
