import * as tslib_1 from "tslib";
import { AfterViewInit, ElementRef, EventEmitter, OnDestroy, OnInit } from "@angular/core";
import { InvoiceService } from "../../service/invoice.service";
import { getPaymentImage } from "../../util/userutil";
var CreditCardComponent = /** @class */ (function () {
    function CreditCardComponent(invoiceService) {
        this.invoiceService = invoiceService;
        this.cardChangeEmitter = new EventEmitter();
        this.cardHandler = this.onChange.bind(this);
        this.currentCard = undefined;
    }
    CreditCardComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.invoiceService.findPaymentMethod(this.userId).subscribe(function (v) {
            if (v !== undefined && v.card !== undefined) {
                _this.currentCard = v.card;
                _this.paymentImage = _this.getPaymentImageUrl(_this.currentCard.brand);
            }
        });
    };
    CreditCardComponent.prototype.ngAfterViewInit = function () {
        this.initCardInfoView();
    };
    CreditCardComponent.prototype.ngOnDestroy = function () {
        if (this.card != null) {
            this.card.removeEventListener('change', this.cardHandler);
            this.card.destroy();
        }
    };
    CreditCardComponent.prototype.onChange = function (_a) {
        var error = _a.error;
        if (error) {
            this.error = error.message;
        }
        else {
            this.error = null;
            this.cardChangeEmitter.emit(this.card);
        }
    };
    CreditCardComponent.prototype.changeCardInfo = function () {
        this.currentCard = undefined;
    };
    CreditCardComponent.prototype.initCardInfoView = function () {
        if (this.cardInfo !== undefined) {
            this.card = elements.create('card', { style: { base: { 'max-width': '200px' } } });
            this.card.mount(this.cardInfo.nativeElement);
            this.card.addEventListener('change', this.cardHandler);
        }
    };
    CreditCardComponent.prototype.storeCreditCard = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var localThis;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        localThis = this;
                        if (this.currentCard !== undefined) {
                            return [2 /*return*/, Promise.resolve()];
                        }
                        return [4 /*yield*/, this.invoiceService.getPaymentIntent()
                                .toPromise()
                                .then(function (sk) {
                                return stripe.handleCardSetup(sk, _this.card);
                            })
                                .then(function (result) {
                                console.debug("Stored credit card for user ", result);
                                if (result.error) {
                                    throw result.error;
                                }
                                else {
                                    return localThis.invoiceService.storeUserPaymentMethod(result).toPromise();
                                }
                            })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    CreditCardComponent.prototype.getPaymentImageUrl = function (paymentMethod) {
        return './../../assets/paymentmethod/' + getPaymentImage(paymentMethod);
    };
    return CreditCardComponent;
}());
export { CreditCardComponent };
