import { filter, map, mergeMap } from 'rxjs/operators';
import { EventEmitter } from "@angular/core";
import { AnalysisService } from "../../service/analysis.service";
import { PricingService } from "../../service/pricing.services";
import { FileService } from "../../service/file.service";
import * as FileSaver from "file-saver";
import { ProjectService } from "../../service/project.service";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { LoggerService } from "../../service/logger.service";
import { FileMetaDataDialog } from "./file-meta-data.dialog";
import { UploadDataService } from "./upload-data.service";
import { environment } from '../../../environments/environment';
import { SubscriptionHandler } from '../../util/subscription.handler';
var FileInfoComponent = /** @class */ (function () {
    function FileInfoComponent(analysisService, pricingService, fileService, projectService, loggerService, uploadDataService, snackbar, dialog) {
        this.analysisService = analysisService;
        this.pricingService = pricingService;
        this.fileService = fileService;
        this.projectService = projectService;
        this.loggerService = loggerService;
        this.uploadDataService = uploadDataService;
        this.snackbar = snackbar;
        this.dialog = dialog;
        this.remover = new EventEmitter();
        this.languageUpdate = new EventEmitter();
        this.totalPrice = 0;
        this.alive = true;
        this.statusMap = new Map();
        this.uploadPercent = -1;
        this.subscriptionHandler = new SubscriptionHandler(this);
        this.statusMap.set("conv2xliff", "Converted to XLIFF");
        this.statusMap.set("pdf", "Created PDF");
        this.statusMap.set("frontpage_img", "Generated frontpage image");
        this.statusMap.set("wordcount", "Calculated word count");
        this.statusMap.set("detectedsourcelang", "Detected language");
    }
    FileInfoComponent.prototype.ngOnInit = function () {
        var _this = this;
        if (this.file) {
            this.file.statusText = "";
            // Check whether there is any upload observer available for this file
            var uploadObserver_1 = this.getUploadObserver(this.file);
            if (uploadObserver_1 != null) {
                // If so, subscribe to it and listen to progress events
                this.uploadPercent = (uploadObserver_1.lastVal >= 0 ? uploadObserver_1.lastVal : 0);
                uploadObserver_1.progressObs.subscribe(function (p) {
                    _this.uploadPercent = p;
                }, function (error) {
                }, function () {
                    // Reset when done
                    uploadObserver_1 = null;
                    _this.uploadPercent = -1;
                });
            }
            this.getProgress(this.pId, this.file.name);
            var fileAnalysSubscription = this.analysisService.enrollForFile(this.pId, this.file.name).subscribe(function (data) {
                if (data.type == "wordcount") {
                    _this.file.wordcount = data.value;
                }
                else if (data.type == "detectedsourcelang") {
                    if (_this.file.sourcelang == undefined)
                        _this.updateFileLang(data.value, false);
                    _this.file.detectedsourcelang = data.value;
                }
            });
            this.subscriptionHandler.push(fileAnalysSubscription);
            var logSubscription = this.loggerService.enrollForProject(this.pId).subscribe(function (data) {
                var filename = data.filename;
                if (filename && filename.indexOf(_this.file.name) >= 0) {
                    _this.getProgress(_this.pId, _this.file.name);
                }
            });
            this.subscriptionHandler.push(logSubscription);
            //Init the file pricing once
            this.pricingService.getTotalFilePrice(this.pId, this.file.name).subscribe(function (total) { return _this.totalPrice = total; });
            //Then enroll in the socket and recalculate each change made
            var priceSubscription = this.pricingService.enrollForProject(this.pId).pipe(mergeMap(function (s) { return _this.pricingService.getTotalFilePrice(_this.pId, _this.file.name); }))
                .subscribe(function (total) { return _this.totalPrice = total; });
            this.subscriptionHandler.push(priceSubscription);
            var sourceUpdateSubscription = this.projectService.enrollForProject(this.pId).pipe(filter(function (data) { return data.action == "SOURCE_UPDATE" && data.filename == _this.file.name; }), map(function (data) { return data.text; }), map(function (t) { return JSON.parse(t); }))
                .subscribe(function (v) {
                _this.file[v.key] = v.value;
            });
            this.subscriptionHandler.push(sourceUpdateSubscription);
            if (this.pdf4source)
                this.pdf4sourceName = this.pdf4source.name;
        }
    };
    FileInfoComponent.prototype.getUploadObserver = function (file) {
        // Retrieve the upload observer (if any)
        var fileProgress = this.uploadDataService.getUploadData(this.pId);
        if (fileProgress != null && fileProgress[file.name] != null) {
            return fileProgress[file.name].progressdata;
        }
        else
            return null;
    };
    FileInfoComponent.prototype.getProgress = function (pId, filename) {
        var _this = this;
        var dateformat = require("dateformat");
        this.loggerService.getLogs(pId, filename, null).subscribe(function (l) {
            _this.file.statusText = "";
            l.forEach(function (e) {
                _this.file.statusText += "- " + dateformat(Date.parse(e.timestamp), "yyyy-mm-dd HH:MM:ss.l") + ": " +
                    ((e.langpair) ? "[" + e.langpair + "] " : "") +
                    (_this.statusMap.has(e.action.toLowerCase()) ? _this.statusMap.get(e.action.toLowerCase()) : e.action) + " \n";
                if (!_this.file.statusReady)
                    _this.file.statusReady = (e.action.toLowerCase().indexOf("conv2xliff") >= 0);
            });
        });
    };
    FileInfoComponent.prototype.getStatusImageDisplay = function () {
        return this.file.statusReady ? "inline-block" : "none";
    };
    FileInfoComponent.prototype.getStatusTooltip = function () {
        return this.file.statusText;
    };
    FileInfoComponent.prototype.ngOnDestroy = function () {
        this.alive = false; // switches the IntervalObservable off
    };
    FileInfoComponent.prototype.download = function () {
        var _this = this;
        this.fileService.downloadSource(this.pId, this.file.name).subscribe(function (n) {
            FileSaver.saveAs(n, _this.file.name, true);
        });
    };
    FileInfoComponent.prototype.updateFileLang = function ($event, store) {
        var _this = this;
        this.file.sourcelang = $event;
        this.languageUpdate.emit(this.file.sourcelang);
        if (store) {
            this.projectService
                .setFileLanguage(this.pId, this.file.name, $event)
                .subscribe(function (v) {
                if (environment.isDebug) {
                    _this.snackbar.open("Language updated", "", {
                        duration: 2000
                    });
                }
            }, function (error) { return console.error(error); });
        }
    };
    FileInfoComponent.prototype.copyDetectedToSource = function () {
        if (this.file.detectedsourcelang != this.file.sourcelang)
            this.updateFileLang(this.file.detectedsourcelang, true);
        //else do nothing
    };
    FileInfoComponent.prototype.isFileUploading = function () {
        return this.uploadPercent >= 0 && this.uploadPercent < 101;
    };
    FileInfoComponent.prototype.removeFile = function () {
        this.remover.emit(this.file);
    };
    FileInfoComponent.prototype.showDetailPricing = function () {
        // this.pricingService.getDetailedFilePrice(this.pId, this.file.name).subscribe()
        console.log("To implement");
    };
    FileInfoComponent.prototype.openFileMetaData = function () {
        var _this = this;
        var dialogRef = this.dialog.open(FileMetaDataDialog, {
            panelClass: 'simpleDialog',
            width: "550px",
            data: { file: this.file, projectId: this.pId }
        });
        dialogRef.afterClosed().subscribe(function (a) {
            if (a == "success") {
                _this.file.wordcount = dialogRef.componentInstance.words;
            }
        });
    };
    return FileInfoComponent;
}());
export { FileInfoComponent };
