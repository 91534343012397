import { HttpClient } from "@angular/common/http";
import { getBaseUrl } from '../util/httpclient';
var ConverterService = /** @class */ (function () {
    function ConverterService(httpClient) {
        this.httpClient = httpClient;
        this.CONVERTER_API = getBaseUrl() + "/api/v1/conversion";
    }
    ConverterService.prototype.getConvertableFileFormats = function () {
        return this.httpClient.get(this.CONVERTER_API + "/filetypes");
    };
    return ConverterService;
}());
export { ConverterService };
