import { EventEmitter, OnChanges, SimpleChanges } from "@angular/core";
import { ProjectService } from "../../service/project.service";
import { Project } from "../../dto/project";
import { UserService } from '../../service/user.service';
var ProjectInfoCreateComponent = /** @class */ (function () {
    function ProjectInfoCreateComponent(projectService, userService) {
        this.projectService = projectService;
        this.userService = userService;
        this.proposedDueDate = undefined;
        this.mtValid = false;
        this.defaultShares = undefined;
        this.onAllowMtChanged = new EventEmitter();
        this.onSaveSharesChanged = new EventEmitter();
        this.eventEmitter = new EventEmitter();
        this.isDueDateBeforeProposal = false;
        this.minDueDate = new Date();
        this.allowMT = true;
        this.saveSharesAsDefault = false;
        this.defaultSharesChanged = false;
    }
    ProjectInfoCreateComponent.prototype.ngOnInit = function () {
        this.minDueDate = new Date();
        this.minDueDate.setUTCHours(0, 0, 0, 0);
        if (this.project.duedate != undefined) {
            this.duedate = this.project.duedate;
        }
        else {
            this.duedate = this.proposedDueDate;
        }
        if (this.project['mtEnabled'] != undefined)
            this.allowMT = this.project['mtEnabled'];
        else
            this.allowMT = this.mtValid;
        // Immediately indicate the initial value of the 'allowMT' flag to the parent page
        this.onAllowMtChanged.emit(this.allowMT);
        if (this.project.shares === undefined)
            this.projectShares = (this.defaultShares !== undefined ? this.defaultShares : []);
        else
            this.projectShares = this.project.shares;
        this.defaultSharesChanged = this.checkDefaultSharesChanged();
    };
    ProjectInfoCreateComponent.prototype.ngOnChanges = function (changes) {
        if (changes.hasOwnProperty("proposedDueDate")) {
            if (this.project.duedate === undefined) {
                this.duedate = this.proposedDueDate;
            }
            this.checkDueDate();
        }
        else if (changes.hasOwnProperty("defaultShares")) {
            this.defaultShares = changes.defaultShares.currentValue;
            if (this.project.shares === undefined && this.defaultShares !== undefined)
                this.projectShares = this.defaultShares;
            this.defaultSharesChanged = this.checkDefaultSharesChanged();
        }
        else if (changes.hasOwnProperty("mtValid")) {
            this.setMtValid(changes.mtValid.currentValue);
        }
    };
    ProjectInfoCreateComponent.prototype.storeProjectInfo = function () {
        this.setTitle();
        this.setProjectDueDate();
        this.saveCurrentProjectShares();
        this.eventEmitter.emit("STORE");
    };
    ProjectInfoCreateComponent.prototype.setTitle = function () {
        this.projectService.setTitle(this.project).subscribe();
    };
    ProjectInfoCreateComponent.prototype.checkDueDate = function () {
        var compareDate = new Date(this.proposedDueDate);
        //Some extra margin to avoid the warning for popping up because of seconds difference
        compareDate.setUTCMinutes(compareDate.getUTCMinutes() - 5);
        this.isDueDateBeforeProposal = (this.project.duedate != undefined && this.project.duedate <= compareDate);
    };
    ProjectInfoCreateComponent.prototype.setProjectDueDateToProposed = function () {
        this.duedate = new Date(this.proposedDueDate);
        this.setProjectDueDate();
    };
    ProjectInfoCreateComponent.prototype.setProjectDueDate = function () {
        this.project.duedate = this.duedate;
        this.checkDueDate();
        if (this.project.duedate == undefined)
            this.project.duedate = new Date(this.proposedDueDate);
        this.projectService.setDueDate(this.project).subscribe();
    };
    ProjectInfoCreateComponent.prototype.allowMTChanged = function () {
        var _this = this;
        this.projectService.setMtEnabledForProject(this.project.id, this.allowMT).subscribe(function (v) {
            _this.onAllowMtChanged.emit(_this.allowMT);
        });
    };
    ProjectInfoCreateComponent.prototype.setMtValid = function (mtValid) {
        this.mtValid = mtValid;
        // If no MT value set on project, then use the validity as default value for the 'mt allowed" flag (if it is
        // different from the current value, at least)
        if (this.project['mtEnabled'] === undefined && this.mtValid !== this.allowMT) {
            this.allowMT = this.mtValid;
            this.onAllowMtChanged.emit(this.allowMT);
        }
    };
    ProjectInfoCreateComponent.prototype.saveProjectShares = function (selectedColleagues) {
        this.projectShares = selectedColleagues;
        this.defaultSharesChanged = this.checkDefaultSharesChanged();
        this.saveCurrentProjectShares();
    };
    ProjectInfoCreateComponent.prototype.saveCurrentProjectShares = function () {
        this.projectService.setShares(this.project.id, this.projectShares).subscribe(function (v) {
        }, function (error) { return console.error("Error saving project shares", error); });
    };
    ProjectInfoCreateComponent.prototype.saveSharesChanged = function () {
        this.onSaveSharesChanged.emit(this.saveSharesAsDefault);
    };
    ProjectInfoCreateComponent.prototype.checkDefaultSharesChanged = function () {
        var _this = this;
        // Check whether the user's default shares are different from the project's shares
        // If so, we can ask them whether they want to save this current selection as their default
        if (this.defaultShares === undefined || this.defaultShares === null ||
            this.defaultShares.length === 0) {
            return !(this.projectShares === undefined || this.projectShares === null || this.projectShares.length === 0);
        }
        else if (this.projectShares === undefined || this.projectShares === null ||
            this.projectShares.length === 0) {
            return true;
        }
        else {
            if (this.defaultShares.length === this.projectShares.length) {
                // Check whether each default occurs in the project shares as well
                this.defaultShares.forEach(function (ds) {
                    var found = false;
                    _this.projectShares.forEach(function (ps) {
                        if (ps.id === ds.id)
                            found = true;
                    });
                    if (!found)
                        return true;
                });
                return false;
            }
            else
                return true;
        }
    };
    return ProjectInfoCreateComponent;
}());
export { ProjectInfoCreateComponent };
