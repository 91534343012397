import { OnInit } from "@angular/core";
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { ProjectService } from "../../service/project.service";
import { Project } from "../../dto/project";
import { LanguageService } from "../../service/language.service";
import { UserService } from '../../service/user.service';
import { getUserName } from "../../util/jobutil";
var ProjectInfoComponent = /** @class */ (function () {
    function ProjectInfoComponent(projectService, languageService, userService) {
        this.projectService = projectService;
        this.languageService = languageService;
        this.userService = userService;
        this.sourcelang = undefined;
        this.targetlangs = undefined;
        this.editDueDate = false;
        this.minDueDate = new Date();
    }
    ProjectInfoComponent.prototype.ngOnInit = function () {
        var _this = this;
        if (this.project != null) {
            this.initTitleFieldUpdate();
            if (this.project.langpairs != null && this.project.langpairs.length > 0) {
                this.sourcelang = this.project.langpairs[0].source;
                Observable.from(this.project.langpairs)
                    .pipe(map(function (lp) { return lp.target; }))
                    .concatMap(function (target) { return _this.languageService.codeToDescription(target); })
                    .scan(function (t1, t2) { return t1 + ", " + t2; })
                    .subscribe(function (targetLangs) { return _this.targetlangs = targetLangs; });
            }
        }
    };
    ProjectInfoComponent.prototype.titleChange = function ($event) {
        this.titleChanges.next($event);
    };
    ProjectInfoComponent.prototype.initTitleFieldUpdate = function () {
        var _this = this;
        this.titleChanges = new Subject();
        // Debounce so it only updates every second instead of every keystroke
        this.titleChanges.asObservable().debounceTime(1000).subscribe(function (v) {
            return _this.projectService.setTitle(_this.project).subscribe();
        });
    };
    ProjectInfoComponent.prototype.saveShares = function (selectedColleagues) {
        this.project.shares = selectedColleagues;
        this.projectService.setShares(this.project.id, this.project.shares).subscribe();
    };
    ProjectInfoComponent.prototype.toggleEditDueDate = function () {
        this.editDueDate = !this.editDueDate;
        console.debug(this.editDueDate);
    };
    ProjectInfoComponent.prototype.setProjectDueDate = function () {
        this.projectService.setDueDate(this.project).subscribe();
    };
    ProjectInfoComponent.prototype.getRequesterName = function () {
        return getUserName(this.project.requestedby);
    };
    return ProjectInfoComponent;
}());
export { ProjectInfoComponent };
