import { EventEmitter } from "@angular/core";
var LangSelectComponent = /** @class */ (function () {
    function LangSelectComponent() {
        this.selectedLangs = [];
        this.single = false;
        this.dialect = true;
        this.eventEmitter = new EventEmitter();
        this.langDialogOpen = false;
    }
    LangSelectComponent.prototype.ngOnInit = function () {
        if (this.selectedLangs == undefined)
            this.selectedLangs = [];
    };
    LangSelectComponent.prototype.toggleLanguageChooser = function () {
        this.langDialogOpen = !this.langDialogOpen;
    };
    LangSelectComponent.prototype.openLanguageChooser = function () {
        this.langDialogOpen = true;
    };
    LangSelectComponent.prototype.closeLanguageChooser = function () {
        this.langDialogOpen = false;
    };
    LangSelectComponent.prototype.removeLanguage = function (language) {
        this.selectedLangs.splice(this.selectedLangs.indexOf(language), 1);
        this.eventEmitter.emit({ language: language, type: 'remove' });
    };
    LangSelectComponent.prototype.onLanguageUpdate = function ($event) {
        this.eventEmitter.emit($event);
    };
    LangSelectComponent.prototype.doPulse = function () {
        return this.selectedLangs === undefined || this.selectedLangs.length === 0;
    };
    return LangSelectComponent;
}());
export { LangSelectComponent };
