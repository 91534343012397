import { OnInit } from "@angular/core";
import { ProjectService } from "../../service/project.service";
import { Project } from "../../dto/project";
import { FileService } from "../../service/file.service";
var TargetFileListComponent = /** @class */ (function () {
    function TargetFileListComponent(projectService, fileService) {
        this.projectService = projectService;
        this.fileService = fileService;
        this.isTileView = true;
    }
    TargetFileListComponent.prototype.ngOnInit = function () {
    };
    TargetFileListComponent.prototype.getLangPairs = function () {
        return this.project.langpairs;
    };
    TargetFileListComponent.prototype.toggleView = function () {
        this.isTileView = !this.isTileView;
    };
    TargetFileListComponent.prototype.setTileView = function (tileView) {
        this.isTileView = tileView;
    };
    return TargetFileListComponent;
}());
export { TargetFileListComponent };
