import { filter, map, publish } from 'rxjs/operators';
import { Observable } from "rxjs";
import "rxjs";
import { HttpClient } from "@angular/common/http";
import { IntervalObservable } from "rxjs/observable/IntervalObservable";
import { getBaseSocketUrl, getBaseUrl, normalizeFileName } from "../util/httpclient";
var ProjectService = /** @class */ (function () {
    function ProjectService(http) {
        this.http = http;
        this.PROJECT_API = getBaseUrl() + "/api/v1/projects";
        this.PROJECT_SOCKET_URL = getBaseSocketUrl() + "/projects/feed";
        console.debug("ProjectService initializing => connects to " + this.PROJECT_API);
    }
    ProjectService.prototype.initProjectFeed = function () {
        var _this = this;
        this.ws = Observable.webSocket(this.PROJECT_SOCKET_URL);
        //Keep the socket alive (every 4 minutes, server timeout is set to 5 minutes)
        IntervalObservable.create(30000).subscribe(function (o) {
            return _this.ws.next({ 'action': 'keepalive' });
        });
        this.messages = this.makeHot(this.ws).pipe(filter(function (m) { return m != null; }));
        this.messages.subscribe(function (msg) {
        }, 
        /*msg => console.log("> Received " + JSON.stringify(msg) + " @" + this.PROJECT_SOCKET_URL),*/
        function (error) { return console.error("Error receiving project messages", error); });
    };
    //For more info see analyses.service.ts
    ProjectService.prototype.makeHot = function (cold) {
        var obs = cold.pipe(publish());
        obs.connect();
        return obs;
    };
    ProjectService.prototype.enrollForProject = function (pId) {
        return this.enroll(pId)
            .map(function (msg) { return msg.data; });
    };
    ProjectService.prototype.enrollForProjectAndFile = function (pId, fileName) {
        return this.enrollForProject(pId).pipe(filter(function (data) {
            return data.filename === fileName;
        }));
    };
    ProjectService.prototype.enroll = function (pId) {
        if (!this.ws || this.ws.closed)
            this.initProjectFeed();
        //To enroll you have to send a message to the socket with the action "enroll" and to which objects you want to enroll
        this.ws.next({ 'action': 'enroll', 'pid': pId });
        //The JSON structure of the message normally has the objects parameters to identify and a "data" object which contains the actual information
        //E.g. {"pid":"10", "data":{"action":"PDF", "langname":"damocles.docx"}}
        return this.messages.pipe(filter(function (msg) {
            // console.log("PROJECT MSG RECEIVED", msg);
            return msg.pid == pId;
        }));
    };
    ProjectService.prototype.getQuickAccessProjects = function (limit, min) {
        return this.http.get(this.PROJECT_API + "/quickaccess?limit=" + limit + "&min=" + min);
    };
    ProjectService.prototype.getMyProjects = function () {
        return this.http.get(this.PROJECT_API);
    };
    ProjectService.prototype.getMyProjectsWithStatus = function (statuses) {
        if (statuses != null && statuses.length > 0) {
            var url = this.PROJECT_API + "?" + statuses.map(function (s) { return "status=" + encodeURIComponent(s); }).join("&");
            return this.http.get(url);
        }
        else
            return this.getMyProjects();
    };
    ProjectService.prototype.getMyUnpaidProjects = function (companyId) {
        return this.http.get(this.PROJECT_API + "/find/unpaid/" + companyId);
    };
    ProjectService.prototype.getProject = function (id) {
        return this.http.get(this.PROJECT_API + "/" + id);
    };
    ProjectService.prototype.createProject = function (p, ip) {
        var qryParams = (ip != null ? "?uip=" + ip : "");
        return this.http.post(this.PROJECT_API + "/insert" + qryParams, JSON.stringify(p), { responseType: 'json' });
    };
    ProjectService.prototype.initProject = function (p, ip) {
        var qryParams = (ip != null ? "?uip=" + ip : "");
        return this.http.post(this.PROJECT_API + "/init" + qryParams, JSON.stringify(p), { responseType: 'json' });
    };
    ProjectService.prototype.draftProject = function (p, ip) {
        var qryParams = (ip != null ? "?uip=" + ip : "");
        return this.http.post(this.PROJECT_API + "/draft/" + p + qryParams, JSON.stringify(p), { responseType: 'json' });
    };
    ProjectService.prototype.cancelProject = function (p) {
        return this.http.post(this.PROJECT_API + "/cancel/" + p.id, "", { responseType: 'text' });
    };
    ProjectService.prototype.startProject = function (p, user, pricingElement, saveSharesAsDefault, ip) {
        var qryParams = (ip != null ? "?uip=" + ip : "");
        // Note that the price sent along here will be the 'totalprice' stored on the project
        var body = "{\"project\":" + JSON.stringify(p) +
            ", \"requestedby\":" + JSON.stringify(user) +
            ", \"totalprice\":" + JSON.stringify(pricingElement.totalIncVat().toFixed(2)) +
            ", \"pricingElement\":" + JSON.stringify(pricingElement) +
            ", \"saveDefaultShares\":" + JSON.stringify(saveSharesAsDefault) + "}";
        return this.http.post(this.PROJECT_API + "/start/" + p.id + qryParams, body, { responseType: 'json' });
    };
    ProjectService.prototype.addSourceFileData = function (file, pId) {
        return this.http.post(this.PROJECT_API + "/add/sourcefile/" + pId, normalizeFileName(file), { responseType: 'json' });
    };
    ProjectService.prototype.addSourceFilesData = function (file, pId) {
        var fileArray = [];
        file.forEach(function (fn) {
            fileArray.push(normalizeFileName(fn));
        });
        return this.http.post(this.PROJECT_API + "/add/sourcefiles/" + pId, JSON.stringify(fileArray), { responseType: 'json' });
    };
    ProjectService.prototype.addDocFileData = function (file, source4pdf, pId) {
        var body = "{" +
            "\"file\": " + JSON.stringify(normalizeFileName(file)) +
            (source4pdf != null ? ", \"source4pdf\": " + JSON.stringify(source4pdf) : "") +
            "}";
        return this.http.post(this.PROJECT_API + "/add/docfile/" + pId, body, { responseType: 'json' });
    };
    ProjectService.prototype.removeSourceFile = function (fileName, pId) {
        return this.http.post(this.PROJECT_API + "/remove/sourcefile/" + pId + "/" + encodeURIComponent(fileName), "", { responseType: "text" });
    };
    ProjectService.prototype.removeDocFile = function (fileName, pId) {
        return this.http.post(this.PROJECT_API + "/remove/docfile/" + pId + "/" + encodeURIComponent(fileName), "", { responseType: "text" });
    };
    ProjectService.prototype.setService = function (project) {
        return this.http.post(this.PROJECT_API + '/setservice/' + project.id, project.service, { responseType: 'text' });
    };
    ProjectService.prototype.addLangPairData = function (serviceParam, pId) {
        return this.http.post(this.PROJECT_API + "/add/serviceparam/" + pId, JSON.stringify(serviceParam), { responseType: 'json' });
    };
    ProjectService.prototype.removeLangPair = function (pId, languagePair) {
        return this.http.post(this.PROJECT_API + "/remove/langpair/" + pId + "/" + encodeURIComponent(languagePair), "", { responseType: "text" });
    };
    ProjectService.prototype.getFiles = function (p, type) {
        if (p && p.files) {
            return p.files.filter(function (v) {
                return type != null ? v.type === type : true;
            });
        }
        return [];
    };
    ProjectService.prototype.getSourceLanguage = function (project) {
        if (project != null) {
            if (project.langpairs != null && project.langpairs.length > 0) {
                return project.langpairs[0].source;
            }
            if (project.files != null && project.files.length > 0) {
                var sourceLang_1;
                project.files
                    .filter(function (f) { return f.sourcelang != null; })
                    .forEach(function (f) {
                    sourceLang_1 = f.sourcelang;
                });
                return sourceLang_1;
            }
        }
        return undefined;
    };
    ProjectService.prototype.getSourceLanguageObs = function (project) {
        return Observable.of(this.getSourceLanguage(project));
    };
    ProjectService.prototype.getTargetLanguages = function (project) {
        var targets = [];
        if (project && project.langpairs) {
            project.langpairs.map(function (lp) { return lp.target; }).filter(function (t) { return targets.indexOf(t) === -1; }).forEach(function (t) {
                if (targets.indexOf(t) === -1)
                    targets.push(t);
            });
        }
        return targets;
    };
    ProjectService.prototype.getTargetLanguagesObs = function (project) {
        return Observable.of(this.getTargetLanguages(project));
    };
    ProjectService.prototype.getFileNames = function (project, type) {
        return this.getFiles(project, type).map(function (f) { return f.name; });
    };
    ProjectService.prototype.getProjectBaseInfo = function (projectId) {
        return this.http.get(this.PROJECT_API + "/baseinfo/" + projectId);
    };
    ProjectService.prototype.setTitle = function (project) {
        return this.http.post(this.PROJECT_API + "/settitle/" + project.id, project.title, { responseType: 'text' });
    };
    ProjectService.prototype.setDueDate = function (project) {
        return this.http.post(this.PROJECT_API + "/setduedate/" + project.id, project.duedate.toISOString(), { responseType: 'text' });
    };
    ProjectService.prototype.setShares = function (pId, projectShares) {
        return this.http.post(this.PROJECT_API + '/setshares/' + pId, JSON.stringify(projectShares), { responseType: 'text' });
    };
    ProjectService.prototype.setFileLanguage = function (pId, fileName, lang) {
        return this.http.post(this.PROJECT_API + "/files/updatelang/" + pId + "/" + encodeURIComponent(fileName), lang, { responseType: 'text' });
    };
    ProjectService.prototype.setFileWordCount = function (pId, fileName, wc) {
        return this.http.post(this.PROJECT_API + "/files/updatewordcount/" + pId + "/" + encodeURIComponent(fileName) + "/" + wc, "", { responseType: 'text' });
    };
    ProjectService.prototype.setFileProcessType = function (pId, filename, processType) {
        return this.http.post(this.PROJECT_API + '/files/updateprocesstype/' + pId + '/' + encodeURIComponent(filename), processType, { responseType: 'text' });
    };
    ProjectService.prototype.setAltFileTypeAsked = function (pId, filename) {
        return this.http.post(this.PROJECT_API + '/files/altfiletype/asked/' + pId + '/' + encodeURIComponent(filename), true, { responseType: 'text' });
    };
    ProjectService.prototype.setRequestedBy = function (project, currentUser) {
        return this.http.post(this.PROJECT_API + "/setrequestedby/" + project.id, { userId: currentUser.id }, { responseType: 'text' });
    };
    ProjectService.prototype.getFileProgress = function (pId, fileName) {
        return this.http.get(this.PROJECT_API + "/files/progress/" + pId + "/" + encodeURIComponent(fileName), { responseType: 'json' });
    };
    ProjectService.prototype.setMtEnabledForProject = function (pId, enabled) {
        return this.http.post(this.PROJECT_API + "/updatemtenabled/" + pId, enabled, { responseType: 'text' });
    };
    ProjectService.prototype.setPaymentMethod = function (project, value) {
        return this.http.post(this.PROJECT_API + '/updatepaymentmethod/' + project.id, value, { responseType: 'text' });
    };
    ProjectService.prototype.calculateProposedDueDate = function (projectId) {
        return this.http.get(this.PROJECT_API + "/calc/duration/" + projectId, { responseType: 'json' }).pipe(map(function (d) { return d; }), map(function (d) { return d + 24; }));
    };
    ProjectService.prototype.getAllProjects = function (status) {
        var qryParams = (status != null ? "?status=" + encodeURIComponent(status) : "");
        return this.http.get(this.PROJECT_API + "/admin/find" + qryParams);
    };
    ProjectService.prototype.getProjectsForCleanup = function (olderThanMonths, status) {
        var qryParams = "?otmnths=" + encodeURIComponent(olderThanMonths);
        qryParams += "&status=" + encodeURIComponent(status);
        return this.http.get(this.PROJECT_API + "/admin/cleanup" + qryParams);
    };
    ProjectService.prototype.cleanupProjects = function (olderThanMonths, status) {
        var qryParams = "?otmnths=" + encodeURIComponent(olderThanMonths);
        qryParams += "&status=" + encodeURIComponent(status);
        return this.http.delete(this.PROJECT_API + "/admin/cleanup" + qryParams);
    };
    return ProjectService;
}());
export { ProjectService };
