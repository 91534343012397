import { interval as observableInterval } from 'rxjs';
import { map } from 'rxjs/operators';
import { OnInit } from '@angular/core';
var CountdownComponent = /** @class */ (function () {
    function CountdownComponent() {
    }
    CountdownComponent.prototype.ngOnInit = function () {
        var _this = this;
        this._diff = this.getTimeDiff();
        this.calcTimers(this._diff);
        // Re-calculate every 30 seconds
        observableInterval(30000)
            .pipe(map(function () {
            return _this.getTimeDiff();
        }))
            .subscribe(function (diff) {
            _this._diff = diff;
            _this.calcTimers(_this._diff);
        });
    };
    CountdownComponent.prototype.getTimeDiff = function () {
        return Date.parse(this.enddate) - Date.parse(new Date().toString());
    };
    CountdownComponent.prototype.calcTimers = function (diff) {
        this._overdue = diff < 0;
        if (this._overdue)
            diff = diff * -1;
        if (!this._overdue || this.showoverdue) {
            this._days = this.getDays(diff);
            this._hours = this.getHours(diff);
            this._minutes = this.getMinutes(diff);
            this._seconds = this.getSeconds(diff);
        }
        else {
            this._days = 0;
            this._hours = 0;
            this._minutes = 0;
            this._seconds = 0;
        }
    };
    CountdownComponent.prototype.getDays = function (t) {
        return Math.floor(t / (1000 * 60 * 60 * 24));
    };
    CountdownComponent.prototype.getHours = function (t) {
        return Math.floor((t / (1000 * 60 * 60)) % 24);
    };
    CountdownComponent.prototype.getMinutes = function (t) {
        return Math.floor((t / 1000 / 60) % 60);
    };
    CountdownComponent.prototype.getSeconds = function (t) {
        return Math.floor((t / 1000) % 60);
    };
    return CountdownComponent;
}());
export { CountdownComponent };
