import { EventEmitter } from "@angular/core";
import { TaskService } from "../../service/task.service";
import { switchMap, tap } from "rxjs/operators";
import { PricingService } from "../../service/pricing.services";
import { Task } from "../../dto/job";
import { UserService } from "../../service/user.service";
import { isTranslationTask } from "../../util/jobutil";
import { Router } from '@angular/router';
var ProjectTaskComponent = /** @class */ (function () {
    function ProjectTaskComponent(userService, taskService, pricingService, router) {
        this.userService = userService;
        this.taskService = taskService;
        this.pricingService = pricingService;
        this.router = router;
        this.isQuote = false;
        this.taskChangeEmitter = new EventEmitter();
        this.state = undefined;
        this.user = undefined;
        this.mtCost = undefined;
        this.serviceFee = undefined;
    }
    ProjectTaskComponent.prototype.ngOnInit = function () {
        this.loadProfilePic();
        if (this.state == null) {
            this.initTaskState();
        }
        this.initFixedCosts();
    };
    ProjectTaskComponent.prototype.initTaskState = function () {
        if (this.task === undefined) {
            this.state = undefined;
            return;
        }
        if (this.task.completed !== undefined) {
            this.state = 'completed';
            return;
        }
        if (this.task.acceptdate !== undefined) {
            this.state = 'accepted';
            return;
        }
        if (this.task.rejectdate !== undefined) {
            this.state = 'rejected';
            return;
        }
        if (this.task.counterProposal !== undefined) {
            this.state = 'counterproposed';
            return;
        }
        if (this.task.senddate !== undefined) {
            this.state = 'send';
            return;
        }
    };
    ProjectTaskComponent.prototype.getPrice = function () {
        var price = 0;
        if (this.task.price != null) {
            price = this.task.price;
        }
        if (this.mtCost != null) {
            price = +price + +this.mtCost;
        }
        if (this.serviceFee != null) {
            price = +price + +this.serviceFee;
        }
        return price;
    };
    ProjectTaskComponent.prototype.getProposalPrice = function () {
        var price = this.getPrice();
        if (this.task.counterProposal.extraCost != null) {
            price = +price + +this.task.counterProposal.extraCost;
        }
        return price;
    };
    ProjectTaskComponent.prototype.loadProfilePic = function () {
        var _this = this;
        if (this.task !== undefined && this.task.vendorId !== undefined) {
            this.userService.getUser(this.task.vendorId)
                .subscribe(function (next) {
                _this.user = next;
            }, function (error) { return _this.user = undefined; });
        }
        else {
            this.user = undefined;
        }
    };
    ProjectTaskComponent.prototype.acceptCounterProposal = function () {
        var _this = this;
        this.taskService.acceptProposalTask(this.task)
            .pipe(switchMap(function (t) { return _this.taskService.getTask(_this.task.id); }), tap(function (t) { return _this.task = t; }), tap(function (t) { return _this.initTaskState(); }))
            .subscribe(function (t) {
            return _this.taskChangeEmitter.emit("accept");
        });
    };
    ProjectTaskComponent.prototype.removeTask = function () {
        this.taskChangeEmitter.emit('cancel');
    };
    ProjectTaskComponent.prototype.initFixedCosts = function () {
        var _this = this;
        if (isTranslationTask(this.task)) {
            var langpair = { source: this.task.sourcelanguage, target: this.task.targetlanguage };
            this.pricingService.getServiceFeeAndMTCost(this.task.projectId, langpair)
                .subscribe(function (c) {
                _this.mtCost = c['mtCost'];
                _this.serviceFee = c['serviceFee'];
            });
        }
    };
    ProjectTaskComponent.prototype.rateTranslator = function () {
        this.router.navigate(['/newreview', this.task.id], { queryParams: { callback: this.router.url } });
    };
    return ProjectTaskComponent;
}());
export { ProjectTaskComponent };
