import { PipeTransform } from '@angular/core';
var TextcutPipe = /** @class */ (function () {
    function TextcutPipe() {
    }
    TextcutPipe.prototype.transform = function (value, length) {
        if (length == undefined)
            return value;
        var l = parseInt(length);
        if (value.length <= l)
            return value;
        else {
            return value.substr(0, l) + "…";
        }
    };
    return TextcutPipe;
}());
export { TextcutPipe };
