import { defaultIfEmpty } from 'rxjs/operators';
import { OnInit, NgZone } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { UserService } from "./service/user.service";
import { ActivatedRoute, Router } from "@angular/router";
import { LogInService } from "./service/login.service";
import { UserInviteDialog } from "./usercomponent/user.invite.dialog";
import { environment } from "../environments/environment";
import { SimpleDialog } from "./simple-dialog.component";
import { Project } from './dto/project';
import { ProjectService } from './service/project.service';
var AppComponent = /** @class */ (function () {
    function AppComponent(dialog, _userService, projectService, router, route, loginService, ngZone) {
        this.dialog = dialog;
        this._userService = _userService;
        this.projectService = projectService;
        this.router = router;
        this.route = route;
        this.loginService = loginService;
        this.ngZone = ngZone;
        this.title = 'lilo';
        this.ieWarnDismissed = false;
        this.isOrigDebug = false;
    }
    AppComponent.prototype.ngOnInit = function () {
        var _this = this;
        // Show a warning if the user is using Internet Explorer
        if (!this.ieWarnDismissed && this.isInternetExplorer())
            this.ieWarnDialog();
        this._userService.signInFromLocalStorage().pipe(defaultIfEmpty(undefined)).subscribe(function (u) {
            if (u == undefined) {
                Promise.resolve().then(function () {
                    _this.checkForPopupRequest();
                });
            }
            else
                _this.setUser(u);
        }, function (error) {
            console.debug("An error occurred during sign-in from local storage: " + error);
        });
        //Listen to the login emitter and update the authenticated field and user id after a join/signin/logout
        this._userService.loginEmitter.subscribe(function (n) {
            if (n === true) {
                _this.setUser(_this._userService.getCurrentUser());
            }
            else
                _this.setUser(null);
        });
        this.initDebug();
    };
    AppComponent.prototype.onActivate = function (event) {
        window.scroll(0, 0);
    };
    AppComponent.prototype.setUser = function (user) {
        if (user != null) {
            this.isUserAuthenticated = true;
            this.user = user;
        }
        else {
            this.isUserAuthenticated = false;
            this.user = null;
        }
    };
    AppComponent.prototype.checkForPopupRequest = function () {
        var _this = this;
        this.route.queryParams
            .forEach(function (params) {
            if (params.hasOwnProperty('join'))
                _this.joinPopup();
            else if (params.hasOwnProperty('signin'))
                _this.signInPopup();
        });
    };
    AppComponent.prototype.isInternetExplorer = function () {
        console.log("User-Agent: " + window.navigator.userAgent);
        // Internet Explorer 6-11
        return /msie\s|trident\//i.test(window.navigator.userAgent);
    };
    AppComponent.prototype.ieWarnDialog = function () {
        var _this = this;
        var dialogRef = this.dialog.open(SimpleDialog, {
            width: '400px', disableClose: true, ariaLabel: "Ack, Internet Explorer!", role: "alertdialog",
            panelClass: 'simpleDialog',
            data: {
                dialogHtml: "<p>Hey there, looks like you are using Internet Explorer?! <br/>" +
                    "<br/>" +
                    "Sadly this browser is rather outdated and no longer supported. " +
                    "We strongly advise switching to a modern browser, such as <a href='https://www.mozilla.org/en-US/firefox/' target='_blank'>Firefox</a>, " +
                    "<a href='https://www.google.com/chrome/' target='_blank'>Chrome</a>, " +
                    "<a href='https://www.opera.com/' target='_blank'>Opera</a> or even Edge (which comes pre-installed on recent Windows machines).<br/>" +
                    "<br/>" +
                    "You <b>can</b> continue with your current browser, but know that <b>some functions may be slow or not work</b>.</p>",
                dialogButtonName: "Gotcha"
            }
        });
        dialogRef.afterClosed().subscribe(function (v) { return _this.ieWarnDismissed = true; });
    };
    AppComponent.prototype.signInPopup = function () {
        var dialogRef = this.openSignInPopup();
    };
    AppComponent.prototype.joinPopup = function () {
        var dialogRef = this.openJoinDialog('');
    };
    AppComponent.prototype.openJoinDialog = function (callback) {
        return this.loginService.openJoinDialog(callback);
    };
    AppComponent.prototype.openSignInPopup = function () {
        return this.loginService.openSignInPopup('');
    };
    AppComponent.prototype.openInviteDialog = function () {
        return this.dialog.open(UserInviteDialog, { width: '50%' });
    };
    AppComponent.prototype.newProject = function () {
        var _this = this;
        if (this.router.url === '/newproject' || this.router.url === '/') {
            var p = new Project();
            this.projectService.initProject(p, this._userService.getRemoteUserIp()).subscribe(function (result) {
                var projectId = result.id;
                _this.router.navigate(['/pdetail', projectId]);
            }, function (err) {
                console.error("Error initializing project", err);
            });
        }
        else {
            this.router.navigate(['/newproject']);
        }
    };
    AppComponent.prototype.isTranslator = function () {
        return this._userService.isCurrentUserTranslator;
    };
    AppComponent.prototype.isInternalTranslator = function () {
        return this._userService.isCurrentUserInternalTranslator;
    };
    AppComponent.prototype.isEnableRequest = function () {
        var user = this._userService.getCurrentUser();
        if (user && user.enableRequests != undefined) {
            return user.enableRequests;
        }
        else
            return false;
    };
    AppComponent.prototype.companyId = function () {
        var user = this._userService.getCurrentUser();
        if (user) {
            return user.companyId;
        }
        else
            return undefined;
    };
    AppComponent.prototype.isDebug = function () {
        return environment.isDebug;
    };
    AppComponent.prototype.logout = function () {
        var _this = this;
        this._userService.signOut().subscribe({
            next: function () {
                _this.user = null;
                _this.isUserAuthenticated = false;
                var dialogRef = _this.dialog.open(SimpleDialog, {
                    width: "400px",
                    panelClass: 'simpleDialog',
                    data: { dialogMessage: "You have successfully logged out." }
                });
                dialogRef.afterClosed().subscribe(function (r) {
                    return _this.router.navigateByUrl("");
                });
            },
            complete: function () {
                // Completed
            }
        });
    };
    AppComponent.prototype.navigate = function (commands) {
        var _this = this;
        this.ngZone.run(function () { return _this.router.navigate(commands); }).then();
    };
    // Debug handling
    AppComponent.prototype.initDebug = function () {
        if (this.isDebug()) {
            this.isOrigDebug = true;
            var isDebug = localStorage.getItem("isDebug");
            if (isDebug != null)
                environment.isDebug = JSON.parse(isDebug);
        }
    };
    AppComponent.prototype.toggleDebug = function () {
        environment.isDebug = !environment.isDebug;
        if (!environment.isDebug)
            localStorage.setItem("isDebug", JSON.stringify(false));
        else
            localStorage.removeItem("isDebug");
    };
    return AppComponent;
}());
export { AppComponent };
