import { ElementRef, Renderer2 } from "@angular/core";
import { FileService } from "../service/file.service";
import { EditorRow } from "./dto/transunit";
import { DomSanitizer } from '@angular/platform-browser';
var InlayoutComponent = /** @class */ (function () {
    function InlayoutComponent(fileService, sanitizer, renderer, elRef) {
        this.fileService = fileService;
        this.sanitizer = sanitizer;
        this.renderer = renderer;
        this.elRef = elRef;
    }
    InlayoutComponent.prototype.ngOnInit = function () {
        this.initSpannedHtml();
    };
    InlayoutComponent.prototype.handleSelection = function (prevElement, newElement) {
        if (prevElement != null) {
            var inLayoutElement = this.elRef.nativeElement.querySelector('.seg' + prevElement.id);
            if (inLayoutElement != null)
                this.renderer.setStyle(inLayoutElement, "border", "none");
            else
                console.warn("Project " + this.projectId + " -- file " + this.fileName + ": segment " + prevElement.id + " not found");
        }
        if (newElement != undefined) {
            var inLayoutElement = this.elRef.nativeElement.querySelector('.seg' + newElement.id);
            if (inLayoutElement != null) {
                this.renderer.setStyle(inLayoutElement, "border", "2px solid #4ebdcc");
                inLayoutElement.scrollIntoView({
                    behavior: 'auto',
                    block: 'center',
                    inline: 'center'
                });
            }
        }
    };
    InlayoutComponent.prototype.selectSpan = function (spanId) {
        // console.debug(spanId);
    };
    InlayoutComponent.prototype.initSpannedHtml = function () {
        var _this = this;
        this.spannedHtml = "Loading...";
        this.fileService.downloadSegHtml(this.projectId, this.fileName)
            .subscribe(function (v) {
            var reader = new FileReader();
            reader.onload = function (e) {
                var text = reader.result.toString();
                _this.spannedHtml = _this.sanitizer.bypassSecurityTrustHtml(text);
            };
            reader.readAsText(v);
        }, function (e) {
            _this.spannedHtml = "Layout is not available for this file";
        });
        //TODO add eventlisteners which emit a segmentId which would then be processed by the editor to select the value
    };
    Object.defineProperty(InlayoutComponent.prototype, "element", {
        get: function () {
            return this._element;
        },
        set: function (el) {
            this.handleSelection(this._element, el);
            this._element = el;
        },
        enumerable: true,
        configurable: true
    });
    return InlayoutComponent;
}());
export { InlayoutComponent };
