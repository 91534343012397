import { HttpClient } from "@angular/common/http";
import { getBaseUrl } from "../util/httpclient";
var DomainService = /** @class */ (function () {
    function DomainService(http) {
        var _this = this;
        this.http = http;
        this.DOMAIN_API = getBaseUrl() + "/api/v1/domains";
        this.getDomains().subscribe(function (m) { return _this.domainCache = m; });
    }
    DomainService.prototype.getDomains = function () {
        return this.http.get(this.DOMAIN_API);
    };
    return DomainService;
}());
export { DomainService };
