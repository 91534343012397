import { MatDialogRef } from "@angular/material/dialog";
var MultiLanguageSelectionComponent = /** @class */ (function () {
    function MultiLanguageSelectionComponent(dialogRef, languages) {
        this.dialogRef = dialogRef;
        this.languages = languages;
        this.selectedLanguages = [];
    }
    MultiLanguageSelectionComponent.prototype.add = function () {
        this.dialogRef.close(this.selectedLanguages);
    };
    return MultiLanguageSelectionComponent;
}());
export { MultiLanguageSelectionComponent };
