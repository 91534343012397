import { map } from 'rxjs/operators';
import { ChatService } from "../service/chat.service";
import { ChatComponent } from "./chat.component";
import { UserService } from "../service/user.service";
import { LanguagePipe } from "../util/language.pipe";
import { LanguageService } from "../service/language.service";
var MessageComponent = /** @class */ (function () {
    function MessageComponent(userService, chatService, languageService) {
        this.userService = userService;
        this.chatService = chatService;
        this.languageService = languageService;
        this.profilePicExists = false;
        this.isThread = false; //Indicator if it is the main message or a reply on another message
        this.isRead = true;
        this.reply = false;
        this.isSelf = false;
        this.langPipe = new LanguagePipe(languageService);
    }
    MessageComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.isRead = ChatComponent.isReadMessage(this.message, this.user.id);
        this.isSelf = this.user.id === this.message.user.id;
        if (!this.isSelf)
            this.setUserDesc();
        this.userService.getProfilePic(this.message.user.id)
            .subscribe(function (next) {
            _this.profilePic = next;
            _this.profilePicExists = true;
        }, function (error) { return _this.profilePicExists = false; });
    };
    MessageComponent.prototype.setUserDesc = function () {
        var _this = this;
        if (this.message.user != undefined && this.message.user.type != undefined) {
            if (this.message.user.type === "customer") {
                this.userDesc = "Customer";
            }
            else if (this.message.user.type === "translator") {
                if (this.message.user.task != undefined) {
                    var task_1 = this.message.user.task;
                    if (task_1.targetLanguage != undefined) {
                        this.langPipe.transform(task_1.targetLanguage).subscribe(function (l) {
                            if (task_1.activity === "Translation") {
                                _this.userDesc = "Translator for " + l;
                            }
                            else if (task_1.activity === "Revision") {
                                _this.userDesc = "Reviser for " + l;
                            }
                        });
                    }
                }
                if (this.userDesc == undefined)
                    this.userDesc = "Translator";
            }
        }
    };
    MessageComponent.prototype.activateReply = function () {
        this.reply = true;
    };
    MessageComponent.prototype.addReply = function (reply) {
        var _this = this;
        this.chatService.createReply(reply, this.message.id).pipe(map(function (res) {
            return res;
        }), map(function (res) { return res.data; }))
            .subscribe(function (msg) {
            if (_this.message.replies == undefined) {
                _this.message.replies = [];
            }
            _this.message.replies.push(msg);
        });
        this.reply = false;
    };
    return MessageComponent;
}());
export { MessageComponent };
