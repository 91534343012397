import { PipeTransform } from '@angular/core';
import { LanguageService } from "../service/language.service";
var LanguagePipe = /** @class */ (function () {
    function LanguagePipe(languageService) {
        this.languageService = languageService;
    }
    LanguagePipe.prototype.transform = function (value) {
        return this.languageService.codeToDescription(value);
    };
    return LanguagePipe;
}());
export { LanguagePipe };
