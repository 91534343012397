import { UserService } from "../service/user.service";
var InviteSettingComponent = /** @class */ (function () {
    function InviteSettingComponent(userService) {
        this.userService = userService;
    }
    InviteSettingComponent.prototype.ngOnInit = function () {
        var _this = this;
        //Fetch my invites
        this.userService.getInvites(this.user.id).subscribe(function (invites) { return _this.invites = invites; });
    };
    InviteSettingComponent.prototype.inviteState = function (invite) {
        if (invite.user != undefined)
            return "accepted";
        return "pending";
    };
    InviteSettingComponent.prototype.sendDate = function (invite) {
        if (invite.senddate != undefined) {
            return new Date(invite.senddate).toLocaleDateString();
        }
        return "";
    };
    return InviteSettingComponent;
}());
export { InviteSettingComponent };
