import { SimpleChanges } from "@angular/core";
import { TaskService } from "../../service/task.service";
import { UserService } from "../../service/user.service";
import { ReviewService } from "../../service/review.service";
import { getUserName } from "../../util/jobutil";
var ReviewItemComponent = /** @class */ (function () {
    function ReviewItemComponent(reviewService, taskService, userService) {
        this.reviewService = reviewService;
        this.taskService = taskService;
        this.userService = userService;
    }
    ReviewItemComponent.prototype.ngOnInit = function () {
        this.setReview();
    };
    ReviewItemComponent.prototype.ngOnChanges = function (changes) {
        if (changes.index) {
            this.index = changes.index.currentValue;
        }
        if (changes.review) {
            this.review = changes.review.currentValue;
            this.setReview();
        }
    };
    ReviewItemComponent.prototype.setReview = function () {
        var _this = this;
        if (this.review !== undefined) {
            if (this.review.writer != undefined)
                this.userService.getUser(this.review.writer.id).subscribe(function (u) {
                    _this.poster = u;
                });
            else {
                // Create a fake user if we have no data on who posted the review
                var u = Object();
                u.name = "Anonymous";
                u.pcolor = "#B5B5B5";
                this.poster = u;
            }
            this.calcStarCount();
        }
    };
    ReviewItemComponent.prototype.getPosterName = function () {
        return getUserName(this.poster);
    };
    ReviewItemComponent.prototype.calcStarCount = function () {
        var scoreCount = this.review.scores.length;
        var scoreTotal = 0;
        this.review.scores.forEach(function (s) { return scoreTotal += s.rate; });
        var scoreAvg = scoreTotal / (scoreCount > 0 ? scoreCount : 1);
        this.starCount = scoreAvg / 25 + 1;
    };
    return ReviewItemComponent;
}());
export { ReviewItemComponent };
