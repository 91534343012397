import { MatDialogRef } from "@angular/material/dialog";
import { ProjectService } from "../../service/project.service";
import { filterDocFiles, getFileExtension } from "../../util/jobutil";
import { MatSnackBar } from '@angular/material';
var DocumentationPDFDialog = /** @class */ (function () {
    function DocumentationPDFDialog(dialogRef, data, snackbar, projectService) {
        this.dialogRef = dialogRef;
        this.data = data;
        this.snackbar = snackbar;
        this.projectService = projectService;
        this.projectFiles = [];
        this.isRemoving = false;
    }
    DocumentationPDFDialog.prototype.ngOnInit = function () {
        var _this = this;
        if (this.data.projectId)
            this.projectId = this.data.projectId;
        if (this.data.srcFile)
            this.srcFile = this.data.srcFile;
        if (this.data.projectFiles)
            this.setProjectFiles(this.data.projectFiles);
        else
            this.projectService.getProject(this.projectId)
                .subscribe(function (p) {
                _this.setProjectFiles(p.files);
            });
    };
    DocumentationPDFDialog.prototype.setProjectFiles = function (files) {
        this.projectFiles = files;
        this.docPdf = this.getPdf4Source(this.srcFile.name);
    };
    DocumentationPDFDialog.prototype.selectFile = function (event) {
        var fileList = event.target.files;
        if (fileList.length > 0) {
            var file_1 = fileList[0];
            if (getFileExtension(file_1.name).toLowerCase() !== "pdf") {
                this.errorMsg = "Oops, file " + file_1.name + " does not appear to be a PDF? Please select another file.";
                return;
            }
            var docFiles = filterDocFiles(this.projectFiles);
            if (docFiles && docFiles.find(function (v) { return v.name === file_1.name; })) {
                this.errorMsg = "Sorry, you cannot add 2 files with the same name, and a file with name " + file_1.name + " already exists.";
                return;
            }
            this.errorMsg = null;
            this.docPdf = file_1;
        }
    };
    DocumentationPDFDialog.prototype.enableUpload = function () {
        return this.docPdf !== undefined && (this.docPdf instanceof File);
    };
    DocumentationPDFDialog.prototype.isDocPdfFile = function () {
        return this.docPdf !== undefined && (this.docPdf instanceof File);
    };
    DocumentationPDFDialog.prototype.upload = function () {
        this.dialogRef.close({
            docPdf: this.docPdf
        });
    };
    DocumentationPDFDialog.prototype.getPdf4Source = function (srcFileName) {
        if (this.projectFiles) {
            return filterDocFiles(this.projectFiles)
                .filter(function (f) {
                return f.docinfo != undefined && f.docinfo.source4pdf != undefined && f.docinfo.source4pdf === srcFileName;
            })[0];
        }
        else
            return null;
    };
    DocumentationPDFDialog.prototype.removeDocPdf = function () {
        var _this = this;
        if (this.docPdf != null && !this.isRemoving) {
            this.isRemoving = true;
            this.projectService.removeDocFile(this.docPdf.name, this.projectId)
                .subscribe(function (v) {
                console.debug("Project " + _this.projectId + ": removed documentation file '" + _this.docPdf.name + "'");
                // Remove the documentation file from the collection
                _this.projectFiles.filter(function (v) { return v.type === "DOC" && v.name === _this.docPdf.name; })
                    .forEach(function (f) {
                    var idx = _this.projectFiles.indexOf(f);
                    _this.projectFiles.splice(idx, 1);
                });
                _this.docPdf = null;
                _this.isRemoving = false;
            }, function (error) {
                console.error("Error removing documentation file " + _this.docPdf.name, error);
                _this.snackbar.open("Unable to remove documentation file " + _this.docPdf.name + ": " +
                    error.status + " " + error.statusText, "", {
                    duration: 2000
                });
                _this.isRemoving = false;
            }, function () { return _this.isRemoving = false; });
        }
    };
    return DocumentationPDFDialog;
}());
export { DocumentationPDFDialog };
