import { map, tap } from 'rxjs/operators';
import { OnInit } from "@angular/core";
import { Observable } from 'rxjs';
import { ProjectListComponent } from '../projectcomponent/project-list.component';
var SalesInvoiceComponent = /** @class */ (function () {
    function SalesInvoiceComponent() {
        this.projects = [];
        this.listCols = ProjectListComponent.invoiceCols;
    }
    SalesInvoiceComponent.prototype.ngOnInit = function () {
        var _this = this;
        Observable.from(this.invoice.projects)
            .pipe(map(function (p) { return p; }), tap(function (p) { return _this.projects.push(p); })).subscribe();
    };
    return SalesInvoiceComponent;
}());
export { SalesInvoiceComponent };
