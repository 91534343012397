import { filter, map, switchMap } from 'rxjs/operators';
import { ChangeDetectorRef } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { UserService } from "../service/user.service";
import { InvoiceService } from "../service/invoice.service";
import { TaskService } from "../service/task.service";
import { ProjectService } from "../service/project.service";
var InvoiceOverviewComponent = /** @class */ (function () {
    function InvoiceOverviewComponent(route, router, userService, invoiceService, projectService, taskService, cdRef) {
        this.route = route;
        this.router = router;
        this.userService = userService;
        this.invoiceService = invoiceService;
        this.projectService = projectService;
        this.taskService = taskService;
        this.cdRef = cdRef;
        this.userInput = null;
        this.unpaidProjects = [];
        this.openProjectAmount = 0.0;
    }
    InvoiceOverviewComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.salesRegisteredInvoices = [];
        if (this.userInput != null) {
            this.setUser(this.userInput);
        }
        this.route.params.pipe(filter(function (params) { return _this.userInput === null && params['id'] != null; }), switchMap(function (params) { return _this.userService.getUser(params['id']); })).subscribe(function (c) { return _this.setUser(c); });
    };
    InvoiceOverviewComponent.prototype.setUser = function (user) {
        var _this = this;
        this.user = user;
        if (user.companyId != undefined) {
            this.userService.findCompanyInfo(user.companyId).subscribe(function (c) { return _this.setCompany(c); });
        }
        this.invoiceService.findRegisteredUserSalesInvoices(this.user.id).pipe(map(function (invs) { return invs; }))
            .subscribe(function (invs) {
            invs.forEach(function (inv) { return _this.salesRegisteredInvoices.push(inv); });
            _this.cdRef.detectChanges();
        }, function (error) {
            console.error("Error getting sales invoices", error);
        });
        this.cdRef.detectChanges();
    };
    InvoiceOverviewComponent.prototype.setCompany = function (company) {
        var _this = this;
        this.company = company;
        if (this.userService.isCurrentUserTranslator) {
            this.invoiceService.findRegisteredPurchaseInvoices(this.company.id)
                .subscribe(function (invs) {
                _this.purchaseRegisteredInvoices = invs;
                _this.cdRef.detectChanges();
            }, function (error) { return console.error("Error getting purchase invoices", error); });
            this.taskService.getInvoiceableTasks().subscribe(function (tasks) {
                _this.invoiceableTaskCount = tasks.length;
                _this.cdRef.detectChanges();
            });
        }
        this.invoiceService.findRegisteredCompanySalesInvoices(this.company.id).pipe(map(function (invs) { return invs; }))
            .subscribe(function (invs) {
            invs.forEach(function (inv) { return _this.salesRegisteredInvoices.push(inv); });
            _this.cdRef.detectChanges();
        }, function (error) {
            console.error("Error getting company sales invoices", error);
        });
        this.projectService.getMyUnpaidProjects(this.company.id).subscribe(function (projects) {
            _this.unpaidProjects = projects;
            _this.openProjectAmount = 0;
            if (projects != null && projects.length > 0) {
                projects.forEach(function (p) {
                    if (p.totalprice) {
                        _this.openProjectAmount += p.totalprice;
                    }
                });
                _this.cdRef.detectChanges();
            }
        }, function (error) {
            console.error("Error getting unpaid projects", error);
        });
    };
    InvoiceOverviewComponent.prototype.isTranslator = function () {
        return this.userService.isCurrentUserTranslator;
    };
    InvoiceOverviewComponent.prototype.openInvoice = function (inv) {
        this.router.navigate(['/invoice', inv.id]);
    };
    InvoiceOverviewComponent.prototype.openNewInvoice = function () {
        this.router.navigate(['/invoice', 'new']);
    };
    InvoiceOverviewComponent.prototype.gotoProject = function (id) {
        this.router.navigate(['/pdetail', id]);
    };
    return InvoiceOverviewComponent;
}());
export { InvoiceOverviewComponent };
