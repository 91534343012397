import { filter, map, mergeMap, publish } from 'rxjs/operators';
import { Observable } from "rxjs";
import "rxjs";
import { Job, Task } from "../dto/job";
import { HttpClient } from "@angular/common/http";
import { IntervalObservable } from "rxjs/observable/IntervalObservable";
import { getBaseSocketUrl, getBaseUrl } from "../util/httpclient";
import { toLangPairString } from '../util/jobutil';
/**
 * Created by jefv on 2/11/2017.
 */
var TaskService = /** @class */ (function () {
    function TaskService(http) {
        this.http = http;
        this.TASK_API = getBaseUrl() + "/api/v1/tasks";
        this.TASK_SOCKET_URL = getBaseSocketUrl() + "/tasks/feed";
    }
    TaskService.prototype.initTaskFeed = function () {
        var _this = this;
        this.ws = Observable.webSocket(this.TASK_SOCKET_URL);
        //Keep the socket alive (every 30 seconds, server timeout is set to 5 minutes)
        IntervalObservable.create(30000).subscribe(function (o) {
            return _this.ws.next({ 'action': 'keepalive' });
        });
        this.messages = this.makeHot(this.ws).pipe(filter(function (m) { return m != null; }));
        // this.messages.subscribe(msg => console.log("> Received " + JSON.stringify(msg) + " @" + this.TASK_SOCKET_URL),
        //   error => console.error(error));
    };
    //For more info see analyses.service.ts
    TaskService.prototype.makeHot = function (cold) {
        var obs = cold.pipe(publish());
        obs.connect();
        return obs;
    };
    TaskService.prototype.enrollForTask = function (tId) {
        if (!this.ws || this.ws.closed)
            this.initTaskFeed();
        //To enroll you have to send a message to the socket with the action "enroll" and to which objects you want to enroll
        this.ws.next({ 'action': 'enroll', 'tid': tId });
        //The JSON structure of the message normally has the objects parameters to identify and a "data" object which contains the actual information
        //E.g. {"tid":"10", "data":{"action":"PDF", "langname":"damocles.docx"}}
        return this.messages.pipe(filter(function (msg) {
            return msg.tid == tId;
        }), map(function (msg) { return msg.data; }));
    };
    TaskService.prototype.enrollForProject = function (pId) {
        return this.enroll(pId)
            .map(function (msg) { return msg.data; });
    };
    TaskService.prototype.enrollForProjectAndLangPair = function (pId, langPair, enrollNoLangPairAlso) {
        if (enrollNoLangPairAlso === void 0) { enrollNoLangPairAlso = true; }
        // If the 'enrollNoLangPairAlso' flag is false, then ONLY events that have a language pair specified that is equal
        // to the given one will be returned; if the events have no language pair specified, they are ignored and will not be returned
        // If the 'enrollNoLangPairAlso' flag is true, then events that have a language pair equal to the given one OR
        // none at all are returned
        return this.enroll(pId).pipe(filter(function (msg) {
            return (msg.langpair === undefined && enrollNoLangPairAlso) || msg.langpair == langPair;
        }), map(function (msg) { return msg.data; }));
    };
    TaskService.prototype.enroll = function (pId) {
        if (!this.ws || this.ws.closed)
            this.initTaskFeed();
        this.ws.next({ 'action': 'enroll', 'pid': pId });
        return this.messages.filter(function (msg) {
            return msg.pid == pId;
        });
    };
    TaskService.prototype.getUnacceptedTasks = function () {
        return this.http.get(this.TASK_API + "/unaccepted");
    };
    TaskService.prototype.getAcceptedTasks = function () {
        return this.http.get(this.TASK_API + "/accepted");
    };
    TaskService.prototype.getCompletedTasks = function () {
        return this.http.get(this.TASK_API + "/completed");
    };
    TaskService.prototype.getInvoiceableTasks = function () {
        return this.http.get(this.TASK_API + "/invoiceable");
    };
    TaskService.prototype.findTaskForInvoice = function (invoiceId) {
        return this.http.get(this.TASK_API + "/findbyinvoice/" + invoiceId);
    };
    TaskService.prototype.findTaskForProject = function (pId, sourceLang, targetLang) {
        var lp = toLangPairString({ source: sourceLang, target: targetLang });
        return this.http.get(this.TASK_API + "/findbylp/" + pId + "/" + lp).pipe(map(function (t) { return t; }), map(function (t) { return t.filter(function (task) { return !task.canceldate; }); }));
    };
    TaskService.prototype.getTaskInfoForProject = function (pId) {
        return this.http.get(this.TASK_API + "/gettaskinfobyproject/" + pId).pipe(map(function (v) { return v; }), mergeMap(function (v) { return v; }), map(function (v) { return v || {}; }));
    };
    TaskService.prototype.getTaskCount = function (projectId) {
        return this.http.get(this.TASK_API + "/countbyproject/" + projectId);
    };
    TaskService.prototype.getTaskCountPerLangPair = function (projectId) {
        return this.http.get(this.TASK_API + '/countperlp/' + projectId);
    };
    TaskService.prototype.getTask = function (taskId) {
        return this.http.get(this.TASK_API + "/id/" + taskId);
    };
    TaskService.prototype.initTask = function (files, service, source, target, tasktype, sequence, pId, vendor) {
        var task = new Task();
        task.vendor = vendor.name;
        task.vendorId = vendor.id;
        task.jobs = [];
        task.service = service;
        task.activity = tasktype;
        task.sequence = sequence;
        task.basePrice = vendor.basePrice;
        task.percentageSet = vendor.percentageSet;
        task.sourcelanguage = source;
        task.targetlanguage = target;
        task.projectId = pId;
        files.filter(function (v) {
            return v.type === "SOURCE";
        })
            .map(function (f) {
            var job = new Job();
            job.file = f.name;
            job.source = source;
            job.target = target;
            return job;
        }).forEach(function (j) { return task.jobs.push(j); });
        return task;
    };
    TaskService.prototype.addTask = function (files, service, source, target, tasktype, sequence, pId, vendor) {
        var task = this.initTask(files, service, source, target, tasktype, sequence, pId, vendor);
        return this.insertTask(task);
    };
    TaskService.prototype.insertTask = function (task) {
        return this.http.post(this.TASK_API + "/insert", JSON.stringify(task), { responseType: "json" })
            .map(function (a) { return a; });
    };
    TaskService.prototype.cancelTask = function (task) {
        if (task.id != null)
            return this.http.post(this.TASK_API + "/cancel", task.id, { responseType: "text" });
        else {
            console.warn("WARNING: Task to cancel has no ID and can thus not be canceled", task);
        }
    };
    TaskService.prototype.sendTask = function (task) {
        return this.http.post(this.TASK_API + "/send", task.id, { responseType: "json" });
    };
    TaskService.prototype.acceptTask = function (taskId, expectedDate) {
        var body = { id: taskId, expectedDate: expectedDate };
        return this.http.post(this.TASK_API + "/accept", body, { responseType: "json" });
    };
    TaskService.prototype.rejectTask = function (taskId, reason, suggestedColleague) {
        var colleagueId = undefined;
        if (suggestedColleague !== undefined)
            colleagueId = suggestedColleague.id;
        var body = {
            reason: reason,
            suggestedColleague: colleagueId
        };
        return this.http.post(this.TASK_API + "/reject/" + taskId, body, { responseType: "json" });
    };
    //This method is used to check if the task is waiting to be send by the trigger from the project start
    //If so it the update should be handled within two minutes, if not, something probably went wrong in the sending of the task.
    TaskService.prototype.isProbablySending = function (task, project) {
        if (task.senddate != null)
            return false;
        var projectStartDate = undefined;
        var minutes = undefined;
        if (project.startdate != null) {
            projectStartDate = new Date(project.startdate);
            minutes = Math.round((((new Date().getTime() - projectStartDate.getTime()) % 86400000) % 3600000) / 60000);
        }
        return minutes != undefined && minutes < 2;
    };
    TaskService.prototype.counterProposeTask = function (task, counterProposal) {
        return this.http.post(this.TASK_API + "/counterpropose/" + task.id, counterProposal, { responseType: "text" });
    };
    TaskService.prototype.acceptProposalTask = function (task) {
        return this.http.post(this.TASK_API + "/acceptproposal/" + task.id, "", { responseType: "text" });
    };
    TaskService.prototype.availableJobs = function (task) {
        return this.http.get(this.TASK_API + "/" + task.id + "/jobs/ready");
    };
    TaskService.prototype.setEditorAccessDate = function (taskId, fileName) {
        return this.http.post(this.TASK_API + "/seteditoraccess/" + taskId, fileName, { responseType: "text" });
    };
    TaskService.prototype.setJobDownloadDate = function (taskId, fileName) {
        return this.http.post(this.TASK_API + "/setjobdownload/" + taskId, fileName, { responseType: "text" });
    };
    TaskService.prototype.hasNextTask = function (task) {
        // /nexttask:pId/:langpair/:currentactivity/:currentsequence"
        return this.http.get(this.TASK_API + '/nexttask/' + task.projectId + '/'
            + task.sourcelanguage + '_' + task.targetlanguage + '/' + task.activity + '/' + task.sequence)
            .map(function (t) { return t != null; });
    };
    return TaskService;
}());
export { TaskService };
