import { from as observableFrom } from 'rxjs';
import { map, toArray } from 'rxjs/operators';
import { EventEmitter, SimpleChanges } from "@angular/core";
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { UserService } from "../service/user.service";
import { LanguageService } from "../service/language.service";
var CompetenceListComponent = /** @class */ (function () {
    function CompetenceListComponent(userService, languageService) {
        this.userService = userService;
        this.languageService = languageService;
        this.competenceRows = undefined;
        this.competenceData = new MatTableDataSource();
        this.availCols = ['selectBox', 'activity', 'sourceLang', 'targetLang', 'price'];
        this.onSelectionChange = new EventEmitter();
        this.onPriceChange = new EventEmitter();
    }
    CompetenceListComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.languageService.getCharBasedLanguages().subscribe(function (cb) { return _this.charBasedLanguages = cb; });
        if (this.competences != undefined)
            this.prepCompetenceData();
    };
    CompetenceListComponent.prototype.ngOnChanges = function (changes) {
        if (changes.competences != undefined) {
            this.competences = changes.competences.currentValue;
            // Reset selection
            this.selection = undefined;
            this.prepCompetenceData();
        }
    };
    CompetenceListComponent.prototype.ngAfterViewInit = function () {
        this.competenceData.sort = this.sort;
    };
    Object.defineProperty(CompetenceListComponent.prototype, "matSort", {
        set: function (ms) {
            this.sort = ms;
            this.competenceData.sort = this.sort;
        },
        enumerable: true,
        configurable: true
    });
    CompetenceListComponent.prototype.isCharacterBasedLang = function (lang) {
        if (this.charBasedLanguages)
            return this.charBasedLanguages.indexOf(lang) >= 0;
        return false;
    };
    CompetenceListComponent.prototype.prepCompetenceData = function () {
        var _this = this;
        var start = Date.now();
        observableFrom(this.competences).pipe(map(function (c) {
            var row = new CompetenceRow();
            row.id = c.id;
            row.activity = c.activity;
            // todo: use actual language name here, so the sorting sorts properly
            row.sourceLang = c.source;
            row.targetLang = c.target;
            row.price = c.price;
            row.origObj = c;
            return row;
        }), toArray()).subscribe(function (rows) {
            _this.competenceRows = rows;
            _this.competenceData.data = _this.competenceRows;
            console.debug("Displaying " + _this.competenceRows.length + " competences took " + (Date.now() - start) + " ms.");
        });
    };
    CompetenceListComponent.prototype.selectionChanged = function ($event, elem) {
        if (this.selection == undefined)
            this.selection = [];
        var idx = this.selection.indexOf(elem.origObj);
        if (idx >= 0)
            this.selection.splice(idx, 1);
        else
            this.selection.push(elem.origObj);
        this.onSelectionChange.emit(this.selection);
    };
    CompetenceListComponent.prototype.priceChanged = function ($event, elem) {
        var obj = Object();
        obj.competence = elem.origObj;
        obj.newprice = elem.price;
        this.onPriceChange.emit(obj);
    };
    return CompetenceListComponent;
}());
export { CompetenceListComponent };
// Small data-class that holds the competence data for each row
var CompetenceRow = /** @class */ (function () {
    function CompetenceRow() {
    }
    return CompetenceRow;
}());
