import { MatDialogRef } from "@angular/material/dialog";
var SimpleDialog = /** @class */ (function () {
    function SimpleDialog(dialogRef, data) {
        this.dialogRef = dialogRef;
        this.data = data;
        this.dialogButtonName = "Close";
    }
    SimpleDialog.prototype.ngOnInit = function () {
        if (this.data.dialogTitle)
            this.dialogTitle = this.data.dialogTitle;
        if (this.data.dialogMessage)
            this.dialogMessage = this.data.dialogMessage;
        if (this.data.dialogHtml)
            this.dialogHtml = this.data.dialogHtml;
        if (this.data.dialogButtonName != null)
            this.dialogButtonName = this.data.dialogButtonName;
    };
    return SimpleDialog;
}());
export { SimpleDialog };
