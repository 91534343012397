import { OnInit } from "@angular/core";
import { UserService } from "../service/user.service";
var AdminDashboardComponent = /** @class */ (function () {
    function AdminDashboardComponent(userService) {
        this.userService = userService;
    }
    AdminDashboardComponent.prototype.ngOnInit = function () {
    };
    return AdminDashboardComponent;
}());
export { AdminDashboardComponent };
