import { UserService } from "../service/user.service";
import { HubspotService } from "../service/hubspot.service";
import { Router } from "@angular/router";
var IntegrationsSettingComponent = /** @class */ (function () {
    function IntegrationsSettingComponent(userService, hubspotService, router) {
        this.userService = userService;
        this.hubspotService = hubspotService;
        this.router = router;
        this.hubspotTestSuccess = undefined;
        this.hubspotTestMessage = undefined;
    }
    IntegrationsSettingComponent.prototype.ngOnInit = function () {
        if (this.user.hubspot == undefined) {
            this.user.hubspot = {
                'hapiKey': undefined
            };
        }
    };
    IntegrationsSettingComponent.prototype.testHubspot = function () {
        var _this = this;
        if (this.user.hubspot == undefined || this.user.hubspot.hapiKey == undefined) {
            this.hubspotTestSuccess = false;
            this.hubspotTestMessage = "No hapiKey filled in.";
        }
        else {
            this.hubspotService.testHubspotConnection(this.user.hubspot.hapiKey)
                .subscribe(function (v) {
                if (v === "success") {
                    _this.hubspotTestSuccess = true;
                    _this.hubspotTestMessage = "Key is successful";
                }
                else {
                    _this.hubspotTestSuccess = false;
                    _this.hubspotTestMessage = "Key invalid";
                }
            });
        }
    };
    IntegrationsSettingComponent.prototype.createHubspotProject = function () {
        this.router.navigate(["hubspotproject"]);
    };
    IntegrationsSettingComponent.prototype.save = function () {
        this.userService.updateUserData(this.user)
            .subscribe();
    };
    return IntegrationsSettingComponent;
}());
export { IntegrationsSettingComponent };
