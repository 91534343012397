import { of as observableOf } from 'rxjs';
import { map } from 'rxjs/operators';
import { EventEmitter, OnInit } from "@angular/core";
import { Vendor } from "../../dto/vendor";
import { VendorService } from "../../service/vendor.service";
import { PricingService } from "../../service/pricing.services";
import { StatisticsService } from "../../service/statistics.service";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { UserService } from "../../service/user.service";
import { TaskSpec } from "../../dto/job";
import { extractSourceLang, extractTargetLang } from "../../util/jobutil";
var VendorSelectorComponent = /** @class */ (function () {
    function VendorSelectorComponent(vendorService, pricingService, statisticsService, userService, dialog) {
        this.vendorService = vendorService;
        this.pricingService = pricingService;
        this.statisticsService = statisticsService;
        this.userService = userService;
        this.dialog = dialog;
        this.vendorEmitter = new EventEmitter();
        this.vendorcount = 0;
        this.allVendorCount = 0;
        this.userId = null;
        this.userEmail = null;
        this.contactRequestResult = null;
        this.loading = true;
    }
    VendorSelectorComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.loading = true;
        this.userEmail = null;
        this.contactRequestResult = null;
        if (this.langpair && this.activity) {
            this.taskSpec = new TaskSpec();
            this.taskSpec.projectId = this.pId;
            this.taskSpec.activity = this.activity;
            this.taskSpec.sourcelanguage = extractSourceLang(this.langpair);
            this.taskSpec.targetlanguage = extractTargetLang(this.langpair);
            // TODO: make separate call for getting the competence count, rather than having to retrieve ALL competences for a simple count
            if (this.userService.isLoggedIn()) {
                var currentUser = this.userService.getCurrentUser();
                this.userId = currentUser.id;
                this.userEmail = currentUser.email;
            }
            this.allVendors = this.vendorService.getCompetences(this.langpair, this.activity, this.userId);
            this.allVendors.subscribe(function (c) { return _this.allVendorCount = c.length; });
            var fetchVendors = void 0;
            if (this.pId != null) {
                fetchVendors = this.vendorService.getBestProjectCompetences(this.pId, this.langpair, this.activity, 'Translation', "3");
            }
            else {
                fetchVendors = this.vendorService.getBestCompetences(this.langpair, this.activity, this.userId);
            }
            this.filteredVendors = fetchVendors
                .map(function (v) { return v.slice(0, 3); }) //Slice the vendors so only the first 3 partners are shown
                .map(function (v) {
                _this.vendorcount = v.length;
                return v;
            })
                .map(function (v) {
                _this.loading = false;
                return v;
            })
                .flatMap(function (v) {
                if (_this.vendorcount == 0) {
                    return _this.getDefaultFilteredVendors();
                }
                else
                    return observableOf(v);
            });
        }
    };
    VendorSelectorComponent.prototype.selectVendor = function (vendor) {
        this.vendorEmitter.emit(vendor);
    };
    VendorSelectorComponent.prototype.showMorePartners = function () {
        var _this = this;
        var dialogRef = this.dialog.open(VendorSelectorDialog, {
            width: '80%',
            height: '80%',
            data: {
                projectId: this.pId,
                langpair: this.langpair,
                activity: this.activity,
                showPricing: this.pId != undefined,
                taskSpec: this.taskSpec,
                userId: this.userId
            }
        });
        dialogRef.afterClosed().subscribe(function (result) {
            if (result instanceof Vendor) {
                _this.selectVendor(result);
            }
        });
        return dialogRef;
    };
    VendorSelectorComponent.prototype.setFilteredVendors = function () {
        this.filteredVendors = this.getDefaultFilteredVendors();
    };
    VendorSelectorComponent.prototype.getDefaultFilteredVendors = function () {
        var _this = this;
        return this.allVendors.pipe(map(function (v) { return filterVendors(v, _this.filterText); }), map(function (v) { return v.slice(0, 3); }), //Slice the vendors so only the first 3 partners are shown
        map(function (v) {
            _this.vendorcount = v.length;
            return v;
        }));
    };
    VendorSelectorComponent.prototype.onSubmitContactRequest = function () {
        var _this = this;
        this.userService.sendContactRequest(this.userEmail, this.taskSpec, this.userId).subscribe(function (v) {
            _this.contactRequestResult = "Great! We received your query and will get back to you as soon as possible.";
        }, function (error) {
            console.error("Error sending contact request", error);
            _this.contactRequestResult = "An error occurred sending your request. " +
                "Please try again and, if the problem persists, contact us directly at info@lilo.global.";
        });
    };
    return VendorSelectorComponent;
}());
export { VendorSelectorComponent };
var VendorSelectorDialog = /** @class */ (function () {
    function VendorSelectorDialog(dialogRef, data, vendorService) {
        this.dialogRef = dialogRef;
        this.data = data;
        this.vendorService = vendorService;
    }
    VendorSelectorDialog.prototype.ngOnInit = function () {
        this.langpair = this.data.langpair;
        this.activity = this.data.activity;
        this.showPricing = this.data.showPricing;
        this.userId = this.data.userId;
        this.taskSpec = this.data.taskSpec;
        if (this.langpair && this.activity) {
            this.allVendors = this.vendorService.getCompetences(this.langpair, this.activity, this.userId);
            this.setFilteredVendors();
        }
    };
    VendorSelectorDialog.prototype.selectVendor = function (event) {
        this.dialogRef.close(event);
    };
    VendorSelectorDialog.prototype.setFilteredVendors = function () {
        var _this = this;
        this.filteredVendors = this.allVendors.pipe(map(function (v) { return filterVendors(v, _this.filterText); }));
    };
    return VendorSelectorDialog;
}());
export { VendorSelectorDialog };
function filterVendors(vendors, text) {
    if (text) {
        return vendors.filter(function (v) {
            return v.name.trim().toLocaleLowerCase().indexOf(text.trim().toLocaleLowerCase()) >= 0;
        });
    }
    return vendors;
}
