import { EventEmitter } from "@angular/core";
import { LangSelectComponent } from "../../util/lang-select.component";
var LangpairSelectComponent = /** @class */ (function () {
    function LangpairSelectComponent() {
        this.eventEmitter = new EventEmitter();
        this.changeEmitter = new EventEmitter();
        this.hasChanges = false;
    }
    LangpairSelectComponent.prototype.ngOnInit = function () {
        // Already show the next button (it'll be disabled) if there are no target languages
        // That way it appears during project create, which seems natural
        // this.hasChanges = this.targetLanguages == null || this.targetLanguages.length == 0;
        console.debug(JSON.stringify(this.selectedParameters));
    };
    LangpairSelectComponent.prototype.emitParameters = function () {
        this.hasChanges = false;
        this.eventEmitter.emit(this.selectedParameters);
        if (this.targetLangSelect != null) {
            this.targetLangSelect.closeLanguageChooser();
        }
        if (this.sourceLangSelect != null) {
            this.sourceLangSelect.closeLanguageChooser();
        }
    };
    LangpairSelectComponent.prototype.onSourceLanguageUpdate = function (event) {
        event.source = true;
        this.onLanguageUpdate(event);
    };
    LangpairSelectComponent.prototype.onTargetLanguageUpdate = function (event) {
        event.target = true;
        this.onLanguageUpdate(event);
    };
    LangpairSelectComponent.prototype.onLanguageUpdate = function (event) {
        this.hasChanges = true;
        this.changeEmitter.emit(event);
    };
    return LangpairSelectComponent;
}());
export { LangpairSelectComponent };
