import { VendorService } from "../service/vendor.service";
import { ActivityService } from "../service/activity.service";
import { LanguageService } from "../service/language.service";
import { UserService } from "../service/user.service";
var TranslatorOverviewComponent = /** @class */ (function () {
    function TranslatorOverviewComponent(vendorService, activitiesService, languageService, userService) {
        this.vendorService = vendorService;
        this.activitiesService = activitiesService;
        this.languageService = languageService;
        this.userService = userService;
        this.domains = [];
    }
    TranslatorOverviewComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.activitiesService.getActivities().subscribe(function (activities) { return _this.activities = activities; });
        this.languageService.getLanguages().subscribe(function (languages) { return _this.languages = languages; });
        this.languageService.getDialectLanguages().subscribe(function (languages) { return _this.dialectLanguages = languages; });
    };
    TranslatorOverviewComponent.prototype.search = function () {
        this.results = this.vendorService.getCompetences(this.source + "_" + this.target, this.activity);
    };
    return TranslatorOverviewComponent;
}());
export { TranslatorOverviewComponent };
