import { OnChanges, OnInit, SimpleChanges, OnDestroy } from "@angular/core";
import { debounceTime, switchMap } from 'rxjs/operators';
import { PricingService } from "../../service/pricing.services";
import { Project } from "../../dto/project";
import { UserService } from "../../service/user.service";
import { LogInService } from "../../service/login.service";
import { SubscriptionHandler } from '../../util/subscription.handler';
import { toLangPairString } from '../../util/jobutil';
import { EventEmitter } from '@angular/core';
var ProjectPricingOverviewComponent = /** @class */ (function () {
    function ProjectPricingOverviewComponent(pricingService, userService, loginService) {
        this.pricingService = pricingService;
        this.userService = userService;
        this.loginService = loginService;
        this.allowPriceDownload = false;
        this.priceCalculatedEmitter = new EventEmitter();
        this.subscriptionHandler = new SubscriptionHandler(this);
    }
    ProjectPricingOverviewComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.updateTotalPrice();
        var subscription = this.pricingService.enrollForProject(this.project.id)
            .pipe(debounceTime(500), switchMap(function (p) { return _this.pricingService.getTotalProjectPrice(_this.project.id); })).subscribe(function (p) { return _this.setPricing(p); });
        this.subscriptionHandler.push(subscription);
    };
    ProjectPricingOverviewComponent.prototype.ngOnDestroy = function () {
    };
    ProjectPricingOverviewComponent.prototype.updateTotalPrice = function () {
        var _this = this;
        // The pricing is unfortunately calculated twice each time: once when the reloadPriceTrigger changes and once when
        // the event from the websocket comes in
        // We should only need the event, but since it's so slow to come in, I've left it as is to ensure the price on the
        // page gets updated asap
        // TODO: make sure the pricing is only calculated when it needs to be
        this.pricingService.getTotalProjectPrice(this.project.id)
            .subscribe(function (p) { return _this.setPricing(p); });
    };
    ProjectPricingOverviewComponent.prototype.ngOnChanges = function (changes) {
        if (changes.project != null) {
            this.updateTotalPrice();
        }
        else if (changes.reloadPriceTrigger != null) {
            this.updateTotalPrice();
        }
    };
    ProjectPricingOverviewComponent.prototype.hasPrice = function (langPair) {
        return this.pricing != undefined && this.pricing.langPairDetails != undefined &&
            this.pricing.langPairDetails[langPair] != undefined &&
            this.pricing.langPairDetails[langPair].taskCount > 0;
    };
    ProjectPricingOverviewComponent.prototype.toLangPairString = function (source, target) {
        return toLangPairString({ source: source, target: target });
    };
    ProjectPricingOverviewComponent.prototype.getPrice = function (langPair, inclCredit) {
        if (inclCredit === void 0) { inclCredit = true; }
        if (this.hasPrice(langPair)) {
            return this.pricing.langPairDetails[langPair].total(inclCredit);
        }
        else
            return 0;
    };
    ProjectPricingOverviewComponent.prototype.setPricing = function (pricing) {
        this.pricing = pricing;
        this.priceCalculatedEmitter.emit(pricing);
    };
    ProjectPricingOverviewComponent.prototype.openJoin = function () {
        this.loginService.openJoinDialog('pdetail/' + this.project.id);
    };
    ProjectPricingOverviewComponent.prototype.openSignIn = function () {
        this.loginService.openSignInPopup("pdetail/" + this.project.id);
    };
    ProjectPricingOverviewComponent.prototype.totalWithTasksOnly = function () {
        var _this = this;
        if (this.pricing.langPairDetails != undefined && this.pricing.taskCount > 0) {
            return Object.keys(this.pricing.langPairDetails)
                .map(function (v, i, a) { return v; })
                .map(function (v) { return _this.getPrice(v); })
                .reduce(function (p1, p2) { return p1 + p2; }, 0);
        }
        else
            return 0;
    };
    ProjectPricingOverviewComponent.prototype.totalInclVATWithTaskOnly = function () {
        return this.totalWithTasksOnly() * (this.pricing.vatRate + 1);
    };
    ProjectPricingOverviewComponent.prototype.downloadPriceOverview = function () {
        var _this = this;
        this.pricingService.downloadPricingPdf(this.project.id)
            .subscribe(function (f) { return saveAs(f, _this.project.title + '.pdf'); });
    };
    return ProjectPricingOverviewComponent;
}());
export { ProjectPricingOverviewComponent };
