import { EventEmitter } from "@angular/core";
var EventRegistrationComponent = /** @class */ (function () {
    function EventRegistrationComponent() {
        this.event = { "from": null, "to": null, "description": null };
        this.minDate = new Date().toISOString().substring(0, 10);
        this.eventEmitter = new EventEmitter();
    }
    EventRegistrationComponent.prototype.add = function () {
        this.eventEmitter.emit(this.event);
    };
    EventRegistrationComponent.prototype.isValid = function () {
        return this.event && this.event.from && this.event.to && this.event.from < this.event.to;
    };
    EventRegistrationComponent.prototype.getToMinDate = function () {
        if (this.event && this.event.from)
            return this.event.from;
        return null;
    };
    EventRegistrationComponent.prototype.getFromMinDate = function () {
        return this.minDate;
    };
    return EventRegistrationComponent;
}());
export { EventRegistrationComponent };
