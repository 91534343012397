import { EventEmitter, OnInit } from "@angular/core";
import { ProjectService } from '../../service/project.service';
var SourceFileListComponent = /** @class */ (function () {
    function SourceFileListComponent(projectService) {
        this.projectService = projectService;
        this.allowRemoval = true;
        this.onFileRemove = new EventEmitter();
        this.onDocFileAdded = new EventEmitter();
        this.onFileMetaUpdate = new EventEmitter();
    }
    SourceFileListComponent.prototype.ngOnInit = function () {
    };
    SourceFileListComponent.prototype.setFileLanguage = function (filename, language) {
        this.files
            .filter(function (f) { return f.name === filename; })
            .forEach(function (f) {
            if (f.sourcelang === undefined) {
                f.sourcelang = language;
                f.detectedsourcelang = language;
            }
        });
    };
    SourceFileListComponent.prototype.removeFile = function (file) {
        if (this.allowRemoval) {
            this.files.splice(this.files.indexOf(file), 1);
            this.onFileRemove.emit(file);
        }
    };
    SourceFileListComponent.prototype.docFileUploaded = function (docFileData) {
        this.onDocFileAdded.emit(docFileData);
    };
    SourceFileListComponent.prototype.fileDataUpdated = function (fileData) {
        this.onFileMetaUpdate.emit(fileData);
    };
    return SourceFileListComponent;
}());
export { SourceFileListComponent };
