import { map } from 'rxjs/operators';
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { UserService } from "../service/user.service";
import { ActivatedRoute, Router } from "@angular/router";
import { SimpleDialog } from "../simple-dialog.component";
import { ErrorSnackbarComponent } from "../error.snackbar.component";
var MailActivationScreen = /** @class */ (function () {
    function MailActivationScreen(route, router, userService, dialog, snackbar) {
        this.route = route;
        this.router = router;
        this.userService = userService;
        this.dialog = dialog;
        this.snackbar = snackbar;
    }
    MailActivationScreen.prototype.ngOnInit = function () {
        var _this = this;
        this.route.params.pipe(map(function (params) { return params['authkey']; }))
            .subscribe(function (key) {
            return _this.userService.activateAccount(key).subscribe(function (result) {
                _this.user = result;
            }, function (error1) { return console.error("Error activating user account", error1); });
        });
        this.route.queryParams.pipe(map(function (params) { return params['callback']; }))
            .subscribe(function (cb) {
            _this.callback = cb;
        });
    };
    MailActivationScreen.prototype.updatePassword = function () {
        var _this = this;
        //Check the password
        if (this.checkPasswordEqual()) {
            //Call the update password, and subscribe to the result
            this.userService.updatePassword(this.user.id, this.password).subscribe(function (r) {
                //If the result is success (so the password is changed) show a success dialog
                if (r == "success") {
                    var dialogRef = _this.dialog.open(SimpleDialog, {
                        width: "400px",
                        panelClass: 'simpleDialog',
                        data: { dialogMessage: "Your account was successfully activated, and your password set." }
                    });
                    //When the successdialog is closed, we sing in to LiLo and directly navigate to the profile URL so the user can complete their profile.
                    dialogRef.afterClosed().subscribe(function (n) {
                        return _this.userService.signInWithMail(_this.user.email, _this.password).subscribe(function (next) {
                            if (_this.callback) {
                                _this.router.navigateByUrl(_this.callback);
                            }
                            else {
                                _this.router.navigate(["user", _this.user.id]);
                            }
                        });
                    });
                }
                else {
                    var ref = _this.snackbar.openFromComponent(ErrorSnackbarComponent, {
                        duration: 2000,
                        data: { errorText: 'Something went wrong, please try again later.' }
                    });
                }
            }, function (error) {
                var ref = _this.snackbar.openFromComponent(ErrorSnackbarComponent, {
                    duration: 2000,
                    data: { errorText: 'Something went wrong, please try again later.' }
                });
                console.error("Error updating password", error);
            });
        }
    };
    MailActivationScreen.prototype.checkPasswordEqual = function () {
        return this.password && this.password.length > 0 && this.password == this.confirmPassword;
    };
    return MailActivationScreen;
}());
export { MailActivationScreen };
