import { EventEmitter } from "@angular/core";
var DragndropDirective = /** @class */ (function () {
    function DragndropDirective() {
        this.fileChangeEmitter = new EventEmitter();
    }
    DragndropDirective.prototype.onDrop = function (evt) {
        evt.preventDefault();
        evt.stopPropagation();
        var files = evt.dataTransfer.files;
        if (files.length > 0) {
            this.fileChangeEmitter.emit(files);
        }
    };
    DragndropDirective.prototype.onDragOver = function (evt) {
        evt.preventDefault();
        evt.stopPropagation();
        //TODO make clear on the component that dragging is occurring and thus that you are able to drop files on the component.
    };
    return DragndropDirective;
}());
export { DragndropDirective };
