import { EventEmitter, OnInit, OnChanges, SimpleChanges } from "@angular/core";
import { UserService } from "../../service/user.service";
import { Router } from '@angular/router';
import { filter, tap } from 'rxjs/operators';
import { Subject } from 'rxjs';
var TranslatorCardComponent = /** @class */ (function () {
    function TranslatorCardComponent(userService, router) {
        this.userService = userService;
        this.router = router;
        this.translator = undefined;
        this.isSelected = false;
        this.isLast = false;
        this.isQuote = false;
        this.translatorSelect = new EventEmitter();
        this.flipped = false;
        this.duedateChanges = new Subject();
        this.daysUnavailable = 0;
        this.unavailabilities = [];
    }
    TranslatorCardComponent.prototype.ngOnInit = function () {
        var _this = this;
        // At the moment, the user's "mother tongue" is chosen from the list of languages without dialects
        // So we need to strip the dialect from the language here if we want to match it with the user's mother tongue
        if (this.targetLang != null) {
            var idx = this.targetLang.indexOf('-');
            if (idx > 0) {
                this.targetLang = this.targetLang.substring(0, idx);
            }
        }
        // Debounce so it only updates every second instead of every keystroke
        this.duedateChanges.asObservable().debounceTime(1000).subscribe(function (v) {
            return _this.fetchUnavailability();
        });
        this.duedateChanges.next(''); // trigger the first unavailability check
    };
    TranslatorCardComponent.prototype.ngOnChanges = function (change) {
        if (change['duedate'] != null) {
            this.duedateChanges.next(change);
        }
    };
    TranslatorCardComponent.prototype.isNative = function () {
        return this.targetLang != null && this.targetLang === this.translator.motherTongue;
    };
    TranslatorCardComponent.prototype.getPrice = function () {
        var price = this.translator['price'];
        if (price == null) {
            price = 0.0;
        }
        if (this.translator['mtCost'] != null) {
            price = price + this.translator['mtCost'];
        }
        if (this.translator['serviceFee'] != null) {
            price = price + this.translator['serviceFee'];
        }
        return price;
    };
    TranslatorCardComponent.prototype.selectTranslator = function () {
        // this.translator["isSelected"] = !this.translator["isSelected"];
        this.isSelected = !this.isSelected;
        this.translatorSelect.emit(this.isSelected ? this.translator : undefined);
    };
    TranslatorCardComponent.prototype.openProfileInfo = function () {
        this.router.navigate(['/profile', this.translator.id]);
    };
    TranslatorCardComponent.prototype.fetchUnavailability = function () {
        var _this = this;
        if (this.duedate != null) {
            var projectStartTime_1 = new Date();
            this.userService.fetchUnavailability(this.translator.id)
                .flatMap(function (u) { return u; })
                .pipe(filter(function (u) { return u.to != null && u.from != null; }), filter(function (u) { return _this.isBetween(projectStartTime_1, u.from, u.to)
                || _this.isBetween(_this.duedate, u.from, u.to); }), tap(function (u) { return _this.unavailabilities[_this.unavailabilities.length] = u; }), tap(function (u) { return _this.daysUnavailable = _this.daysUnavailable + _this.calcDaysUnavailable(u); }))
                .subscribe();
        }
    };
    TranslatorCardComponent.prototype.calcDaysUnavailable = function (unavailability) {
        var from = unavailability.from > new Date().toISOString().slice(0, 10) ? unavailability.from : new Date().toISOString().slice(0, 10);
        return this.daysDiff(from, unavailability.to);
    };
    TranslatorCardComponent.prototype.isBetween = function (date, from, to) {
        return date.toISOString().slice(0, 10) <= to.slice(0, 10) && date.toISOString().slice(0, 10) >= from.slice(0, 10);
    };
    TranslatorCardComponent.prototype.daysDiff = function (from, to) {
        var fromDate = new Date(from);
        var toDate = new Date(to);
        var diff = Math.abs(fromDate.getTime() - toDate.getTime());
        var diffDays = Math.ceil(diff / (1000 * 3600 * 24));
        return diffDays;
    };
    TranslatorCardComponent.prototype.unavailabilityTitle = function () {
        var unavailabilityTitle = '';
        if (this.unavailabilities != null) {
            this.unavailabilities
                .map(function (u) { return '* Unavailable from ' + u.from + ' till ' + u.to + '\n'; })
                .forEach(function (s) { return unavailabilityTitle = unavailabilityTitle + s; });
        }
        return unavailabilityTitle;
    };
    return TranslatorCardComponent;
}());
export { TranslatorCardComponent };
