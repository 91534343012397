import { filter } from 'rxjs/operators';
import { OnDestroy, OnInit } from "@angular/core";
import { Project } from "../dto/project";
import { ProjectService } from "../service/project.service";
import { ActivatedRoute, Router } from "@angular/router";
import { MatDialog } from "@angular/material/dialog";
import { UserService } from "../service/user.service";
import { ConfirmDialog } from "../confirm.dialog";
import { SimpleDialog } from "../simple-dialog.component";
import { PricingService } from "../service/pricing.services";
import { TaskService } from '../service/task.service';
import { LogInService } from '../service/login.service';
import { getGeneralDocFiles, getSourceFiles } from "../util/jobutil";
import { SubscriptionHandler } from '../util/subscription.handler';
var ProjectFollowupComponent = /** @class */ (function () {
    function ProjectFollowupComponent(projectService, taskService, userService, loginService, pricingService, router, route, dialog) {
        this.projectService = projectService;
        this.taskService = taskService;
        this.userService = userService;
        this.loginService = loginService;
        this.pricingService = pricingService;
        this.router = router;
        this.route = route;
        this.dialog = dialog;
        this.hasChanges = false;
        this.validTaskCount = false;
        this.canCancel = false;
        this.isCompleted = false;
        this.isCanceled = false;
        this.sourceFiles = [];
        this.docFiles = [];
        this.taskCountSubscription = undefined;
        this.subscriptionHandler = new SubscriptionHandler(this);
        this.collapseOrigFiles = true;
        this.openLanguageBar = undefined;
        this.updateOrderSubmit = false;
    }
    ProjectFollowupComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.hasChanges = this.project.startdate === null || this.project.startdate === undefined;
        this.isCompleted = this.project.chargeid !== undefined;
        this.isCanceled = this.project.canceled !== undefined && this.project.canceled !== null;
        this.sourceLanguage = this.project.langpairs !== undefined && this.project.langpairs.length > 0 ?
            this.project.langpairs[0].source : undefined;
        this.sourceFiles = getSourceFiles(this.project);
        this.docFiles = getGeneralDocFiles(this.project);
        var projectSubscription = this.projectService.enrollForProject(this.project.id)
            .pipe(filter(function (v) { return v.action == 'PROJECT_CHECKOUT_REMOVE' || v.action == 'PROJECT_START' || v.action == 'PROJECT_QUOTE_ACCEPT'; }))
            .subscribe(function (v) {
            _this.hasChanges = (v.action == 'PROJECT_CHECKOUT_REMOVE' || v.action == 'PROJECT_QUOTE_ACCEPT');
            if (v.action == 'PROJECT_QUOTE_ACCEPT') {
                _this.project.isQuote = false;
            }
        });
        this.subscriptionHandler.push(projectSubscription);
        if (this.taskCountSubscription === undefined) {
            this.taskCountSubscription = this.taskService.enrollForProject(this.project.id).subscribe(function (a) { return _this.checkTaskCount(); });
            this.subscriptionHandler.push(this.taskCountSubscription);
        }
        this.taskService.getTaskInfoForProject(this.project.id)
            .pipe(filter(function (t) { return t.acceptdate != undefined; }))
            .isEmpty()
            .subscribe(function (b) {
            _this.canCancel = b;
        });
        this.route.queryParams
            .forEach(function (params) {
            if (params.hasOwnProperty('gototask')) {
                _this.openLanguageBar = params.gototask;
            }
        });
        this.checkTaskCount();
    };
    ProjectFollowupComponent.prototype.ngOnDestroy = function () {
    };
    ProjectFollowupComponent.prototype.toggleCollapseOrigFiles = function () {
        this.collapseOrigFiles = !this.collapseOrigFiles;
    };
    ProjectFollowupComponent.prototype.onDocFileUploaded = function (docInfo) {
        var _this = this;
        if (docInfo != null && docInfo.docfile != null) {
            this.projectService.addDocFileData(docInfo.docfile.name, docInfo.source4pdf, this.project.id).subscribe(function (data) {
                _this.docFiles.push(data);
            }, function (error) {
                console.error(error);
            });
        }
    };
    ProjectFollowupComponent.prototype.setOpenLanguageBar = function (lp) {
        this.openLanguageBar = lp != undefined ? lp.target : undefined;
    };
    ProjectFollowupComponent.prototype.checkTaskCount = function () {
        var _this = this;
        this.taskService.getTaskCountPerLangPair(this.project.id)
            .map(function (m) {
            return Object.values(m).filter(function (e) { return e > 0; });
        })
            .map(function (lps) {
            return lps.length;
        })
            .subscribe(function (lpCount) {
            _this.validTaskCount = lpCount === _this.project.langpairs.length;
        });
    };
    ProjectFollowupComponent.prototype.updateOrder = function () {
        var _this = this;
        this.updateOrderSubmit = true;
        var userId = this.project.requestedby.id;
        // Calculate the pricing again and when that is done, start the project
        // Note that if we manually adjusted the pricingElement of the project, it will not be overwritten
        this.pricingService.getTotalProjectPrice(this.project.id)
            .switchMap(function (pricing) {
            console.debug("Project " + _this.project.id + ": UPDATING", pricing);
            return _this.projectService.startProject(_this.project, userId, pricing, false, _this.userService.getRemoteUserIp());
        }).subscribe(function (v) {
            // redirect to project dashboard
            return _this.router.navigateByUrl("pdashboard");
        }, function (error) {
            console.error("Error updating project", error);
            _this.updateOrderSubmit = false;
        });
    };
    ProjectFollowupComponent.prototype.cancelProject = function () {
        var _this = this;
        var dialogRef = this.dialog.open(ConfirmDialog, {
            width: '450px',
            panelClass: 'confirmDialog',
            data: {
                confirmTitle: 'Are you sure?',
                // confirmMessage: 'If you cancel your project, you will have to pay a fee to the translators who already confirmed to do the job.',
                confirmText: 'Yes, cancel project',
                cancelText: 'No, take me back',
                invertStyle: true
            }
        });
        dialogRef.afterClosed().pipe(filter(function (t) { return t === "confirm"; }))
            .subscribe(function (p) {
            _this.projectService.cancelProject(_this.project).subscribe(function (v) {
                var successDialog = _this.dialog.open(SimpleDialog, {
                    width: '400px',
                    panelClass: 'simpleDialog',
                    data: { dialogMessage: 'Your project has been canceled.' }
                });
                successDialog.afterOpen().subscribe(function (_) {
                    return _this.router.navigateByUrl("");
                });
            });
        });
    };
    ProjectFollowupComponent.prototype.openLogin = function () {
        this.loginService.openSignInPopup();
    };
    return ProjectFollowupComponent;
}());
export { ProjectFollowupComponent };
