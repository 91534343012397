import * as tslib_1 from "tslib";
import { TaskService } from "../service/task.service";
import { UserService } from "../service/user.service";
import { ReviewService } from "../service/review.service";
import { ActivatedRoute } from "@angular/router";
var RatingFullComponent = /** @class */ (function () {
    function RatingFullComponent(reviewService, taskService, userService, route) {
        this.reviewService = reviewService;
        this.taskService = taskService;
        this.userService = userService;
        this.route = route;
    }
    RatingFullComponent.prototype.ngOnInit = function () {
        var _this = this;
        if (this.userId != null) {
            this.setUserId(this.userId);
        }
        else {
            this.route.params
                .forEach(function (params) { return _this.setUserId(params['id']); });
        }
    };
    RatingFullComponent.prototype.setUserId = function (userId) {
        var _this = this;
        this.userId = userId;
        this.reviewService.getReviewsForUser(userId)
            .subscribe(function (reviews) {
            _this.setReviews(reviews);
        });
    };
    RatingFullComponent.prototype.setReviews = function (reviews) {
        var e_1, _a, e_2, _b;
        this.reviews = reviews;
        if (this.reviews) {
            //TODO do it with observables
            var count = 0;
            var total = 0;
            try {
                for (var _c = tslib_1.__values(this.reviews), _d = _c.next(); !_d.done; _d = _c.next()) {
                    var rev = _d.value;
                    try {
                        for (var _e = (e_2 = void 0, tslib_1.__values(rev.scores)), _f = _e.next(); !_f.done; _f = _e.next()) {
                            var score = _f.value;
                            count++;
                            total += score.rate;
                        }
                    }
                    catch (e_2_1) { e_2 = { error: e_2_1 }; }
                    finally {
                        try {
                            if (_f && !_f.done && (_b = _e.return)) _b.call(_e);
                        }
                        finally { if (e_2) throw e_2.error; }
                    }
                }
            }
            catch (e_1_1) { e_1 = { error: e_1_1 }; }
            finally {
                try {
                    if (_d && !_d.done && (_a = _c.return)) _a.call(_c);
                }
                finally { if (e_1) throw e_1.error; }
            }
            if (count != null && count > 0)
                this.generalScore = total / count;
            else
                this.generalScore = 0;
        }
        else {
            this.generalScore = 0;
        }
    };
    RatingFullComponent.prototype.mugshotURL = function () {
        //TODO fetch the real profilepig
        return "../../../assets/avatar/anom.png";
    };
    return RatingFullComponent;
}());
export { RatingFullComponent };
