import { map } from 'rxjs/operators';
import { ActivatedRoute } from "@angular/router";
import { UserService } from "../service/user.service";
import { FileService } from "../service/file.service";
import { InvoiceService } from "../service/invoice.service";
import * as FileSaver from "file-saver";
import { LogInService } from "../service/login.service";
var InvoiceComponent = /** @class */ (function () {
    function InvoiceComponent(route, invoiceService, userService, fileService, loginService) {
        this.route = route;
        this.invoiceService = invoiceService;
        this.userService = userService;
        this.fileService = fileService;
        this.loginService = loginService;
    }
    InvoiceComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.route.params.pipe(map(function (params) { return params['id']; }))
            .subscribe(function (invoiceId) {
            _this.invoiceId = null;
            _this.company = null;
            _this.user = _this.userService.getCurrentUser();
            if (_this.user == undefined) {
                _this.userService.loginEmitter.subscribe(function (v) { return _this.ngOnInit(); });
            }
            //If there is an invoice id, this component is used as a view on the invoice data
            //If the invoice id is "new", then we are in registration mode
            if (invoiceId == 'new') {
                //In case it is a new invoice, we can use the current user & company and we do not wait for the invoice's data
                _this.invoice = {};
                _this.invoice.type = "PURCHASE";
                _this.fetchEntityInfo();
            }
            else {
                _this.invoiceId = invoiceId;
                _this.invoiceService.getInvoice(invoiceId).subscribe(function (invoice) {
                    _this.invoice = invoice;
                    _this.fetchEntityInfo();
                });
            }
        });
    };
    InvoiceComponent.prototype.fetchEntityInfo = function () {
        var _this = this;
        //Try fetching the company info from the invoice
        if (this.invoice != null && this.invoice.company != null) {
            this.userService.findCompanyInfo(this.invoice.company.id).subscribe(function (company) { return _this.company = company; });
        }
        else if (this.invoice != null && this.invoice.user != null) { //If there is no company linked to the invoice, fetch the company from the user on the invoice
            this.userService.getUser(this.invoice.user.id).subscribe(function (user) { return _this.user = user; });
        }
        else { //If there is no invoice (or no user on an invoice) fetch the company from the user that is already set (if there is one already
            if (this.user) {
                if (this.user.companyId != null) {
                    this.userService.findCompanyInfo(this.user.companyId).subscribe(function (company) {
                        _this.company = company;
                    });
                }
            }
            else { //If the user was not yet set, subscribe to the userEmitter (which will emit a value once userService has set the user.
                this.userService.userEmitter.subscribe(function (v) {
                    if (_this.user == undefined) {
                        _this.user = v;
                        _this.fetchEntityInfo();
                    }
                });
            }
        }
    };
    InvoiceComponent.prototype.downloadInvoicePDF = function () {
        var _this = this;
        var entityId = this.getInvoiceEntityId();
        this.fileService.downloadInvoicePDF(entityId, this.invoice.id).subscribe(function (n) {
            FileSaver.saveAs(n, _this.invoice.number + ".pdf", true);
        });
    };
    InvoiceComponent.prototype.getInvoiceEntityId = function () {
        if (this.company != null && this.company.id != null)
            return this.company.id;
        return this.user.id;
    };
    return InvoiceComponent;
}());
export { InvoiceComponent };
