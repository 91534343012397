import { filter } from 'rxjs/operators';
import { TaskService } from "../service/task.service";
var ColleaguesInfoComponent = /** @class */ (function () {
    function ColleaguesInfoComponent(taskService) {
        this.taskService = taskService;
        this.colleagueInfo = [];
    }
    ColleaguesInfoComponent.prototype.ngOnInit = function () {
        var _this = this;
        // Only show tasks for the same language pair
        this.taskService.getTaskInfoForProject(this.pId).pipe(filter(function (v) {
            return v.id != _this.currentTask.id &&
                v.sourcelanguage == _this.currentTask.sourcelanguage &&
                v.targetlanguage == _this.currentTask.targetlanguage;
        }))
            .subscribe(function (v) { return _this.colleagueInfo.push(v); });
    };
    return ColleaguesInfoComponent;
}());
export { ColleaguesInfoComponent };
