import { OnInit } from "@angular/core";
import { UserService } from "../../service/user.service";
import { ActivatedRoute, Router } from "@angular/router";
import { ProjectService } from "../../service/project.service";
import { getUserNameFromProject } from "../../util/jobutil";
var ProjectFollowupListComponent = /** @class */ (function () {
    function ProjectFollowupListComponent(route, router, userService, projectService) {
        this.route = route;
        this.router = router;
        this.userService = userService;
        this.projectService = projectService;
        this.projectStates = ["Draft", "Running", "Completed"];
        this.projects = undefined;
        this.filteredProjects = undefined;
        this.projectState = "Running";
        this.loading = false;
    }
    ProjectFollowupListComponent.prototype.ngOnInit = function () {
        this.loadProjects(this.projectState);
    };
    ProjectFollowupListComponent.prototype.findProjects = function (status) {
        // Only do find if current status is different than given one
        if (this.projectState !== status) {
            this.loadProjects(status);
        }
    };
    ProjectFollowupListComponent.prototype.loadProjects = function (status) {
        var _this = this;
        var start = Date.now();
        this.projectState = status;
        this.loading = true;
        this.projectService.getAllProjects(status)
            .subscribe(function (projects) {
            _this.projects = _this.sort(projects);
            _this.setFilteredProjects();
            console.debug("Loading " + _this.filteredProjects.length + " projects took " + (Date.now() - start) + " ms.");
        }, function (error) {
            _this.loading = false;
            console.error("Error loading projects", error);
        }, function () { return _this.loading = false; });
    };
    ProjectFollowupListComponent.prototype.sort = function (projects) {
        return projects.sort(function (p1, p2) {
            if (p1.creationdate && p2.creationdate) {
                if (p1.creationdate > p2.creationdate)
                    return -1;
                if (p1.creationdate < p2.creationdate)
                    return 1;
                else
                    return 0;
            }
            else if (p1.creationdate)
                return -1;
            else if (p2.creationdate)
                return 1;
            else
                return 0;
        });
    };
    ProjectFollowupListComponent.prototype.setFilteredProjects = function () {
        var _this = this;
        if (this.filterText && this.filterText.length > 0)
            this.filteredProjects = this.projects
                .map(function (v) { return filterProject(v, _this.filterText); })
                .filter(function (v) { return v != undefined; });
        else
            this.filteredProjects = this.projects;
    };
    ProjectFollowupListComponent.prototype.refresh = function () {
        this.loadProjects(this.projectState);
    };
    ProjectFollowupListComponent.prototype.gotoProject = function (p) {
        // 'nfreq' (= no-force-requester) ensures the current user is not set as requester of the project if the project has
        // no requester yet (they will still be set when 'place order' is pressed though!)
        this.router.navigate(['/pdetail', p.id], { queryParams: { nfreq: 'y' } });
    };
    return ProjectFollowupListComponent;
}());
export { ProjectFollowupListComponent };
function filterProject(project, text) {
    if (text) {
        var searchString_1 = project.title;
        if (project.files) {
            project.files.forEach(function (f) { return searchString_1 = searchString_1 + " " + f.name; });
        }
        // Include user name
        searchString_1 += getUserNameFromProject(project, false);
        // Include company name
        if (project.requestedby != null) {
            if (project.requestedby.companyName != null)
                searchString_1 += project.requestedby.companyName;
            else if (project.requestedby.companyId != null)
                searchString_1 += project.requestedby.companyId;
        }
        if (searchString_1.trim().toLocaleLowerCase().indexOf(text.trim().toLocaleLowerCase()) >= 0)
            return project;
        else
            return undefined;
    }
    return project;
}
