/**
 * When this handler is created it will replaces the existing "onDestroy" function with the handleOnDestroy-function
 * So, when OnDestroy is called by the lifecylcehook, handleOnDestroy is called
 * That method will call the original onDestroy of the component and unscubscribe all registered subscriptions
 */
var SubscriptionHandler = /** @class */ (function () {
    function SubscriptionHandler(component) {
        this.component = component;
        // The methods are stored on the original component because this component only has access to the lifecycle hook
        this.component['SubscriptionHandlerOrigOnDestroy'] = this.component.ngOnDestroy;
        this.component.ngOnDestroy = this.handleOnDestroy;
        this.component['SubscriptionsToHandle'] = [];
    }
    SubscriptionHandler.prototype.handleOnDestroy = function () {
        // call the original onDestroy function
        this['SubscriptionHandlerOrigOnDestroy']();
        // unsubscribe all the registered subscriptions
        this['SubscriptionsToHandle'].forEach(function (s) { return s.unsubscribe(); });
    };
    /**
     * Register a subscription
     * @param subscription subscription will be registered
     */
    SubscriptionHandler.prototype.push = function (subscription) {
        this.component['SubscriptionsToHandle'].push(subscription);
    };
    return SubscriptionHandler;
}());
export { SubscriptionHandler };
