import { filter, map } from 'rxjs/operators';
import { OnDestroy } from "@angular/core";
import { FileService } from "../service/file.service";
import { SubscriptionHandler } from './subscription.handler';
var FrontpageComponent = /** @class */ (function () {
    function FrontpageComponent(fileservice) {
        this.fileservice = fileservice;
        this.showIconIfUnavailable = true;
        this.frontpageExist = false;
        this.frontpageImage = null;
        this.doctypeImage = null;
        this.subscriptionHandler = new SubscriptionHandler(this);
    }
    FrontpageComponent.prototype.ngOnInit = function () {
        var _this = this;
        if (this.height == null)
            this.height = "100%";
        if (this.width == null)
            this.width = "100%";
        if (this.fileName) {
            this.doctypeImage = this.getDoctypeImageUrl();
            if (this.pId != null) {
                this.handleFrontPageImageUrl();
                var frontPageSubscription = this.fileservice.enrollForProject(this.pId).pipe(map(function (data) {
                    return data;
                }), filter(function (data) { return data.action == "FRONTPAGE_IMG" && data.filename == _this.fileName; })).subscribe(function (data) {
                    _this.handleFrontPageImageUrl();
                });
                this.subscriptionHandler.push(frontPageSubscription);
            }
        }
    };
    FrontpageComponent.prototype.ngOnDestroy = function () {
    };
    FrontpageComponent.prototype.handleFrontPageImageUrl = function () {
        var _this = this;
        if (this.fileName) {
            this.frontpageExist = false; //TODO really check if it exists, will probably need external functions for that
            this.fileservice.getFrontpage(this.pId, this.fileName)
                .subscribe(function (next) {
                _this.frontpageImage = next;
                _this.frontpageExist = true;
            }, function (error) {
                _this.frontpageExist = false;
            });
        }
    };
    FrontpageComponent.prototype.getDoctypeImageUrl = function () {
        // Most images found at https://www.flaticon.com/packs/file-type-set
        var basePath = "../../assets/doctype/";
        if (this.fileName) {
            var filename = this.fileName;
            return basePath + filename.substring(filename.lastIndexOf(".") + 1) + ".png";
        }
    };
    FrontpageComponent.prototype.handleDoctypeImageNotFound = function () {
        this.doctypeImage = "../../../assets/file.png";
    };
    return FrontpageComponent;
}());
export { FrontpageComponent };
