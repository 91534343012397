import { OnInit } from "@angular/core";
import { ProjectService } from "../service/project.service";
import { FileService } from "../service/file.service";
import { TaskService } from "../service/task.service";
import { map } from 'rxjs/operators';
var TaskFileListComponent = /** @class */ (function () {
    function TaskFileListComponent(projectService, taskService, fileService) {
        this.projectService = projectService;
        this.taskService = taskService;
        this.fileService = fileService;
    }
    TaskFileListComponent.prototype.ngOnInit = function () {
        this.setTask(this.task);
    };
    TaskFileListComponent.prototype.setTask = function (task) {
        var _this = this;
        this.task = task;
        this.taskService.availableJobs(this.task).subscribe(function (j) {
            _this.availableJobs = j;
        });
        this.fileService.availableXliffs(this.task).pipe(map(function (v) {
            return v;
        })).subscribe(function (v) {
            _this.availableXliffs = v;
        });
        this.fileService.availablePdfs(this.task).subscribe(function (v) { return _this.availablePdfs = v; });
        this.fileService.availableTMXs(this.task).subscribe(function (v) { return _this.availableTmxs = v; });
    };
    TaskFileListComponent.prototype.getPdf4Source = function (fileName) {
        if (this.docFiles) {
            return this.docFiles.filter(function (f) { return f.docinfo != null && f.docinfo.source4pdf != null && f.docinfo.source4pdf === fileName; })[0];
        }
        else
            return null;
    };
    return TaskFileListComponent;
}());
export { TaskFileListComponent };
