import * as tslib_1 from "tslib";
import { filter, map, publish } from 'rxjs/operators';
import { Observable } from "rxjs";
import "rxjs";
import { HttpClient } from "@angular/common/http";
import { IntervalObservable } from "rxjs/observable/IntervalObservable";
import { isStatCategory, StatCategory } from "../util/statutil";
import { getBaseSocketUrl, getBaseUrl } from "../util/httpclient";
var StatisticsService = /** @class */ (function () {
    function StatisticsService(http) {
        this.http = http;
        this.STATISTICS_SOCKET_URL = getBaseSocketUrl() + "/statistics/feed";
        this.STATISTICS_API = getBaseUrl() + "/api/v1/analysis/stats";
    }
    StatisticsService.prototype.initStatsFeed = function () {
        var _this = this;
        this.ws = Observable.webSocket(this.STATISTICS_SOCKET_URL);
        //Keep the socket alive (every 4 minutes, server timeout is set to 5 minutes)
        IntervalObservable.create(30000).subscribe(function (o) {
            return _this.ws.next({ 'action': 'keepalive' });
        });
        this.messages = this.makeHot(this.ws).pipe(filter(function (m) { return m != null; }));
        // this.messages.subscribe(msg => console.debug("> Received " + JSON.stringify(msg) + " @" + this.STATISTICS_SOCKET_URL),
        //   error => console.error(error));
    };
    //More info see analyses.service.ts
    StatisticsService.prototype.makeHot = function (cold) {
        var obs = cold.pipe(publish());
        obs.connect();
        return obs;
    };
    StatisticsService.prototype.enrollToStatFeed = function (pId, langPair) {
        if (this.ws === undefined || this.ws.closed)
            this.initStatsFeed();
        //To enroll you have to send a message to the socket with the action "enroll" and to which objects you want to enroll
        this.ws.next({ 'action': 'enroll', 'pid': pId });
        //The JSON structure of the message normally has the objects parameters to identify and a "data" object which contains the actual information
        //E.g. {"pid":"10", "filename":"damocles.docx", "data":{"type":"sourcelang", "value":"en"}}
        return this.messages.pipe(map(function (msg) {
            return msg;
        }), filter(function (msg) {
            return msg.pid == pId && (!langPair || langPair == msg.langpair);
        }));
    };
    StatisticsService.prototype.enrollForProject = function (pId) {
        return this.enrollToStatFeed(pId, null).pipe(map(function (msg) { return msg.data; }), //TODO
        map(function (msg) {
            return msg;
        }));
    };
    StatisticsService.prototype.enrollForProjectAndLangPair = function (pId, langPair) {
        return this.enrollToStatFeed(pId, langPair).pipe(
        // Returning full object (and not just "data") because we need to know what language pair these stats are for
        //    .map(msg => msg.data)
        map(function (msg) {
            return msg;
        }));
    };
    StatisticsService.prototype.getAllCategories = function () {
        return this.http.get(this.STATISTICS_API + "/categories");
    };
    StatisticsService.prototype.getCurrentProjectStatistics = function (pId) {
        var obs = this.http.get(this.STATISTICS_API + "/project/" + pId, { responseType: 'json' });
        return this.mapCurrentStatistics(obs);
    };
    /**
     * Deprecated since no stats are being calculated after MT anymore: we assume that the amount of no matches equals
     * the MT word count.
     *
     * @deprecated
     */
    StatisticsService.prototype.getMtStatistics = function (pId) {
        var obs = this.http.get(this.STATISTICS_API + "/mt/project/" + pId, { responseType: 'json' });
        return this.mapMtStatistics(obs);
    };
    StatisticsService.prototype.getCurrentLangpairStatistics = function (pId, langpair) {
        var obs = this.http.get(this.STATISTICS_API + "/langpair/" + pId + "/" + encodeURIComponent(langpair), { responseType: 'json' });
        return this.mapCurrentStatistics(obs);
    };
    StatisticsService.prototype.getTaskStatistics = function (taskId) {
        var obs = this.http.get(this.STATISTICS_API + "/task/" + taskId, { responseType: 'json' });
        return this.mapCurrentStatistics(obs);
    };
    StatisticsService.prototype.hasAllTMStats = function (pId) {
        return this.http.get(this.STATISTICS_API + "/hasalltm/" + pId);
    };
    StatisticsService.prototype.mapCurrentStatistics = function (obs) {
        return obs.pipe(map(function (values) {
            var e_1, _a;
            var map = new Map();
            try {
                for (var values_1 = tslib_1.__values(values), values_1_1 = values_1.next(); !values_1_1.done; values_1_1 = values_1.next()) {
                    var v = values_1_1.value;
                    var currentValue = map.get(v.type);
                    if (currentValue == null)
                        currentValue = 0;
                    currentValue += v.value;
                    map.set(v.type, currentValue);
                }
            }
            catch (e_1_1) { e_1 = { error: e_1_1 }; }
            finally {
                try {
                    if (values_1_1 && !values_1_1.done && (_a = values_1.return)) _a.call(values_1);
                }
                finally { if (e_1) throw e_1.error; }
            }
            return map;
        }));
    };
    StatisticsService.prototype.mapMtStatistics = function (obs) {
        return obs.pipe(map(function (result) {
            var e_2, _a;
            if (result && result.length > 0) {
                var count = 0;
                try {
                    for (var result_1 = tslib_1.__values(result), result_1_1 = result_1.next(); !result_1_1.done; result_1_1 = result_1.next()) {
                        var v = result_1_1.value;
                        // Only take the "fuzzy" category into account
                        // The "no match" category simply means no MT was done for those words (because it already had a good enough TM match)
                        if (isStatCategory(v.type, StatCategory.fuzzy_match)) {
                            count += v.value;
                        }
                    }
                }
                catch (e_2_1) { e_2 = { error: e_2_1 }; }
                finally {
                    try {
                        if (result_1_1 && !result_1_1.done && (_a = result_1.return)) _a.call(result_1);
                    }
                    finally { if (e_2) throw e_2.error; }
                }
                return count;
            }
            else
                return null;
        }));
    };
    return StatisticsService;
}());
export { StatisticsService };
