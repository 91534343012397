import { tap } from 'rxjs/operators';
import { OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
var RootComponent = /** @class */ (function () {
    function RootComponent(activatedRoute) {
        this.activatedRoute = activatedRoute;
        this.isDrop = false;
        this.dropValue = 'large';
        this.isApp = false;
        this.isPriceCalc = false;
    }
    RootComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.activatedRoute.queryParams.pipe(tap(function (params) {
            if (params.hasOwnProperty('dropOnly')) {
                _this.setDropOnly(params.dropOnly);
            }
            else if (params.hasOwnProperty('priceCalc')) {
                _this.setPriceCalc();
            }
            else {
                _this.setMainApp();
            }
        }))
            .subscribe(function (c) {
        });
    };
    RootComponent.prototype.setDropOnly = function (value) {
        this.isDrop = true;
        this.dropValue = value;
        this.isApp = false;
        this.isPriceCalc = false;
    };
    RootComponent.prototype.setPriceCalc = function () {
        this.isDrop = false;
        this.isApp = false;
        this.isPriceCalc = true;
    };
    RootComponent.prototype.setMainApp = function () {
        this.isDrop = false;
        this.isApp = true;
        this.isPriceCalc = false;
    };
    return RootComponent;
}());
export { RootComponent };
