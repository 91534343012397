import * as tslib_1 from "tslib";
import { EventEmitter, SimpleChanges, OnDestroy } from "@angular/core";
import { FileService } from "../../service/file.service";
import * as FileSaver from "file-saver";
import { AnalysisService } from "../../service/analysis.service";
import { MatDialog } from '@angular/material/dialog';
import { FileMetaDataDialog } from '../util/file-meta-data.dialog';
import { ProjectService } from "../../service/project.service";
import { DocumentationPDFDialog } from "./documentation-pdf-dialog";
import { SERVICE_SWORN_TRANSLATION, SERVICE_EDITING } from "../../mock-activities";
import { SubscriptionHandler } from '../../util/subscription.handler';
var SourceFileComponent = /** @class */ (function () {
    function SourceFileComponent(projectService, fileService, analysisService, dialog) {
        this.projectService = projectService;
        this.fileService = fileService;
        this.analysisService = analysisService;
        this.dialog = dialog;
        this.allowRemoval = true;
        this.onFileRemove = new EventEmitter();
        this.onDocPdfUpload = new EventEmitter();
        this.onFileMetaUpdate = new EventEmitter();
        this.isManual = false;
        this.pdfFailed = false;
        this.wordCountChanged = false;
        this.languageChanged = false;
        this.subscriptionHandler = new SubscriptionHandler(this);
    }
    SourceFileComponent.prototype.ngOnInit = function () {
        var _this = this;
        var analysisSubscription = this.analysisService.enrollForFile(this.pId, this.file.name).subscribe(function (data) {
            if (data.type == "wordcount") {
                if (_this.file.wordcount !== data.value)
                    _this.wordCountChanged = true;
                _this.file.wordcount = data.value;
            }
            else if (data.type == "detectedsourcelang") {
                if (_this.file.detectedsourcelang !== data.value)
                    _this.languageChanged = true;
                _this.file.detectedsourcelang = data.value;
                if (_this.file.sourcelang == null)
                    _this.file.sourcelang = data.value;
            }
        });
        this.subscriptionHandler.push(analysisSubscription);
        var projectSubscription = this.projectService.enrollForProjectAndFile(this.pId, this.file.name).subscribe(function (data) {
            if (data.text != undefined) {
                var json = JSON.parse(data.text);
                if (json.key === "pdfFailed") {
                    _this.pdfFailed = json.value;
                }
                if (json.key === "processType") {
                    _this.isManual = (json.value === "MANUAL");
                }
            }
        });
        this.subscriptionHandler.push(projectSubscription);
        this.setFile(this.file);
    };
    SourceFileComponent.prototype.ngOnDestroy = function () {
    };
    SourceFileComponent.prototype.ngOnChanges = function (changes) {
        if (changes.file !== undefined) {
            this.setFile(changes.file.currentValue);
        }
    };
    SourceFileComponent.prototype.setFile = function (file) {
        this.file = file;
        this.isManual = this.file.processType == "MANUAL";
        // TODO: also indicate whether the source file has a custom PDF specified; if so, change the warning text to 'Change custom PDF.'
        this.pdfFailed = this.file.pdfFailed !== undefined ? this.file.pdfFailed : false;
    };
    SourceFileComponent.prototype.download = function () {
        var _this = this;
        this.fileService.downloadSource(this.pId, this.file.name)
            .subscribe(function (n) {
            FileSaver.saveAs(n, _this.file.name, true);
        });
    };
    SourceFileComponent.prototype.remove = function () {
        if (this.allowRemoval) {
            this.onFileRemove.emit(this.file);
        }
    };
    SourceFileComponent.prototype.openFileMetaData = function () {
        var _this = this;
        var dialogRef = this.dialog.open(FileMetaDataDialog, {
            panelClass: 'simpleDialog',
            width: "550px",
            data: { projectId: this.pId, file: this.file }
        });
        dialogRef.afterClosed().subscribe(function (a) {
            if (a == "success") {
                // Set language to empty
                _this.file.sourcelanguage = "";
                _this.file.wordcount = dialogRef.componentInstance.words;
                _this.onFileMetaUpdate.emit({
                    "filename": _this.file.name,
                    "wordcount": _this.file.wordcount
                });
            }
        });
    };
    SourceFileComponent.prototype.uploadDocPdf = function () {
        var _this = this;
        var dialogRef = this.dialog.open(DocumentationPDFDialog, {
            panelClass: 'simpleDialog',
            width: '550px',
            data: { projectId: this.pId, srcFile: this.file }
        });
        dialogRef.afterClosed().subscribe(function (files) {
            if (files)
                _this.uploadDocFiles(files);
        });
    };
    SourceFileComponent.prototype.uploadDocFiles = function (event) {
        var _this = this;
        var docPdf = event.docPdf;
        // We are uploading the documentation file in an async block so the rest of the application does not wait for the
        // upload to complete
        (function () { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var start;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                start = new Date().getTime();
                // 20 second delay to fake lengthy upload (only for debugging)
                // await sleep(20*1000);
                // Upload this as a "doc-PDF" (=PDF version of a source document)
                this.fileService.uploadPdfDocFile(docPdf, this.pId, this.file.name).subscribe(function (data) {
                    var end = new Date();
                    console.log("Project " + _this.pId + ": upload of " + docPdf.name + " [pdf-documentation] in " + (end.getTime() - start) + " ms");
                    // Add the actual file data to the project
                    _this.addDocFileData(docPdf, _this.file.name);
                }, function (error) { return console.log("Error uploading documentation PDF for " + _this.file.name, error); });
                return [2 /*return*/];
            });
        }); })();
    };
    SourceFileComponent.prototype.addDocFileData = function (docPdf, srcFileName) {
        var _this = this;
        // Note that the 'addDocFileData' function does not replace any existing custom PDF for this source file
        // This needs to be done beforehand!
        this.projectService.addDocFileData(docPdf.name, srcFileName, this.pId).subscribe(function (data) {
            _this.onDocPdfUpload.emit(data);
        }, function (error) { return console.log("Error adding documentation PDF data for " + _this.file.name, error); });
    };
    SourceFileComponent.prototype.isManualService = function () {
        return this.service === SERVICE_SWORN_TRANSLATION || this.service === SERVICE_EDITING;
    };
    return SourceFileComponent;
}());
export { SourceFileComponent };
