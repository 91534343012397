import { ElementRef, EventEmitter } from "@angular/core";
import { FormControl } from '@angular/forms';
import { map, startWith } from 'rxjs/operators';
import { DomainService } from "../service/domain.service";
var DomainComponent = /** @class */ (function () {
    function DomainComponent(domainService) {
        this.domainService = domainService;
        this.myControl = new FormControl();
        this.options = [];
        this.domains = [];
        this.domainEmitter = new EventEmitter();
    }
    DomainComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.domainService.getDomains().subscribe(function (d) {
            _this.options = d;
            _this.initFilteredOptions();
        });
    };
    DomainComponent.prototype.initFilteredOptions = function () {
        var _this = this;
        this.filteredOptions = this.myControl.valueChanges.pipe(startWith(''), map(function (v) { return v; }), map(function (val) {
            return _this.filter(val);
        }));
    };
    DomainComponent.prototype.filter = function (val) {
        return this.options.filter(function (option) {
            return option.toLowerCase().indexOf(val.toLowerCase()) === 0;
        });
    };
    DomainComponent.prototype.add = function () {
        if (this.myControl.value.toString().length > 1) {
            this.domains.push(this.myControl.value);
            this.domainEmitter.emit({ action: "add", value: this.myControl.value });
            if (this.options.indexOf(this.myControl.value) < 0)
                this.options.push(this.myControl.value);
            this.myControl.setValue("");
            this.domainInputter.nativeElement.blur();
            this.initFilteredOptions();
        }
    };
    DomainComponent.prototype.remove = function (domain) {
        this.domains.splice(this.domains.indexOf(domain), 1);
        this.domainEmitter.emit({ action: "remove", value: domain });
    };
    return DomainComponent;
}());
export { DomainComponent };
