import * as tslib_1 from "tslib";
import { switchMap } from 'rxjs/operators';
import { ActivatedRoute, Router } from "@angular/router";
import { TaskService } from "../service/task.service";
import { ReviewService } from "../service/review.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { UserService } from "../service/user.service";
import { existsAndNotEmpty } from "../util/apputil";
var ReviewCreateComponent = /** @class */ (function () {
    function ReviewCreateComponent(reviewService, taskService, userService, route, router, snackbar) {
        this.reviewService = reviewService;
        this.taskService = taskService;
        this.userService = userService;
        this.route = route;
        this.router = router;
        this.snackbar = snackbar;
        this.review = Object();
        this.anonymous = false;
    }
    ReviewCreateComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.route.params.pipe(switchMap(function (params) { return _this.taskService.getTask(params['id']); }))
            .subscribe(function (t) {
            _this.task = t;
            var currentUser = _this.userService.getCurrentUser();
            _this.setReview(t, currentUser);
        });
        this.route.queryParams
            .filter(function (params) { return params.callback; })
            .subscribe(function (params) {
            _this.callback = params.callback;
        });
    };
    ReviewCreateComponent.prototype.isExistingReview = function () {
        return existsAndNotEmpty(this.review.id);
    };
    ReviewCreateComponent.prototype.setReview = function (t, currentUser) {
        var _this = this;
        this.reviewService.getReviewsForTask(t.id).subscribe(function (r) {
            var e_1, _a;
            var existingFound = false;
            if (existsAndNotEmpty(r)) {
                try {
                    for (var _b = tslib_1.__values(r), _c = _b.next(); !_c.done; _c = _b.next()) {
                        var rev = _c.value;
                        if (rev.writer !== undefined && rev.writer.id === currentUser.id) {
                            _this.review = rev;
                            existingFound = true;
                            break;
                        }
                    }
                }
                catch (e_1_1) { e_1 = { error: e_1_1 }; }
                finally {
                    try {
                        if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                    }
                    finally { if (e_1) throw e_1.error; }
                }
            }
            if (!existingFound)
                _this.initReview(t, currentUser);
        });
    };
    ReviewCreateComponent.prototype.initReview = function (t, currentUser) {
        var e_2, _a;
        this.review.task = Object();
        this.review.task.id = t.id;
        this.review.task.projectId = t.projectId;
        this.review.task.activity = t.activity;
        this.review.task.sourceLanguage = t.sourcelanguage;
        this.review.task.targetLanguage = t.targetlanguage;
        this.review.target = Object();
        this.review.target.id = t.vendorId;
        this.review.target.name = t.vendor;
        if (currentUser) {
            this.review.writer = Object();
            this.review.writer.id = currentUser.id;
            this.review.writer.name = currentUser.firstName + " " + currentUser.lastName;
        }
        this.review.scores = [];
        try {
            for (var _b = tslib_1.__values(this.reviewService.categories), _c = _b.next(); !_c.done; _c = _b.next()) {
                var category = _c.value;
                var score = Object();
                score.category = category;
                score.rate = 5;
                this.review.scores.push(score);
            }
        }
        catch (e_2_1) { e_2 = { error: e_2_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_2) throw e_2.error; }
        }
    };
    ReviewCreateComponent.prototype.postReview = function () {
        var _this = this;
        if (this.anonymous) {
            this.review.writer = undefined;
        }
        this.reviewService.postReview(this.review).subscribe(function (v) {
            _this.snackbar.open("Review posted", "", {
                duration: 2000
            });
            _this.redirect();
        }, function (e) {
            console.error("Error posting review", e);
        });
        this.redirect();
    };
    ReviewCreateComponent.prototype.redirect = function () {
        if (this.callback !== undefined)
            this.router.navigate([this.callback]);
        else
            this.router.navigate(["newproject"]);
    };
    return ReviewCreateComponent;
}());
export { ReviewCreateComponent };
