import * as tslib_1 from "tslib";
import { EventEmitter, OnInit } from "@angular/core";
import { NativeDateAdapter } from "@angular/material/core";
import { TimeComponent } from "./time.component";
export var NATIVE_DATE_FORMATS = {
    parse: {
        dateInput: { month: 'short', year: 'numeric', day: 'numeric' }
    },
    display: {
        // dateInput: { month: 'short', year: 'numeric', day: 'numeric' },
        dateInput: 'input',
        monthYearLabel: { year: 'numeric', month: 'short' },
        dateA11yLabel: { year: 'numeric', month: 'long', day: 'numeric' },
        monthYearA11yLabel: { year: 'numeric', month: 'long' },
    }
};
var AppDateAdapter = /** @class */ (function (_super) {
    tslib_1.__extends(AppDateAdapter, _super);
    function AppDateAdapter() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    AppDateAdapter.prototype.format = function (date, displayFormat) {
        if (displayFormat === 'input') {
            return formatDate(date);
        }
        else
            return _super.prototype.format.call(this, date, displayFormat);
    };
    AppDateAdapter.prototype.parse = function (value) {
        try {
            var d = new Date(value);
            d.setHours(0, 0, 0);
            return d;
        }
        catch (e) {
            throw e;
        }
    };
    return AppDateAdapter;
}(NativeDateAdapter));
export { AppDateAdapter };
var DateTimeComponent = /** @class */ (function () {
    function DateTimeComponent() {
        this.defaultToToday = true;
        this.dateSelected = new EventEmitter();
        this.invalidDateSelected = new EventEmitter();
        this.tooltip = "Pick a date. (yyyy-mm-dd)";
        this.textColor = "inherit";
        this.validTime = false;
    }
    DateTimeComponent.prototype.ngOnInit = function () {
        this.initSelectedDate();
        this.minDate = this.initTimeOnDate(this.minDate);
        this.maxDate = this.initTimeOnDate(this.maxDate);
        this.currentTime = this.dateToTime(this.inputDate);
        // Check whether the selected date is valid
        this.checkDate();
        this.validTime = this.updateTime();
    };
    DateTimeComponent.prototype.reInit = function (newInputDate) {
        this.inputDate = newInputDate;
        this.initSelectedDate();
    };
    DateTimeComponent.prototype.initSelectedDate = function () {
        if (!this.inputDate && this.defaultToToday) {
            // If no selected date given, then use today
            this.inputDate = new Date();
        }
        else if (this.inputDate && !(this.inputDate instanceof Date)) {
            // If a selected date is given, but it is not a Date object, then attempt to create a Date object
            this.inputDate = new Date(this.inputDate);
        }
        if (this.inputDate)
            this.selectedDate = this.initTimeOnDate(new Date(this.inputDate));
    };
    DateTimeComponent.prototype.initTimeOnDate = function (d) {
        // Set default time for date
        if (d != undefined)
            d.setHours(23, 59, 0, 0);
        return d;
    };
    DateTimeComponent.prototype.checkDate = function () {
        if (this.isValid()) {
            this.textColor = "inherit";
        }
        else {
            this.textColor = "red";
        }
    };
    DateTimeComponent.prototype.isValid = function () {
        var sd = this.initTimeOnDate(this.selectedDate);
        if (sd == null) {
            this.tooltip = "Invalid date format. Dates must be of format 'yyyy-mm-dd'.";
            return false;
        }
        if (this.minDate != null && this.minDate > sd) {
            this.tooltip = "Date is earlier than the mimimum date (" + formatDate(this.minDate) + ").";
            return false;
        }
        if (this.maxDate != null && this.maxDate < sd) {
            this.tooltip = "Date is newer than the maximum date (" + formatDate(this.maxDate) + ").";
            return false;
        }
        this.tooltip = "Pick a date. (yyyy-mm-dd)";
        return true;
    };
    DateTimeComponent.prototype.setSelectedDate = function (v) {
        this.selectedDate = new Date(v);
        this.currentTime = this.dateToTime(this.selectedDate);
        this.timeComponent.setTime(this.currentTime);
        this.checkDate();
    };
    DateTimeComponent.prototype.selectDate = function (v) {
        this.validTime = this.updateTime();
        if (this.isValid() && this.validTime)
            this.dateSelected.emit(this.getFullDate());
        else
            this.flagInvalidDate(this.getFullDate());
    };
    DateTimeComponent.prototype.isMinDay = function () {
        return this.minDate != undefined && this.selectedDate != undefined && this.selectedDate.getUTCDate() == this.minDate.getUTCDate();
    };
    DateTimeComponent.prototype.getFullDate = function (time) {
        if (time === void 0) { time = this.currentTime; }
        var dateTime = new Date(this.selectedDate.getTime());
        if (time != undefined) {
            var hours = parseInt(this.currentTime.substr(0, 2));
            var minutes = parseInt(this.currentTime.substr(3, 2));
            dateTime.setHours(hours, minutes, 0, 0);
        }
        else {
            dateTime = this.initTimeOnDate(dateTime);
        }
        return dateTime;
    };
    DateTimeComponent.prototype.getHours = function () {
        if (this.currentTime != undefined)
            return "" + parseInt(this.currentTime.substr(0, 2));
    };
    DateTimeComponent.prototype.getMinutes = function () {
        if (this.currentTime != undefined) {
            var index = this.currentTime.indexOf(":");
            return "" + parseInt(this.currentTime.substr(index + 1));
        }
    };
    DateTimeComponent.prototype.dateToTime = function (d) {
        if (d != undefined) {
            return d.getHours() + ":" + d.getMinutes();
        }
        return null;
    };
    DateTimeComponent.prototype.selectTime = function (time) {
        this.currentTime = time;
        this.validTime = true;
        if (this.isValid()) {
            this.dateSelected.emit(this.getFullDate());
        }
        else
            this.flagInvalidDate(this.getFullDate(time));
    };
    DateTimeComponent.prototype.flagInvalidDate = function (date) {
        this.validTime = false;
        this.invalidDateSelected.emit(date);
    };
    /**
     * Returns whether or not the 'time' currently selected is valid.
     */
    DateTimeComponent.prototype.updateTime = function () {
        if (this.timeComponent != undefined) {
            var sd = this.initTimeOnDate(this.selectedDate);
            // If the selected date is equal to or earlier than minimum date
            if (sd != undefined && this.minDate != undefined
                && sd.getUTCDate() <= this.minDate.getUTCDate()) {
                // If no time specified yet, or time is smaller than minimum time
                if (!this.currentTime || this.currentTime <= this.timeComponent.actualMinTime) {
                    this.currentTime = this.timeComponent.actualMinTime;
                }
            }
            // This field is normally kept up to date through @Input(), however on the moment of validate time it is not yet up to date
            this.timeComponent.checkMinimum = this.isMinDay();
            return this.timeComponent.validateTime();
        }
        return true;
    };
    return DateTimeComponent;
}());
export { DateTimeComponent };
/**
 * Format the date according to the international notation ('yyyy-mm-dd').
 *
 * @param d date to format
 */
export function formatDate(d) {
    var day = d.getDate().toLocaleString(undefined, { minimumIntegerDigits: 2 });
    var month = (d.getMonth() + 1).toLocaleString(undefined, { minimumIntegerDigits: 2 });
    var year = d.getFullYear();
    return year + "-" + month + "-" + day;
}
/**
 * Format the date-time according to the international notation ('yyyy-mm-ddThh:mm').
 *
 * @param d date to format
 */
export function formatDateForDatabase(d) {
    var day = d.getDate().toLocaleString(undefined, { minimumIntegerDigits: 2 });
    var month = (d.getMonth() + 1).toLocaleString(undefined, { minimumIntegerDigits: 2 });
    var year = d.getFullYear();
    return year + "-" + month + "-" + day + "T" + d.getHours() + ":" + d.getMinutes();
}
