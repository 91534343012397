import { EventEmitter } from "@angular/core";
var HubspotPageComponent = /** @class */ (function () {
    function HubspotPageComponent() {
        this.isSelected = false;
        this.onSelect = new EventEmitter();
    }
    HubspotPageComponent.prototype.ngOnInit = function () {
        var _this = this;
        //Fetch the master page
        if (this.page.translated_from_id) {
            this.masterPage = this.allPages.find(function (p) { return p.id === _this.page.translated_from_id; });
            console.debug(this.masterPage);
            console.debug("MasterPage");
        }
    };
    HubspotPageComponent.prototype.toggleSelect = function () {
        this.isSelected = !this.isSelected;
        this.onSelect.emit(JSON.stringify({
            pageId: this.page.id,
            selected: this.isSelected
        }));
    };
    return HubspotPageComponent;
}());
export { HubspotPageComponent };
