import { filter, flatMap, map } from 'rxjs/operators';
import { SimpleChanges } from "@angular/core";
import { FileService } from "../../service/file.service";
import { HttpClient } from '@angular/common/http';
import { MatDialog } from '@angular/material';
import { HelpDialogComponent } from '../../util/help.dialog.component';
import * as FileSaver from "file-saver";
import { getSourceFiles, toLangPairString } from "../../util/jobutil";
var LangpairTargetFilesComponent = /** @class */ (function () {
    function LangpairTargetFilesComponent(fileService, dialog, http) {
        this.fileService = fileService;
        this.dialog = dialog;
        this.http = http;
        this.sourceFiles = [];
        this.downloadableFilesInfo = [];
    }
    LangpairTargetFilesComponent.prototype.ngOnInit = function () {
        var _this = this;
        var langpairString = toLangPairString({ source: this.langPair.source, target: this.langPair.target });
        this.sourceFiles = getSourceFiles(this.project);
        this.downloadableFilesInfo = [];
        this.fileService.fetchAvailableFinals(this.project.id, langpairString)
            .pipe(filter(function (f) { return f != undefined; }), map(function (f) { return f; }), flatMap(function (f) { return f; }), map(function (filename) { return _this.sourceFiles.find(function (f) { return f.name === filename; }); }))
            .subscribe(function (f) {
            _this.downloadableFilesInfo.push(f);
        });
    };
    LangpairTargetFilesComponent.prototype.ngOnChanges = function (changes) {
        if (changes.viewAsTiles) {
            this.viewAsTiles = changes.viewAsTiles.currentValue;
        }
    };
    LangpairTargetFilesComponent.prototype.openTransCheckHelp = function () {
        var _this = this;
        this.http.get('assets/help/transcheck-help.html', { responseType: 'text' })
            .subscribe(function (helptext) {
            _this.dialog.open(HelpDialogComponent, {
                width: '700px',
                maxHeight: '65%',
                data: { helpTitle: "Why and how should you do translation checking?", helpHtmlContent: helptext }
            });
        });
    };
    LangpairTargetFilesComponent.prototype.download = function (file) {
        var langpairString = toLangPairString(this.langPair);
        this.fileService.downloadFinal(this.project.id, langpairString, file.name)
            .subscribe(function (n) {
            FileSaver.saveAs(n, file.name, true);
        });
    };
    LangpairTargetFilesComponent.prototype.getLpString = function () {
        return toLangPairString(this.langPair);
    };
    return LangpairTargetFilesComponent;
}());
export { LangpairTargetFilesComponent };
