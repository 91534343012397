import { HttpClient } from "@angular/common/http";
import { getBaseUrl } from "../util/httpclient";
import { existsAndNotEmpty } from "../util/apputil";
var ReviewService = /** @class */ (function () {
    function ReviewService(http) {
        this.http = http;
        this.REVIEW_API = getBaseUrl() + "/api/v1/reviews";
        this.categories = [{ name: "How good is the quality?", code: "Quality" }, {
                name: "How likely are you to send new tasks?",
                code: "Reuse"
            }];
    }
    ReviewService.prototype.postReview = function (review) {
        if (existsAndNotEmpty(review.id))
            return this.http.post(this.REVIEW_API + "/update", JSON.stringify(review), { responseType: 'text' });
        else
            return this.http.post(this.REVIEW_API + "/insert", JSON.stringify(review), { responseType: 'text' });
    };
    ReviewService.prototype.getReviewsForUser = function (userId) {
        return this.http.get(this.REVIEW_API + "/findbyuser/" + userId);
    };
    ReviewService.prototype.getReviewSummaryForUser = function (userId) {
        return this.http.get(this.REVIEW_API + "/findbyuser/summary/" + userId);
    };
    ReviewService.prototype.getReviewsForTask = function (taskId) {
        return this.http.get(this.REVIEW_API + "/findbytask/" + taskId);
    };
    return ReviewService;
}());
export { ReviewService };
