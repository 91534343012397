import { of as observableOf } from 'rxjs';
import { ACTIVITIES, SERVICES, ACTIVITY_EDITING, EDITING_DEPENDENCIES, DEFAULT_DEPENDENCIES } from "../mock-activities";
/**
 * Created by jefv on 27/09/2017.
 */
var ActivityService = /** @class */ (function () {
    function ActivityService() {
    }
    ActivityService.prototype.getActivities = function () {
        return observableOf(ACTIVITIES);
    };
    ActivityService.prototype.getServices = function () {
        return observableOf(SERVICES);
    };
    ActivityService.prototype.getDependenciesForActivity = function (activity) {
        if (ACTIVITY_EDITING === activity) {
            return observableOf(EDITING_DEPENDENCIES);
        }
        else {
            return observableOf(DEFAULT_DEPENDENCIES);
        }
    };
    return ActivityService;
}());
export { ActivityService };
