import { EventEmitter } from "@angular/core";
var RatingComponent = /** @class */ (function () {
    function RatingComponent() {
        this.editingRating = 0;
        this.onStarSelected = new EventEmitter();
    }
    RatingComponent.prototype.ngOnInit = function () {
        this.editingRating = this.rating;
    };
    RatingComponent.prototype.starChecked = function (from) {
        if (this.editingRating >= from) {
            return "checked";
        }
        else
            return "unchecked";
    };
    RatingComponent.prototype.rate = function (v) {
        // if(this.editing)
        this.editingRating = v;
    };
    RatingComponent.prototype.setRate = function () {
        this.rating = this.editingRating;
        this.onStarSelected.emit(this.rating);
    };
    return RatingComponent;
}());
export { RatingComponent };
