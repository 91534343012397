import * as tslib_1 from "tslib";
import { ElementRef, EventEmitter, OnInit } from "@angular/core";
import { FileService } from "../../service/file.service";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ErrorSnackbarComponent } from "../../error.snackbar.component";
import { Subject } from "rxjs";
import { sleep } from "../../util/apputil";
import { UploadDataService } from "./upload-data.service";
var FileAcceptorComponent = /** @class */ (function () {
    function FileAcceptorComponent(fileService, uploadDataService, snackbar, dialog) {
        this.fileService = fileService;
        this.uploadDataService = uploadDataService;
        this.snackbar = snackbar;
        this.dialog = dialog;
        this.uploadedFiles = [];
        this.uploadedDocFiles = [];
        this.onFileUpload = new EventEmitter();
        this.onFileLanguageUpdate = new EventEmitter();
        this.onFileRemove = new EventEmitter();
        this.onDocFileUpload = new EventEmitter();
        this.fileUpload = "";
        this.docFileUpload = "";
        this.sourceLanguageCount = 0;
    }
    FileAcceptorComponent.prototype.ngOnInit = function () {
        this.calcSourceLangCount();
    };
    FileAcceptorComponent.prototype.uploadFiles = function (event) {
        var _this = this;
        var fileList = event.target.files;
        if (fileList.length > 0) {
            var _loop_1 = function () {
                var file = fileList[i];
                // Check if there is already a file with the same name in the project and show an error snackbar
                if (this_1.uploadedFiles.find(function (v) { return v.name === file.name; })) {
                    var ref = this_1.snackbar.openFromComponent(ErrorSnackbarComponent, {
                        duration: 3000,
                        verticalPosition: "top",
                        data: {
                            errorText: "\n                Sorry, you cannot add 2 files with the same name. <br/>\n                A file with name <span style=\"font-weight: bold\">" + file.name + "</span> already exists."
                        }
                    });
                    return { value: void 0 };
                }
            };
            var this_1 = this;
            // Check for duplicate files
            for (var i = 0; i < fileList.length; i++) {
                var state_1 = _loop_1();
                if (typeof state_1 === "object")
                    return state_1.value;
            }
            var _loop_2 = function () {
                var file = fileList[i];
                // Create a subject to send progress events to
                var progress = new Subject();
                // Store the subject as observable in a data provider, so we keep track of it even when navigating away from the page
                this_2.uploadDataService.addUploadData(this_2.pId, file.name, progress);
                // We are uploading the source file in an async block so the rest of the application does not wait for the
                // upload to complete
                (function () { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                    var start;
                    var _this = this;
                    return tslib_1.__generator(this, function (_a) {
                        start = new Date().getTime();
                        this.fileService.uploadProjectFileWithProgress(file, this.pId, null, progress).subscribe(function (data) {
                            var end = new Date();
                            console.log("Project " + _this.pId + ": upload of " + file.name + " in " + (end.getTime() - start) + " ms");
                        }, function (error) { return console.error("Error uploading source file " + file.name + " => ", error); }, function () {
                            progress.complete();
                            _this.uploadDataService.removeUploadData(_this.pId, file.name);
                        });
                        return [2 /*return*/];
                    });
                }); })();
                this_2.onFileUpload.emit(file);
                this_2.uploadedFiles.push(file);
            };
            var this_2 = this;
            // Loop and upload the files
            for (var i = 0; i < fileList.length; i++) {
                _loop_2();
            }
        }
        this.fileUpload = null;
    };
    FileAcceptorComponent.prototype.fakeProgress = function (prgrss, filename) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var start;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        start = 10;
                        _a.label = 1;
                    case 1:
                        if (!(start <= 100)) return [3 /*break*/, 6];
                        prgrss.next(start);
                        start = start + 1;
                        if (!(start % 25 === 0)) return [3 /*break*/, 3];
                        return [4 /*yield*/, sleep(30 * 1000)];
                    case 2:
                        _a.sent();
                        return [3 /*break*/, 5];
                    case 3: return [4 /*yield*/, sleep(500)];
                    case 4:
                        _a.sent();
                        _a.label = 5;
                    case 5: return [3 /*break*/, 1];
                    case 6:
                        prgrss.complete();
                        this.uploadDataService.removeUploadData(this.pId, filename);
                        return [2 /*return*/];
                }
            });
        });
    };
    FileAcceptorComponent.prototype.openDocUploadDialog = function () {
        var _this = this;
        var dialogRef = this.dialog.open(DocumentationUploadDialog, {
            width: '500px',
            height: '350px',
            data: { srcFiles: this.uploadedFiles, docFiles: this.uploadedDocFiles }
        });
        dialogRef.afterClosed().subscribe(function (files) {
            if (files)
                _this.uploadDocFiles(files);
        });
    };
    FileAcceptorComponent.prototype.uploadDocFiles = function (event) {
        var _this = this;
        var file = event.docfile;
        // Check if there is already a documentation file with the same name in the project and show an error if so
        if (this.uploadedDocFiles.find(function (v) { return v.name === file.name; })) {
            var ref = this.snackbar.openFromComponent(ErrorSnackbarComponent, {
                duration: 3000,
                verticalPosition: "top",
                data: { errorText: 'Sorry, you cannot add 2 files with the same name.' }
            });
            return;
        }
        // We are uploading the documentation file in an async block so the rest of the application does not wait for the
        // upload to complete
        (function () { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var start;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                start = new Date().getTime();
                // 20 second delay to fake lengthy upload (only for debugging)
                // await sleep(20*1000);
                // If a source4pdf is specified, then we need to upload this as a "doc-PDF" (=PDF version of a source document)
                if (event.source4pdf != null)
                    this.fileService.uploadPdfDocFile(file, this.pId, event.source4pdf).subscribe(function (data) {
                        var end = new Date();
                        console.log("Project " + _this.pId + ": upload of " + file.name + " [pdf-documentation] in " + (end.getTime() - start) + " ms");
                        _this.snackbar.open("Added documentation file '" + file.name + "' to project.", "", {
                            duration: 2000
                        });
                    }, function (error) { return console.error("Error uploading documentation file", error); });
                else
                    this.fileService.uploadDocFile(file, this.pId).subscribe(function (data) {
                        var end = new Date();
                        console.log("Project " + _this.pId + ": upload of " + file.name + " [documentation] in " + (end.getTime() - start) + " ms");
                        _this.snackbar.open("Added documentation file '" + file.name + "' to project.", "", {
                            duration: 2000
                        });
                    }, function (error) { return console.error("Error uploading documentation file", error); });
                return [2 /*return*/];
            });
        }); })();
        this.onDocFileUpload.emit(event);
        this.docFileUpload = null;
    };
    FileAcceptorComponent.prototype.getPdf4Source = function (file) {
        if (this.uploadedDocFiles) {
            return this.uploadedDocFiles.filter(function (f) { return f.docinfo != null && f.docinfo.source4pdf != null && f.docinfo.source4pdf === file.name; })[0];
        }
        else
            return null;
    };
    FileAcceptorComponent.prototype.removeFile = function (fileToRemove) {
        this.uploadedFiles.splice(this.uploadedFiles.indexOf(fileToRemove), 1);
        this.onFileRemove.emit(fileToRemove);
        this.calcSourceLangCount();
    };
    FileAcceptorComponent.prototype.updateLanguage = function (file, $event) {
        var eventMsg = Object();
        eventMsg.filename = file.name;
        eventMsg.lang = $event;
        this.calcSourceLangCount();
        this.onFileLanguageUpdate.emit(eventMsg);
    };
    FileAcceptorComponent.prototype.calcSourceLangCount = function () {
        this.sourceLanguageCount = this.uploadedFiles.map(function (f) { return f.sourcelang; }).filter(onlyUnique).length;
    };
    FileAcceptorComponent.prototype.openFileBrowser = function () {
        if (this.uploadInput)
            this.uploadInput.nativeElement.click();
    };
    return FileAcceptorComponent;
}());
export { FileAcceptorComponent };
function onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
}
// TODO: enable "drag & drop" for adding files
// TODO: allow the user to add multiple files in the dialog (instead of only one)
// => TODO: allow the user to upload multiple files at once (by selecting a directory or a zip file)
// TODO: in case of a documentation-PDF being added, replace the previous one if there was any for the same source file?
var DocumentationUploadDialog = /** @class */ (function () {
    function DocumentationUploadDialog(dialogRef, data) {
        this.dialogRef = dialogRef;
        this.data = data;
        this.srcFiles = [];
        this.docFiles = [];
    }
    DocumentationUploadDialog.prototype.ngOnInit = function () {
        if (this.data) {
            this.srcFiles = this.data.srcFiles;
            this.docFiles = this.data.docFiles;
        }
        this.errorMsg = null;
    };
    DocumentationUploadDialog.prototype.selectFile = function (event) {
        var fileList = event.target.files;
        if (fileList.length > 0) {
            var file_1 = fileList[0];
            if (this.docFiles && this.docFiles.find(function (v) { return v.name === file_1.name; })) {
                this.errorMsg = "Sorry, you cannot add 2 files with the same name, and a file with the name " + file_1.name + " already exists.";
                return;
            }
            this.errorMsg = null;
            this.docFile = file_1;
        }
    };
    DocumentationUploadDialog.prototype.isPdf = function () {
        if (this.docFile) {
            return this.docFile.name.toLowerCase().endsWith(".pdf");
        }
        return false;
    };
    DocumentationUploadDialog.prototype.upload = function () {
        this.dialogRef.close({
            docfile: this.docFile,
            source4pdf: this.selectedSource4Pdf ? this.selectedSource4Pdf.name : null
        });
    };
    return DocumentationUploadDialog;
}());
export { DocumentationUploadDialog };
