import { HttpClient } from "@angular/common/http";
import { getBaseUrl } from "../util/httpclient";
import { UserService } from "./user.service";
export var PAYMENT_METHOD_STRIPE = "STRIPE";
export var PAYMENT_METHOD_BANKTRANSFER = "BANKTRANSFER";
export var PAYMENT_METHOD_PAYPAL = "PAYPAL";
var InvoiceService = /** @class */ (function () {
    function InvoiceService(http, userService) {
        this.http = http;
        this.userService = userService;
        this.INVOICE_API = getBaseUrl() + "/api/v1/invoices";
        this.PAYMENT_API = getBaseUrl() + "/api/v1/payment";
        this.PROJECT_API = getBaseUrl() + "/api/v1/projects";
    }
    InvoiceService.prototype.registerInvoice = function (invoice, tasks, rewards, company) {
        //Set some task info to the invoice
        invoice.tasks = [];
        tasks.map(function (t) {
            var taskInvInfo = {};
            taskInvInfo.id = t.id;
            taskInvInfo.price = t.price;
            taskInvInfo.data = t.activity + " " + t.sourcelanguage + " " + t.targetlanguage;
            return taskInvInfo;
        }).forEach(function (t) {
            invoice.tasks.push(t);
        });
        //Set some reward info to the invoice
        invoice.rewards = [];
        rewards.map(function (r) {
            var rewardInvInfo = {};
            rewardInvInfo.inviteId = r.inviteId;
            rewardInvInfo.projectId = r.projectId;
            rewardInvInfo.rewardAmount = r.rewardAmount;
            return rewardInvInfo;
        }).forEach(function (r) { return invoice.rewards.push(r); });
        //Set some company info to the invoice
        invoice.company = {};
        invoice.company.id = company.id;
        invoice.company.name = company.name;
        invoice.company.address = company.address;
        invoice.company.country = company.country;
        invoice.company.vat = company.vat;
        invoice.company.bankaccount = company.bankaccount;
        invoice.uploadby = this.userService.getCurrentUser();
        //Post the invoice to the service
        return this.http.post(this.INVOICE_API + "/register", JSON.stringify(invoice), { responseType: 'json' });
    };
    InvoiceService.prototype.getInvoice = function (invoiceId) {
        return this.http.get(this.INVOICE_API + "/findbyid/" + invoiceId);
    };
    InvoiceService.prototype.findRegisteredPurchaseInvoices = function (companyId) {
        return this.http.get(this.INVOICE_API + "/purchase/findbycompanyid/" + companyId);
    };
    InvoiceService.prototype.findRegisteredCompanySalesInvoices = function (companyId) {
        return this.http.get(this.INVOICE_API + "/sales/findbycompanyid/" + companyId);
    };
    InvoiceService.prototype.findRegisteredUserSalesInvoices = function (userId) {
        return this.http.get(this.INVOICE_API + "/sales/findbyuserid/" + userId);
    };
    InvoiceService.prototype.findRegisteredProjectSalesInvoices = function (projectId) {
        return this.http.get(this.INVOICE_API + "/sales/findbyprojectid/" + projectId);
    };
    InvoiceService.prototype.findPaymentMethod = function (userId) {
        return this.http.get(this.PAYMENT_API + "/methods/" + userId);
    };
    InvoiceService.prototype.hasPaymentMethodRecord = function (userId) {
        return this.http.get(this.PAYMENT_API + "/hasrecord/" + userId);
    };
    InvoiceService.prototype.storeUserPaymentMethod = function (intent) {
        return this.http.post(this.PAYMENT_API + "/storemethod/" + this.userService.getCurrentUser().id, JSON.stringify(intent), { responseType: 'json' });
    };
    InvoiceService.prototype.storeProjectPaypal = function (project, order) {
        return this.http.post(this.PROJECT_API + '/storepaypal/' + project, JSON.stringify(order), { responseType: 'json' });
    };
    InvoiceService.prototype.clearPaymentMethod = function (userId) {
        return this.http.post(this.PAYMENT_API + "/clearmethod/" + userId, "", { responseType: 'text' });
    };
    InvoiceService.prototype.getPaymentIntent = function () {
        return this.http.get(this.PAYMENT_API + '/paymentintent');
    };
    return InvoiceService;
}());
export { InvoiceService };
