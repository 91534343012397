import * as tslib_1 from "tslib";
import { EventEmitter, SimpleChanges, OnDestroy } from "@angular/core";
import { TaskService } from "../../service/task.service";
import { TaskSpec } from "../../dto/job";
import { Observable, Subject } from "rxjs";
import { debounceTime, flatMap, map, switchMap, tap } from "rxjs/operators";
import { PricingService } from "../../service/pricing.services";
import { StatisticsService } from "../../service/statistics.service";
import { UserService } from "../../service/user.service";
import { VendorService } from "../../service/vendor.service";
import { SubscriptionHandler } from '../../util/subscription.handler';
import { toLangPairString } from '../../util/jobutil';
var LanguageBarComponent = /** @class */ (function () {
    function LanguageBarComponent(taskService, pricingService, statisticsService, userService, vendorService) {
        this.taskService = taskService;
        this.pricingService = pricingService;
        this.statisticsService = statisticsService;
        this.userService = userService;
        this.vendorService = vendorService;
        this.translators = undefined;
        this.revisers = undefined;
        this.isCollapsed = true;
        // With project creation the translators and revisers are loaded from the project creation component
        // This is so the preloading can be done once the languages are selected (which makes it faster)
        // With project followup we load them within this component
        this.loadInternally = false;
        this.projectFinal = false;
        this.isQuote = false;
        this.onNext = new EventEmitter();
        this.onExpand = new EventEmitter();
        this.onPriceChange = new EventEmitter();
        this.translationTask = undefined;
        this.filteredRevisers = [];
        this.revisionTasks = [];
        this.revisionRounds = [undefined];
        this.dbTasks = [];
        this.revisersCount = 0;
        this.totalprice = 0;
        this.serviceFee = 0;
        this.taskStoreObservable = new Subject();
        this.subscriptionHandler = new SubscriptionHandler(this);
        /**
         * So, there's a multitude of triggers invoking this method when you select a new translator.
         *
         * First off, the onTranslatorSelected is invoked, which calls this method and then creates a new task based on the selection.
         * When a new task is created, the previous one is cancelled and a new one is created: both these actions each trigger
         * (through the task feed we enrolled to in ngOnInit) again a recalculation of the pricing.
         * Furthermore, translator-list is enrolled to the price feed, which gets an event too due to the update in tasks, and
         * couples this back here.
         *
         * To lower the amount of recalculations, we post an event on the event emitter and debounce with 500ms so that the observer itself
         * is only called every 500ms after the last event is emitted
         */
        this.recalcTotalPricingEmitter = new EventEmitter();
    }
    LanguageBarComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.initRecalcTotalPricingObs();
        this.initTasksFromServer();
        // Enroll to receive any updates to the tasks, so we can update the total price accordingly
        var projectLPFeedSubscription = this.taskService.enrollForProjectAndLangPair(this.projectId, this.langpair.source + "_" + this.langpair.target)
            .pipe(debounceTime(500), tap(function (v) {
            // console.debug("LanguageBar " + this.langpair.target + ": task message received", v);
            if (v.action === "TASK_CREATE" || v.action === "TASK_CANCEL") {
                var taskId = v.task_id;
                // If no task ID or..
                if (taskId === null ||
                    // If a task was created that we don't know about yet or...
                    (v.action === "TASK_CREATE" && !_this.hasTask(taskId)) ||
                    // If a task was canceled we do know about
                    (v.action === "TASK_CANCEL" && _this.hasTask(taskId))) {
                    // Then update the tasks
                    _this.initTasksFromServer();
                }
                // Else we can simply assume we're already aware of the changes coming in (since we did them ourselves here)
            }
            else {
                _this.loadProfilePics();
                _this.recalcTotalPricing();
            }
        })).subscribe();
        this.subscriptionHandler.push(projectLPFeedSubscription);
        this.loadProfilePics();
        if (this.loadInternally) {
            this.loadTranslatorList();
            this.loadReviserList(true);
        }
        this.initStoreObservable();
    };
    LanguageBarComponent.prototype.ngOnDestroy = function () {
    };
    LanguageBarComponent.prototype.ngDoCheck = function () {
        // if (this.translationTask === undefined && this.translators != undefined && this.translators[0] != undefined) {
        //   this.translationTask = this.taskService.initTask(this.files, this.langpair.source, this.langpair.target, "Translation", 0, this.projectId, this.translators[0]);
        //   this.recalcTotalPricing();
        //   this.loadProfilePics();
        // }
    };
    LanguageBarComponent.prototype.ngOnChanges = function (changes) {
        // If the language bar is opened too quickly, it might be that the translator or reviser list hasn't been loaded from
        // the server yet.When they are loaded (which we'll get notified of by the changes on the input field), we need to
        // update the translator lists accordingly.
        if (changes.translators !== undefined) {
            this.translators = changes.translators.currentValue;
            this.filterRevisers();
        }
        if (changes.revisers !== undefined) {
            this.revisers = changes.revisers.currentValue;
            this.filterRevisers();
        }
    };
    LanguageBarComponent.prototype.initTasksFromServer = function () {
        var _this = this;
        this.dbTasks = [];
        this.revisionTasks = [];
        this.taskService.findTaskForProject(this.projectId, this.langpair.source, this.langpair.target)
            .pipe(flatMap(function (tasks) { return tasks.sort(function (t1, t2) { return t1.sequence - t2.sequence; }); }), map(function (task) {
            _this.dbTasks.push(task);
            return task;
        }), map(function (task) {
            if (task.activity === "Translation") {
                _this.translationTask = task;
            }
            else {
                _this.revisionTasks.push(task);
                _this.revisionRounds[task.sequence - 1] = task.sequence;
            }
            return task;
        }))
            .subscribe(function () {
        }, function (error) {
            console.error("Error initializing tasks", error);
        }, function () {
            _this.loadProfilePics();
            _this.recalcTotalPricing();
            _this.filterRevisers();
        });
    };
    LanguageBarComponent.prototype.hasTask = function (taskId) {
        return (this.translationTask != null && this.translationTask.id === taskId) ||
            (this.revisionTasks != null && this.revisionTasks.filter(function (t) { return t.id === taskId; }).length > 0);
    };
    /**
     * Filters the list of available revisers based on the selected translator and revisers selected in the
     * preceding revision rounds.
     */
    LanguageBarComponent.prototype.filterRevisers = function () {
        var _this = this;
        this.filteredRevisers = [];
        if (this.revisers !== undefined) {
            if (this.translationTask !== undefined) {
                var currentReviser_1 = this.revisionTasks[0] !== undefined ? this.revisionTasks[0].vendorId : undefined;
                this.filteredRevisers[0] = this.revisers.filter(function (r) { return r.id !== _this.translationTask.vendorId || r.id === currentReviser_1; });
            }
            else {
                this.filteredRevisers[0] = this.revisers;
            }
            this.revisionTasks
                .filter(function (t) { return t !== undefined; })
                .forEach(function (t) {
                var currentReviser = _this.revisionTasks[t.sequence] !== undefined ? _this.revisionTasks[t.sequence].vendorId : undefined;
                _this.filteredRevisers[t.sequence] = _this.revisers.filter(function (r) { return r.id !== t.vendorId || r.id === currentReviser; });
            });
        }
    };
    LanguageBarComponent.prototype.initStoreObservable = function () {
        var _this = this;
        this.taskStoreObservable.debounceTime(500)
            .pipe(switchMap(function (t) { return _this.storeTaskChanges(); }))
            .subscribe(function () {
        }, function (error) {
            console.error("Error storing tasks", error);
        });
    };
    LanguageBarComponent.prototype.onTranslatorSelected = function (translator) {
        if (translator !== undefined) {
            if (this.translationTask === undefined || this.translationTask.vendorId !== translator.id) {
                this.translationTask = this.taskService.initTask(this.files, this.service, this.langpair.source, this.langpair.target, 'Translation', 0, this.projectId, translator);
            }
        }
        else {
            this.translationTask = undefined;
        }
        this.recalcTotalPricing();
        this.loadProfilePics();
        this.filterRevisers();
        this.taskStoreObservable.next(translator);
    };
    LanguageBarComponent.prototype.onReviserSelected = function (index, translator) {
        if (translator !== undefined) {
            if (this.revisionTasks[index] === undefined || this.revisionTasks[index].vendorId !== translator.id) {
                this.revisionTasks[index] = this.taskService.initTask(this.files, this.service, this.langpair.source, this.langpair.target, 'Revision', index + 1, this.projectId, translator);
            }
        }
        else {
            this.revisionTasks[index] = undefined;
        }
        this.recalcTotalPricing();
        this.loadProfilePics();
        this.filterRevisers();
        this.taskStoreObservable.next(translator);
    };
    LanguageBarComponent.prototype.addRevision = function (index) {
        this.revisionRounds[index] = index + 1;
    };
    LanguageBarComponent.prototype.handleTaskChange = function (event, activity, index) {
        if (event === "cancel") {
            if (activity === "Translation") {
                this.translationTask = undefined;
                this.loadTranslatorList();
            }
            else {
                this.revisionTasks[index] = undefined;
                this.revisionRounds[index] = index + 1;
                this.loadReviserList(true);
            }
        }
        if (event === "accept") {
            if (activity === "Translation") {
                this.translationTask.price = null;
                this.recalcTotalPricing();
            }
        }
    };
    LanguageBarComponent.prototype.loadTranslatorList = function () {
        var _this = this;
        if (this.translators == null || this.translators.length === 0) {
            var langPair = toLangPairString(this.langpair);
            this.vendorService.getBestProjectCompetences(this.projectId, langPair, 'Translation', this.service)
                .subscribe(function (translators) {
                if (translators.length > 0) {
                    translators[0]["isSelected"] = true;
                    translators[0]["isRecommended"] = true;
                }
                _this.translators = translators;
            });
        }
    };
    LanguageBarComponent.prototype.loadReviserList = function (filter) {
        var _this = this;
        if (this.revisers == null) {
            var langPair = toLangPairString(this.langpair);
            this.vendorService.getBestProjectCompetences(this.projectId, langPair, 'Revision', this.service)
                .subscribe(function (translators) {
                if (translators.length > 0) {
                    translators[0]["isRecommended"] = true;
                }
                _this.revisers = translators;
                if (filter)
                    _this.filterRevisers();
            });
        }
    };
    LanguageBarComponent.prototype.getFilteredRevisers = function (index) {
        if (this.filteredRevisers[index] !== undefined) {
            return this.filteredRevisers[index];
        }
        return this.revisers;
    };
    LanguageBarComponent.prototype.recalcTotalPricing = function () {
        this.recalcTotalPricingEmitter.emit('recalculate');
    };
    LanguageBarComponent.prototype.initRecalcTotalPricingObs = function () {
        var _this = this;
        // Subscribe to the event emitter, debounce and switch(Map) to the other observer for the total price
        // To make sure the first events (or the events happening after the recalc is done) happen faster then while there is already
        // one running, we first set the debounce time to 10ms, while executing server calls to 500ms and afterwards to 100ms
        var debounceTimer = 10;
        var subscription = this.recalcTotalPricingEmitter.pipe(debounceTime(debounceTimer), // important
        tap(function () { return debounceTimer = 500; })).switchMap(function () { return _this.createTotalPricingObs(); })
            .pipe(tap(function () { return debounceTimer = 100; }))
            .subscribe(function (p) {
            _this.setTotalPrice(p);
        }, function (error) {
            console.error('Error calculating total price for ' + _this.langpair.target, error);
        });
        // store the subscription so it can be unsubscribed on ngOnDestroy
        this.subscriptionHandler.push(subscription);
    };
    LanguageBarComponent.prototype.createTotalPricingObs = function () {
        var _this = this;
        // create the different observers to get the total price
        var translationPriceObs = this.translationTask != null ? this.calcPriceForTask(this.translationTask) : Observable.of(.0);
        var revisionPriceObs = Observable.from(this.revisionTasks)
            .filter(function (t) { return t != null; })
            .switchMap(function (t) { return _this.calcPriceForTask(t); });
        var serviceFeeObs = this.pricingService.getServiceFee(this.projectId, this.langpair)
            .pipe(tap(function (serviceFee) {
            _this.serviceFee = serviceFee;
        }));
        var mtCostObs = this.pricingService.getMTCost(this.projectId, this.langpair);
        // merge all the different observers and sum the prices
        var recalcTotalPriceObs = translationPriceObs
            .merge(revisionPriceObs, serviceFeeObs, mtCostObs)
            .reduce(function (p1, p2) {
            return p1 + p2;
        }, 0);
        return recalcTotalPriceObs;
    };
    LanguageBarComponent.prototype.setTotalPrice = function (price) {
        var priceDifference = Math.abs(this.totalprice - price);
        if (priceDifference >= 0.01) {
            console.debug("Project " + this.projectId + " -- " + this.langpair.source + "_" + this.langpair.target + ": " +
                "updated total price to €" + price + " (was €" + this.totalprice + ")");
            this.totalprice = price;
            this.onPriceChange.emit(this.totalprice);
        }
    };
    LanguageBarComponent.prototype.calcPriceForTask = function (t) {
        var transObs;
        if (t.price != null) {
            // The "+" turns the price from a string into a number
            transObs = Observable.of(+t.price);
        }
        else if (t.id != undefined) {
            transObs = this.pricingService.getPriceForTask(t.id);
        }
        else {
            var taskSpec = new TaskSpec();
            taskSpec.activity = t.activity;
            taskSpec.service = t.service;
            taskSpec.projectId = t.projectId;
            taskSpec.sourcelanguage = t.sourcelanguage;
            taskSpec.targetlanguage = t.targetlanguage;
            transObs = this.pricingService.getPriceForTaskSpecAndVendor(taskSpec, t.vendorId, t.basePrice);
        }
        return transObs;
    };
    LanguageBarComponent.prototype.storeTaskChanges = function () {
        var _this = this;
        // Remove the old tasks which are not selected anymore
        var removeObs = Observable.merge.apply(Observable, tslib_1.__spread(this.dbTasks
            .filter(function (t) { return _this.translationTask !== t && _this.revisionTasks.indexOf(t) < 0; })
            .map(function (t) { return _this.taskService.cancelTask(t); })));
        //Insert the newly created tasks
        var tasks = [];
        if (this.translationTask != undefined && this.translationTask.id === undefined) {
            tasks.push(this.translationTask);
        }
        if (this.revisionTasks.length > 0) {
            this.revisionTasks.filter(function (t) { return t !== undefined && t.id === undefined; })
                .forEach(function (t) { return tasks.push(t); });
        }
        var createObs = Observable.merge.apply(Observable, tslib_1.__spread(tasks.map(function (t) { return _this.taskService.insertTask(t); }))).pipe(tap(function (t) { return _this.dbTasks.push(t); }));
        return Observable.merge.apply(Observable, tslib_1.__spread([removeObs, createObs])).finally(function () {
            _this.initTasksFromServer();
        });
    };
    LanguageBarComponent.prototype.goToNext = function () {
        //Move to the next language pair (or project info)
        this.collapse();
        this.onNext.emit(this.langpair);
    };
    LanguageBarComponent.prototype.collapse = function () {
        this.isCollapsed = true;
    };
    LanguageBarComponent.prototype.expand = function () {
        this.isCollapsed = false;
        if (!this.isCollapsed) {
            this.onExpand.next(this.langpair);
        }
    };
    LanguageBarComponent.prototype.toggleCollapse = function () {
        this.isCollapsed = !this.isCollapsed;
        if (!this.isCollapsed) {
            this.onExpand.next(this.langpair);
        }
    };
    LanguageBarComponent.prototype.loadProfilePics = function () {
        var _this = this;
        if (this.translationTask !== undefined && this.translationTask.vendorId !== undefined) {
            this.userService.getUser(this.translationTask.vendorId).subscribe(function (user) { return _this.translatorUser = user; });
        }
        else {
            this.translatorUser = undefined;
        }
        if (this.revisionTasks !== undefined && this.revisionTasks.length > 0 && this.revisionTasks[0] !== undefined) {
            this.userService.getUser(this.revisionTasks[0].vendorId).subscribe(function (user) { return _this.reviserUser = user; });
        }
        else {
            this.reviserUser = undefined;
        }
        this.revisersCount = this.revisionTasks.filter(function (t) { return t !== undefined; }).length;
    };
    LanguageBarComponent.prototype.getTotalPrice = function () {
        // Only show price if there are any tasks (to avoid showing just the service fee)
        if (this.translationTask != null || (this.revisionTasks != null && this.revisionTasks.length > 0)) {
            return this.totalprice;
        }
        else {
            return undefined;
        }
    };
    return LanguageBarComponent;
}());
export { LanguageBarComponent };
