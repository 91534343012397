var UploadDataService = /** @class */ (function () {
    function UploadDataService() {
        this.uploadData = new Map();
    }
    UploadDataService.prototype.getUploadData = function (pId) {
        return this.uploadData.get(pId);
    };
    UploadDataService.prototype.addUploadData2 = function (pId, data) {
        for (var key in data) {
            this.addUploadData(pId, key, data[key].progress);
        }
    };
    UploadDataService.prototype.addUploadData = function (pId, fileName, progress) {
        // console.log("ADD data service => " + fileName + " => " + progress);
        var tempData;
        if (this.uploadData.has(pId) && this.uploadData.get(pId) != null) {
            tempData = this.uploadData.get(pId);
        }
        else
            tempData = {};
        var d = new UploadProgressData(progress);
        tempData[fileName] = { progressdata: d };
        this.uploadData.set(pId, tempData);
    };
    UploadDataService.prototype.removeUploadData = function (pId, fileName) {
        // console.log("REMOVE data service => " + fileName);
        if (this.uploadData.has(pId) && this.uploadData.get(pId) != null) {
            var tempData = this.uploadData.get(pId);
            if (tempData[fileName] != null) {
                delete tempData[fileName];
            }
        }
    };
    return UploadDataService;
}());
export { UploadDataService };
var UploadProgressData = /** @class */ (function () {
    function UploadProgressData(progress) {
        var _this = this;
        this.lastVal = -1;
        this.progressObs = progress;
        // Subscribe to the observer to keep track of the last value
        this.progressObs.subscribe(function (v) {
            _this.lastVal = v;
        });
    }
    return UploadProgressData;
}());
export { UploadProgressData };
