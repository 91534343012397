import { EventEmitter, OnInit } from "@angular/core";
import { map, startWith, switchMap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { UserService } from '../service/user.service';
import { CookieService } from 'ngx-cookie-service';
var GTACInputComponent = /** @class */ (function () {
    function GTACInputComponent(userService, cookieService) {
        this.userService = userService;
        this.cookieService = cookieService;
        this.isAccepted = false;
        this.gtacAcceptEmitter = new EventEmitter();
    }
    GTACInputComponent.prototype.ngOnInit = function () {
        this.checkGTAC();
    };
    GTACInputComponent.prototype.checkGTAC = function () {
        var _this = this;
        // This observable will check the value of the cookie and the value of the logged in user
        // If there is a value in the cookie and a logged in user without an acceptedgtac the user is updated
        var gtacCheckObs = Observable.of('')
            .pipe(
        // first fetch from the cookie
        map(function (v) {
            if (_this.cookieService.check('lilogtac')) {
                return { version: JSON.parse(_this.cookieService.get('lilogtac')) };
            }
            else {
                return { version: undefined };
            }
        }), 
        // check if there is one stored on the user, if so return that one, if not update the user
        map(function (gtac) {
            var user = _this.userService.getCurrentUser();
            if (user != null) {
                if (user.acceptedgtac == null) {
                    user.acceptedgtac = gtac;
                    _this.userService.updateAcceptedGTAC(gtac.version).subscribe();
                    return gtac;
                }
                else {
                    return user.acceptedgtac;
                }
            }
            else {
                return gtac;
            }
        }), 
        // we only need the version itself
        map(function (gtac) { return gtac.version; }));
        // The observable needs to be triggered when a user is logged in (loginemitter) or before a user is logged in
        var obs;
        if (this.userService.getCurrentUser() == null) {
            obs = this.userService.loginEmitter
                .pipe(startWith(true), switchMap(function () { return gtacCheckObs; }));
        }
        else {
            obs = gtacCheckObs;
        }
        obs.subscribe(function (gtac) {
            _this.isAccepted = gtac != null && UserService.gtacversion <= gtac;
            _this.gtacAcceptEmitter.emit(_this.isAccepted);
        });
    };
    GTACInputComponent.prototype.updateGTAC = function () {
        var acceptedVersion = this.isAccepted ? UserService.gtacversion : undefined;
        this.userService.updateAcceptedGTAC(acceptedVersion).subscribe();
        this.gtacAcceptEmitter.emit(this.isAccepted);
    };
    return GTACInputComponent;
}());
export { GTACInputComponent };
