import { EventEmitter, OnInit, SimpleChanges } from "@angular/core";
import { UserService } from '../../service/user.service';
var ColleagueSelectComponent = /** @class */ (function () {
    function ColleagueSelectComponent(userService) {
        this.userService = userService;
        this.selectedColleagues = [];
        this.onSelectedColleaguesChange = new EventEmitter();
        this.colleagueDialogOpen = false;
    }
    ColleagueSelectComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.userService.findColleagues()
            .subscribe(function (colleagues) {
            _this.colleagues = colleagues;
            _this.filterColleagues();
        });
        if (this.selectedColleagues === undefined) {
            this.selectedColleagues = [];
        }
    };
    ColleagueSelectComponent.prototype.ngOnChanges = function (changes) {
        if (changes.hasOwnProperty("selectedColleagues")) {
            this.selectedColleagues = changes.selectedColleagues.currentValue;
            if (this.colleagues != undefined)
                this.filterColleagues();
        }
    };
    ColleagueSelectComponent.prototype.filterColleagues = function () {
        var _this = this;
        this.filteredSelection = [];
        if (this.selectedColleagues !== undefined) {
            // Get the data about the selected colleagues from the "all colleagues" collection
            // Names might have changed or colleagues might be gone, so make sure to take this into account
            // TODO: maybe don't filter in followup mode? but then when saving don't save along ex-colleagues!
            this.selectedColleagues.forEach(function (selected) {
                for (var i = 0; i < _this.colleagues.length; i++) {
                    var c = _this.colleagues[i];
                    if (c.id === selected.id) {
                        _this.filteredSelection.push(c);
                        break;
                    }
                }
            });
        }
    };
    ColleagueSelectComponent.prototype.toggleColleagueChooser = function () {
        this.colleagueDialogOpen = !this.colleagueDialogOpen;
    };
    ColleagueSelectComponent.prototype.openColleagueChooser = function () {
        this.colleagueDialogOpen = true;
    };
    ColleagueSelectComponent.prototype.closeColleagueChooser = function () {
        this.colleagueDialogOpen = false;
    };
    ColleagueSelectComponent.prototype.removeColleague = function (colleague) {
        this.filteredSelection.splice(this.filteredSelection.indexOf(colleague), 1);
        this.onSelectedColleaguesChange.emit(this.filteredSelection);
    };
    ColleagueSelectComponent.prototype.onColleagueUpdate = function (event) {
        this.onSelectedColleaguesChange.emit(this.filteredSelection);
    };
    return ColleagueSelectComponent;
}());
export { ColleagueSelectComponent };
