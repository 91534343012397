import { debounceTime, filter, map } from 'rxjs/operators';
import { EventEmitter } from "@angular/core";
import { UserService } from "../service/user.service";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ConfirmDialog } from "../confirm.dialog";
import { CountryService } from "../service/country.service";
import { ErrorSnackbarComponent } from "../error.snackbar.component";
import { Subject } from "rxjs";
var CompanySettingsComponent = /** @class */ (function () {
    function CompanySettingsComponent(userService, countryService, snackbar, dialog) {
        this.userService = userService;
        this.countryService = countryService;
        this.snackbar = snackbar;
        this.dialog = dialog;
        this.isTranslator = false;
        this.companyEmitter = new EventEmitter();
        this.checkingVat = false;
    }
    CompanySettingsComponent.prototype.ngOnInit = function () {
        var _this = this;
        if (this.user.privatePerson != undefined)
            this.privatePerson = this.user.privatePerson;
        else
            this.privatePerson = false;
        if (this.companyId) {
            this.userService.findCompanyInfo(this.companyId).subscribe(function (n) { return _this.company = n; });
        }
        else {
            this.initNew();
        }
        this.initVatFieldUpdate();
        this.countryService.getCountries()
            .subscribe(function (c) {
            return _this.countries = c;
        });
    };
    CompanySettingsComponent.prototype.initNew = function () {
        this.company = Object();
    };
    CompanySettingsComponent.prototype.allowPrivatePersonSelection = function () {
        return !this.isTranslator && !(this.company && this.company.id);
    };
    CompanySettingsComponent.prototype.allowCompanySelection = function () {
        return this.isTranslator || (this.company && !this.privatePerson);
    };
    CompanySettingsComponent.prototype.validateToken = function () {
        var _this = this;
        if (this.token)
            this.userService.findCompanyInfoByToken(this.token).subscribe(function (n) {
                _this.company = n;
                _this.companyId = n.id;
                _this.companyEmitter.emit(_this.company);
            }, function (e) {
                var ref = _this.snackbar.openFromComponent(ErrorSnackbarComponent, {
                    duration: 2000,
                    verticalPosition: "top",
                    data: { errorText: 'Token could not be validated.' }
                });
            });
    };
    CompanySettingsComponent.prototype.sendInvite = function () {
        var _this = this;
        //Check if there is already a company token
        if (this.company && !this.company.token) {
            this.userService.renewCompanyToken(this.company.id).subscribe(function (n) {
                _this.company.token = n;
                _this.sendCompanyInvite();
            });
        }
        else {
            this.sendCompanyInvite();
        }
    };
    CompanySettingsComponent.prototype.sendCompanyInvite = function () {
        var _this = this;
        // todo: should this be this.user iso currentUser?
        this.userService.sendCompanyInvite(this.userService.getCurrentUser().id, this.inviteMail).subscribe(function (v) {
            _this.snackbar.open("Invite has been sent", "", {
                duration: 2000
            });
        });
    };
    CompanySettingsComponent.prototype.renewToken = function () {
        var _this = this;
        var confirmText = "Warning: renewing the token will invalidate all invites that have been sent. \n" +
            "Are you sure you want to renew the token?";
        var dialogRef = this.dialog.open(ConfirmDialog, {
            width: '450px',
            height: '350px',
            panelClass: 'confirmDialog',
            data: {
                confirmMessage: confirmText, confirmText: "Yes", cancelText: "No"
            }
        });
        dialogRef.afterClosed().pipe(filter(function (a) { return a == "confirm"; }))
            .subscribe(function (a) {
            return _this.userService.renewCompanyToken(_this.company.id).subscribe(function (n) {
                return _this.company.token = n;
            });
        });
    };
    CompanySettingsComponent.prototype.save = function () {
        var _this = this;
        // Update user for 'private person' flag (if changed)
        if (this.privatePerson != this.user.privatePerson || this.user.privatePerson == undefined) {
            this.user.privatePerson = this.privatePerson;
            this.userService.updateUserData(this.user).subscribe(function (u) {
                return _this.snackbar.open("Profile updated", '', { duration: 2000 });
            });
        }
        else if (this.user.privatePerson) {
            // Show a 'snackbar' here anyway, or it looks to the user like nothing happened
            this.snackbar.open("Profile updated", '', { duration: 2000 });
        }
        // Disregard any company data if user is 'private person'
        if (!this.user.privatePerson) {
            // If the user has a customer group ID, then copy it to the company
            if (this.user.custGroupId != null)
                this.company.custGroupId = this.user.custGroupId;
            if (this.company.id) {
                // If there is already a company ID, update the company
                this.company.lastupdateby = this.user.id;
                this.userService.updateCompanyInfo(this.company).subscribe(function (v) {
                    _this.snackbar.open("Company updated", '', {
                        duration: 2000
                    });
                    _this.companyEmitter.emit(_this.company);
                });
            }
            else {
                // If there is no company ID yet and the user is not marked as a private person, create the company
                this.company.firstUser = this.user.id;
                this.userService.createCompanyInfo(this.company, null).subscribe(function (id) {
                    _this.snackbar.open("Company created", '', {
                        duration: 2000
                    });
                    _this.companyId = id;
                    _this.company.id = id;
                    _this.companyEmitter.emit(_this.company);
                });
            }
        }
    };
    CompanySettingsComponent.prototype.vatChange = function ($event) {
        this.checkingVat = true;
        this.vatChanges.next($event);
    };
    CompanySettingsComponent.prototype.initVatFieldUpdate = function () {
        var _this = this;
        this.vatChanges = new Subject();
        //debounce so it only updates every second instead of every keystroke
        this.vatChanges.asObservable().pipe(debounceTime(1000)).subscribe(function (v) {
            return _this.userService.validateVat(_this.company.vat).pipe(map(function (v) { return v; }))
                .subscribe(function (v) {
                _this.isVatValid = v;
                _this.checkingVat = false;
            });
        });
    };
    return CompanySettingsComponent;
}());
export { CompanySettingsComponent };
