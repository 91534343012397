import { OnInit } from "@angular/core";
import { FileService } from '../service/file.service';
var DocumentationSettingComponent = /** @class */ (function () {
    function DocumentationSettingComponent(fileService) {
        this.fileService = fileService;
        this.documentationFiles = [];
    }
    DocumentationSettingComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.fileService.getCompanyDocumentationList(this.companyId).subscribe(function (f) {
            _this.documentationFiles = f;
        });
    };
    DocumentationSettingComponent.prototype.addFile = function (file) {
        this.documentationFiles.push(file.docfile);
    };
    return DocumentationSettingComponent;
}());
export { DocumentationSettingComponent };
