import * as tslib_1 from "tslib";
import { EventEmitter, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import { NativeDateAdapter } from "@angular/material/core";
export var NATIVE_DATE_FORMATS = {
    parse: {
        dateInput: { month: 'short', year: 'numeric', day: 'numeric' }
    },
    display: {
        // dateInput: { month: 'short', year: 'numeric', day: 'numeric' },
        dateInput: 'input',
        monthYearLabel: { year: 'numeric', month: 'short' },
        dateA11yLabel: { year: 'numeric', month: 'long', day: 'numeric' },
        monthYearA11yLabel: { year: 'numeric', month: 'long' },
    }
};
var AppDateAdapter = /** @class */ (function (_super) {
    tslib_1.__extends(AppDateAdapter, _super);
    function AppDateAdapter() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    AppDateAdapter.prototype.format = function (date, displayFormat) {
        if (displayFormat === 'input') {
            return formatDate(date);
        }
        else
            return _super.prototype.format.call(this, date, displayFormat);
    };
    AppDateAdapter.prototype.parse = function (value) {
        try {
            var d = new Date(value);
            d.setHours(0, 0, 0);
            return d;
        }
        catch (e) {
            throw e;
        }
    };
    return AppDateAdapter;
}(NativeDateAdapter));
export { AppDateAdapter };
var DateTime2Component = /** @class */ (function () {
    function DateTime2Component() {
        this.selectedDateChange = new EventEmitter();
        this.minutes = 59;
        this.hours = 23;
        this.tooltip = "Pick a date. (yyyy-mm-dd)";
    }
    DateTime2Component.prototype.ngOnInit = function () {
        if (this.selectedDate != undefined) {
            if (typeof this.selectedDate == "string")
                this.selectedDate = new Date(this.selectedDate);
            this.date = this.selectedDate;
            this.hours = this.selectedDate.getHours();
            this.minutes = this.selectedDate.getMinutes();
        }
        else {
            this.date = new Date();
        }
    };
    DateTime2Component.prototype.ngOnChanges = function (changes) {
        if (changes.hasOwnProperty("selectedDate")) {
            this.ngOnInit();
        }
    };
    DateTime2Component.prototype.dateChange = function () {
        if (this.hours > 23)
            this.hours = 23;
        if (this.hours < 0)
            this.hours = 0;
        if (this.minutes > 59)
            this.minutes = 59;
        if (this.minutes < 0)
            this.minutes = 0;
        var toUpdateDate = new Date(this.date);
        toUpdateDate.setHours(this.hours);
        toUpdateDate.setMinutes(this.minutes);
        this.date = toUpdateDate;
        this.selectedDate = this.date;
        this.selectedDateChange.emit(this.selectedDate);
    };
    return DateTime2Component;
}());
export { DateTime2Component };
export function formatDate(d) {
    var day = d.getDate().toLocaleString(undefined, { minimumIntegerDigits: 2 });
    var month = (d.getMonth() + 1).toLocaleString(undefined, { minimumIntegerDigits: 2 });
    var year = d.getFullYear();
    return year + "-" + month + "-" + day;
}
