var Project = /** @class */ (function () {
    function Project() {
        // project shares need to be instantiated as undefined, so we know when to apply the default shares
        this.shares = undefined;
        this.isQuote = false;
    }
    return Project;
}());
export { Project };
// TODO: get rid of this flag once the quote functionality is ready for production
export var ENABLE_QUOTE = false;
