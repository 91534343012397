import { filter, map, publish } from 'rxjs/operators';
import { Observable, zip } from "rxjs";
import { DetailedPricing, Pricing } from "../dto/job";
import { HttpClient, HttpParams } from "@angular/common/http";
import { UserService } from "./user.service";
import { IntervalObservable } from "rxjs/observable/IntervalObservable";
import { getBaseSocketUrl, getBaseUrl } from "../util/httpclient";
import { toLangPairString } from '../util/jobutil';
var PricingService = /** @class */ (function () {
    function PricingService(http, userService) {
        this.http = http;
        this.userService = userService;
        this.PRICING_API = getBaseUrl() + "/api/v1/pricing";
        this.CREDIT_API = getBaseUrl() + "/api/v1/credits";
        this.FILES_API = getBaseUrl() + "/api/v1/files";
        this.PRICING_SOCKET_URL = getBaseSocketUrl() + "/pricing/feed";
    }
    PricingService.prototype.initPricingFeed = function () {
        var _this = this;
        this.ws = Observable.webSocket(this.PRICING_SOCKET_URL);
        //Keep the socket alive (every 4 minutes, server timeout is set to 5 minutes)
        IntervalObservable.create(30000).subscribe(function (o) {
            return _this.ws.next({ 'action': 'keepalive' });
        });
        this.messages = this.makeHot(this.ws).pipe(filter(function (m) { return m != null; }));
        // this.messages.subscribe(msg => console.log("> Received " + JSON.stringify(msg) + " @" + this.PRICING_SOCKET_URL),
        //   error => console.error(error));
    };
    //To know the difference between a cold and a hot observable check https://angular-2-training-book.rangle.io/handout/observables/cold_vs_hot_observables.html
    // or here : https://medium.com/@benlesh/hot-vs-cold-observables-f8094ed53339
    //Not really sure if it is needed to use a hot observable, but it does give more of a live messaging, also found other examples which were much more complicated, might have missed something there.
    PricingService.prototype.makeHot = function (cold) {
        var obs = cold.pipe(publish());
        obs.connect();
        return obs;
    };
    PricingService.prototype.enrollForProject = function (pId) {
        return this.enroll(pId).map(function (msg) { return msg.data; });
    };
    PricingService.prototype.enrollForProjectAndLangPair = function (pId, langPair, enrollNoLangPairAlso) {
        if (enrollNoLangPairAlso === void 0) { enrollNoLangPairAlso = true; }
        // If the 'enrollNoLangPairAlso' flag is false, then ONLY events that have a language pair specified that is equal
        // to the given one will be returned; if the events have no language pair specified, they are ignored and will not be returned
        // If the 'enrollNoLangPairAlso' flag is true, then events that have a language pair equal to the given one OR
        // none at all are returned
        return this.enroll(pId).pipe(filter(function (msg) {
            return (msg.langpair === undefined && enrollNoLangPairAlso) || msg.langpair == langPair;
        }), map(function (msg) { return msg.data; }));
    };
    PricingService.prototype.enroll = function (pId) {
        if (!this.ws || this.ws.closed)
            this.initPricingFeed();
        //To enroll you have to send a message to the socket with the action "enroll" and to which objects you want to enroll
        this.ws.next({ 'action': 'enroll', 'pid': pId });
        //The JSON structure of the message normally has the objects parameters to identify and a "data" object which contains the actual information
        //E.g. {"pid":"10"}
        return this.messages.pipe(filter(function (msg) { return msg.pid == pId; }));
    };
    PricingService.prototype.getTotalProjectPrice = function (pId) {
        var _this = this;
        return this.http.get(this.PRICING_API + "/project/" + pId).pipe(map(function (p) { return _this.extractData(p); }), map(function (p) { return p; }));
    };
    PricingService.prototype.getTotalFilePrice = function (pId, fileName) {
        return this.http.get(this.PRICING_API + "/file/" + pId + "/" + encodeURIComponent(fileName)).pipe(map(this.extractData), map(function (p) {
            return p.total();
        }));
    };
    PricingService.prototype.getPriceForTask = function (tId) {
        return this.http.get(this.PRICING_API + "/task/" + tId).pipe(map(function (v) {
            return v;
        }));
    };
    PricingService.prototype.getPriceForTaskSpecAndVendor = function (taskSpec, vendorId, basePrice) {
        var params = new HttpParams().set('pid', taskSpec.projectId)
            .set('langpair', encodeURIComponent(toLangPairString({ source: taskSpec.sourcelanguage, target: taskSpec.targetlanguage })))
            .set('activity', taskSpec.activity);
        if (taskSpec.service != null) {
            params = params.set('service', taskSpec.service.toLowerCase());
        }
        if (basePrice && basePrice > 0) {
            params = params.set('basePrice', '' + basePrice);
        }
        return this.http.get(this.PRICING_API + '/vendor/' + vendorId, { params: params }).pipe(map(function (p) {
            return Number(p).valueOf();
        }));
    };
    PricingService.prototype.getPriceForTaskSpecAndVendors = function (taskSpec, vendors) {
        var params;
        params = new HttpParams().set('translators', JSON.stringify(vendors.map(function (v) { return v.id; })))
            .set('pid', taskSpec.projectId)
            .set('langpair', encodeURIComponent(toLangPairString({ source: taskSpec.sourcelanguage, target: taskSpec.targetlanguage })))
            .set('activity', taskSpec.activity.toLowerCase());
        if (taskSpec.service != null) {
            params = params.set('service', taskSpec.service.toLowerCase());
        }
        return this.http.get(this.PRICING_API + '/vendor', { params: params }).pipe(map(function (p) {
            return p;
        }));
    };
    PricingService.prototype.getLangPairPrice = function (pId, source, target) {
        var _this = this;
        return this.http.get(this.PRICING_API + "/langpair/" + pId + "/" + encodeURIComponent(toLangPairString({ source: source, target: target }))).pipe(map(function (p) { return _this.extractData(p); }));
    };
    PricingService.prototype.getServiceFee = function (projectId, langpair) {
        return this.http.get(this.PRICING_API + "/servicefee/langpair/" + projectId + "/" + encodeURIComponent(toLangPairString(langpair)))
            .pipe(map(function (p) { return Number(p).valueOf(); }));
    };
    PricingService.prototype.getMTCost = function (projectId, langpair) {
        var lp;
        if (typeof langpair == "string")
            lp = langpair;
        else
            lp = toLangPairString(langpair);
        return this.http.get(this.PRICING_API + "/mtcost/langpair/" + projectId + "/" + encodeURIComponent(lp))
            .pipe(map(function (p) { return Number(p).valueOf(); }));
    };
    PricingService.prototype.getServiceFeeAndMTCost = function (projectId, langpair) {
        return zip(this.getServiceFee(projectId, langpair), this.getMTCost(projectId, langpair))
            .map(function (v) {
            return { serviceFee: v[0], mtCost: v[1] };
        });
    };
    PricingService.prototype.getCreditCalculationTypes = function () {
        return this.http.get(this.CREDIT_API + "/calc/types");
    };
    PricingService.prototype.getCreditCalculationParams = function (calculationType) {
        return this.http.get(this.CREDIT_API + "/calc/params/" + calculationType);
    };
    PricingService.prototype.getCreditValidationTypes = function () {
        return this.http.get(this.CREDIT_API + "/valid/types");
    };
    PricingService.prototype.getCreditValidationParams = function (validationType) {
        return this.http.get(this.CREDIT_API + "/valid/params/" + validationType);
    };
    PricingService.prototype.createCredit = function (credit) {
        //Fill in the user id from the session storage
        credit["uid"] = JSON.parse(sessionStorage.getItem("user")).id;
        //Call the post method
        return this.http.post(this.CREDIT_API, JSON.stringify(credit), {});
    };
    PricingService.prototype.getCreditsOverview = function (userId) {
        return this.http.get(this.CREDIT_API + "/user/overview/" + userId);
    };
    PricingService.prototype.validateCredit = function (userId, token) {
        return this.http.post(this.CREDIT_API + "/validate/" + token, JSON.stringify({ user: userId }), {});
    };
    PricingService.prototype.downloadPricingPdf = function (projectId) {
        return this.http.get(this.PRICING_API + "/previewpdf/" + projectId, { responseType: 'blob' });
    };
    PricingService.prototype.extractData = function (res) {
        var _this = this;
        // We specifically create a new Pricing object here, since if you just "cast" to it you won't have access to the methods
        // of the Pricing object
        // I assume this is because the object is sent over as JSON object from the server, which contains only data and no functions
        var p = res || Pricing;
        var pricing = p.hasOwnProperty("langPairDetails") ? new DetailedPricing() : new Pricing();
        pricing.vatRate = p.vatRate;
        pricing.lilo = p.lilo;
        pricing.mt = p.mt;
        pricing.mtsaving = p.mtsaving;
        pricing.tmsaving = p.tmsaving;
        pricing.revision = p.revision;
        pricing.translation = p.translation;
        pricing.credit = p.credit;
        pricing.taskCount = p.taskCount;
        if (p.hasOwnProperty("langPairDetails")) {
            pricing.langPairDetails = p.langPairDetails;
            Object.keys(p.langPairDetails)
                .forEach(function (v, i, a) {
                pricing.langPairDetails[v] = _this.extractData(p.langPairDetails[v]);
            });
        }
        return pricing;
    };
    return PricingService;
}());
export { PricingService };
