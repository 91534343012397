import * as tslib_1 from "tslib";
import { filter } from 'rxjs/operators';
import { OnChanges, OnInit } from "@angular/core";
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { UserService } from "../../service/user.service";
import { FileService } from "../../service/file.service";
import * as FileSaver from "file-saver";
import { ConfirmDialog } from "../../confirm.dialog";
import { SelectionModel } from "@angular/cdk/collections";
var FolderViewComponent = /** @class */ (function () {
    function FolderViewComponent(userService, fileService, dialog, snackbar) {
        this.userService = userService;
        this.fileService = fileService;
        this.dialog = dialog;
        this.snackbar = snackbar;
        this.dirCount = 0;
        this.fileCount = 0;
        this.displayedColumns = ['select', 'icon', 'name', 'size', 'lastmodified'];
        this.dataSource = new MatTableDataSource();
        this.selection = new SelectionModel(true, []);
        this.fileUpload = "";
    }
    FolderViewComponent.prototype.ngOnInit = function () {
        this.subDir = null;
        this.updateFileContent(false);
    };
    FolderViewComponent.prototype.ngAfterViewInit = function () {
        this.dataSource.sort = this.sort;
    };
    FolderViewComponent.prototype.ngOnChanges = function () {
        this.subDir = null;
        this.updateFileContent(false);
    };
    Object.defineProperty(FolderViewComponent.prototype, "matSort", {
        set: function (ms) {
            this.sort = ms;
            this.dataSource.sort = this.sort;
        },
        enumerable: true,
        configurable: true
    });
    FolderViewComponent.prototype.rowClicked = function (e) {
        //  console.debug(e);
        if (e.dir === "true" && e.hasOwnProperty("path")) {
            this.subDir = e.path + (e.path.length > 0 && !e.path.endsWith("/") ? "/" : "") + e.name;
            this.updateFileContent(false);
        }
        else {
            this.fileService.download(this.type, this.id, this.subDir, e.name).subscribe(function (res) {
                FileSaver.saveAs(res, e.name, true);
            });
        }
    };
    FolderViewComponent.prototype.refreshFileContent = function () {
        this.updateFileContent(true);
    };
    FolderViewComponent.prototype.updateFileContent = function (showSnackbar) {
        var _this = this;
        this.fileService.fileContent(this.type, this.id, this.subDir).subscribe(function (data) {
            _this.content = data;
            console.debug("File content for " + _this.type + " " + _this.id + " " + (_this.subDir ? " and subdir '" + _this.subDir + "'" : ""), _this.content);
            _this.dataSource.data = _this.content;
            // Clear selection each time content is updated
            _this.selection.clear();
            // Calculate how many directories/files
            _this.dirCount = 0;
            _this.fileCount = 0;
            _this.dataSource.data.forEach(function (row) {
                if (row.dir === "true")
                    _this.dirCount++;
                else
                    _this.fileCount++;
            });
            if (showSnackbar) {
                _this.snackbar.open("Reloaded view for " + _this.type + " " + _this.id + (_this.subDir ? ": /" + _this.subDir : ""), "", {
                    duration: 2000
                });
            }
        });
    };
    FolderViewComponent.prototype.goUp = function () {
        var idx = this.subDir.lastIndexOf("/");
        if (idx > 0) {
            this.subDir = this.subDir.substring(0, idx);
        }
        else {
            this.subDir = null;
        }
        this.updateFileContent(false);
    };
    FolderViewComponent.prototype.addFile = function (event) {
        var _this = this;
        var fileList = event.target.files;
        if (fileList.length > 0) {
            var file_1 = fileList[0];
            this.fileService.fileExists(this.type, this.id, this.subDir, file_1.name).subscribe(function (result) {
                if (result === "true") {
                    _this.openFileExistsDialog(file_1);
                }
                else
                    _this.upload(file_1, false);
            });
        }
    };
    FolderViewComponent.prototype.openFileExistsDialog = function (file) {
        var _this = this;
        var confDialog = this.dialog.open(ConfirmDialog, {
            width: '400px',
            panelClass: 'confirmDialog',
            data: {
                confirmMessage: 'File ' + file.name + ' already exists in this folder. Do you wish to replace it?',
                confirmText: 'Yes, replace',
                cancelText: 'No, cancel'
            }
        });
        confDialog.afterClosed().pipe(filter(function (t) { return t === "confirm"; }))
            .subscribe(function (p) {
            _this.upload(file, true);
        });
    };
    FolderViewComponent.prototype.upload = function (file, confirmedReplace) {
        var _this = this;
        (function () { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var start;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                start = new Date().getTime();
                this.fileService.addFile(file, this.type, this.id, this.subDir, confirmedReplace).subscribe(function (data) {
                    var end = new Date();
                    _this.snackbar.open("File added for " + _this.type + " " + _this.id + (_this.subDir ? " to " + _this.subDir : "") +
                        ": " + file.name + " uploaded in " + (end.getTime() - start) + " ms", "", {
                        duration: 2000
                    });
                    console.log("File added for " + _this.type + " " + _this.id + (_this.subDir ? " to " + _this.subDir : "") +
                        ": " + file.name + " uploaded in " + (end.getTime() - start) + " ms");
                    _this.updateFileContent(false);
                }, function (error) {
                    // Open snackbar if error
                    console.log("Error adding file", error);
                    _this.snackbar.open("Error adding file for " + _this.type + " " + _this.id + (_this.subDir ? " to " + _this.subDir : "") +
                        ": " + error.status + " " + error.statusText, "", {
                        duration: 5000
                    });
                });
                return [2 /*return*/];
            });
        }); })();
    };
    FolderViewComponent.prototype.removeSelected = function () {
        var _this = this;
        if (this.selection.selected.length == 0) {
            this.snackbar.open("Nothing selected to remove! Please select one or more.", "", {
                duration: 4000
            });
        }
        else {
            var dirNumber_1 = 0;
            var fileNumber_1 = 0;
            var nonEmptyDir_1 = false;
            this.selection.selected.forEach(function (row) {
                if (row.dir === "true") {
                    if (!nonEmptyDir_1)
                        nonEmptyDir_1 = (row.count > 0);
                    dirNumber_1++;
                }
                else
                    fileNumber_1++;
            });
            // Do not allow removal of non-empty dirs
            if (nonEmptyDir_1) {
                this.snackbar.open("One or more non-empty folders were selected! Only empty folders can be removed.", "", {
                    duration: 4000
                });
            }
            else {
                // Compose confirm message based on selected files/folders
                var msg = "";
                if (dirNumber_1 > 0 && fileNumber_1 > 0) {
                    msg = "the " + dirNumber_1 + " selected folder(s) and " + fileNumber_1 + " selected file(s)";
                }
                else if (dirNumber_1 > 0) {
                    msg = (dirNumber_1 > 1 ? "the " + dirNumber_1 + " selected folders" : "folder \'" + this.selection.selected[0].name + "\'");
                }
                else if (fileNumber_1 > 0) {
                    msg = (fileNumber_1 > 1 ? "the " + fileNumber_1 + " selected files" : "file \'" + this.selection.selected[0].name + "\'");
                }
                var confDialog = this.dialog.open(ConfirmDialog, {
                    width: '400px',
                    panelClass: 'confirmDialog',
                    data: {
                        confirmMessage: 'Are you sure you wish to remove ' + msg + '?',
                        confirmText: 'Yes',
                        cancelText: 'No'
                    }
                });
                confDialog.afterClosed().pipe(filter(function (t) { return t === "confirm"; }))
                    .subscribe(function (p) {
                    var start = new Date().getTime();
                    _this.fileService.removeFile(_this.selection.selected, _this.type, _this.id, _this.subDir).subscribe(function (data) {
                        var end = new Date();
                        _this.snackbar.open("Successfully removed " + _this.selection.selected.length + " file(s) and/or folder(s) for " + _this.type + " " + _this.id +
                            (_this.subDir ? " at " + _this.subDir : "") +
                            " (" + (end.getTime() - start) + " ms)", "", {
                            duration: 2000
                        });
                        _this.updateFileContent(false);
                    }, function (error) {
                        // Open snackbar if error
                        console.log("Error removing file(s)/folder(s)", error);
                        _this.snackbar.open("Error removing " + _this.selection.selected.length + " file(s) and/or folder(s) for " + _this.type + " " + _this.id +
                            (_this.subDir ? " in " + _this.subDir : "") +
                            ": " + error.status + " " + error.statusText, "", {
                            duration: 5000
                        });
                    });
                });
            }
        }
    };
    FolderViewComponent.prototype.renameSelected = function () {
        var _this = this;
        if (this.selection.selected.length == 0) {
            this.snackbar.open("No file selected to rename! Please select one.", "", {
                duration: 4000
            });
        }
        else if (this.selection.selected.length == 1) {
            // Only allow rename for one file at a time
            var fname_1 = this.selection.selected[0].name;
            var isDir_1 = this.selection.selected[0].dir;
            var dialogRef = this.dialog.open(RenameFileDialog, {
                width: '500px',
                data: { currentFileName: fname_1, isDir: isDir_1 }
            });
            dialogRef.afterClosed().pipe(filter(function (t) {
                // Only proceed if a name was given and if it was not equal to the current name
                return t != undefined && t.trim().length > 0 && t.trim() !== fname_1.trim();
            }))
                .subscribe(function (nn) {
                var newName = nn.trim();
                _this.fileService.renameFile(newName, _this.type, _this.id, _this.subDir, fname_1).subscribe(function (data) {
                    var end = new Date();
                    _this.snackbar.open((isDir_1 ? "Folder " : "File ") + fname_1 + " renamed to " + newName +
                        " for " + _this.type + " " + _this.id + (_this.subDir ? " in " + _this.subDir : ""), "", {
                        duration: 2000
                    });
                    _this.updateFileContent(false);
                }, function (error) {
                    // Open snackbar if error
                    console.log("Error renaming " + (isDir_1 ? "folder" : "file"), error);
                    _this.snackbar.open("Error renaming " + (isDir_1 ? "folder " : "file ") + fname_1 + " for " + _this.type + " " + _this.id + (_this.subDir ? " in " + _this.subDir : "") +
                        ": " + error.status + " " + error.statusText, "", {
                        duration: 5000
                    });
                });
            });
        }
        else {
            this.snackbar.open("More than one file and/or folder selected to rename! Please select only one.", "", {
                duration: 4000
            });
        }
    };
    FolderViewComponent.prototype.createNewFolder = function () {
        var _this = this;
        var dialogRef = this.dialog.open(AddFolderDialog, {
            width: '500px',
            data: {}
        });
        dialogRef.afterClosed().pipe(filter(function (t) {
            // Only proceed if a name was given and if it was not equal to the current name
            return t != undefined && t.trim().length > 0;
        }))
            .subscribe(function (nn) {
            var folderName = nn.trim();
            _this.fileService.createDir(folderName, _this.type, _this.id, _this.subDir).subscribe(function (data) {
                var end = new Date();
                _this.snackbar.open("Folder " + folderName + " created for " + _this.type + " " + _this.id + (_this.subDir ? " in " + _this.subDir : ""), "", {
                    duration: 2000
                });
                _this.updateFileContent(false);
            }, function (error) {
                // Open snackbar if error
                console.log("Error creating folder", error);
                _this.snackbar.open("Error creating folder for " + _this.type + " " + _this.id + (_this.subDir ? " in " + _this.subDir : "") +
                    ": " + error.status + " " + error.statusText, "", {
                    duration: 5000
                });
            });
        });
    };
    FolderViewComponent.prototype.enableFileOps = function () {
        // Don't allow file operations when in the #bin directory
        return this.subDir == null || !(this.subDir.indexOf("#bin") == 0);
    };
    /** Check whether the number of selected elements matches the total number of selectable rows. */
    FolderViewComponent.prototype.isAllSelected = function () {
        var numSelected = this.selection.selected.length;
        var numRows = 0;
        this.dataSource.data.forEach(function (row) {
            numRows++;
        });
        return numSelected == numRows;
    };
    /** Selects all rows if they are not all selected; otherwise clear selection. */
    FolderViewComponent.prototype.masterToggle = function () {
        var _this = this;
        this.isAllSelected() ?
            this.selection.clear() :
            this.dataSource.data.forEach(function (row) {
                _this.selection.select(row);
            });
    };
    return FolderViewComponent;
}());
export { FolderViewComponent };
var RenameFileDialog = /** @class */ (function () {
    function RenameFileDialog(dialogRef, data) {
        this.dialogRef = dialogRef;
        this.data = data;
    }
    RenameFileDialog.prototype.ngOnInit = function () {
        this.currentFileName = this.data.currentFileName;
        this.newFileName = this.data.currentFileName;
        this.isDir = this.data.isDir;
    };
    RenameFileDialog.prototype.continue = function () {
        this.dialogRef.close(this.newFileName);
    };
    return RenameFileDialog;
}());
export { RenameFileDialog };
var AddFolderDialog = /** @class */ (function () {
    function AddFolderDialog(dialogRef, data) {
        this.dialogRef = dialogRef;
        this.data = data;
    }
    AddFolderDialog.prototype.ngOnInit = function () {
        this.newFolderName = "";
    };
    AddFolderDialog.prototype.continue = function () {
        this.dialogRef.close(this.newFolderName);
    };
    return AddFolderDialog;
}());
export { AddFolderDialog };
