import { MatDialogRef } from "@angular/material/dialog";
var ConfirmDialog = /** @class */ (function () {
    function ConfirmDialog(dialogRef, data) {
        this.dialogRef = dialogRef;
        this.data = data;
        this.confirmText = "Confirm";
        this.cancelText = "Cancel";
        this.invertStyle = false;
    }
    ConfirmDialog.prototype.ngOnInit = function () {
        if (this.data.confirmTitle)
            this.confirmTitle = this.data.confirmTitle;
        if (this.data.confirmMessage)
            this.confirmMessage = this.data.confirmMessage;
        if (this.data.confirmHtml)
            this.confirmHtml = this.data.confirmHtml;
        if (this.data.confirmText)
            this.confirmText = this.data.confirmText;
        if (this.data.cancelText)
            this.cancelText = this.data.cancelText;
        if (this.data.invertStyle)
            this.invertStyle = this.data.invertStyle;
    };
    ConfirmDialog.prototype.cancel = function () {
        this.dialogRef.close("cancel");
    };
    ConfirmDialog.prototype.confirm = function () {
        this.dialogRef.close("confirm");
    };
    return ConfirmDialog;
}());
export { ConfirmDialog };
