import { DomSanitizer } from "@angular/platform-browser";
import { HttpClient, HttpParams } from "@angular/common/http";
import { getBaseUrl, normalizeFileName } from "../util/httpclient";
var TmService = /** @class */ (function () {
    function TmService(http, sanitizer) {
        this.http = http;
        this.sanitizer = sanitizer;
        this.url = getBaseUrl();
        this.TM_API = this.url + "/api/v1/tm";
        console.debug("TmService initializing => connects to " + this.TM_API);
    }
    TmService.prototype.uploadTmxFile = function (tmxFile, tmIdx) {
        var formData = new FormData();
        var fileName = normalizeFileName(tmxFile.name);
        formData.append('uploadFile', tmxFile, fileName);
        formData.append('title', fileName);
        var params = new HttpParams();
        params.append("debug", "y");
        return this.http.post(this.TM_API + "/tmx/" + tmIdx, formData, {
            responseType: 'text',
            params: params
        });
    };
    return TmService;
}());
export { TmService };
