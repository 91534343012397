import { from as observableFrom } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { Task } from "../dto/job";
import { ProjectService } from "../service/project.service";
import { Router } from "@angular/router";
var TaskCardComponent = /** @class */ (function () {
    function TaskCardComponent(router, projectService) {
        this.router = router;
        this.projectService = projectService;
        this.wordcount = 0;
    }
    TaskCardComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.projectService.getProjectBaseInfo(this.task.projectId).subscribe(function (info) {
            _this.setProjectInfo(info);
        });
    };
    TaskCardComponent.prototype.setProjectInfo = function (info) {
        var _this = this;
        this.projectInfo = info;
        this.wordcount = 0;
        observableFrom(this.projectInfo.files).pipe(map(function (file) { return file; }), filter(function (v) {
            return v.type === "SOURCE";
        }), 
        //TODO currently the filter is excluded until the update projects after start is finished
        // .filter(file =>
        //   this.task.jobs.find(j => j.file == file.name) != null
        // )
        map(function (file) {
            return file;
        }), map(function (file) { return file.wordcount; }))
            .forEach(function (wc) {
            _this.wordcount += wc;
        });
    };
    TaskCardComponent.prototype.openTask = function (task) {
        this.router.navigate(['/tdetail', task.id]);
    };
    TaskCardComponent.prototype.fileCount = function () {
        return this.task.jobs.length;
    };
    TaskCardComponent.prototype.taskProgress = function (task) {
        return "TODO";
    };
    return TaskCardComponent;
}());
export { TaskCardComponent };
