import { merge as observableMerge, of as observableOf } from 'rxjs';
import { defaultIfEmpty, filter, map, tap } from 'rxjs/operators';
import { HttpClient } from "@angular/common/http";
import { getBaseUrl } from "../util/httpclient";
var LanguageService = /** @class */ (function () {
    function LanguageService(http) {
        var _this = this;
        this.http = http;
        this.codeCacheMap = new Map();
        this.LANGUAGE_API = getBaseUrl() + "/api/v1/languages";
        this.getLanguageMapObservable().subscribe(function (m) { return _this.languageMap = m; });
        this.getDialectLanguageMapObservable().subscribe(function (m) { return _this.languageDialectMap = m; });
    }
    LanguageService.prototype.getLanguages = function () {
        return this.http.get(this.LANGUAGE_API + "/codes");
    };
    LanguageService.prototype.getDialectLanguages = function () {
        return this.http.get(this.LANGUAGE_API + "/dialect/codes");
    };
    LanguageService.prototype.getCharBasedLanguages = function () {
        var _this = this;
        if (this.charBasedCache == undefined)
            return this.http.get(this.LANGUAGE_API + "/charbased/codes").pipe(tap(function (cb) {
                _this.charBasedCache = cb;
            }));
        else
            return observableOf(this.charBasedCache);
    };
    LanguageService.prototype.getRight2LeftLanguages = function () {
        var _this = this;
        if (this.right2LeftCache == undefined)
            return this.http.get(this.LANGUAGE_API + "/right2left/codes").pipe(tap(function (r2l) {
                _this.right2LeftCache = r2l;
            }));
        else
            return observableOf(this.right2LeftCache);
    };
    LanguageService.prototype.getLanguageMap = function () {
        if (this.languageMap)
            return observableOf(this.languageMap);
        else
            return this.getLanguageMapObservable();
    };
    LanguageService.prototype.getDialectLanguageMap = function () {
        if (this.languageDialectMap)
            return observableOf(this.languageDialectMap);
        else
            return this.getDialectLanguageMapObservable();
    };
    LanguageService.prototype.getLanguageMapObservable = function () {
        return this.http.get(this.LANGUAGE_API).pipe(map(this.arrayToMap));
    };
    LanguageService.prototype.getDialectLanguageMapObservable = function () {
        return this.http.get(this.LANGUAGE_API + "/dialect").pipe(map(this.arrayToMap));
    };
    LanguageService.prototype.codeToDescription = function (code) {
        var _this = this;
        if (this.codeCacheMap.has(code))
            return observableOf(this.codeCacheMap.get(code));
        return observableMerge(this.getLanguageMap(), this.getDialectLanguageMap()).pipe(filter(function (v) { return v.has(code); }), map(function (v) { return v.get(code); }), map(function (v) {
            _this.codeCacheMap.set(code, v);
            return v;
        }), defaultIfEmpty(code));
    };
    //Maps cannot be simply constructed from a json string, that is why there is a workaround in place
    LanguageService.prototype.arrayToMap = function (a) {
        var map = new Map();
        a
            .map(function (value) { return JSON.parse(value); })
            .forEach(function (v) { return map.set(v.key, v.value); });
        return map;
    };
    return LanguageService;
}());
export { LanguageService };
